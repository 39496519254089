import { getPaginationParamsDefault, isEmpty } from 'core/helpers';
import React, { useEffect, useState } from 'react';
import Select, { CSSObjectWithLabel, GetOptionLabel } from 'react-select';
import { debounce } from 'lodash';

interface DropDownProps {
    mutate?: any;
    labelKey?: string;
    valueKey?: string;
    apiData?: any;
    placeholder?: string;
    isClearable?: boolean;
    isSearchable?: boolean;
    DropdownIndicator?: any;
    ValueContainer?: any;
    onChange: (e: any) => void;
    controlStyles?: CSSObjectWithLabel;
    multiValueStyles?: CSSObjectWithLabel;
    indicatorStyles?: CSSObjectWithLabel;
    valueStyles?: CSSObjectWithLabel;
    optionStyles?: CSSObjectWithLabel;
    dropdownStyles?: CSSObjectWithLabel;
    className?: string;
    classNamePrefix?: string;
    searchFilter?: string;
    width?: number;
    height?: number;
    value?: any;
    loading?: boolean;
    name?: string;
    selectedObject?: { value: string; label: string } | null;
    isMulti?: boolean;
    getOptionLabel?: GetOptionLabel<any> | undefined;
    closeMenuOnSelect?: boolean;
    onMenuOpen?: () => void;
    onMenuClose?: () => void;
    customOptions?: any[];
}

interface DropDownState {
    options?: Array<{ value: string; label: string }>; // Adjust types based on your data structure
    selected?: { value: string; label: string } | null;
    inputValue?: string;
    isLoading: boolean;
    dataPage: number;
    totalPages: number;
}

const DropDown: React.FC<DropDownProps> = ({
    mutate,
    labelKey = 'name',
    valueKey = 'name',
    apiData,
    placeholder = 'Select...',
    isClearable = true,
    isSearchable = true,
    DropdownIndicator,
    ValueContainer,
    onChange,
    controlStyles = {},
    multiValueStyles = {},
    indicatorStyles = {},
    valueStyles = {},
    optionStyles = {},
    dropdownStyles = {},
    className,
    classNamePrefix,
    searchFilter,
    width,
    value,
    height,
    loading,
    name = 'dropdown-select',
    selectedObject,
    isMulti = false,
    getOptionLabel,
    closeMenuOnSelect,
    onMenuOpen,
    onMenuClose,
    customOptions = undefined,
}) => {
    const [state, setState] = useState<DropDownState>({
        options: [],
        selected: selectedObject || null,
        inputValue: '',
        isLoading: false,
        totalPages: 0,
        dataPage: 1,
    });

    const { options, selected, inputValue, isLoading, dataPage, totalPages } = state;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetchOptions = React.useCallback(
        debounce((value) => {
            setState((prev) => ({ ...prev, isLoading: true }));
            mutate?.(
                `${labelKey}=${value}&${getPaginationParamsDefault({
                    pagination: { current: state.dataPage, pageSize: 20 },
                })}`,
                {
                    onSuccess: (response: { data: any }) => {
                        const newData = response?.data || response;
                        const newOptions = newData.map((item: { [x: string]: any }) => ({
                            value: item[valueKey],
                            label: item[labelKey],
                        }));
                        const dataCount = response['x-total-count'] || response['totalSize'] || 0;
                        setState((prev: any) => ({
                            ...prev,
                            isLoading: false,
                            dataPage: 1,
                            options: newOptions,
                            totalPages: dataCount > 20 ? 0 : Math.ceil(dataCount / 20),
                        }));
                    },
                    onError: () => {
                        setState((prev) => ({ ...prev, isLoading: false }));
                    },
                },
            );
        }, 2000),
        [labelKey, valueKey, mutate, state.dataPage],
    );

    useEffect(() => {
        if (!isEmpty(inputValue) && isSearchable && mutate) {
            fetchOptions(inputValue);
        }
    }, [inputValue, fetchOptions, isSearchable, mutate]);

    const getMoreData = () => {
        const apiResult = apiData?.data || apiData;

        if (dataPage <= totalPages && apiResult?.length <= 20) {
            setState((prev) => ({ ...prev, isLoading: true }));
            mutate?.(
                `${searchFilter ? searchFilter + '&' : ''}${getPaginationParamsDefault({
                    pagination: { current: dataPage, pageSize: 20 },
                })}`,
                {
                    onSuccess: (response: { data: any[] }) => {
                        const newData: any = response?.data || response;
                        const newOptions = newData?.map((item: { [x: string]: any }) => ({
                            value: item[valueKey],
                            label: item[labelKey],
                        }));

                        const dataCount = response['x-total-count'] || response['totalSize'] || 0;

                        setState((prev: any) => ({
                            ...prev,
                            options: [...prev.options, ...newOptions],
                            isLoading: false,
                            dataPage: prev.dataPage + 1,
                            totalPages: newData > 20 ? 0 : Math.ceil(dataCount / 20),
                        }));
                    },
                    onError: () => {
                        setState((prev) => ({ ...prev, isLoading: false }));
                    },
                },
            );
        }
    };

    useEffect(() => {
        if (apiData?.length === 0 || !apiData) {
            setState((prev) => ({ ...prev, isLoading: true }));

            mutate?.(
                getPaginationParamsDefault({
                    pagination: {
                        current: 1,
                        pageSize: 20,
                    },
                }),

                {
                    onSuccess: (response: { data: any }) => {
                        const newData = response?.data || response;
                        const newOptions = newData.map((item: { [x: string]: any }) => ({
                            value: item[valueKey],
                            label: item[labelKey],
                        }));
                        const dataCount = response['x-total-count'] || response['totalSize'] || 0;
                        setState((prev: any) => ({
                            ...prev,
                            isLoading: false,
                            dataPage: 1,
                            options: newOptions,
                            totalPages: newData > 20 ? 0 : Math.ceil(dataCount / 20),
                        }));
                    },
                    onError: () => {
                        setState((prev) => ({ ...prev, isLoading: false }));
                    },
                },
            );
            setState((prev) => ({ ...prev, isLoading: false, dataPage: 1 }));
        } else {
            const newOptions = apiData?.data?.map((item: { [x: string]: any }) => ({
                value: item[valueKey],
                label: item[labelKey],
            }));

            setState((prev: any) => ({
                ...prev,
                selected: selectedObject || null,
                options: newOptions,
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mutate]);

    return (
        <div style={{ width: `${width ? `${width}px` : '100%'}` }}>
            <Select
                options={customOptions || options}
                name={name}
                inputId={name}
                isLoading={isLoading || loading}
                onMenuScrollToBottom={getMoreData}
                placeholder={placeholder}
                isClearable={isClearable}
                onInputChange={(value) => setState((prev) => ({ ...prev, inputValue: value }))}
                isSearchable={isSearchable}
                className={className}
                classNamePrefix={classNamePrefix}
                value={value ?? selected}
                onChange={(selected) => {
                    setState((prev: any) => ({ ...prev, selected: selected || null }));
                    onChange(selected);
                }}
                isMulti={isMulti}
                styles={{
                    control: (base) => ({
                        ...base,
                        borderColor: '#E5E5E5',
                        borderWidth: 1,
                        minHeight: height || 48,
                        cursor: 'pointer',
                        background: '#F9F9F9',
                        fontSize: '0.875rem',
                        paddingLeft: '1rem',
                        boxShadow: 'none',
                        width: '100%',
                        ...controlStyles,
                    }),
                    option: (base) => ({
                        ...base,
                        color: '#66666',
                        cursor: 'pointer',
                        fontSize: 12,
                        width: '100%',
                        ...optionStyles,
                    }),
                    indicatorSeparator: (base) => ({
                        ...base,
                        backgroundColor: 'white',
                        display: 'none',
                        ...indicatorStyles,
                    }),
                    multiValueLabel: (base) => ({
                        ...base,
                        color: '#000000',
                        background: 'white',
                        padding: 4,
                        ...multiValueStyles,
                    }),
                    multiValueRemove: (base) => ({
                        ...base,
                        color: '#000000',
                        background: 'white',
                        padding: 4,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        ...multiValueStyles,
                    }),
                    valueContainer: (base) => ({
                        ...base,
                        paddingLeft: '2.5rem',
                        ...valueStyles,
                    }),
                    dropdownIndicator: (base) => ({
                        ...base,
                        paddingLeft: '0rem',
                        paddingRight: '0rem',
                        ...dropdownStyles,
                    }),
                    menu: (provided) => ({
                        ...provided,
                        width: '100%',
                        minWidth: '100%',
                    }),
                }}
                components={{
                    ...(ValueContainer ? { ValueContainer } : {}),
                    ...(DropdownIndicator ? { DropdownIndicator } : {}),
                }}
                getOptionLabel={getOptionLabel}
                closeMenuOnSelect={closeMenuOnSelect}
                onMenuOpen={onMenuOpen}
                onMenuClose={onMenuClose}
            />
        </div>
    );
};

export default DropDown;
