import { publicIpv4 } from 'public-ip';

export const refreshIpAddress = async () => {
  let ipAddress = '';

  try {
   ipAddress = await publicIpv4();
  } catch (error) {
    console.error("Error fetching ipAddress:", error);
  }
  localStorage.setItem('ipAddress', ipAddress);
  return ipAddress;
};


export const startIpRefresh = () => {
    refreshIpAddress(); // Initial call to set the IP address
    setInterval(() => {
      refreshIpAddress();
    }, 10 * 60 * 1000); // Refresh every 10 minutes
  };