import React, {useState} from "react";
import {useFormik} from "formik";
import { Preview } from "../../../../auth/step/Preview";
import * as Yup from 'yup';
import { phoneRegExp } from "core/helpers";
import { NumericFormat } from "react-number-format";
import { envelope, phone } from "core/assets/imgs";
import { useRequestOnboardingStepUserCreationDetails, useRequestActivateUser } from "../../../../auth/hooks/useUser";




const fileTypes = ['JPG', 'PNG', 'GIF'];

const Schema = Yup.object().shape({
  // login: Yup.string()
  //   .required('Login Name is required')
  //   .matches(/^\S*$/, 'Username should not contain spaces').max(50, 'Text field must be at most 50 characters long'),

  firstName: Yup.string().required('First Name is required').max(50, 'Text field must be at most 50 characters long'),
  lastName: Yup.string().required('Last Name is required').max(50, 'Text field must be at most 50 characters long'),
  email: Yup.string().email('Wrong email format').required('Email is required').max(50, 'Text field must be at most 50 characters long'),
  // termsCondtion: Yup.string().required('Remember is required'),
  phoneNumber: Yup.string()
    .required('Phone Number is required')
    .matches(phoneRegExp, 'Phone number is not valid'),
});
let stepJson: any = [];
let stepOneJson;

interface Step1Props {
  handleNextStep: () => void;
  setUserLogin: any;
  setStepOneData: any
}



const StepOne: React.FC<Step1Props> = ({ handleNextStep, setUserLogin, setStepOneData }) => {

    const { data, mutate, isLoading } =
      useRequestOnboardingStepUserCreationDetails();
  
      const { data: dataActivate, mutate: mutateActivate, isLoading: isLoadingActivate } =
    useRequestActivateUser();

  const initialValues = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    login: ""
};




 

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values) => {
      setUserLogin(values.login)


      setStepOneData(   {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          password: '12345',
          phoneNumber: values.phoneNumber,
          login: values.email,
      })
      
       handleNextStep();

      // mutate(
      //   {
      //     firstName: values.firstName,
      //     lastName: values.lastName,
      //     email: values.email,
      //     password: '12345',
      //     phoneNumber: values.phoneNumber,
      //     login: values.login,
      //   },

      //   {
      //     onSuccess: (res: any) => {
      //    sessionStorage.setItem('login', values.login);
      //       handleNextStep();
      //       // mutateActivate(values.email)
      //     },
      //   }
      // );
    },
  });


 


  return (
    <>
      <div className={"userDetails"}>
                              <form onSubmit={formik.handleSubmit}>
        <div className="mt-1">
          <div className="col-lg-4 col-form-label py-0 input-height">
            {formik.values.firstName.length !== 0 && (
              <label className="required fw-bold fs-9">First Name * </label>
            )}
          </div>

          <div className="form-control form-control-solid  ">
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.75586 20.0308C2.54865 20.3895 2.67143 20.8482 3.03009 21.0554C3.38875 21.2626 3.84747 21.1398 4.05468 20.7812L2.75586 20.0308ZM20.9455 20.7813C21.1527 21.14 21.6115 21.2628 21.9701 21.0556C22.3288 20.8484 22.4516 20.3897 22.2444 20.031L20.9455 20.7813ZM4.05468 20.7812C4.91093 19.2991 6.14212 18.0684 7.62457 17.2129L6.87476 15.9137C5.16433 16.9009 3.74379 18.3208 2.75586 20.0308L4.05468 20.7812ZM7.62457 17.2129C9.10702 16.3573 10.7885 15.9068 12.5002 15.9069L12.5002 14.4069C10.5253 14.4068 8.5852 14.9265 6.87476 15.9137L7.62457 17.2129ZM12.5002 15.9069C14.2118 15.9069 15.8933 16.3573 17.3757 17.213L18.1255 15.9138C16.4151 14.9266 14.475 14.4069 12.5002 14.4069L12.5002 15.9069ZM17.3757 17.213C18.8581 18.0686 20.0893 19.2993 20.9455 20.7813L22.2444 20.031C21.2565 18.321 19.836 16.901 18.1255 15.9138L17.3757 17.213ZM17.7501 9.15686C17.7501 12.0564 15.3996 14.4069 12.5002 14.4069V15.9069C16.2281 15.9069 19.2501 12.8848 19.2501 9.15686H17.7501ZM12.5002 14.4069C9.60065 14.4069 7.25005 12.0563 7.25005 9.15686H5.75005C5.75005 12.8848 8.77226 15.9069 12.5002 15.9069V14.4069ZM7.25005 9.15686C7.25005 6.25736 9.60056 3.90686 12.5001 3.90686V2.40686C8.77213 2.40686 5.75005 5.42894 5.75005 9.15686H7.25005ZM12.5001 3.90686C15.3995 3.90686 17.7501 6.25737 17.7501 9.15686H19.2501C19.2501 5.42894 16.228 2.40686 12.5001 2.40686V3.90686Z"
                fill="#CCCCCC"
              />
            </svg>

            <input
              type="text"
              placeholder="First Name * "
              className={'no-outline ps-4 text-dark fs-8'}
              {...formik.getFieldProps('firstName')}
            />
          </div>

          <div className="fv-plugins-message-container input-height">
            <div className="fv-help-block fs-9" style={{ color: 'red' }}>
              {formik.touched.firstName &&
                formik.errors.firstName &&
                formik.errors.firstName}
            </div>
          </div>
        </div>

        <div className="mt-1">
          <div className="col-lg-4 col-form-label py-0 input-height">
            {formik.values.lastName.length !== 0 && (
              <label className="required fw-bold fs-9">Last Name</label>
            )}
          </div>

          <div className="form-control form-control-solid  ">
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.75586 20.0308C2.54865 20.3895 2.67143 20.8482 3.03009 21.0554C3.38875 21.2626 3.84747 21.1398 4.05468 20.7812L2.75586 20.0308ZM20.9455 20.7813C21.1527 21.14 21.6115 21.2628 21.9701 21.0556C22.3288 20.8484 22.4516 20.3897 22.2444 20.031L20.9455 20.7813ZM4.05468 20.7812C4.91093 19.2991 6.14212 18.0684 7.62457 17.2129L6.87476 15.9137C5.16433 16.9009 3.74379 18.3208 2.75586 20.0308L4.05468 20.7812ZM7.62457 17.2129C9.10702 16.3573 10.7885 15.9068 12.5002 15.9069L12.5002 14.4069C10.5253 14.4068 8.5852 14.9265 6.87476 15.9137L7.62457 17.2129ZM12.5002 15.9069C14.2118 15.9069 15.8933 16.3573 17.3757 17.213L18.1255 15.9138C16.4151 14.9266 14.475 14.4069 12.5002 14.4069L12.5002 15.9069ZM17.3757 17.213C18.8581 18.0686 20.0893 19.2993 20.9455 20.7813L22.2444 20.031C21.2565 18.321 19.836 16.901 18.1255 15.9138L17.3757 17.213ZM17.7501 9.15686C17.7501 12.0564 15.3996 14.4069 12.5002 14.4069V15.9069C16.2281 15.9069 19.2501 12.8848 19.2501 9.15686H17.7501ZM12.5002 14.4069C9.60065 14.4069 7.25005 12.0563 7.25005 9.15686H5.75005C5.75005 12.8848 8.77226 15.9069 12.5002 15.9069V14.4069ZM7.25005 9.15686C7.25005 6.25736 9.60056 3.90686 12.5001 3.90686V2.40686C8.77213 2.40686 5.75005 5.42894 5.75005 9.15686H7.25005ZM12.5001 3.90686C15.3995 3.90686 17.7501 6.25737 17.7501 9.15686H19.2501C19.2501 5.42894 16.228 2.40686 12.5001 2.40686V3.90686Z"
                fill="#CCCCCC"
              />
            </svg>

            <input
              type="text"
              placeholder="Last Name * "
              className={'no-outline ps-4 text-dark fs-8'}
              {...formik.getFieldProps('lastName')}
            />
          </div>

          <div className="fv-plugins-message-container input-height">
            <div className="fv-help-block fs-9" style={{ color: 'red' }}>
              {formik.touched.lastName &&
                formik.errors.lastName &&
                formik.errors.lastName}
            </div>
          </div>
        </div>

        {/* <div className="mt-1">
          <div className="col-lg-4 col-form-label py-0 input-height">
            {formik.values.login.length !== 0 && (
              <label className="required fw-bold fs-9">User Name</label>
            )}
          </div>

          <div className="form-control form-control-solid  ">
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.75586 20.0308C2.54865 20.3895 2.67143 20.8482 3.03009 21.0554C3.38875 21.2626 3.84747 21.1398 4.05468 20.7812L2.75586 20.0308ZM20.9455 20.7813C21.1527 21.14 21.6115 21.2628 21.9701 21.0556C22.3288 20.8484 22.4516 20.3897 22.2444 20.031L20.9455 20.7813ZM4.05468 20.7812C4.91093 19.2991 6.14212 18.0684 7.62457 17.2129L6.87476 15.9137C5.16433 16.9009 3.74379 18.3208 2.75586 20.0308L4.05468 20.7812ZM7.62457 17.2129C9.10702 16.3573 10.7885 15.9068 12.5002 15.9069L12.5002 14.4069C10.5253 14.4068 8.5852 14.9265 6.87476 15.9137L7.62457 17.2129ZM12.5002 15.9069C14.2118 15.9069 15.8933 16.3573 17.3757 17.213L18.1255 15.9138C16.4151 14.9266 14.475 14.4069 12.5002 14.4069L12.5002 15.9069ZM17.3757 17.213C18.8581 18.0686 20.0893 19.2993 20.9455 20.7813L22.2444 20.031C21.2565 18.321 19.836 16.901 18.1255 15.9138L17.3757 17.213ZM17.7501 9.15686C17.7501 12.0564 15.3996 14.4069 12.5002 14.4069V15.9069C16.2281 15.9069 19.2501 12.8848 19.2501 9.15686H17.7501ZM12.5002 14.4069C9.60065 14.4069 7.25005 12.0563 7.25005 9.15686H5.75005C5.75005 12.8848 8.77226 15.9069 12.5002 15.9069V14.4069ZM7.25005 9.15686C7.25005 6.25736 9.60056 3.90686 12.5001 3.90686V2.40686C8.77213 2.40686 5.75005 5.42894 5.75005 9.15686H7.25005ZM12.5001 3.90686C15.3995 3.90686 17.7501 6.25737 17.7501 9.15686H19.2501C19.2501 5.42894 16.228 2.40686 12.5001 2.40686V3.90686Z"
                fill="#CCCCCC"
              />
            </svg>
            <input
              type="text"
              placeholder="User Name * "
              className={'no-outline ps-4 text-dark fs-8'}
              {...formik.getFieldProps('login')}
            />
          </div>

          <div className="fv-plugins-message-container input-height">
            <div className="fv-help-block fs-9" style={{ color: 'red' }}>
              {formik.touched.login &&
                formik.errors.login &&
                formik.errors.login}
            </div>
          </div>
        </div> */}

        <div className="mt-1">
          <div className="col-lg-4 col-form-label py-0 input-height">
            {formik.values.phoneNumber.length !== 0 && (
              <label className="required fw-bold fs-9">Phone Number</label>
            )}
          </div>

          <div className={`form-control form-control-solid  d-flex`}>
            <img src={phone} alt="fullName" className="icon-style" />
            <NumericFormat
              type="text"
              className="no-outline ps-2 text-dark fs-8"
              {...formik.getFieldProps('phoneNumber')}
              placeholder=" Phone Number*"
              allowNegative={false}
              allowLeadingZeros={true}
            />
          </div>

          <div
            className="fv-plugins-message-container input-height"
            style={{ color: 'red' }}
          >
            <div className="fv-help-block fs-9">
              {formik.touched.phoneNumber &&
                formik.errors.phoneNumber &&
                formik.errors.phoneNumber}
            </div>
          </div>
        </div>

        <div className="mt-1">
          <div className="col-lg-4 col-form-label py-0 input-height">
            {formik.values.email.length !== 0 && (
              <label className="required fw-bold fs-9">Email</label>
            )}
          </div>

          <div className="form-control form-control-solid  ">
            <img src={envelope} alt="firstName" className="icon-style" />

            <input
              type="email"
              placeholder="Email * "
              className={'no-outline ps-4 text-dark fs-8'}
              {...formik.getFieldProps('email')}
            />
          </div>

          <div className="fv-plugins-message-container input-height">
            <div className="fv-help-block fs-9" style={{ color: 'red' }}>
              {formik.touched.email &&
                formik.errors.email &&
                formik.errors.email}
            </div>
          </div>
        </div>

   

          <div className={"userDetailsNextButton"}>
                                {/* <button
                                     className="text-success fw-bolder  fs-base text-center cursor-pointer"
                             
                                         type="submit"
                                >
                                    Next
                                </button> */}
            

            <button
                id="kt_password_cancel "
                type="submit"
                className={`btn nav-btn-Proceed ${
                  !(formik.isValid && formik.dirty )
                    ? 'btn-secondary'
                    : 'btn-success'
                } text-white px-7 py-2`}
                disabled={!(formik.isValid && formik.dirty )}
                // onClick={(e) => handleNextStep(e)}
              >
                {!isLoading && <span className="indicator-label">Proceed</span>}
                {isLoading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Please wait...{' '}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
                                </div>
      </form>
                        </div>
    </>
  );
};

export { StepOne };
