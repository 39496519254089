import React from 'react';
import { arrowRight } from '../../../core/assets/imgs';

const Service = () => {
  return (
    <div id="benefits">
      <section className="section bg-grey">
        <div className="section-title section-center">
          <span className="section-title-header fs-2x">Benefits</span>
          {/* <div className="underline"></div> */}
          <span className="section-title-body fs-base">
            The Federal Government of Nigeria is committed to ensuring the
            efficient management of public funds, <br />
            improving financial control, facilitating greater transparency, and
            promoting better service delivery
          </span>
        </div>

        <div className="services-center section-center ">
          <article className="service text-start fs-bas">
            <svg
              width="44"
              height="44"
              viewBox="0 0 44 44"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="44" height="44" rx="5" fill="#D2E7CD" />
              <path
                d="M30.25 17.5C30.25 19.9853 26.5563 22 22 22C17.4437 22 13.75 19.9853 13.75 17.5M30.25 17.5C30.25 15.0147 26.5563 13 22 13C17.4437 13 13.75 15.0147 13.75 17.5M30.25 17.5V22M13.75 17.5V22M13.75 22C13.75 24.4853 17.4437 26.5 22 26.5C26.5563 26.5 30.25 24.4853 30.25 22M13.75 22V26.5C13.75 28.9853 17.4437 31 22 31C26.5563 31 30.25 28.9853 30.25 26.5V22"
                stroke="#1F8505"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <span className="service-content-header ">Central Repository</span>
            {/* <div className="underline"></div> */}
            <span className="service-content-body">
              The FGN Treasury Portal serves as a central repository of
              disaggregated banking data for all TSA collections and payments
              transactions, thereby ensuring a complete view of the nation’s
              financial position
            </span>
          </article>

          <article className="service text-start fs-bas">
            <svg
              width="44"
              height="44"
              viewBox="0 0 44 44"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="44" height="44" rx="5" fill="#D2E7CD" />
              <path
                d="M31.375 29.5H12.625M14.125 29.5V22.75H19.375M19.375 29.5V18.25H24.625M24.625 13.75H29.875V29.5H24.625V13.75Z"
                stroke="#1F8505"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <span className="service-content-header ">
              Comprehensive Reporting
            </span>
            {/* <div className="underline"></div> */}
            <span className="service-content-body">
              Government entities now have access to comprehensive reporting of
              MDA cash positions, and can easily download detailed bank
              statements to aid seamless reconciliation activities.
            </span>
          </article>

          <article className="service text-start fs-bas">
            <svg
              width="44"
              height="44"
              viewBox="0 0 44 44"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="44" height="44" rx="5" fill="#D2E7CD" />
              <path
                d="M31 29.5H13V14.5M29.5002 16L22.0002 23.5L19.0002 20.5L13.0002 26.5M29.5002 16V19.75M29.5002 16H25.7502"
                stroke="#1F8505"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <span className="service-content-header ">Fiscal Insights</span>
            {/* <div className="underline"></div> */}
            <span className="service-content-body">
              The Portal provides dashboards and analytics to help Government
              better manage and optimize its funds via automated insights to aid
              fiscal policy formulation and budgetary decision-making.
            </span>
          </article>
        </div>

        <div className="d-flex  align-items-center  justify-content-center">
          <button className="btn btn-success rounded-1 home-btn mt-1">
            <span className="fs-5">
              Get Started
              <img
                src={arrowRight}
                className="icon-style ms-2"
                alt="arrowRight"
              />
            </span>
          </button>
        </div>
      </section>
    </div>
  );
};

export { Service };
