import React, {useState} from 'react';
import '../../../../../App.css';
import {useNavigate} from "react-router-dom";




const TaxPayments = () => {
    const [selectedOption, setSelectedOption] = useState("vat");

    const navigate = useNavigate();

    const handleSubmit = () => {
        if (selectedOption === "vat") {
            navigate("/admin/payment/tax-payments/vat");
        } else if (selectedOption === "wht") {
            navigate("/admin/payment/tax-payments/wht");
        } else if (selectedOption === "stamp-duty") {
            navigate("/admin/payment/tax-payments/stamp-duty");
        } else if (selectedOption === "paye") {
            navigate("/admin/payment/tax-payments/paye");
        }else if (selectedOption === "contract") {
            navigate("/admin/payment/contract");
        }
    };


    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };


    return (
        <>
            <div className={'taxPayments'}>
                <div className={'taxPaymentsBreadcrumb'}>
                    <div>Payments</div>
                    <svg
                        width="10"
                        height="13"
                        viewBox="0 0 10 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                            fill="#1F8505"
                        />
                    </svg>
                    <div>Tax Payments</div>
                </div>

                <div className={'taxPaymentsRow1'}>
                    <div className={'makeTaxPaymentText'}>Make Tax Payment</div>

                    <div className={'chooseTaxTypeText'}>Choose tax type</div>

                    <div className={'taxPaymentType'}>
                        <div>
                            <label
                                style={{
                                    backgroundColor: selectedOption === "vat" ? '#EDF9F1' : '#FFFFFF',
                                    border: selectedOption === "vat" ? '1px solid #1F8505' : '1px solid #CCCCCC',
                                }}
                                onClick={() => {
                                    if (selectedOption !== "vat") {
                                        setSelectedOption("vat");
                                    }
                                }}
                            >
                                <div className={'inputDiv'}>
                                    <input
                                        style={{
                                            marginRight: '15px',
                                        }}
                                        type="radio"
                                        value="vat"
                                        checked={selectedOption === "vat"}
                                        onChange={handleOptionChange}
                                    />
                                </div>

                                <div>
                                    <div>
                                        {selectedOption === "vat" ? (
                                            <div>
                                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M2.50076 32.5H37.5008M5.00076 27.5H35.0008M31.2508 15V27.5M23.7508 15V27.5M16.2508 15V27.5M8.75076 15V27.5M3.75069 15H36.2507L20.0007 5L3.75069 15Z" stroke="#1F8505" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </div>
                                        ) : (
                                            <div>
                                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M2.50076 32.5H37.5008M5.00076 27.5H35.0008M31.2508 15V27.5M23.7508 15V27.5M16.2508 15V27.5M8.75076 15V27.5M3.75069 15H36.2507L20.0007 5L3.75069 15Z" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </div>
                                        )}
                                    </div>

                                    <div className={'taxType'}
                                         style={{
                                             fontWeight: selectedOption === "vat" ? 500 : 400,
                                             color: selectedOption === "vat" ? '#1F8505' : '#666666',
                                         }}
                                    >
                                        Value Added Tax (VAT)
                                    </div>
                                </div>
                            </label>
                        </div>

                        <div>
                            <label
                                style={{
                                    backgroundColor: selectedOption === "wht" ? '#EDF9F1' : '#FFFFFF',
                                    border: selectedOption === "wht" ? '1px solid #1F8505' : '1px solid #CCCCCC',
                                }}
                                onClick={() => {
                                    if (selectedOption !== "wht") {
                                        setSelectedOption("wht");
                                    }
                                }}
                            >
                                <div className={'inputDiv'}>
                                    <input
                                        style={{
                                            marginRight: '15px',
                                        }}
                                        type="radio"
                                        value="wht"
                                        checked={selectedOption === "wht"}
                                        onChange={handleOptionChange}
                                    />
                                </div>

                                <div>
                                    <div>
                                        {selectedOption === "wht" ? (
                                            <div>
                                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M2.50076 32.5H37.5008M5.00076 27.5H35.0008M31.2508 15V27.5M23.7508 15V27.5M16.2508 15V27.5M8.75076 15V27.5M3.75069 15H36.2507L20.0007 5L3.75069 15Z" stroke="#1F8505" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </div>
                                        ) : (
                                            <div>
                                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M2.50076 32.5H37.5008M5.00076 27.5H35.0008M31.2508 15V27.5M23.7508 15V27.5M16.2508 15V27.5M8.75076 15V27.5M3.75069 15H36.2507L20.0007 5L3.75069 15Z" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </div>
                                        )}
                                    </div>

                                    <div className={'taxType'}
                                         style={{
                                             fontWeight: selectedOption === "wht" ? 500 : 400,
                                             color: selectedOption === "wht" ? '#1F8505' : '#666666',
                                         }}
                                    >
                                        Withholding Tax (WHT)
                                    </div>
                                </div>
                            </label>
                        </div>
                    </div>

                    <div className={'taxPaymentType'} style={{paddingTop: '20px'}}>
                        <div>
                            <label
                                style={{
                                    backgroundColor: selectedOption === "paye" ? '#EDF9F1' : '#FFFFFF',
                                    border: selectedOption === "paye" ? '1px solid #1F8505' : '1px solid #CCCCCC',
                                }}
                                onClick={() => {
                                    if (selectedOption !== "paye") {
                                        setSelectedOption("paye");
                                    }
                                }}
                            >
                                <div className={'inputDiv'}>
                                    <input
                                        style={{
                                            marginRight: '15px',
                                        }}
                                        type="radio"
                                        value="paye"
                                        checked={selectedOption === "paye"}
                                        onChange={handleOptionChange}
                                    />
                                </div>

                                <div>
                                    <div>
                                        {selectedOption === "paye" ? (
                                            <div>
                                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M2.50076 32.5H37.5008M5.00076 27.5H35.0008M31.2508 15V27.5M23.7508 15V27.5M16.2508 15V27.5M8.75076 15V27.5M3.75069 15H36.2507L20.0007 5L3.75069 15Z" stroke="#1F8505" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </div>
                                        ) : (
                                            <div>
                                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M2.50076 32.5H37.5008M5.00076 27.5H35.0008M31.2508 15V27.5M23.7508 15V27.5M16.2508 15V27.5M8.75076 15V27.5M3.75069 15H36.2507L20.0007 5L3.75069 15Z" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </div>
                                        )}
                                    </div>

                                    <div className={'taxType'}
                                         style={{
                                             fontWeight: selectedOption === "paye" ? 500 : 400,
                                             color: selectedOption === "paye" ? '#1F8505' : '#666666',
                                         }}
                                    >
                                        Pay-As-You-Earn (PAYE)
                                    </div>
                                </div>
                            </label>
                        </div>

                        {/* <div>
                            <label
                                style={{
                                    backgroundColor: selectedOption === "paye" ? '#EDF9F1' : '#FFFFFF',
                                    border: selectedOption === "paye" ? '1px solid #1F8505' : '1px solid #CCCCCC',
                                }}
                                onClick={() => {
                                    if (selectedOption !== "paye") {
                                        setSelectedOption("paye");
                                    }
                                }}
                            >
                                <div className={'inputDiv'}>
                                    <input
                                        style={{
                                            marginRight: '15px',
                                        }}
                                        type="radio"
                                        value="paye"
                                        checked={selectedOption === "paye"}
                                        onChange={handleOptionChange}
                                    />
                                </div>

                                <div>
                                    <div>
                                        {selectedOption === "paye" ? (
                                                <div>
                                                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M2.50076 32.5H37.5008M5.00076 27.5H35.0008M31.2508 15V27.5M23.7508 15V27.5M16.2508 15V27.5M8.75076 15V27.5M3.75069 15H36.2507L20.0007 5L3.75069 15Z" stroke="#1F8505" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                </div>
                                        ) : (
                                            <div>
                                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M2.50076 32.5H37.5008M5.00076 27.5H35.0008M31.2508 15V27.5M23.7508 15V27.5M16.2508 15V27.5M8.75076 15V27.5M3.75069 15H36.2507L20.0007 5L3.75069 15Z" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </div>
                                        )}
                                    </div>

                                    <div className={'taxType'}
                                    style={{
                                        fontWeight: selectedOption === "paye" ? 500 : 400,
                                        color: selectedOption === "paye" ? '#1F8505' : '#666666',
                                    }}
                                    >
                                        Pay-As-You-Earn (PAYE)
                                    </div>
                                </div>
                            </label>
                        </div> */}








                          <div className={'taxPaymentType'} >
                       <div>
                           <label
                               style={{
                                   backgroundColor: selectedOption === "stamp-duty" ? '#EDF9F1' : '#FFFFFF',
                                   border: selectedOption === "stamp-duty" ? '1px solid #1F8505' : '1px solid #CCCCCC',
                               }}
                               onClick={() => {
                                   if (selectedOption !== "stamp-duty") {
                                       setSelectedOption("stamp-duty");
                                   }
                               }}
                           >
                               <div className={'inputDiv'}>
                                   <input
                                       style={{
                                           marginRight: '15px',
                                       }}
                                       type="radio"
                                       value="stamp-duty"
                                       checked={selectedOption === "stamp-duty"}
                                       onChange={handleOptionChange}
                                   />
                               </div>

                               <div>
                                   <div>
                                       {selectedOption === "contract" ? (
                                               <div>
                                                   <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                       <path d="M2.50076 32.5H37.5008M5.00076 27.5H35.0008M31.2508 15V27.5M23.7508 15V27.5M16.2508 15V27.5M8.75076 15V27.5M3.75069 15H36.2507L20.0007 5L3.75069 15Z" stroke="#1F8505" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                   </svg>
                                               </div>
                                       ) : (
                                           <div>
                                               <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                   <path d="M2.50076 32.5H37.5008M5.00076 27.5H35.0008M31.2508 15V27.5M23.7508 15V27.5M16.2508 15V27.5M8.75076 15V27.5M3.75069 15H36.2507L20.0007 5L3.75069 15Z" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                               </svg>
                                           </div>
                                       )}
                                   </div>

                                   <div className={'taxType'}
                                   style={{
                                       fontWeight: selectedOption === "stamp-duty" ? 500 : 400,
                                       color: selectedOption === "stamp-duty" ? '#1F8505' : '#666666',
                                   }}
                                   >
                                     Stamp Duty
                                   </div>
                               </div>
                           </label>
                       </div>
                   </div>
                    </div>

                 

                    <div className={'taxProceedButton'}>
                        <button onClick={handleSubmit}>
                            Proceed
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TaxPayments;
