import { useFormik } from "formik";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import {
  arrowDown,
  arrowRight,
  arrowUp,
  envelope,
} from "core/assets/imgs";
import { AboutPage } from "app/components/about";
import { Footer } from "app/components/footer";
import NavBar from "app/components/nav/NavBar";
import { Service } from "app/components/service";
import { useRequestSubscription } from "../payments/hooks/useRevenuePayment";
import { UserHero } from "app/components/Hero.tsx/userHero";
import { UserAboutPage } from "app/components/about/UserAbout";
import { chat } from "core/assets/icons";

const profileDetailsSchema = Yup.object().shape({
  email: Yup.string().email("Wrong email format").required("Email is required"),
});

const initialValues = {
  email: "",
};

const UserStory: React.FC = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>(initialValues);
  const [show, setShow] = useState<any>(false);

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const [arrow, setArrow] = useState(true);
  const [FaqNo, setFaqNo] = useState<Number>(0);
  const handleFaq = (index: Number) => {
    if (index == FaqNo && arrow) {
      setArrow(false);
    } else {
      setArrow(true);
      setFaqNo(index);
    }
  };

  const done = () => {
    navigate("/payment");
  };

  const { data: subData, mutate, isLoading } = useRequestSubscription();

  // useEffect(() => {
  //   if (subData) {
  //     setShow(true);
  //   }
  // }, [mutate, subData]);

  const formik = useFormik({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: (values) => {
     //console.log(values);

      mutate(values);

      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    },
  });

  const FloatingButton = () => {
    return (
      <div className="floating-button-container ">
        <button className="floating-button">
          <img src={chat} alt="" className="w-25e h-25e" />
        </button>
      </div>
    );
  };

  const PageModal = () => {
    return (
      <Modal
        dialogClassName=" m-auto px-9 modal-card"
        aria-hidden="true"
        show={show}
        onHide={() => setShow(false)}
        animation
        centered
        backdrop={"static"}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="rounded">
          <div className="d-flex flex-column align-items-center m-10">
            <article className="about-img d-flex justify-content-center align-items-center">
              <iframe
                className="hero-photo-video"
                width="90%"
                height="304px"
                src="https://www.youtube.com/embed/zH1HtWW_3-Q"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; "
              ></iframe>
            </article>
          </div>
        </Modal.Body>
      </Modal>
    );
  };
  const faq = [
  {
      title: "Why use TMS?",
      desc: "The Treasury Portal is an application that provides ease of payments for government services through multiple payment channels for your convenience.",
    },
    {
      title: "How to pay for school fees?",
      desc: "To make fees payment or any other payment, please go to Revenue Payment, search for the MDA and select service.",
    },
    {
      title: "Do I have to register to use TMS?",
      desc: "No. You can go ahead to make payment for a services through the provided options without registering. Once you have made your first payment, you can carry out subsequent payment using same details.",
    },
  ];
  return (
    <div>
      <NavBar />
      <UserHero />
      <UserAboutPage />

      <div className="py-20 w-100 px-5" id="help">
        <div className="help-center-section">
          <div className="d-flex flex-column align-items-center">
            <span className="help-center-bold-text">
              Frequently Asked Questions ?
              <span className="help-center-normal-text mt-1 fs-base">
                Everything you need to know about the portal. <br />
                Can’t find what you are looking for?{" "}
                <span className="help-contact">Contact Support</span>
              </span>
            </span>
          </div>
          <div>
            {faq.map((value, index) => {
              return (
                <div key={index}>
                  <div
                    className={`border-bottom border-gray-800 faq-header ${
                      arrow && FaqNo !== index ? "bg-white" : ""
                    }`}
                    onClick={() => handleFaq(index)}
                  >
                    <span className="faq-header-text ">{value.title}</span>
                    {arrow && FaqNo === index ? (
                      <span>
                        <img src={arrowUp} />
                      </span>
                    ) : (
                      <span>
                        <img src={arrowDown} />
                      </span>
                    )}
                  </div>

                  {arrow && FaqNo === index && (
                    <div className="faq-body">{value?.desc}</div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className=" d-flex align-items-center  justify-content-center mt-20">
          <button
            className="btn btn-success rounded-1 userHome-btn "
            onClick={done}
          >
            <span className="fs-6">
              Pay Now
              <img
                src={arrowRight}
                className="icon-style ms-2"
                alt="arrowRight"
              />
            </span>
          </button>
        </div>
      </div>

      <div className="section bg-grey">
        <div className="section-center d-flex flex-column align-items-center  justify-content-center">
          <div className="help-center-hero">
            <div className="footer--center-bottom">
              <div className="foot-news-letter">
                <div className="right-side">
                  <div>
                    <span className="subcriber-pool">Be inspired</span>
                    <span className="subcriber-pool-body">
                      Stay up to date with everything you need to know.
                    </span>
                  </div>
                </div>
                <div className="news-letter-container">
                  <div>
                    <span className="span-header mb-2">Join us</span>
                  </div>
                  <form
                    onSubmit={formik.handleSubmit}
                    noValidate
                    className="form align-middle  gx-0 gy-5 mt-5"
                  >
                    <div className="col-12  flex-small align-items-center ">
                      <div className="flex-col">
                        <div className="form-control form-control-solid ">
                          <img
                            src={envelope}
                            alt="Email"
                            className="icon-style"
                          />
                          <input
                            type="text"
                            placeholder="Email"
                            className={"no-outline ps-4 text-dark fs-8"}
                            {...formik.getFieldProps("email")}
                          />
                        </div>
                      </div>
                      <div className="flex-subscribe">
                        <button
                          className="btn btn-footer py-3 px-8"
                          type="submit"
                        >
                          {!isLoading && (
                            <span className="indicator-label text-dark fs-6 fw-bold">
                              Subscribe
                            </span>
                          )}
                          {isLoading && (
                            <span className="indicator-progress">
                              Subscribe...
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                  <span className="subscribe-policy-body mobile-center">
                    We care about your data in our
                    <span className="text-success underline-success ms-1">
                      Privacy Policy
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {PageModal()}
      {FloatingButton()}
    </div>
  );
};

export { UserStory };
