import { AxiosResponse } from "axios";
import { axiosInstance2 } from "axiosInstance";

export async function getProjectSummary(): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance2.get(
    `/services/organization/api/projects/summary`
  );
  return data;
}

export async function editProjectDetails(values): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance2.patch(
    `/services/organization/api/projects/${values.id}`,
    values
  );
  return data;
}

export async function updateProjectStatus(values): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance2.patch(
    `/services/organization/api/projects?id=${values.id}&status=${values.status}`,
  );
  return data;
}
export async function getProjectsById(id: string): Promise<any | null> {
  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.get(
    `/services/organization/api/projects/${id}`
  );
  return data;
}

export async function getProjects(filter: string): Promise<any | null> {
  const { data, headers }: AxiosResponse<{ user: any }> =
    await axiosInstance2.get(`/services/organization/api/projects?${filter}`);
  return { data, totalSize: headers["x-total-count"] };
}
