import DropDown from 'app/components/dropdown';
import { NumericInput } from 'app/components/formInputs/NumericInput';
import { money, notePencil, service, userCircle } from 'core/assets/imgs';
import { useEffect, useRef, useState } from 'react';
import {
    DropdownIndicator,
    MdaValueContainer,
    ValueContainer,
    getOptionLabel,
    getOptions,
    handlePartValueChange,
    isAllowedNumeric,
    onChangeCustomFields,
    searchIndicator,
} from './UsefulFn';
import { InputField } from 'app/components/formInputs/InputFields';
import { InputForm } from 'app/components/formInputs/InputForm';

interface Step1Props {
    formik: any;
    isUpdating: boolean;
    paramsData: boolean;
    GetServicesList: (e: any) => void;
    location: any;
    mdaList: any;
    servicePaymComps: any;
    setAmount: any;
    done: any;
    serviceList: any;
    handleChange: (e: any) => void;
    currentService: any;
    selectedOptions: any;
    priceFixed: boolean;
    updateAmount: (name: string, newAmount: any) => void;
    customFieldsData: any;
    customFields: any;
    partLock: boolean;
    disableAmount: boolean;
    totalAmount: any;
    setCustomFields: (e: any) => void;
    disableNext: boolean;
    fetchService: boolean;
    setPartAmount: (e: any) => void;
    partAmount: any;
    priceOptions: any;
    isMenuOpen: any;
    setIsMenuOpen: (e: any) => void;
    mutate: any;
}

const Step1: React.FC<Step1Props> = ({
    formik,
    paramsData,
    GetServicesList,
    location,
    mdaList,
    servicePaymComps,
    setAmount,
    isUpdating,
    done,
    serviceList,
    handleChange,
    currentService,
    selectedOptions,
    priceFixed,
    updateAmount,
    customFieldsData,
    customFields,
    partLock,
    disableAmount,
    totalAmount,
    setCustomFields,
    disableNext,
    fetchService,
    partAmount,
    setPartAmount,
    priceOptions,
    isMenuOpen,
    setIsMenuOpen,
    mutate,
}) => {
    const ref = useRef<any>(null);

    const handleMenuClose = () => {
        setIsMenuOpen(false);
    };

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            handleMenuClose();
        }
    };

    const defaultSelectedDropdown = {
        value: formik.values.mda,
        label: mdaList?.data?.find((option) => option.id === formik.values.mda)?.name || '',
    };

    const [selectedDropdown, setSelectedDropdown] = useState(formik.values.mda ? defaultSelectedDropdown : null);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getLabel = (array: any) => {
        const label = array?.map((item: { description: any; code: any }) => {
            var demo = {
                value: item?.code,
                label: item?.description,
            };
            return demo;
        });
        return label;
    };
    return (
        <div className="card m-auto p-11 responsive-card">
            <span className="text-dark fs-3 fw-bolder  text-center">Service Payment</span>
            <form onSubmit={formik.handleSubmit} noValidate className="form align-middle  gx-0 gy-5 mt-3">
                <div>
                    <div className={`mt-1  ${paramsData ? 'opacity-75' : ''}`}>
                        <div className="col-lg-4 col-form-label py-0 input-height">
                            {formik.values.mda.length !== 0 && (
                                <label className="required fw-bold fs-9">Select MDA</label>
                            )}
                        </div>

                        {paramsData ? (
                            <div className={`form-control form-control-solid`}>
                                <img
                                    src={userCircle}
                                    alt="Select MDA "
                                    className="icon-style"
                                    style={{ color: 'red' }}
                                />
                                <select
                                    className={`no-outline ps-4 fs-8 ${
                                        formik.values.mda !== '' ? 'text-dark' : 'text-muted'
                                    } `}
                                    disabled={paramsData}
                                    onChange={(selected: any) => GetServicesList(selected.target.value)}
                                    defaultValue={location?.state?.mdaId}
                                >
                                    <option value="">Select MDA</option>
                                    <option value={`${location?.state?.mdaId}`}>{location?.state?.mdaName}</option>
                                </select>
                            </div>
                        ) : (
                            <DropDown
                                labelKey={'name'}
                                valueKey={'id'}
                                mutate={mutate}
                                apiData={mdaList}
                                DropdownIndicator={searchIndicator}
                                ValueContainer={MdaValueContainer}
                                onChange={(e: any) => {
                                    GetServicesList(e ? e?.value : null);
                                    setSelectedDropdown(e);
                                }}
                                isClearable
                                isSearchable
                                placeholder={'Search MDA *'}
                                className=""
                                classNamePrefix="fs-8"
                                name="select-mda"
                                selectedObject={selectedDropdown}
                            />
                        )}

                        <div className="fv-plugins-message-container input-height">
                            <div className="fv-help-block fs-9 text-danger">
                                {!paramsData && formik.errors.mda && formik.errors.mda}
                            </div>
                        </div>
                    </div>

                    {fetchService ? (
                        <div className="shine h5 w5 my-5" />
                    ) : (
                        <div className={`mt-1  ${paramsData ? 'opacity-75' : ''}`}>
                            <div className="col-lg-4 col-form-label py-0 input-height">
                                {formik.values.service.length !== 0 && (
                                    <label className="required fw-bold fs-9">Select Service</label>
                                )}
                            </div>

                            <div
                                className={`form-control form-control-solid ${
                                    !formik.errors.service && !paramsData ? '' : ''
                                }`}
                            >
                                <img src={service} alt="Select Service" className="icon-style" />

                                <select
                                    className={`no-outline ps-4 fs-8 ${
                                        formik.values.service !== '' ? 'text-dark' : 'text-muted'
                                    } `}
                                    {...formik.getFieldProps('service')}
                                    disabled={paramsData || formik.values.mda.length === 0 ? true : false}
                                    onChange={(e) => setAmount(e)}
                                >
                                    <option value="">
                                        {paramsData ? location?.state?.serviceName : 'Select Service *'}
                                    </option>
                                    {serviceList &&
                                        serviceList?.map((array: any, i: any) => {
                                            return (
                                                <option value={array?.id} key={i}>
                                                    {array?.description}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>

                            <div className="fv-plugins-message-container input-height">
                                <div className="fv-help-block fs-9 text-danger">
                                    {formik.touched.service && formik.errors.service && formik.errors.service}
                                </div>
                            </div>
                        </div>
                    )}

                    {servicePaymComps?.length > 1 ? (
                        <>
                            {/* multiSelect with fixed price start */}
                            <div className="mt-1" ref={ref}>
                                <div className="col-lg-6 col-form-label py-0 input-height my-1">
                                    <label className="required fw-bold fs-9">Select Service Item</label>
                                </div>

                                {/* Use this  */}
                                <DropDown
                                    DropdownIndicator={DropdownIndicator}
                                    ValueContainer={ValueContainer}
                                    onChange={handleChange}
                                    isClearable
                                    isSearchable
                                    className=""
                                    classNamePrefix="fs-8"
                                    name="select-mda"
                                    isMulti={currentService?.multiSelect}
                                    onMenuOpen={() => setIsMenuOpen(true)}
                                    onMenuClose={() => setIsMenuOpen(false)}
                                    closeMenuOnSelect={!currentService?.multiSelect}
                                    customOptions={priceOptions}
                                    value={selectedOptions}
                                    valueStyles={{ paddingLeft: '2rem' }}
                                    getOptionLabel={(e: any) => getOptionLabel(e, isMenuOpen)}
                                    dropdownStyles={{ paddingLeft: 8, paddingRight: 8 }}
                                />

                                <div className="input-height" />
                            </div>
                            {/* multiSelect with fixed price end */}

                            {!priceFixed &&
                                selectedOptions.map((values: any, index: number) => (
                                    <div key={index} className="mt-1 opacity-75">
                                        <div className="col-lg-12 col-form-label py-0 input-height">
                                            <label className="required fw-bold fs-9 capitaliseText">
                                                {values?.name}
                                            </label>
                                        </div>
                                        <div className={`form-control form-control-solid`}>
                                            <img src={money} alt="Enter Amount" className="icon-style" />
                                            <NumericInput
                                                value={values?.amount}
                                                placeholder="Enter Amount"
                                                allowNegative={false}
                                                //disabled={disableAmount}
                                                allowLeadingZeros={true}
                                                onValueChange={(event) => updateAmount(values?.name, event)}
                                            />
                                        </div>

                                        <div className="fv-plugins-message-container input-height"></div>
                                    </div>
                                ))}

                            {/* multiSelect Total Amount start */}
                            <div className={`mt-1`}>
                                <div className="col-lg-12 col-form-label py-0 input-height">
                                    <label className="required fw-bold fs-9">
                                        {partLock ? 'Total Amount Payable' : 'Total Amount(Naira)'}
                                    </label>
                                </div>
                                <div
                                    className={`form-control form-control-solid ${
                                        !formik.errors.amount && !disableAmount ? '' : ''
                                    }`}
                                >
                                    <img src={money} alt="" className="icon-style" />

                                    <NumericInput
                                        value={totalAmount}
                                        placeholder={`${partLock ? 'Total Amount Payable' : 'Total Amount(Naira)'} *`}
                                        allowNegative={false}
                                        disabled={true}
                                        allowLeadingZeros={true}
                                        decimalScale={2}
                                    />
                                </div>
                                <div className="fv-plugins-message-container input-height"></div>
                            </div>
                            {/* multiSelect Total Amount end*/}
                        </>
                    ) : fetchService ? (
                        <div className="shine h5 w5 my-5" />
                    ) : (
                        <div className={`mt-1  ${disableAmount ? 'opacity-75' : ''}`}>
                            <div className="col-lg-12 col-form-label py-0 input-height">
                                {formik?.values?.amount && formik?.values?.amount !== undefined && (
                                    <label className="required fw-bold fs-9">
                                        {partLock ? 'Total Amount Payable' : 'Enter Amount(Naira)'}
                                    </label>
                                )}
                            </div>
                            <div
                                className={`form-control form-control-solid ${
                                    !formik.errors.amount && !disableAmount ? '' : ''
                                }`}
                            >
                                <img src={money} alt="" className="icon-style" />

                                <NumericInput
                                    value={formik.values.amount}
                                    placeholder={`${partLock ? 'Total Amount Payable' : 'Enter Amount(Naira)'} *`}
                                    allowNegative={false}
                                    disabled={disableAmount}
                                    onValueChange={(event) => formik.setFieldValue('amount', event.floatValue)}
                                />
                            </div>
                            <div className="fv-plugins-message-container input-height">
                                <div className="fv-help-block fs-9 text-danger">
                                    {formik.touched.amount && formik.errors.amount && formik.errors.amount}
                                </div>
                            </div>
                        </div>
                    )}

                    {/* part payment start  */}
                    {partLock && (
                        <div className={`mt-1  ${disableAmount ? 'opacity-75' : ''}`}>
                            <div className="col-lg-12 col-form-label py-0 input-height">
                                {formik?.values?.amount?.length !== 0 && (
                                    <label className="required fw-bold fs-9">How much do you want to pay</label>
                                )}
                            </div>
                            <div
                                className={`form-control form-control-solid ${
                                    !formik.errors.amount && !disableAmount ? '' : ''
                                }`}
                            >
                                <img src={money} alt="" className="icon-style" />

                                <NumericInput
                                    value={partAmount}
                                    placeholder=" How much do you want to pay*"
                                    allowNegative={false}
                                    onValueChange={(e: any) => handlePartValueChange(e, setPartAmount)}
                                    isAllowed={(e: any) => isAllowedNumeric(e, formik.values.amount)}
                                    allowLeadingZeros={true}
                                />
                            </div>
                            <div className="fv-plugins-message-container input-height" />
                        </div>
                    )}

                    {/* part payment end  */}

                    {fetchService ? (
                        <div className="shine h5 w5 my-5" />
                    ) : (
                        <InputForm
                            PrefixIcon={notePencil}
                            formik={formik}
                            {...formik.getFieldProps('description')}
                            placeholder="Description (optional)"
                            Label="Description (optional)"
                            labelContainerClassName="col-lg-12 "
                            formikName="description"
                            required={false}
                        />
                    )}

                    {customFieldsData?.length > 0 &&
                        customFieldsData?.map((field: any, id) => (
                            <div className="mt-1" key={id}>
                                <div className="col-lg-12 col-form-label py-0 input-height">
                                    {field?.customFieldType?.type === 'date' ? (
                                        <label className={`${!field?.optional ? 'required' : ' '} fw-bold fs-9`}>
                                            {field?.label}
                                            {field?.optional ? ' (optional)' : ''}
                                        </label>
                                    ) : (
                                        customFields?.[field?.code] &&
                                        customFields?.[field?.code]?.length !== 0 && (
                                            <label className={`${!field?.optional ? 'required' : ' '} fw-bold fs-9`}>
                                                {field?.label}
                                                {field?.optional ? ' (optional)' : ''}
                                            </label>
                                        )
                                    )}
                                </div>

                                {field?.customFieldType?.selectOptions?.length > 0 ? (
                                    <DropDown
                                        DropdownIndicator={DropdownIndicator}
                                        ValueContainer={ValueContainer}
                                        onChange={(e) => {
                                            setCustomFields({
                                                ...customFields,
                                                [field?.code]: e,
                                            });
                                        }}
                                        isClearable={true}
                                        isSearchable={false}
                                        className=""
                                        classNamePrefix="fs-8"
                                        name="select-mda"
                                        isMulti={field?.customFieldType?.type === 'multipleSelect' || false}
                                        customOptions={getLabel(field?.customFieldType?.selectOptions)}
                                        value={customFields[field?.code]}
                                        valueStyles={{ paddingLeft: '2rem' }}
                                        getOptionLabel={getOptions}
                                        dropdownStyles={{ paddingLeft: 8, paddingRight: 8 }}
                                        placeholder={`${
                                            field?.optional ? `${field?.label} (optional)` : field?.label + ' *'
                                        }`}
                                    />
                                ) : (
                                    <div
                                        className={`form-control form-control-solid ${
                                            customFields?.[field?.code]?.length > 0 ? '' : ''
                                        }`}
                                    >
                                        <img src={notePencil} alt="Email" className="icon-style" />

                                        <InputField
                                            type={`${
                                                field.customFieldType?.type === 'numeric'
                                                    ? 'number'
                                                    : field.customFieldType?.type === 'date'
                                                      ? 'date'
                                                      : 'text'
                                            }`}
                                            placeholder={`${
                                                field?.optional ? `${field?.label} (optional)` : field?.label + ' *'
                                            }`}
                                            onChange={(e) => {
                                                onChangeCustomFields(field, e, setCustomFields, customFields);
                                            }}
                                            maxLength={Number(field?.limit)}
                                            value={customFields[field?.code]}
                                        />
                                    </div>
                                )}

                                {/* <span> {field?.limit}</span> */}
                                <div className="fv-plugins-message-container input-height">
                                    {!field?.optional && (
                                        <div className="fv-help-block fs-9 text-danger">
                                            {customFields?.[field?.code]?.length === 0 && `${field?.label} is required`}

                                            {customFields?.[field?.code] === undefined && `${field?.label} is required`}
                                        </div>
                                    )}
                                    {customFields[field?.code]?.length > 0 && field?.limit && (
                                        <span className="text-gray-500 fs-9 ps-1">
                                            {customFields[field?.code]?.length} of {field?.limit} limit text
                                        </span>
                                    )}
                                </div>
                            </div>
                        ))}
                </div>

                <div className="d-flex  justify-content-between mt-5">
                    <div className="">
                        <button type="button" className="btn btn-danger px-7 py-2 text-alt-red " onClick={done}>
                            <span className="indicator-label fs-base ">Cancel</span>
                        </button>
                    </div>

                    <div className="">
                        <button
                            type="submit"
                            className={`btn ${
                                !(formik.isValid && formik.dirty && !disableNext) ? 'btn-secondary' : 'btn-success'
                            } text-white px-7 py-2 h-44 w-min-110 rounded-1`}
                            disabled={!(formik.isValid && formik.dirty && !disableNext)}
                        >
                            <span className="indicator-label fs-base">Next</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Step1;
