import {FlapperSpinner} from "react-spinners-kit";
import {useMdaActivate, useMdaDetails} from "../../../auth/hooks/useUser";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    LineElement,
} from "chart.js";
import {faker} from "@faker-js/faker";
import {Doughnut, Line} from "react-chartjs-2";
import React, {useState} from "react";
import {Select} from "antd";


ChartJS.register(CategoryScale, LinearScale, LineElement);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: "top" as const,
        },
        title: {
            display: true,
            text: "Chart.js Line Chart",
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
    },
};

const labels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];
export const data = {
    labels,
    datasets: [
        {
            data: labels.map(() => faker.datatype.number({ min: 0, max: 1000000 })),
            borderColor: "#A5CE9B",
            pointRadius: 0,
            backgroundColor: "#A5CE9B", // set the backgroundColor property to transparent to remove the background lines
            borderWidth: 1,
        },
    ],
};



const MdaReceivable = () => {
    const [selectedOption, setSelectedOption] = useState("");


    const chartWidth = 100; // Set the desired width
    const chartHeight = 100;

    const chartOptions = {
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    const chartData = {
        labels,
        datasets: [{
            data: labels.map(() => faker.datatype.number({ min: 0, max: 1000000 })),
            backgroundColor: [
                '#979797',
                '#1F8505',
                // Add more colors if you have more data points
            ],
        }],
    };

    const {
        isLoading,
    } = useMdaDetails();

    const {
        isLoading: activateLoading,
    } = useMdaActivate();


    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };


    return (
       <>
                {isLoading || activateLoading ? (
                    <div className="loading-container">
                        <FlapperSpinner/> {/* Replace with your loading indicator */}
                    </div>
                ) : (
                        <div className={"cashForecast"}>
                            <div className={"cashForecastBreadcrumb"}>
                                <div>Analytics</div>

                                <svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z" fill="#1F8505"/>
                                </svg>

                                <div>Cash Forecast</div>
                            </div>

                            <div className={"cashForecastRow2"}>
                                <div className="mx-1">
                                    <Select
                                        style={{ width: 150 }}
                                        options={[
                                            { value: "Last 30 Days", label: "Last 30 Days" },
                                            { value: "Last 60 Days", label: "Last 60 Days" },
                                            { value: "Last 90 Days", label: "Last 90 Days" },
                                        ]}
                                        defaultValue={"Last 30 Days"}
                                        dropdownMatchSelectWidth={false}
                                        size="large"
                                    />

                                    <div>
                                        <label className={'radioLabel'}>
                                            <input
                                                style={{marginRight: '15px'}}
                                                type="checkbox"
                                                value="failed-transaction"
                                                checked={selectedOption === "failed-transaction"}
                                                onChange={handleOptionChange}
                                            />
                                            Revenue
                                        </label>

                                        <label className={'radioLabel'}>
                                            <input
                                                style={{marginRight: '15px'}}
                                                type="checkbox"
                                                value="failed-transaction"
                                                checked={selectedOption === "failed-transaction"}
                                                onChange={handleOptionChange}
                                            />
                                            Expenditure
                                        </label>
                                    </div>

                                    <div>
                                        <button>Calculate</button>
                                    </div>
                                </div>

                                <div>
                                    <div className={'pieChartDiv'}>
                                        <div>
                                            <Doughnut  options={chartOptions}   width={chartWidth} height={chartHeight}  data={chartData} />
                                        </div>

                                       <div>
                                           <div>
                                               <div>N1.25m</div>
                                               <div>Total Amount</div>
                                           </div>

                                           <div>
                                               <div>23.2%</div>
                                               <div>Total Gain</div>
                                           </div>
                                       </div>
                                    </div>

                                    <div className={'lineChartDiv'}>
                                        <div>Projected Revenue</div>
                                        <Line options={options} data={data} />
                                    </div>
                                </div>
                            </div>
                        </div>
                )}
            </>
    );
};

export default MdaReceivable;
