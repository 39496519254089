import { DatePicker, Form, Radio, Select } from "antd";
import { FormSubmitButton } from "app/components/Button/FormSubmitBtn";
import DropDown from "app/components/dropdown";
import { useGetPaginatedAllMda } from "app/pages/payments/hooks/useRevenuePayment";
import { SelectSuffixIcon } from "core/helpers";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import "./PaymentReports.scss";

const { Option } = Select;

const OagfPaymentReports = () => {
  const [form] = Form.useForm();
  const formValues = Form.useWatch([], form);
  const navigate = useNavigate();

  const onFinishFailed = (errorInfo: any) => {
    //console.log("Failed:", errorInfo);
  };

  const {
    data: mdaNameList,
    mutate: mdaMutate,
    isLoading: allMdaLoading,
  } = useGetPaginatedAllMda();

  const onFinish = (values: any) => {
    const { mda, reportType, startDate, endDate, dir, paymentType } = values;

    const mdaValue = mda?.value || "all";
    
    const formattedStartDate = moment(startDate.toISOString()).format(
      "YYYY-MM-DD"
    );
    const formattedEndDate = moment(endDate.toISOString()).format("YYYY-MM-DD");

    let searchFilter = "";
    if (startDate) searchFilter += `start-date=${formattedStartDate}&`;
    if (endDate) searchFilter += `end-date=${formattedEndDate}&`;
    if (mdaValue && mdaValue !== "all") searchFilter += `mda=${mdaValue}&`;
    if (paymentType && paymentType !== "all")
      searchFilter += `type=${paymentType}&`;
    if (dir) searchFilter += `dir=${dir}`;

    // Remove the trailing '&' if the searchFilter is not empty
    if (searchFilter.endsWith("&")) {
      searchFilter = searchFilter.slice(0, -1);
    }

    if (reportType === "outflow") {
      navigate(`/admin/reports/payment-report/outflow?${searchFilter}`);
    } else if (reportType === "inflow") {
      navigate(`/admin/reports/payment-report/inflow?${searchFilter}`);
    }
  };

  return (
    <div className={"reviewerDashboard"}>
      <div className={"ciBreadcrumb pb-0"}>
        <div>Reports</div>
        <svg
          width="10"
          height="13"
          viewBox="0 0 10 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
            fill="#1F8505"
          />
        </svg>
        <div>Payment Reports</div>
      </div>
      <hr className="text-gray-800" />

      <div className="PaymentReportContainer">
        <div className="text-center fs-base text-dark2">
          <span>Report Controller</span>
        </div>

        <div className="mt-5">
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
            form={form}
          >
            <div className="input-grid">
              <Form.Item
                name={"mda"}
                label={<label className="text-federal-blue ">Select MDA</label>}
                rules={[{ required: true, message: "MDA is required" }]}
              >
                <DropDown
                  mutate={mdaMutate}
                  value={formValues?.mda?.value}
                  labelKey={"registeredName"}
                  valueKey={"id"}
                  apiData={mdaNameList}
                  onChange={(e: any) => {
                    form.setFieldValue("mda", e);
                  }}
                  placeholder="Select MDA"
                  valueStyles={{
                    paddingLeft: "0.5rem",
                  }}
                  controlStyles={{
                    paddingLeft: "0.5rem",
                    paddingRight: "0.5rem",
                    height: 32,
                    display: "flex",
                    alignItems: "center",
                    alignContent: "center",
                    borderColor: "#72d393",
                  }}
                  height={37}
                  loading={allMdaLoading}
                  className={`fs-8 pe-0 w-100`}
                />
              </Form.Item>

              <div>
                <Form.Item
                  name={"reportType"}
                  label={
                    <label className="text-federal-blue">
                      Select Report Type
                    </label>
                  }
                  rules={[
                    { required: true, message: "Report Type is required" },
                  ]}
                >
                  <Select placeholder="" suffixIcon={<SelectSuffixIcon />}>
                    <Option value={null}> </Option>
                    <Option value="inflow">Inflow</Option>
                    <Option value="outflow">Outflow</Option>
                  </Select>
                </Form.Item>

                {formValues?.reportType === "outflow" && (
                  <Form.Item
                    name={"paymentType"}
                    label={
                      <label className="text-federal-blue">
                        Select Payment Type
                      </label>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Payment Type is required",
                      },
                    ]}
                  >
                    <Select placeholder="" suffixIcon={<SelectSuffixIcon />}>
                      <Option value={null}> </Option>
                      <Option value="all">All</Option>
                      <Option value="RetailVendor">
                        Retail/Vendor Payment
                      </Option>
                      <Option value="contract_payment">Contract Payment</Option>
                      <Option value="payrollPayments">Payroll Payment</Option>
                      <Option value="pensionPayments">Pension Payment</Option>
                      <Option value="vat_payment">Vat Payment</Option>
                      <Option value="paye_payment">Paye Payment</Option>
                    </Select>
                  </Form.Item>
                )}
              </div>

              <Form.Item
                label="Start Date"
                name="startDate"
                rules={[
                  {
                    type: "object" as const,
                    required: true,
                    message: "Select Start Date",
                  },
                ]}
              >
                <DatePicker placeholder="Start Date" className="w-100" />
              </Form.Item>

              <Form.Item
                label="End Date"
                name="endDate"
                dependencies={["startDate"]}
                rules={[
                  {
                    type: "object" as const,
                    required: true,
                    message: "Select End Date",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const endDate: any = new Date(value);
                      const startDate: any = new Date(
                        getFieldValue("startDate")
                      );

                      if (startDate < endDate || startDate === endDate) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("End Date can't be less than start date!")
                      );
                    },
                  }),
                ]}
              >
                <DatePicker placeholder="End Date" className="w-100" />
              </Form.Item>
            </div>

            <Form.Item label="Sort By" name="dir">
              <Radio.Group>
                <Radio value="asc">Ascending Order (FIFO)</Radio>
                <Radio value="desc">Descending Order (LIFO)</Radio>
              </Radio.Group>
            </Form.Item>

            <div className="d-flex justify-content-center gap-3">
              <button
                className="resetBtn"
                type="button"
                onClick={() => form.resetFields()}
              >
                <span>Reset</span>
              </button>

              <Form.Item className="d-flex justify-content-center">
                <FormSubmitButton
                  form={form}
                  submitProp={"Generate Report"}
                  className={"submitBtn"}
                />
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default OagfPaymentReports;
