import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FlapperSpinner } from 'react-spinners-kit';
import { ErrorHandler } from 'core/helpers';
import SuccessModal from './components/SuccessModal';
import { useGetPayRef } from './hooks/useRevenuePayment';
import { ProcessingPaymentModal } from './components/ProcessingModal';

const SuccessPayment: React.FC = () => {
    const [show, setShow] = useState<boolean>(false);
    const [receiptData, setReceiptData] = useState<any>([]);
    // const { successId, ...others } = useParams();
    const { mutate, isLoading } = useGetPayRef();
    const navigate = useNavigate();
    const [payStatus, setPayStatus] = useState<string>('');

    const loadStatus = (payRef: string) => {
        mutate(payRef, {
            onSuccess: (response: any) => {
                if (response?.invoice?.status.toLowerCase() === 'new') {
                    navigate('/');
                    return;
                }

                const foundObject = response?.paymentHistory.find(
                    (obj: { paymentReference: string }) => obj.paymentReference === payRef,
                );

                if (foundObject) {
                    if (foundObject?.status.toLowerCase() === 'paid') {
                        setPayStatus('paid');
                    } else {
                        setPayStatus('processing');
                    }
                    // if (foundObject?.status.toLowerCase() !== "paid") {
                    //   navigate("/");
                    //   return;
                    // }
                }

                setReceiptData({
                    ...response,
                    grr: response.installPayment ? payRef : response.invoiceNumber,
                    currentPayment: foundObject,
                });
                setShow(true);
            },
            onError: (err: any) => {
                if (err.response.status === 500 || err.response.status === 400) {
                    navigate('/');
                } else {
                    ErrorHandler(err);
                }
            },
        });
    };

    useEffect(() => {
        // Get the current URL and extract query parameters
        const urlParams = new URLSearchParams(window.location.search);

        // Get the value of a specific query parameter
        const tx_ref = urlParams.get('tx_ref');
        const trxref = urlParams.get('trxref');
        const reference = urlParams.get('reference');

        if (tx_ref) {
            loadStatus(tx_ref);
            return;
        } else if (trxref) {
            loadStatus(trxref);
            return;
        } else if (reference) {
            loadStatus(reference);
            return;
        } else {
            navigate('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={`m-vh-${isLoading ? 100 : 95}`}>
            {isLoading ? (
                <div className="loading-container">
                    <FlapperSpinner />
                </div>
            ) : payStatus === 'paid' ? (
                <SuccessModal
                    show={show}
                    setShow={() => setShow(true)}
                    invoiceData={receiptData}
                    done={() => navigate('/')}
                />
            ) : (
                <ProcessingPaymentModal
                    show={show}
                    setShow={() => setShow(true)}
                    invoiceData={receiptData}
                    done={() => navigate('/')}
                />
            )}
        </div>
    );
};

export default SuccessPayment;
