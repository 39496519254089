import React, {useState} from 'react';
import '../../../../../App.css';
import {Layout} from '../../layout';
import {Tabs} from "antd";
import PayByFileUpload from "../RetailVendorPayment/PayByFileUpload";
import PayByAccumulationOfEntries from "../RetailVendorPayment/PayByAccumulationOfEntries";
import {useNavigate} from "react-router-dom";




const BulkTaxUpload = () => {
    const navigate = useNavigate();


    return (
        <>
            <div className={'bulkTaxUpload'}>
                <div className={'bulkTaxUploadBreadcrumb'}>
                    <div>Payments</div>
                    <svg
                        width="10"
                        height="13"
                        viewBox="0 0 10 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                            fill="#1F8505"
                        />
                    </svg>
                    <div>Tax Payments</div>

                    <svg
                        width="10"
                        height="13"
                        viewBox="0 0 10 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                            fill="#1F8505"
                        />
                    </svg>

                    <div>Bulk Tax Upload</div>
                </div>

                <div>
                    <div>Bulk Tax Upload</div>

                    <div className={'btUploadButtons'}>
                        <button onClick={() => {navigate("/admin/payment/vat")}}>
                            Value Added Tax (VAT)
                        </button>
                    </div>

                    <div className={'btUploadButtons'}>
                        <button onClick={() => {navigate("/admin/payment/wht")}}>
                            Withholding Tax (WHT)
                        </button>
                    </div>

                    <div className={'btUploadButtons'}>
                        <button onClick={() => {navigate("/admin/payment/paye")}}>
                            Pay-As-You-Earn Tax (PAYE)
                        </button>
                    </div>

                    <div className={'btUploadButtons'}>
                        <button onClick={() => {navigate("/admin/payment/contract")}}>
                            Contractor Fees and Taxes (Contract/VAT/WHT)
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BulkTaxUpload;
