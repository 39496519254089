import React, { useState } from "react";
import * as Yup from "yup";
import NavBar from "app/components/nav/NavBar";
import { useFormik } from "formik";
import { envelope } from "core/assets/imgs";
import { Footer } from "app/components/footer";

const profileDetailsSchema = Yup.object().shape({
  email: Yup.string().email("Wrong email format").required("Email is required"),
});

const initialValues = {
  email: "",
};

const HelpCenter: React.FC = () => {
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const [arrow, setArrow] = useState(true);
  const [FaqNo, setFaqNo] = useState<Number>(0);
  const handleFaq = (index: Number) => {
    if (index == FaqNo && arrow) {
      setArrow(false);
    } else {
      setArrow(true);
      setFaqNo(index);
    }
  };

  const faq = [
    {
      title: "Why use TMS?",
      desc: "Lorem ipsum dolor sit amet consectetur. Ornare duis nullam blandit tellus vitae lectus pretium risus. Volutpat molestie penatibus in dapibus purus enim nisl egestas ultrices.",
    },
    {
      title: "How to pay for school fees?",
      desc: "Lorem ipsum dolor sit amet consectetur. Ornare duis nullam blandit tellus vitae lectus pretium risus. Volutpat molestie penatibus in dapibus purus enim nisl egestas ultrices.",
    },
    {
      title: "Do I have to register to use TMS?",
      desc: "Lorem ipsum dolor sit amet consectetur. Ornare duis nullam blandit tellus vitae lectus pretium risus. Volutpat molestie penatibus in dapibus purus enim nisl egestas ultrices.",
    },
  ];

  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: (values) => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    },
  });

  return (
    <div>
      <NavBar />
      <div className="root">
        <div className="help-center-hero">
          <div className="help-center-img-card">
            <div className="help-center-item">
              <div>
                <span className="help-center-faq1">The FAQs</span>
                <span className="help-center-faq2">Help Center</span>
                <span className="help-center-faq3">
                  Everything you need to know about the portal.
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="help-center-hero">
          <div className="help-center-section">
            <div>
              {/* <span className="help-center-small-text">Support</span> */}
              <span className="help-center-bold-text">
                Frequently Asked Questions
              </span>
              <span
                className="help-center-normal-text"
                style={{ marginTop: "5rem" }}
              >
                Everything you need to know about the portal. <br />
                Can’t find what you are looking for?{" "}
                <span className="help-contact">Contact Support</span>
              </span>
            </div>
            <div>
              {faq.map((value, index) => {
                return (
                  <div key={index}>
                    <div
                      className={`border-bottom border-gray-800 faq-header ${
                        arrow && FaqNo !== index ? "bg-white" : ""
                      }`}
                      onClick={() => handleFaq(index)}
                    >
                      <span className="faq-header-text ">{value.title}</span>
                      {arrow && FaqNo === index ? (
                        <span>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M20.6695 15.09C20.6695 15.28 20.5995 15.47 20.4495 15.62C20.1595 15.91 19.6795 15.91 19.3895 15.62L12.8695 9.10002C12.3895 8.62002 11.6095 8.62002 11.1295 9.10002L4.60953 15.62C4.31953 15.91 3.83953 15.91 3.54953 15.62C3.25953 15.33 3.25953 14.85 3.54953 14.56L10.0695 8.04002C10.5795 7.53002 11.2695 7.24002 11.9995 7.24002C12.7295 7.24002 13.4195 7.52002 13.9295 8.04002L20.4495 14.56C20.5895 14.71 20.6695 14.9 20.6695 15.09Z"
                              fill="#666666"
                            />
                          </svg>
                        </span>
                      ) : (
                        <span>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.33047 8.90998C3.33047 8.71998 3.40047 8.52998 3.55047 8.37998C3.84047 8.08998 4.32047 8.08998 4.61047 8.37998L11.1305 14.9C11.6105 15.38 12.3905 15.38 12.8705 14.9L19.3905 8.37998C19.6805 8.08998 20.1605 8.08998 20.4505 8.37998C20.7405 8.66998 20.7405 9.14998 20.4505 9.43998L13.9305 15.96C13.4205 16.47 12.7305 16.76 12.0005 16.76C11.2705 16.76 10.5805 16.48 10.0705 15.96L3.55047 9.43998C3.41047 9.28998 3.33047 9.09998 3.33047 8.90998Z"
                              fill="#131212"
                              fillOpacity="0.8"
                            />
                          </svg>
                        </span>
                      )}
                    </div>

                    {arrow && FaqNo === index && (
                      <div className="faq-body">{value?.desc}</div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="help-center-hero">
          <div className="footer--center-bottom">
            <div className="foot-news-letter">
              <div className="right-side">
                <div>
                  <span className="subcriber-pool">
                    Join pool of Subscribers
                  </span>
                  <span className="subcriber-pool-body">
                    Stay in the loop with everything you need to know.
                  </span>
                </div>
              </div>
              <div className="news-letter-container">
                <div style={{ marginBottom: "0.7rem" }}>
                  <span className="span-header">Subscribe our Newsletter</span>
                </div>

                <div className="col-12  flex-small align-items-center ">
                  <div className="flex-col">
                    <div className="form-control form-control-solid ">
                      <img src={envelope} alt="Email" className="icon-style" />
                      <input
                        type="text"
                        placeholder="Email"
                        className={"no-outline ps-4 text-dark fs-8"}
                        {...formik.getFieldProps("email")}
                      />
                    </div>
                  </div>
                  <div className="flex-subscribe">
                    <button className="btn btn-footer py-3 px-8">
                      <span className="indicator-label text-dark fs-6 fw-bold">
                        Subscribe
                      </span>
                    </button>
                  </div>
                </div>
                {/* <span className="subscribe-policy-body">
                  We care about your data in our <span className='subscribe-policy-body-link'>Privacy Policy</span>
                </span> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export { HelpCenter };
