import React, {useState} from 'react';
import SideBar from "app/components/dashboard/SideBar";
import Header from "app/components/dashboard/Header";
import { Outlet } from 'react-router-dom';

type Props = {
  children?: any;
};

const Layout: React.FC<Props> = ({ children }) => {
  const [dashboardSidebar, setDashboardSidebar] = useState(false);

  function showMenu() {
    setDashboardSidebar(!dashboardSidebar);
  }


  return (
    <div className={'mainDashboard'}>
        <header>
            <Header showMenu={showMenu} />
        </header>
        <section className={'dashboardRow2'}>
            <div className={'dashboardSidebar'}>
                <SideBar dashboardSidebar={dashboardSidebar}  showMenu={showMenu} />
            </div>
            {/* {children} */}
            <Outlet/>
        </section>
    </div>
  );
};

export { Layout };
