import { isEmpty } from "core/helpers";
import Select from "react-select";

interface CreateStep3Props {
  formik: any;
  goBack: () => void;
  usersData: any[];
}

export const CreateStep3: React.FC<CreateStep3Props> = ({
  formik,
  goBack,
  usersData,
}) => {
  const selected = formik.values?.users.map((item: any) => ({
    value: item.id,
    label: item.name,
  }));

  const customOptions = usersData?.map(
    (option: { id: any; firstName: string; lastName: string }) => ({
      value: option.id,
      label: option.firstName + " " + option.lastName,
    })
  );

  return (
    <div className="create-content">
      <p className="fs-8 fw-bolder text-dark2 mb-0">User Details</p>

      <span className="text-gray-500 fs-9">
        Please add people that will work on the project
        <br />
        *At least 3 users must be added.
      </span>

      <form
        onSubmit={formik.handleSubmit}
        noValidate
        className="form align-middle  gx-0 gy-5 mt-3"
      >
        <div className="mt-1">
          <div className="col-lg-12 col-form-label py-0 input-height">
            {formik.values.projectId.length !== 0 && (
              <label className="fw-bold fs-9 required">
                Select Existing User
              </label>
            )}
          </div>

          {/* {formik.values.mdaInvoled.map((data) => {
            return (
              <div className="my-5">
                <Select
                  isMulti
                  {...formik.getFieldProps("users")}
                  options={customOptions}
                  closeMenuOnSelect={false}
                  value={selected}
                  onChange={(e: any) => {
                    if (!isEmpty(e)) {
                      const loadData = e.map((item: any) => {
                        const userFulldata = usersData.find(
                          (user: { id: any }) => user.id === item.value
                        );

                        return {
                          name: item.label,
                          id: item.value,
                          login: userFulldata?.login,
                        };
                      });

                      formik.setFieldValue("users", loadData);
                    } else {
                      formik.setFieldValue("users", []);
                    }
                  }}
                  isClearable={true}
                  isSearchable={true}
                  placeholder={"Select Existing User"}
                  className="w-100"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: "#E5E5E5",
                      borderWidth: 1,
                      minHeight: 48,
                      cursor: "pointer",
                      background: "#F9F9F9",
                      fontSize: "0.875rem",
                      paddingLeft: "0",
                      boxShadow: "none",
                    }),

                    option: (baseStyles, state) => ({
                      ...baseStyles,
                      color: "#66666",
                      cursor: "pointer",
                      fontSize: 12,
                    }),
                    indicatorSeparator: (baseStyles, state) => ({
                      ...baseStyles,
                      backgroundColor: "white",
                      display: "none",
                    }),
                    multiValueLabel: (baseStyles, state) => ({
                      ...baseStyles,
                      color: "#000000",
                      background: "white",
                      padding: 4,
                    }),
                    multiValueRemove: (baseStyles, state) => ({
                      color: "#000000",
                      background: "white",
                      padding: 4,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }),
                    valueContainer: (baseStyles, state) => ({
                      ...baseStyles,
                    }),

                    dropdownIndicator: (baseStyles, state) => ({
                      ...baseStyles,
                      paddingLeft: "1rem",
                      paddingRight: "1rem",
                    }),

                    // valueContainer: (baseStyles, state) => ({  background: "red",}),
                  }}
                />
              </div>
            );
          })} */}

          <Select
            isMulti
            {...formik.getFieldProps("users")}
            options={customOptions}
            closeMenuOnSelect={false}
            value={selected}
            onChange={(e: any) => {
              if (!isEmpty(e)) {
                const loadData = e.map((item: any) => {
                  const userFulldata = usersData.find(
                    (user: { id: any }) => user.id === item.value
                  );

                  return {
                    name: item.label,
                    id: item.value,
                    login: userFulldata?.login,
                  };
                });

                formik.setFieldValue("users", loadData);
              } else {
                formik.setFieldValue("users", []);
              }
            }}
            isClearable={true}
            isSearchable={true}
            placeholder={"Select Existing User"}
            className="w-100"
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: "#E5E5E5",
                borderWidth: 1,
                minHeight: 48,
                cursor: "pointer",
                background: "#F9F9F9",
                fontSize: "0.875rem",
                paddingLeft: "0",
                boxShadow: "none",
              }),

              option: (baseStyles, state) => ({
                ...baseStyles,
                color: "#66666",
                cursor: "pointer",
                fontSize: 12,
              }),
              indicatorSeparator: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: "white",
                display: "none",
              }),
              multiValueLabel: (baseStyles, state) => ({
                ...baseStyles,
                color: "#000000",
                background: "white",
                padding: 4,
              }),
              multiValueRemove: (baseStyles, state) => ({
                color: "#000000",
                background: "white",
                padding: 4,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }),
              valueContainer: (baseStyles, state) => ({
                ...baseStyles,
              }),

              dropdownIndicator: (baseStyles, state) => ({
                ...baseStyles,
                paddingLeft: "1rem",
                paddingRight: "1rem",
              }),

              // valueContainer: (baseStyles, state) => ({  background: "red",}),
            }}
          />

          <div className="fv-plugins-message-container input-height">
            <div className="fv-help-block fs-9 text-danger">
              {formik.touched.users &&
                formik.errors.users &&
                formik.errors.users}
            </div>
          </div>
        </div>

        <div className="d-flex  justify-content-between mt-5">
          <div className="">
            <button
              id="kt_password_cancel "
              type="button"
              className="btn btn-white border-success   text-success px-11 py-2 h-44 w-min-110 rounded-1` "
              onClick={goBack}
            >
              <span className="indicator-label fs-base ">Previous</span>
            </button>
          </div>

          <div className="">
            <button
              id="kt_password_cancel "
              type="submit"
              className={`btn ${
                !(formik.isValid && formik.dirty)
                  ? "btn-secondary"
                  : "btn-success"
              } text-white px-15 py-2 h-44 w-min-110 rounded-1`}
              disabled={!(formik.isValid && formik.dirty)}
            >
              <span className="indicator-label fs-base">Next</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
