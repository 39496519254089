import React from "react";
import { multipleServiceCsv } from "core/assets/files";
import { ErrorToast } from "app/components/toast";

interface MyFunction {
  (
    setInputsAmount: React.Dispatch<React.SetStateAction<any[]>>,
    setUploadList: React.Dispatch<React.SetStateAction<boolean>>
  ): void;
}

function dragAndDrop(event: any) {
  event.preventDefault();
  const dragDropArea = document.getElementById("uploadArea");
  return dragDropArea;
}

const UploadMultiple: MyFunction = (setInputsAmount, setUploadList) => {
  const extractData = ($event: any) => {
    const file = $event.target.files[0];
    if (file.type === "text/csv") {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = (event: any) => {
        const csvString = event.target.result;

        let rows = csvString.split("\n");
        rows = rows.filter((line) => line.trim() !== "");

        const headers = rows[0].trim().split(",");
        const data: any[] = [];

        for (let i = 1; i < rows.length; i++) {
          let values = rows[i].trim();
          values = values.split(",");

          const obj: any = {};
          for (let j = 0; j < headers.length; j++) {
            obj[headers[j]] = values[j];
          }
          const newAmount = {
            id: i,
            itemName: obj["Item Name"],
            amount: obj?.Amount ? obj?.Amount : null,
            collectionAccount: "",
            code: "",
          };
          data.push(newAmount);
          setInputsAmount(data);
        }
      };
      setUploadList(false);
    } else {
      ErrorToast("Invalid File type Select a CSV File");
    }
  };

  return (
    <div className="bulkPayments w-100 px-1 py-5">
      <div className={"payByFileUpload"}>
        <div
          className={
            "bulkPaymentDragAndDrop d-flex flex-column justify-content-start"
          }
        >
          <span className="fs-9 text-gray-500 p-1 pb-2 ">
            <a
              className="text-success underline-success"
              href={multipleServiceCsv}
            >
              Click here
            </a>{" "}
            to see sample uploaded format
          </span>

          <label id="uploadArea" onDragOver={(e) => dragAndDrop(e)}>
            <input
              type="file"
              accept="text/csv"
              style={{ display: "none" }}
              onChange={extractData}
            />

            <svg
              width="26"
              height="20"
              viewBox="0 0 26 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.4999 18.7482H6.8749C6.00629 18.7473 5.14779 18.5617 4.35638 18.2037C3.56498 17.8457 2.85877 17.3235 2.28463 16.6717C1.71049 16.0199 1.28156 15.2534 1.0263 14.4231C0.771045 13.5928 0.695299 12.7178 0.804093 11.856C0.912887 10.9942 1.20373 10.1655 1.65733 9.42468C2.11092 8.68391 2.71688 8.04808 3.435 7.55941C4.15312 7.07073 4.96696 6.74039 5.82251 6.5903C6.67806 6.44021 7.55575 6.47381 8.39732 6.68887M7.7499 9.99817C7.75004 8.61177 8.07961 7.24524 8.71147 6.0112C9.34332 4.77716 10.2594 3.71092 11.3841 2.90034C12.5089 2.08977 13.8101 1.55807 15.1807 1.34904C16.5512 1.14002 17.9519 1.25966 19.2671 1.69811C20.5823 2.13656 21.7746 2.88127 22.7456 3.87086C23.7165 4.86046 24.4385 6.06662 24.8519 7.38995C25.2653 8.71328 25.3583 10.1159 25.1233 11.4822C24.8883 12.8486 24.332 14.1395 23.5002 15.2487M11.9126 13.7105L15.6249 9.99813M15.6249 9.99813L19.3372 13.7105M15.6249 9.99813V18.7482"
                stroke="#48B460"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <div className={"dragDrop"}>Drag and drop your files here</div>

            <div className={"fileFormat"}>File format supported: CSV, XSL</div>

            <div className={"orText"}>or</div>

            <div className={"browseFile"}>Browse files</div>

            <div className={"size"}>Maximum size: 2Mb</div>
          </label>
        </div>
      </div>
    </div>
  );
};

export default UploadMultiple;
