interface CreateStep2Props {
  formik: any;
  goBack: () => void;
  bankData: any[];
}

export const CreateStep2: React.FC<CreateStep2Props> = ({
  formik,
  goBack,
  bankData,
  //selectedBank
}) => {
  const handleBankSelect = (e: any) => {
    const Bank = JSON.parse(e);
    formik.setFieldValue("bankArray", Bank);
  };

  return (
    <div className="create-content">
      <p className="fs-8 fw-bolder text-dark2 mb-0">Bank Account</p>

      <span className="text-gray-500 fs-9">
        Please select the bank account you want to use for this project
      </span>

      <form
        onSubmit={formik.handleSubmit}
        noValidate
        className="form align-middle  gx-0 gy-5 mt-3"
      >
        <div className="mt-1">
          <div className="col-lg-12 col-form-label py-0 input-height">
            {formik.values.projectId.length !== 0 && (
              <label className="fw-bold fs-9 required">
                Select Bank Account
              </label>
            )}
          </div>

          <div className={`form-control form-control-solid`}>
            <select
              className={`no-outline w-100 fs-8 ${
                formik.values.projectId !== "" ? "text-dark" : "text-muted"
              } `}
              value={JSON.stringify(formik.values?.bankArray)}
              onChange={(e: any) => handleBankSelect(e.target.value)}
            >
              <option value="">Select Bank Account *</option>

              {bankData?.map((value: any, i: any) => {
                return (
                  <option value={JSON.stringify(value)} key={i}>
                    {value?.accountNumber} - {value?.accountName}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="fv-plugins-message-container input-height">
            <div className="fv-help-block fs-9 text-danger">
              {formik.touched.bankArray &&
                formik.errors.bankArray &&
                formik.errors.bankArray}
            </div>
          </div>
        </div>

        <div className="d-flex  justify-content-between mt-5">
          <div className="">
            <button
              id="kt_password_cancel "
              type="button"
              className="btn btn-white border-success   text-success px-11 py-2 h-44 w-min-110 rounded-1` "
              onClick={goBack}
            >
              <span className="indicator-label fs-base ">Previous</span>
            </button>
          </div>

          <div className="">
            <button
              id="kt_password_cancel "
              type="submit"
              className={`btn ${
                !(formik.isValid && formik.dirty)
                  ? "btn-secondary"
                  : "btn-success"
              } text-white px-15 py-2 h-44 w-min-110 rounded-1`}
              disabled={!(formik.isValid && formik.dirty)}
            >
              <span className="indicator-label fs-base">Next</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
