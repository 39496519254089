import React, {useState} from "react";
import {FlapperSpinner} from "react-spinners-kit";
import {useMdaActivate, useMdaDetails} from "../../../auth/hooks/useUser";
import CreateNewUsersCard from "./CreateNewUsersCard";
import EditExistingUserCard from "./EditExistingUserCard";
const UserManagement = () => {

    const {
        isLoading,
    } = useMdaDetails();

    const {
        isLoading: activateLoading,
    } = useMdaActivate();

    const [activeOption, setActiveOption] = useState("createUsers");


    return (
       <>
                {isLoading || activateLoading ? (
                    <div className="loading-container">
                        <FlapperSpinner/> {/* Replace with your loading indicator */}
                    </div>
                ) : (
                    <>
                        <div className={"userManagement"}>
                                <div className={"userManagementBreadcrumb"}>
                                    <div>Setup & Administration</div>

                                    <svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z" fill="#1F8505"/>
                                    </svg>

                                    <div>User Management</div>
                            </div>

                            <div className={"userManagementRow1"}>
                                <div
                                    className={
                                        activeOption === "createUsers" ? "active" : ""
                                    }
                                    onClick={() => setActiveOption("createUsers")}
                                >
                                    <div>Create New Users</div>
                                    <div>This is to initiate user creation request</div>
                                </div>

                                <div
                                    className={
                                        activeOption === "editExistingUser" ? "active" : ""
                                    }
                                    onClick={() => setActiveOption("editExistingUser")}
                                >
                                    <div>Edit Existing User Details</div>
                                    <div>This shows all user details with options to
                                        edit, activate and deactivate users
                                    </div>
                                </div>
                            </div>


                            {activeOption === "createUsers" && <CreateNewUsersCard />}
                            {activeOption === "editExistingUser" && <EditExistingUserCard />}
                        </div>
                    </>
                )}
            </>
    );
};

export default UserManagement;
