import React, {useState} from 'react';
import '../../../../../App.css';
import {Layout} from '../../layout';
import {Modal} from "antd";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";


const Schema = Yup.object().shape({
    invoiceNo: Yup.string().required('Invoice Number is required'),
    // fundingAccount: Yup.string().required('Funding Account is required'),
});

const initialValues = {
    invoiceNo: '',
    fundingAccount: '',
};

const ElectronicInvoicePayment = () => {
    const [successModal, setSuccessModal] = useState(false);
    const [step, setStep] = useState<number>(1);


    const navigate = useNavigate();

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: async (values) => {

        },
    });

    let fundingAccount = [
        {
            id: '',
            description: 'Select Funding Account',
        },
        {
            id: '1',
            description: '0044829493 - CBN',
        },
    ];


    return (
        <>
            <div className={'electronicInvoicePayment'}>
                <div className={'eipBreadcrumb'}>
                    <div>Payments</div>
                    <svg
                        width="10"
                        height="13"
                        viewBox="0 0 10 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                            fill="#1F8505"
                        />
                    </svg>
                    <div>Bill Payments</div>
                </div>

                {step === 1 && (
                    <div className={'eiPaymentDiv'}>
                        <div>Electronic Invoice Payment</div>

                        <div className={`mt-1`}>
                            <div className="col-form-label py-0 input-height">
                                <label>
                                    Enter invoice number
                                </label>
                            </div>

                            <div className={`form-control form-control-solid`}
                                 style={{display: 'flex', gap: '17px', alignItems: 'center'}}>

                                <svg width="20" height="18" viewBox="0 0 20 18" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M5.125 9.75H14.875M5.125 6.75H14.875M1 16.5V2.25C1 2.05109 1.07902 1.86032 1.21967 1.71967C1.36032 1.57902 1.55109 1.5 1.75 1.5H18.25C18.4489 1.5 18.6397 1.57902 18.7803 1.71967C18.921 1.86032 19 2.05109 19 2.25V16.5L16 15L13 16.5L10 15L7 16.5L4 15L1 16.5Z"
                                        stroke="#CCCCCC" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round"/>
                                </svg>

                                <input
                                    className={`no-outline fs-8 ${
                                        formik.values.invoiceNo !== ''
                                            ? 'text-dark'
                                            : 'text-muted'
                                    } `}
                                    {...formik.getFieldProps('invoiceNo')}
                                    placeholder="Enter invoice number"
                                >
                                </input>
                            </div>

                            <div className="fv-plugins-message-container input-height">
                                <div className="fv-help-block fs-9 text-danger">
                                    {formik.touched.invoiceNo &&
                                        formik.errors.invoiceNo &&
                                        formik.errors.invoiceNo}
                                </div>
                            </div>
                        </div>

                        <div>
                            <div>
                                <button onClick={() => {
                                    navigate("/admin/payment/bill")
                                }}>
                                    Cancel
                                </button>
                            </div>

                            <div>
                                {
                                    formik.isValid && formik.dirty ?
                                        <button
                                            onClick={() => setStep(2)}>
                                            Next
                                        </button> :
                                        <button
                                            disabled
                                            style={{background: '#666666'}}                                        >
                                            Next
                                        </button>
                                }
                            </div>
                        </div>
                    </div>
                )}

                {step === 2 && (
                    <div className={'billPaymentDetailDiv'}>
                        <div className={'billPaymentDetail'}>
                            <div>Payment Detail</div>

                            <div>
                                <div>
                                    <div>MDA</div>
                                    <div>Federal Ministry of Education</div>
                                </div>

                                <div>
                                    <div>Type of Service</div>
                                    <div>O’Level Verification</div>
                                </div>

                                <div>
                                    <div>Amount</div>
                                    <div>N2,000</div>
                                </div>

                                <div>
                                    <div>Description</div>
                                    <div>O’Level Certificate Verification</div>
                                </div>

                                <div>
                                    <div>Full Name</div>
                                    <div>Fatima Umar</div>
                                </div>

                                <div>
                                    <div>Email</div>
                                    <div>Fatimaumar@yahoo.com</div>
                                </div>

                                <div>
                                    <div>Phone Number</div>
                                    <div>+23481030004030</div>
                                </div>
                            </div>
                        </div>

                        <div className={'billPaymentFundingAcct'}>
                            <div>Select funding account</div>

                            <div className={`mt-1`}>
                                {/*<div className="col-form-label py-0 input-height">*/}
                                {/*    <label className="required fw-bold fs-9">*/}
                                {/*        Select Funding Account*/}
                                {/*    </label>*/}
                                {/*</div>*/}

                                <div className={`form-control form-control-solid`}>
                                    <select
                                        className={`no-outline fs-8 ${
                                            formik.values.fundingAccount !== ''
                                                ? 'text-dark'
                                                : 'text-muted'
                                        } `}
                                        {...formik.getFieldProps('fundingAccount')}  
                                   >
                                        <option value="">Select Funding Account</option>
                                        {fundingAccount &&
                                            fundingAccount?.map((array: any, i: any) => {
                                                return (
                                                    <option value={array?.description} key={i}>
                                                        {array?.description}
                                                    </option>
                                                );
                                            })}
                                    </select>
                                </div>

                                {/*<div className="fv-plugins-message-container">*/}
                                {/*    <div className="fv-help-block fs-9 text-danger">*/}
                                {/*        {formik.touched.fundingAccount &&*/}
                                {/*            formik.errors.fundingAccount &&*/}
                                {/*            formik.errors.fundingAccount}*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>

                            <div>
                                <div>
                                    <button onClick={() => setStep(1)}>
                                        Back
                                    </button>
                                </div>

                                <div>
                                    {
                                        formik.isValid && formik.dirty ?
                                            <button
                                                onClick={() => setStep(3)}>
                                                Pay
                                            </button> :
                                            <button
                                                disabled
                                                style={{background: '#666666'}}
                                            >
                                                Pay
                                            </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                )}

                {step === 3 && (
                    <div className={'billPaymentReceived'}>
                        <div>
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M28.5937 15.3125L17.1354 26.25L11.4062 20.7812M38.75 20C38.75 30.3553 30.3553 38.75 20 38.75C9.64466 38.75 1.25 30.3553 1.25 20C1.25 9.64466 9.64466 1.25 20 1.25C30.3553 1.25 38.75 9.64466 38.75 20Z"
                                    stroke="#FBCB38" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>

                        <div>Payment Received</div>

                        <div>We’ve received your N2000, Fatima</div>

                        <div>
                            <div>
                                <div>MDA</div>
                                <div>Federal Ministry of Education</div>
                            </div>

                            <div>
                                <div>Type of Service</div>
                                <div>O’Level Verification</div>
                            </div>

                            <div>
                                <div>Amount</div>
                                <div>N2,000</div>
                            </div>

                            <div>
                                <div>Description</div>
                                <div>O’Level Certificate Verification</div>
                            </div>

                            <div>
                                <div>Full Name</div>
                                <div>Fatima Umar</div>
                            </div>

                            <div>
                                <div>Email</div>
                                <div>Fatimaumar@yahoo.com</div>
                            </div>

                            <div>
                                <div>Phone Number</div>
                                <div>+23481030004030</div>
                            </div>
                        </div>

                        <div>
                            <div>
                                <button onClick={() => setStep(1)}>
                                    Add another
                                </button>
                            </div>

                            <div>
                                <button onClick={() => setSuccessModal(true)}>
                                    Done
                                </button>
                            </div>
                        </div>
                    </div>
                )}


                <Modal
                    centered
                    style={{width: '500px', maxWidth: '100%'}}
                    open={successModal}
                    onOk={() => {
                        setSuccessModal(false);

                    }}
                    onCancel={() => setSuccessModal(false)}
                    footer={null}
                >
                    <div className={'rvpConfirmationModal'}>
                        <svg style={{justifySelf: 'center'}} width="40" height="40" viewBox="0 0 40 40" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M28.5937 15.3125L17.1354 26.25L11.4062 20.7812M38.75 20C38.75 30.3553 30.3553 38.75 20 38.75C9.64466 38.75 1.25 30.3553 1.25 20C1.25 9.64466 9.64466 1.25 20 1.25C30.3553 1.25 38.75 9.64466 38.75 20Z"
                                stroke="#FBCB38" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>

                        {/*<div className={'rvpConfirmationModalTitle'}>Successful!</div>*/}

                        <div style={{textAlign: 'center'}}>
                            Your transaction has been sent to the reviewer group for action
                        </div>

                        <div className={'rvpConfirmationModalActionButton'}>
                            <button className="btn btn-success px-7 py-2 h-44 w-150 rounded-1 " onClick={() => {
                                setSuccessModal(false);
                                navigate("/admin/payment/bill")
                            }}>
                                <span className="indicator-label fs-base text-White">Done</span>
                            </button>
                        </div>
                    </div>
                </Modal>
            </div>
        </>
    );
};

export default ElectronicInvoicePayment;
