import { NumericInput, NumericInputsProps } from './NumericInput';

interface NumericFormProps extends NumericInputsProps {
    name?: string;
    onChange: (e) => void;
    maxLength?: number;
    formik?: any;
    PrefixIcon?: any;
    formikName?: string;
    Label: string;
    labelClassName?: string;
    labelContainerClassName?: string;
    inputContainerClassName?: string;
    showError?: boolean;
    containerClassName?: string;
    required?: boolean;
}

const NumericForm: React.FC<NumericFormProps> = ({
    name = '',
    onChange,
    maxLength,
    formik,
    PrefixIcon,
    formikName,
    Label,
    labelClassName,
    labelContainerClassName,
    inputContainerClassName,
    showError = true,
    containerClassName,
    required = true,

    value,
    prefix = '₦',
    type = 'text',
    isAllowed,
    onValueChange,
    placeholder,
    thousandsGroupStyle = 'thousand',
    thousandSeparator = ',',
    allowNegative = false,
    allowLeadingZeros = false,
    disabled,
    decimalScale,
    isNumberInput = false,
    className,
}) => (
    <div className={`mt-1 ${containerClassName}`}>
        <div className={`col-lg-4 col-form-label py-0 input-height ${labelContainerClassName}`}>
            {formikName && formik ? (
                Number(formik.values[formikName] || 0) > 0 ? (
                    <label className={`${required ? 'required' : ''} required fw-bold fs-9  ${labelClassName}`}>
                        {Label}
                    </label>
                ) : (
                    ''
                )
            ) : (
                <label className={`${required ? 'required' : ''} fw-bold fs-9  ${labelClassName}`}>{Label}</label>
            )}
        </div>

        <div
            className={`form-control form-control-solid ${inputContainerClassName} ${formik && formikName && !formik.errors[formikName] ? '' : ''}`}
        >
            {PrefixIcon && <img src={PrefixIcon} alt="form-icon" className="icon-style" />}

            <NumericInput
                type={type}
                prefix={isNumberInput ? undefined : prefix}
                thousandsGroupStyle={isNumberInput ? undefined : thousandsGroupStyle}
                thousandSeparator={isNumberInput ? undefined : thousandSeparator}
                value={value}
                className={className || 'no-outline text-dark ps-4 fs-8'}
                placeholder={placeholder|| Label}
                allowNegative={allowNegative}
                onValueChange={onValueChange}
                isAllowed={isAllowed}
                allowLeadingZeros={allowLeadingZeros}
                disabled={disabled || false}
                decimalScale={decimalScale}
                {...formik?.getFieldProps?.(formikName)}
            />
        </div>

        <div className="fv-plugins-message-container input-height">
            {showError && formikName && formik && (
                <div className="fv-help-block fs-9 text-danger">
                    {formik.touched[formikName] && formik.errors[formikName] && formik.errors[formikName]}
                </div>
            )}
        </div>
    </div>
);

export { NumericForm };
