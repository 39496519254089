import DropDown from "app/components/dropdown";
import { ConfirmationModal } from "app/components/modal/ConfirmationModal";
import { useOagfCreateNewBank } from "app/pages/auth/hooks/useUser";
import { useGetPaginatedAllMda } from "app/pages/payments/hooks/useRevenuePayment";
import {
  MdaValueNoIconContainer,
  searchIndicator,
} from "app/pages/payments/revenuePayments/components/UsefulFn";
import { LoaderContainer } from "core/helpers";
import { useFormik } from "formik";
import { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import { useNavigate } from "react-router-dom";
import { Breadcrumb } from "../../Project/components/Breadcrumb";
import { AddBankInitialValues, AddBankSchema } from "./component/Schems";

const links = [
  {
    name: "Bank Accounts",
    path: "/admin/bank-accounts/manage",
  },

  {
    name: "Manage Accounts",
  },
];

const CreateNewBank = () => {
  const { mutate: mutateAddNewBank, isLoading: createProjectLoading } =
    useOagfCreateNewBank();

  const {
    data: mdaNameList,
    mutate: mdaMutate,
    isLoading: allMdaLoading,
  } = useGetPaginatedAllMda();

  // 6373,37737,002993`useGetMdaUsers

  const navigate = useNavigate();

  const [show, setShow] = useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const formik = useFormik({
    initialValues: AddBankInitialValues, //projectInitialValues,
    validationSchema: AddBankSchema,
    //ProjectOagfSchema1
    onSubmit: async (values) => {
      setShowConfirm(true);
    },
  });

  const SuccessModal = () => {
    return (
      <Modal
        dialogClassName=" m-auto px-7 modal-card-success"
        aria-hidden="true"
        show={show}
        onHide={() => setShow(false)}
        animation
        centered
        size="lg"
        backdrop={"static"}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="rounded">
          <div className="">
            <div className=" d-flex flex-column align-items-center ">
              <div className="mt-5 mb-3">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.1562 12.1875L13.2812 18.75L9.84375 15.4688M26.25 15C26.25 21.2132 21.2132 26.25 15 26.25C8.7868 26.25 3.75 21.2132 3.75 15C3.75 8.7868 8.7868 3.75 15 3.75C21.2132 3.75 26.25 8.7868 26.25 15Z"
                    stroke="#61CD85"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>

              <div className="email-verification-container mb-3">
                <span className="email-verification-text">Successful!</span>
              </div>

              <div className="email-verification-container mb-5">
                <span className="email-verification-body">
                  New bank account added successfully.
                </span>
              </div>
            </div>

            <div className=" d-flex justify-content-center align-items-center mt-3 pe-9 ps-9 ">
              <button
                className="otp-button"
                onClick={() => navigate("/admin/bank-accounts/manage")}
              >
                <span className="otp-button-text">Ok</span>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const [mdaSelected, setSelectedMda] = useState(null);

  const CreateNewAccount = () => {
    setShowConfirm(false);
    const dataToSend = [
      {
        bankName: formik.values.bankName,
        accountName: formik.values.accountName,
        accountNumber: formik.values.accountNumber,
        currency: formik.values.currency,
        accountType: {
          code: "00000",
          description: formik.values.classification,
        },
        balance: formik.values.openingBalance,
        dailyLimit: formik.values.dailyLimit,
        orgId: formik.values.mda,
      },
    ];

    mutateAddNewBank(dataToSend, {
      onSuccess: () => {
        setShow(true);
      },
      onError: (err: any) => {},
    });
  };

  return (
    <>
      <div className={"adminBody"} ref={containerRef}>
        <Breadcrumb links={links} />

        {createProjectLoading && <LoaderContainer />}

        <div className="create-content-container">
          <div className="create-content">
            <p className="fs-8 fw-bolder text-dark2 mb-0 text-center">
              Add New Bank Account{" "}
            </p>

            <form
              onSubmit={formik.handleSubmit}
              noValidate
              className="form align-middle  gx-0 gy-5 mt-10"
            >
              <div className="my-3">
                <DropDown
                  ValueContainer={MdaValueNoIconContainer}
                  DropdownIndicator={searchIndicator}
                  mutate={mdaMutate}
                  value={mdaSelected}
                  labelKey={"registeredName"}
                  valueKey={"id"}
                  apiData={mdaNameList}
                  onChange={(selected: any) => {
                    setSelectedMda(selected || null);
                    formik.setFieldValue("mda", selected || null);
                  }}
                  placeholder="Search MDA *"
                  className=""
                  classNamePrefix="fs-8"
                  valueStyles={{
                    paddingLeft: "0rem",
                  }}
                  controlStyles={{
                    paddingLeft: "0.5rem",
                    paddingRight: "0.5rem",
                    height: 32,
                    display: "flex",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                  loading={allMdaLoading}
                />
              </div>

              <div className="mt-1">
                <div className="col-lg-12 col-form-label py-0 input-height">
                  {formik.values.accountName.length !== 0 && (
                    <label className="fw-bold fs-9 required">
                      Account Name
                    </label>
                  )}
                </div>

                <div className={`form-control form-control-solid disable`}>
                  <input
                    type="text"
                    placeholder="Account Name"
                    className={"no-outline text-dark fs-8"}
                    {...formik.getFieldProps("accountName")}
                  />
                </div>
                <div className="fv-plugins-message-container input-height">
                  <div className="fv-help-block fs-9 text-danger">
                    {formik.touched.accountName &&
                      formik.errors.accountName &&
                      formik.errors.accountName}
                  </div>
                </div>
              </div>

              <div className="mt-1">
                <div className="col-lg-12 col-form-label py-0 input-height">
                  {formik.values.bankName.length !== 0 && (
                    <label className="fw-bold fs-9 required">Bank Name</label>
                  )}
                </div>

                <div className={`form-control form-control-solid disable`}>
                  <input
                    type="text"
                    //placeholder="ProjectD"
                    className={"no-outline text-dark fs-8"}
                    disabled
                    {...formik.getFieldProps("bankName")}
                  />
                </div>
                <div className="fv-plugins-message-container input-height">
                  <div className="fv-help-block fs-9 text-danger">
                    {formik.touched.bankName &&
                      formik.errors.bankName &&
                      formik.errors.bankName}
                  </div>
                </div>
              </div>

              <div className="mt-1">
                <div className="col-lg-12 col-form-label py-0 input-height">
                  {formik.values.accountNumber.length !== 0 && (
                    <label className="fw-bold fs-9 required">
                      Bank Account Number
                    </label>
                  )}
                </div>

                <div className={`form-control form-control-solid `}>
                  <input
                    type="text"
                    placeholder="Bank Account Number"
                    className={"no-outline text-dark fs-8"}
                    {...formik.getFieldProps("accountNumber")}
                  />
                </div>
                <div className="fv-plugins-message-container input-height">
                  <div className="fv-help-block fs-9 text-danger">
                    {formik.touched.accountNumber &&
                      formik.errors.accountNumber &&
                      formik.errors.accountNumber}
                  </div>
                </div>
              </div>

              <div className="mt-1">
                <div className="col-lg-12 col-form-label py-0 input-height">
                  {formik.values.classification.length !== 0 && (
                    <label className="fw-bold fs-9 required">
                      Bank Account Classification
                    </label>
                  )}
                </div>

                <div className={`form-control form-control-solid `}>
                  <select
                    {...formik.getFieldProps("classification")}
                    className={`no-outline fs-8 w-100 ${
                      formik.values.classification !== ""
                        ? "text-dark"
                        : "text-muted"
                    } `}
                  >
                    <option value="">Account classification</option>
                    <option value="Federation Account">
                      Federation Account (FAAC)
                    </option>
                    <option value="Special Accounts">Special Accounts</option>

                    <option value=" Revolving Account">
                      Revolving Account
                    </option>
                    <option value="TSA-Sub Account">TSA-Sub Account</option>
                    <option value="Donor/Project Account">
                      Donor/Project Account
                    </option>
                  </select>
                </div>
                <div className="fv-plugins-message-container input-height">
                  <div className="fv-help-block fs-9 text-danger">
                    {formik.touched.classification &&
                      formik.errors.classification &&
                      formik.errors.classification}
                  </div>
                </div>
              </div>

              <div className="mt-1">
                <div className="col-lg-12 col-form-label py-0 input-height">
                  {formik.values.currency.length !== 0 && (
                    <label className="fw-bold fs-9 required">Currency</label>
                  )}
                </div>

                <div className={`form-control form-control-solid `}>
                  <select
                    {...formik.getFieldProps("currency")}
                    className={`no-outline fs-8 w-100 ${
                      formik.values.currency !== "" ? "text-dark" : "text-muted"
                    } `}
                  >
                    <option value="">Currency</option>
                    <option value="NGN">Naira</option>
                    <option value="USD">US Dollar</option>
                    <option value="EUR">EURO</option>
                  </select>
                </div>
                <div className="fv-plugins-message-container input-height">
                  <div className="fv-help-block fs-9 text-danger">
                    {formik.touched.currency &&
                      formik.errors.currency &&
                      formik.errors.currency}
                  </div>
                </div>
              </div>

              <div className="mt-1">
                <div className="col-lg-12 col-form-label py-0 input-height">
                  {formik.values.openingBalance &&
                    formik.values.openingBalance > 0 && (
                      <label className="fw-bold fs-9 required">
                        Opening Balance
                      </label>
                    )}
                </div>

                <div className={`form-control form-control-solid`}>
                  <NumericFormat
                    type="text"
                    prefix="₦"
                    thousandsGroupStyle="thousand"
                    thousandSeparator=","
                    className="no-outline text-dark fs-8"
                    placeholder=" Opening Balance"
                    allowNegative={false}
                    allowLeadingZeros={false}
                    onValueChange={(event) =>
                      formik.setFieldValue("openingBalance", event.floatValue)
                    }
                    value={formik.values.openingBalance}
                  />
                </div>

                <div className="fv-plugins-message-container input-height">
                  <div className="fv-help-block fs-9 text-danger">
                    {formik.errors.openingBalance &&
                      formik.errors.openingBalance}
                  </div>
                </div>
              </div>

              <div className="mt-1">
                <div className="col-lg-12 col-form-label py-0 input-height">
                  {formik?.values?.dailyLimit &&
                    formik?.values?.dailyLimit > 0 && (
                      <label className="fw-bold fs-9 required">
                        Daily Transaction Limit
                      </label>
                    )}
                </div>

                <div className={`form-control form-control-solid`}>
                  <NumericFormat
                    type="text"
                    prefix="₦"
                    thousandsGroupStyle="thousand"
                    thousandSeparator=","
                    className="no-outline text-dark fs-8"
                    placeholder="Daily Transaction Limit"
                    allowNegative={false}
                    allowLeadingZeros={false}
                    onValueChange={(event) =>
                      formik.setFieldValue("dailyLimit", event.floatValue)
                    }
                    value={formik.values.dailyLimit}
                  />
                </div>

                <div className="fv-plugins-message-container input-height">
                  <div className="fv-help-block fs-9 text-danger">
                    {formik.errors.dailyLimit && formik.errors.dailyLimit}
                  </div>
                </div>
              </div>

              <div className="d-flex  justify-content-center mt-5">
                <button
                  id="kt_password_cancel "
                  type="submit"
                  className={`btn ${
                    !(formik.isValid && formik.dirty)
                      ? "btn-secondary"
                      : "btn-success"
                  } text-white px-15 py-2 h-44 w-min-110 rounded-1`}
                  disabled={!(formik.isValid && formik.dirty)}
                >
                  <span className="indicator-label fs-8">Submit</span>
                </button>
              </div>
            </form>
          </div>
        </div>

        <ConfirmationModal
          show={showConfirm}
          setShow={setShowConfirm}
          proceed={() => CreateNewAccount()}
        />
      </div>

      {SuccessModal()}
    </>
  );
};

export { CreateNewBank };
