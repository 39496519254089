import React from "react";
import {Tabs} from "antd";
import CreateUsers from "./CreateUsers";


const CreateNewUsersCard = () => {



    return (
            <div className={'userManagementTab'}>
                <Tabs>
                    <Tabs.TabPane tab="Create Users" key="create-users">
                        <CreateUsers/>
                    </Tabs.TabPane>
                </Tabs>
            </div>
    );
};

export default CreateNewUsersCard;
