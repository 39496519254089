import { useFormik } from 'formik';
import Multiselect from 'multiselect-react-dropdown';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import { Delete, infoBlue, plus } from 'core/assets/icons';
import { ErrorToast } from 'app/components/toast';
import {
    useGetProcessTypes,
    useGetUsersDetails,
    useRequestOnboardingStepUserWorkFlowProcess,
    useRequestOnboardingfinal,
} from '../hooks/useUser';
import { FetchAndUnmask } from 'core/helpers';

const Schema = Yup.object().shape({
    firstname: Yup.string().required('First Name is required'),
    lastname: Yup.string().required('Last Name is required'),
    phoneNumber: Yup.string().required('Phone Number is required'),
    email: Yup.string().required('Email is required'),
});

const initialValues = {
    firstname: '',
    lastname: '',
    phoneNumber: '',
    email: '',
};

interface Step1Props {
    handleNextStep: () => void;
    handlePreviousStep: () => void;
    setFormData: React.Dispatch<React.SetStateAction<any>>;
    setTableUserWork: React.Dispatch<React.SetStateAction<any>>;
    setStepTableUserWorkFlow: any;
    stepTableUserWorkFlow: any;
}

const StepSix: React.FC<Step1Props> = ({
    handleNextStep,
    handlePreviousStep,
    setFormData,
    setTableUserWork,
    setStepTableUserWorkFlow,
    stepTableUserWorkFlow,
}) => {
    const [loading, setLoading] = useState<Boolean>(false);

    let userJson = FetchAndUnmask('names');

    let userDataJson = userJson !== null ? JSON.parse(userJson) : null;

    ////console.log(userDataJson);

    const { data: userDetails, mutate: mutateUserDetails, isLoading: mutateUserDetailsLoading } = useGetUsersDetails();

    const { data: ProcessTypes, mutate: mutateProcessTypes, isLoading: ProcessTypesLoading } = useGetProcessTypes();

    useEffect(() => {
        mutateUserDetails();
        mutateProcessTypes();
    }, [mutateUserDetails]);

    const [selected, setSelected] = useState<any>({});
    const [selectedUser, setSelectedUser] = useState<any>([]);
    const [selectedUserArray, setSelectedUserArray] = useState<any>([]);
    const [userdata, setUserList] = useState<any>([]);
    const [showUserList, setShowUserList] = useState(false);
    const [currentSection, setCurrentSection] = useState('');
    const [userProcessType, setUserProcessType] = useState<any>({});
    const [proceed, setProceed] = useState<Boolean>(false);

    const multiselectRef = React.createRef<any>();

    const updateSelectUser = (event: any) => {
        const selectedUserData = event.target.value;

        // If the user is already in the temporary users array, remove it
        if (selectedUser.includes(selectedUserData)) {
            setSelectedUser(selectedUser.filter((tempUser) => tempUser !== selectedUserData));
        } else {
            // Otherwise, add it to the temporary users array
            setSelectedUser([...selectedUser, selectedUserData]);
        }
    };

    const onSubmitSelectUser = (where: any) => {
        setSelectedUserArray([...selectedUserArray, { user: selectedUser, where: where }]);
        setSelectedUser([]);
        setShowUserList(false);
    };

    const { data, mutate, isLoading } = useRequestOnboardingStepUserWorkFlowProcess();

    const { data: final, mutate: mutateFinal, isLoading: isLoadingFinal } = useRequestOnboardingfinal();

    const saveProcessFlow = () => {
        setConfirm(false);
        const result = selectedUserArray.reduce((acc: { [key: string]: string[] }, obj) => {
            const { user, where } = obj;
            if (!acc[where]) {
                acc[where] = [];
            }
            acc[where].push(...user);
            return acc;
        }, {});

        const roles = Object.entries(result).map(([name, users]) => ({
            name,
            users,
        }));

        if (userProcessType.length === 0 || roles.length === 0) {
            ErrorToast('Process type or user is not selected');
        } else {
            const types: string[] = userProcessType.map((obj) => {
                return {
                    ...obj,

                    roles,
                };
            });

            if (types.length >= 1) {
                mutate(
                    { types },
                    {
                        onSuccess: (res: any) => {
                            setShow(true);

                            setTableUserWork(true);
                            setStepTableUserWorkFlow(stepTableUserWorkFlow + 1);

                            setUserProcessType([]);
                            setProceed(true);

                            // handleNextStep();
                        },
                                                        onError: (error) => {
         ErrorToast(error.response.data.message);
        },
                    },
                );
            }
        }

        setSelectedUserArray([]);
        multiselectRef.current.resetSelectedValues();
    };

    const userHandleRemoveSelectUser = (index) => {
        const updatedArray = [...selectedUserArray];
        updatedArray.splice(index, 1);
        setSelectedUserArray(updatedArray);
    };

    const onSelectProcessType = (event) => {
        setUserProcessType(event);
    };

    const userFormArray = [
        { firstName: 'Ayodimeji' },
        { firstName: 'Ifeoluwa' },
        { firstName: 'Jade' },
        { firstName: 'NASD' },
    ];

    const userList = (where: any) => {
        return (
            <Modal
                dialogClassName=" m-auto px-7 modal-card-signup"
                aria-hidden="true"
                show={showUserList}
                onHide={() => setShowUserList(false)}
                animation
                centered
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body className="rounded">
                    <div className="">
                        {userDetails?.map((value: any, index: any) => {
                            if (value.super !== true) {
                                return (
                                    <div
                                        key={index}
                                        className="d-flex align-items-center  h-2r border-bottom border-gray-800 my-2 mx-4 cursor-pointer"
                                    >
                                        <input
                                            className="form-check-input w-1r h-1r rounded-0"
                                            type="checkbox"
                                            value={value?.login}
                                            checked={selectedUser.includes(value?.login)}
                                            onChange={updateSelectUser}
                                        />
                                        <span className="fs-9 ms-2 mb-2">{value?.login}</span>
                                    </div>
                                );
                            }
                        })}

                        <div className=" d-flex flex-column align-items-center mt-5 ">
                            {selectedUser.length >= 1 ? (
                                <button
                                    className={`btn btn-alt-success
                 text-white px-7 py-2 h-42 w-84 rounded-1`}
                                    onClick={() => onSubmitSelectUser(where)}
                                >
                                    <span className="indicator-label fs-base">Ok</span>
                                </button>
                            ) : (
                                <button
                                    className={`btn btn-alt-success
                 text-white px-7 py-2 h-42 w-84 rounded-1`}
                                    disabled
                                    onClick={() => onSubmitSelectUser(where)}
                                >
                                    <span className="indicator-label fs-base">Ok</span>
                                </button>
                            )}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    };

    const onNext = () => {
        mutateFinal(
            {},
            {
                onSuccess: (res: any) => {
                    handleNextStep();
                },
                                                onError: (error) => {
         ErrorToast(error.response.data.message);
        },
            },
        );
    };

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            setShow(true);
            setTableUserWork(true);

            formik.resetForm();
            setStepTableUserWorkFlow(stepTableUserWorkFlow + 1);
        },
    });

    const [show, setShow] = useState<boolean>(false);

    const SuccessModal = () => {
        return (
            <Modal
                dialogClassName=" m-auto px-7 modal-card-success"
                aria-hidden="true"
                show={show}
                onHide={() => setShow(false)}
                animation
                centered
                size="lg"
                backdrop={'static'}
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body className="rounded">
                    <div className="">
                        <div className=" d-flex flex-column align-items-center ">
                            <div className="mt-5 mb-3">
                                <svg
                                    width="30"
                                    height="30"
                                    viewBox="0 0 30 30"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M20.1562 12.1875L13.2812 18.75L9.84375 15.4688M26.25 15C26.25 21.2132 21.2132 26.25 15 26.25C8.7868 26.25 3.75 21.2132 3.75 15C3.75 8.7868 8.7868 3.75 15 3.75C21.2132 3.75 26.25 8.7868 26.25 15Z"
                                        stroke="#61CD85"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </div>

                            <div className="email-verification-container mb-3">
                                <span className="email-verification-text">Successful!</span>
                            </div>

                            <div className="email-verification-container mb-5">
                                <span className="email-verification-body">
                                    You have successfully added a user process flow. To add more flow, please click on
                                    the “add more" button
                                </span>
                            </div>
                        </div>

                        <div className=" d-flex justify-content-between align-items-center mt-3 pe-9 ps-9 ">
                            <button className="otp-button-addmore" onClick={() => setShow(false)}>
                                <span className="">Add more</span>
                            </button>

                            <button className="otp-button " onClick={() => onNext()}>
                                <span className="otp-button-text">Proceed</span>
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    };

    const ProcessTypesList = ProcessTypes?.map((item: any) => ({
        cat: item?.description,
        key: item?.code,
    }));

    const [showConfirm, setConfirm] = useState<any>(false);

    const confrimModal = () => {
        return (
            <Modal
                dialogClassName="  m-auto px-5 modal-card"
                aria-hidden="true"
                show={showConfirm}
                onHide={() => setConfirm(false)}
                animation
                centered
                size="lg"
                backdrop={'static'}
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body className="rounded">
                    <>
                        <div className="m-3 mx-15">
                            <div className=" d-flex flex-column align-items-center ">
                                <img src={infoBlue} alt="" className="w-50px h-50px my-5" />

                                <span className="text-center fs-base text-dark">Are you sure you want to submit?</span>
                            </div>
                            <div className="d-flex justify-content-between mt-10">
                                <button
                                    id="kt_password_cancel "
                                    type="button"
                                    className="btn btn-danger px-7 py-2 text-alt-red h-44 w-110 center-all "
                                    onClick={() => setConfirm(false)}
                                >
                                    <span className="indicator-label fs-base ">Cancel</span>
                                </button>
                                <button
                                    className=" btn btn-success h-44 w-110 center-all"
                                    type="button"
                                    onClick={saveProcessFlow}
                                >
                                    <span className="indicator-label fs-base ">Submit</span>
                                </button>
                            </div>
                        </div>
                    </>
                </Modal.Body>
            </Modal>
        );
    };

    return (
        <>
            <div>
                <div className="user-creation-header mb-3">Workflow Definition</div>
                <div className="user-creation-text mb-9">
                    This section is to assign the users created in the last section to user groups (initiator, reviewer
                    and approver) of the specified process type.
                </div>

                <div className="">
                    <div className="col-lg-6 col-form-label py-0 input-height my-1">
                        <label className="required fw-bold fs-9">Process Type</label>
                    </div>
                    <Multiselect
                        displayValue="cat"
                        ref={multiselectRef}
                        onKeyPressFn={function noRefCheck() {}}
                        onRemove={function noRefCheck() {
                            setUserProcessType([]);
                        }}
                        onSearch={function noRefCheck() {}}
                        onSelect={onSelectProcessType}
                        hidePlaceholder={true}
                        className="fs-10e rounded-1 "
                        style={{
                            chips: {
                                background: '#40A060',
                                minWidth: 100,
                                color: 'white',
                                fontWeight: '500',
                                borderRadius: 3,
                            },
                            searchBox: {
                                borderColor: '#72D393',
                                background: '#F9F9F9',
                                fontSize: 10,
                                height: '100%',
                                padding: '1rem',
                            },
                            option: {
                                color: '#40A060',
                                background: '#F9F9F9',
                            },
                            optionContainer: {},
                        }}
                        options={ProcessTypesList}
                    />
                </div>

                <div>
                    <div className="select-option mt-12 d-flex align-items-center ">
                        <span className="text-muted required fs-base">Initiator</span>
                        <div
                            className=" select-option-side d-flex justify-content-center align-items-center cursor-pointer h-3r w-191 rounded-1"
                            onClick={() => {
                                setCurrentSection('initiator');
                                setShowUserList(true);
                            }}
                        >
                            <img src={plus} alt="plus" className="w-1r h-1r" />
                            <span className=" ms-3 text-success fs-9">Add Initiator</span>
                        </div>
                    </div>

                    {selectedUserArray.map((user, i) => {
                        if (user.where === 'initiator') {
                            return (
                                <div className="d-flex align-items-end ms-5" key={i}>
                                    <span className="bg-footer w-4r  rounded-1 h-44 negative-top" />
                                    <div className=" d-flex h-24px align-items-center jusstify-content-evenly">
                                        <div className=" d-flex align-items-center ">
                                            <span className="bg-gray w-80e  rounded-1 h-4r d-flex align-items-end  negative-left " />
                                            <span className="bg-success w-10r  rounded-3 h-10r d-flex align-items-end negative-left" />
                                        </div>

                                        <span className="ms-12 text-dark2 fs-base fluid-width text-wrap">
                                            {user.user}
                                        </span>
                                        <span onClick={() => userHandleRemoveSelectUser(i)}>
                                            <img src={Delete} alt="deleteIcon" className="ms-4 w-1r h-1r" />
                                        </span>
                                    </div>
                                </div>
                            );
                        }
                    })}
                </div>

                {userProcessType['0']?.cat === 'Collections' ||
                userProcessType['0']?.cat === 'MDA creation' ||
                userProcessType['0']?.cat === 'Project Creation' ||
                userProcessType['0']?.cat === 'User Administration' ? (
                    ''
                ) : (
                    <div>
                        <div className="select-option mt-12 d-flex align-items-center ">
                            <span className="text-muted required fs-base">Reviewer</span>
                            <div
                                className=" select-option-side d-flex justify-content-center align-items-center cursor-pointer h-3r w-191 rounded-1"
                                onClick={() => {
                                    setCurrentSection('reviewer');
                                    setShowUserList(true);
                                }}
                            >
                                <img src={plus} alt="plus" className="w-1r h-1r" />
                                <span className=" ms-3 text-success fs-9">Add Reviewer</span>
                            </div>
                        </div>

                        {selectedUserArray.map((user, i) => {
                            if (user.where === 'reviewer') {
                                return (
                                    <div className="d-flex align-items-end ms-5" key={i}>
                                        <span className="bg-footer w-4r  rounded-1 h-44 negative-top" />
                                        <div className=" d-flex h-24px align-items-center jusstify-content-evenly">
                                            <div className=" d-flex align-items-center ">
                                                <span className="bg-gray w-80e  rounded-1 h-4r d-flex align-items-end  negative-left " />
                                                <span className="bg-success w-10r  rounded-3 h-10r d-flex align-items-end negative-left" />
                                            </div>

                                            <span className="ms-12 text-dark2 fs-base fluid-width text-wrap">
                                                {user.user}
                                            </span>
                                            <span onClick={() => userHandleRemoveSelectUser(i)}>
                                                <img src={Delete} alt="deleteIcon" className="ms-4 w-1r h-1r" />
                                            </span>
                                        </div>
                                    </div>
                                );
                            }
                        })}
                    </div>
                )}

                {userProcessType['0']?.cat === 'Collections' ||
                userProcessType['0']?.cat === 'MDA creation' ||
                userProcessType['0']?.cat === 'Project Creation' ||
                userProcessType['0']?.cat === 'User Administration' ? (
                    ''
                ) : (
                    <div>
                        <div className="select-option mt-12 d-flex align-items-center ">
                            <span className="text-muted required fs-base">Approver</span>
                            <div
                                className=" select-option-side d-flex justify-content-center align-items-center cursor-pointer h-3r w-191 rounded-1"
                                onClick={() => {
                                    setCurrentSection('approver');
                                    setShowUserList(true);
                                }}
                            >
                                <img src={plus} alt="plus" className="w-1r h-1r" />
                                <span className=" ms-3 text-success fs-9">Add Approver</span>
                            </div>
                        </div>

                        {selectedUserArray.map((user, i) => {
                            if (user.where === 'approver') {
                                return (
                                    <div className="d-flex align-items-end ms-5" key={i}>
                                        <span className="bg-footer w-4r  rounded-1 h-44 negative-top" />
                                        <div className=" d-flex h-24px align-items-center jusstify-content-evenly">
                                            <div className=" d-flex align-items-center ">
                                                <span className="bg-gray w-80e  rounded-1 h-4r d-flex align-items-end  negative-left " />
                                                <span className="bg-success w-10r  rounded-3 h-10r d-flex align-items-end negative-left" />
                                            </div>

                                            <span className="ms-12 text-dark2 fs-base fluid-width text-wrap">
                                                {user.user}
                                            </span>
                                            <span onClick={() => userHandleRemoveSelectUser(i)}>
                                                <img src={Delete} alt="deleteIcon" className="ms-4 w-1r h-1r" />
                                            </span>
                                        </div>
                                    </div>
                                );
                            }
                        })}
                    </div>
                )}

                <div className="select-option mt-12 d-flex align-items-center justify-content-around">
                    <span
                        onClick={(e) => setConfirm(true)}
                        className="text-success fw-bolder  fs-base text-center cursor-pointer"
                    >
                        Save
                    </span>
                </div>

                <nav className={`nav navbar-fixed-signup-bottom `} id="nav">
                    <div className="nav-center-signup">
                        <div className="nav-header-signup">
                            <button className="nav-btn-back nav-back-text" id="nav-btn" onClick={handlePreviousStep}>
                                <i className="fa fa-solid fa-arrow-left"></i>{' '}
                                <span style={{ marginLeft: '0.2rem' }}>back</span>
                            </button>{' '}
                            <button
                                id="kt_password_cancel "
                                type="submit"
                                className={`btn nav-btn-Proceed ${
                                    proceed ? 'btn-success' : 'btn-secondary'
                                } text-white px-7 py-2`}
                                disabled={proceed ? false : true}
                                onClick={onNext}
                            >
                                {!isLoading && <span className="indicator-label">Proceed</span>}
                                {isLoading && (
                                    <span className="indicator-progress" style={{ display: 'block' }}>
                                        Please wait...{' '}
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </div>
                </nav>
            </div>
            {SuccessModal()}
            {userList(currentSection)}
            {confrimModal()}
        </>
    );
};

export { StepSix };
