import { DatePicker, Table } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import { useEffect, useState } from "react";
import { FlapperSpinner } from "react-spinners-kit";
import { getWeekRange, isEmpty ,convertToTitleCase } from "core/helpers";
import { Download } from "app/components/download";
import { useMdaPaymentStatus } from "../../../Payments/hooks/usePayment";

const { RangePicker } = DatePicker;

const MdaPaymentRejected = () => {
  const [dateRange, setDateRange] = useState<any>([]);

  const {
    data: PaymentData,
    mutate: PaymentMutate,
    isLoading: PaymentLoading,
  } = useMdaPaymentStatus();

  useEffect(() => {
    const { startDate, endDate } = getWeekRange();
    setDateRange([dayjs(startDate), dayjs(endDate)]);

    const startDateData = moment(startDate).format("YYYY-MM-DD");
    const endDateData = moment(endDate).format("YYYY-MM-DD");
    PaymentMutate({
      status: "REJECTED",
      startDate: startDateData,
      endDate: endDateData,
    });
  }, []);

  const handleDateRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      setDateRange(dates);
      var date1 = moment(new Date(dates[0]).toISOString()).format("YYYY-MM-DD");
      var date2 = moment(new Date(dates[1]).toISOString()).format("YYYY-MM-DD");
      PaymentMutate({ status: "REJECTED", startDate: date1, endDate: date2 });
    } else {
      setDateRange([]);
    }
  };

  const dataSource: any[] = [];
  const dataSourceForExcel: any[] = [
    "Payment Type",
    "Payment Option",
    "Government Payment Reference (GPR)",
    "Amount",
    "Initiation Date & Time Stamp",
    "Rejection Stage",
    "Initiator",
    "Reviewer",
    "Approver",
  ];
  

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: "30",
    },
    {
      title: "Payment Type",
      dataIndex: "paymentType",
      key: "paymentType",
      width: "180",
    },
    {
      title: "Payment Option",
      dataIndex: "paymentOption",
      key: "paymentOption",
      width: "180",
    },
    {
      title: "Government Payment Reference (GPR)",
      dataIndex: "gpr",
      key: "gpr",
      width: "60",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: "80",
    },
    {
      title: "Initiation Date & Time Stamp",
      dataIndex: "initiationDateTime",
      key: "initiationDateTime",
      width: "80", 
    },
    {
      title: "Rejection Stage",
      dataIndex: "rejectionStage",
      key: "rejectionStage",
      width: "80",
    },
    {
      title: "Initiator",
      dataIndex: "initiator",
      key: "initiator",
      width: "80",
    },
    {
      title: "Reviewer",
      dataIndex: "reviewer",
      key: "reviewer",
      width: "80",
    },
    {
      title: "Approver",
      dataIndex: "approver",
      key: "approver",
      width: "80",
    },
  ];

  var totalAmount = 0;

  for (let i = 0; i < PaymentData?.length; i++) {
    var tAmount = PaymentData[i]?.totalAmount;

    if (tAmount) {
      totalAmount += tAmount;
    }
    const row = {
      id: i + 1,
      paymentType: convertToTitleCase(PaymentData[i]?.paymentType) || "N/A",
      paymentOption: PaymentData[i]?.bulk ? "Bulk" : "Single",
      gpr: PaymentData[i]?.gpr,
      amount: PaymentData[i]?.amount
        ? PaymentData[i]?.amount.toLocaleString("en", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : "0.00",
      initiationDateTime: moment(PaymentData[i]?.creationDate).format(
        "MMMM Do YYYY, h:mm a"
      ),
      initiator: PaymentData[i]?.initiator || "N/A",
      reviewer: PaymentData[i]?.reviewer || "N/A",
      approver: PaymentData[i]?.approver || "N/A",
      rejectionStage: !PaymentData[i]?.approver
        ? "Approval"
        : !PaymentData[i]?.reviewer
        ? "Reviewer"
        : "N/A",
    };
    dataSource.push(row);
    dataSourceForExcel.push(Object.values(row).slice(1));
  }

  return (
    <>
      {PaymentLoading ? (
        <div className="loading-container">
          <FlapperSpinner /> {/* Replace with your loading indicator */}
        </div>
      ) : (
        <div className={"outstandingPayment"}>
          <div className={"outstandingPaymentRow1"}>
            <div className={"outstandingPaymentBreadcrumb"}>
              <div>Analytics</div>

              <svg
                width="10"
                height="13"
                viewBox="0 0 10 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                  fill="#1F8505"
                />
              </svg>

              <div>Payment View</div>

              <svg
                width="10"
                height="13"
                viewBox="0 0 10 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                  fill="#1F8505"
                />
              </svg>

              <div>Payment Rejected</div>
            </div>

            <div>
              <span className="data-range-text-overview">Date Range:</span>
              <RangePicker
                className="py-1 fs-8 fw-bold datepicker w-250px"
                value={dateRange}
                onChange={handleDateRangeChange}
                allowClear
              />
            </div>
          </div>

          <div className={"outstandingPaymentRow2"}>
            <div>PAYMENT REJECTED</div>

            <div className="d-flex justify-content-end  mb-9 me-4">
              {!isEmpty(PaymentData) && (
                <Download
                  dataSourceForExcel={dataSourceForExcel}
                  dataSource={dataSource}
                  excelFileTitle={"Payment Rejected for MDA"}
                  pdfFileTitle={"Payment Rejected for MDA"}
                  excelPageTitle={"Payment Rejected for MDA"}
                  grandTotalFor={{
                    transactionAmount: "Transaction Amount",
                    processingFee: "Processing Fee",
                    vatProcessingFee: "VAT Processing Fee",
                    totalProcessingFee: "Total Processing Fee",
                    netAmount: "Net Amount",
                    cumulativeNetAmount: "Cumulative Net Amount",
                  }}
                />
              )}
            </div>

            <div>
              <div>TOTAL:</div>
              <div> &#x20A6;{totalAmount?.toLocaleString()} </div>
            </div>

            {/* <div>
              <Select
                dropdownMatchSelectWidth={false}
                defaultValue="Payment Type"
                style={{ width: 184 }}
                options={[
                  { value: "all", label: "All" },
                  { value: "rvp", label: "Retail/Vendor Payment" },
                  { value: "billPayment", label: "Bill Payment" },
                  { value: "salaryPayment", label: "Salary Payment" },
                  { value: "pensionPayment", label: "Pension Payment" },
                  { value: "taxPayment", label: "Tax Payment" },
                ]}
              />

              <Select
                dropdownMatchSelectWidth={false}
                defaultValue="Payment Option"
                style={{ width: 184 }}
                options={[
                  { value: "all", label: "All" },
                  { value: "single", label: "Single" },
                  { value: "bulk", label: "Bulk" },
                ]}
              />

              <Select
                dropdownMatchSelectWidth={false}
                defaultValue="Rejection Stage"
                style={{ width: 184 }}
                options={[
                  { value: "all", label: "All" },
                  { value: "single", label: "Reviewal" },
                  { value: "bulk", label: "Approval" },
                ]}
              />

              <div className={"filterButton"}>
                <button>
                  <img src={filter} alt="" />
                  <span>Apply Filter</span>
                </button>
              </div>

              <div>
                <button>Reset All</button>
              </div>
            </div> */}

            <div>
              <Table
                dataSource={dataSource}
                bordered
                columns={columns}
                scroll={{ x: "1200px" }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MdaPaymentRejected;
