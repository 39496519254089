import './Stepper.scss'

const Stepper = (step: number) => {
    return (
      <div className="  m-auto my-7e responsive-card overflow-none d-flex  flex-column">
        <div className="d-flex align-items-center">
            <div></div>
          <span
            className={`fw-bold fs-9 ${
              step >= 1 ? "project-touched-step" : "project-unTouched-step"
            }`}
          >
            1
          </span>
  
          <span
            className={`mx-1 ${step >= 1 ? "project-touched-line" : "project-unTouched-line"}`}
          />
  
          <span
            className={`fw-bold fs-9 ${
              step >= 2 ? "project-touched-step" : "project-unTouched-step"
            }`}
          >
            2
          </span>
  
          <span
            className={`mx-1  ${step >= 2 ? "project-touched-line" : "project-unTouched-line"}`}
          />
  
          <span
            className={`fw-bold fs-9 ${
              step >= 3 ? "project-touched-step" : "project-unTouched-step"
            }`}
          >
            3
          </span>

          <span
            className={`mx-1  ${step >= 3 ? "project-touched-line" : "project-unTouched-line"}`}
          />
  
          <span
            className={`fw-bold fs-9 ${
              step === 4 ? "project-touched-step" : "project-unTouched-step"
            }`}
          >
            4
          </span>

         
          
        </div>
        <div className="d-flex justify-content-between my-4 w-600px">
          <span className="fs-9 ">Basic Details</span>
  
          <span className="fs-9 ">Bank Account</span>
  
          <span className="fs-9 ">User Details</span>

          <span className="fs-9 ">Approval Workflow Definition</span>
        </div>
      </div>
    );
  };
  
  export  {Stepper};
  