import { isOagfAdmin } from "core/helpers";
import MdaOutstandingPayment from "./MDA/MdaOutstandingPayment";
import OagfOutstandingPayment from "./OAGF/OagfOutstandingPayment";

const OutstandingPayment = () => {
  return (
    <>
      {isOagfAdmin() ? <OagfOutstandingPayment /> : <MdaOutstandingPayment />}
    </>
  );
};

export { OutstandingPayment };
