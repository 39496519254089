import {Select, Table} from "antd";
import React from "react";
import { Download } from "app/components/download";


const OagfExpenses = () => {

  const dataSource: any[] = [];

  const dataSourceForExcel: any[] = [
    [

    ],
  ];


  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: "30",
    },
    {
      title: "Payment Type",
      dataIndex: "paymentType",
      key: "paymentType",
      width: "180",
    },
    {
      title: "Payment Option",
      dataIndex: "paymentOption",
      key: "paymentOption",
      width: "180",
    },
    {
      title: "Government Payment Reference",
      dataIndex: "gpr",
      key: "gpr",
      width: "60",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: "80",
    },
    {
      title: "Initiation Date & Time Stamp",
      dataIndex: "initiationDateTime",
      key: "initiationDateTime",
      width: "80",
    },
    {
      title: "Initiator",
      dataIndex: "initiator",
      key: "initiator",
      width: "80",
    },
    {
      title: "Reviewer",
      dataIndex: "reviewer",
      key: "reviewer",
      width: "80",
    },
    {
      title: "Approver",
      dataIndex: "approver",
      key: "approver",
      width: "80",
    },
  ];

  for (let i = 1; i <= 100; i++) {
    const row = {
      id: i,
      paymentType: `Pension`,
      paymentOption: `Bulk`,
      gpr: `0000-0000-0000-0002`,
      amount: `₦1,000,000`,
      initiationDateTime: `23 Mar 2023 8:00AM`,
      initiator: `Ogundipe Damilola`,
      reviewer: `Obed Favour`,
      approver: `Joke Silver`,
    };
    dataSource.push(row);
  }


  return (
        <div className={"expReceiptViewRow4"}>
          <div className="d-flex justify-content-end  mb-9 me-4">
            <Download
                dataSourceForExcel={dataSourceForExcel}
                dataSource={dataSource}
                excelFileTitle={"Collection Inflow for MDA"}
                pdfFileTitle={"Collection Inflow for MDA"}
                excelPageTitle={"Collection Inflow for MDA"}
                grandTotalFor={{
                  amount: "Transaction Amount"                }}
            />
          </div>

          <div>
              <div className={"expReceiptViewSelectOptions"}>
                  <Select
                      dropdownMatchSelectWidth={false}
                      defaultValue="Payment Type"
                      style={{ width: 140 }}
                      options={[
                          { value: "rvp", label: "Retail/Vendor Payment" },
                          { value: "billPayment", label: "Bill Payment" },
                          { value: "salaryPayment", label: "Salary Payment" },
                          { value: "pensionPayment", label: "Pension Payment" },
                          { value: "taxPayment", label: "Tax Payment" },
                      ]}
                  />

                  <Select
                      dropdownMatchSelectWidth={false}
                      defaultValue="Payment Option"
                      style={{ width: 152 }}
                      options={[
                          { value: "bulk", label: "Bulk" },
                          { value: "single", label: "Single" },
                      ]}
                  />

                  <Select
                      dropdownMatchSelectWidth={false}
                      defaultValue="Amount"
                      style={{ width: 120 }}
                      options={[
                          { value: "less", label: "Less Than N2,000,000" },
                          { value: "greater", label: "Greater Than N2,000,000" },
                      ]}
                  />

                  <button>
                      <span>Apply Filter</span>
                      <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M2.63037 3.5H13.3698C13.4667 3.5 13.5615 3.52814 13.6426 3.58099C13.7238 3.63384 13.7879 3.70914 13.8271 3.79772C13.8663 3.88631 13.8789 3.98437 13.8634 4.07999C13.8479 4.1756 13.8049 4.26466 13.7398 4.33634L9.63013 8.85697C9.54646 8.949 9.5001 9.06892 9.5001 9.1933V12.7324C9.5001 12.8147 9.47977 12.8958 9.44093 12.9683C9.40209 13.0409 9.34594 13.1028 9.27745 13.1484L7.27745 14.4818C7.20215 14.532 7.11463 14.5608 7.02424 14.5652C6.93384 14.5695 6.84396 14.5493 6.76417 14.5066C6.68438 14.4639 6.61767 14.4003 6.57116 14.3227C6.52466 14.245 6.5001 14.1562 6.5001 14.0657V9.1933C6.5001 9.06892 6.45373 8.949 6.37007 8.85697L2.2604 4.33634C2.19524 4.26466 2.15231 4.1756 2.13681 4.07999C2.12131 3.98437 2.13393 3.88631 2.17311 3.79772C2.2123 3.70914 2.27638 3.63384 2.35755 3.58099C2.43873 3.52814 2.53351 3.5 2.63037 3.5Z" stroke="#CCCCCC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                  </button>
              </div>

              <div>
                  <div>TOTAL &nbsp;</div>
                  <div>N6,000,000,000</div>
              </div>
          </div>

          <Table
              dataSource={dataSource}
              bordered
              columns={columns}
              scroll={{ x: "1700px" }}
          />
        </div>
        );
};

export default OagfExpenses;
