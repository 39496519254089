import { Table } from "antd";
import React from "react";
import "./PaymentUploadSummary.scss";
import moment from "moment";

interface PaymentUploadSummaryProps {
  batchId: string | number;
  dataSource: any;
  paymentType: string;
  totalPayableSum: number;
  processingFee: number;
}

export const PaymentUploadSummary: React.FC<PaymentUploadSummaryProps> = ({
  batchId,
  dataSource,
  totalPayableSum,
  processingFee,
  paymentType,
}) => {
  return (
    <div className={"payment-upload-summary "}>
      <div className={"pus-Row1"}>
        <div>Payment Summary</div>

        <div>
          <div>
            <div className="left-row">
              <div>Transaction Count</div>
              <div>{dataSource.length}</div>
            </div>
            <div className="left-row">
              <div>Date of Transaction</div>
              <div>{moment(new Date()).format("D MMM YYYY")}</div>
            </div>
          </div>
          <div className="right-row">
            <div> ₦{(totalPayableSum + processingFee).toLocaleString()}</div>
            <div>Grand total payable</div>
          </div>
        </div>

        <div>
          <div>
            <div className="left-row">
              <div>Payment Type</div>
              <div>{paymentType}</div>
            </div>

            <div className="left-row">
              <div>Batch Number</div>
              <div>{batchId}</div>
            </div>
          </div>

          <div>
            <div className="left-row">
              <div>Total Amount Payable</div>
              <div>₦{totalPayableSum.toLocaleString()}</div>
            </div>

            <div className="left-row">
              <div>Processing Fee</div>
              <div>₦{processingFee.toLocaleString()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
