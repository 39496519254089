import { InputField } from 'app/components/formInputs/InputFields';
import { ErrorToast } from 'app/components/toast';
import { checked, checkedFilled, receipt, search as searchIcon, userGreen, userPlus } from 'core/assets/icons';
import { arrowDown } from 'core/assets/imgs';
import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useNavigate } from 'react-router-dom';
import { useGetInvoice, useGetMdaBySector, useGetSector } from '../hooks/useRevenuePayment';
import { InvoiceComponent } from './components/Invoice';
import { RevenueComponent } from './components/Revenue';

const MdaDirectoryPayment: React.FC = () => {
    const navigate = useNavigate();
    const [searchWhere, setSearchWhere] = useState<string>('');
    const [dropDown, setDropDown] = useState<boolean>(false);
    const [proceedData, setProceedData] = useState<any>({
        mda: '',
        serviceType: '',
        paymentType: '',
        serviceName: '',
        serviceId: '',
        mdaName: '',
        mdaId: '',
    });
    const [payment, setPaymentType] = useState<any>('');
    const [refNo, setRefNo] = useState<any>('');
    const [data, setData] = useState<any>({
        sector: '',
        organization: '',
        subOrganization: '',
        customFields: [],
    });
    const [selected, setSelected] = useState<any>({});
    const [showSector, setShowSector] = useState<boolean>(false);
    const [showOrganization, setShowOrganization] = useState<boolean>(false);
    const [showSubOrganization, setShowSubOrganization] = useState<boolean>(false);
    const [selectedSector, setSelectedSector] = useState<any>({});
    const [selectedOrg, setSelectedOrg] = useState<any>([]);
    const [selectedSubOrg, setSelectedSubOrg] = useState<any>({});
    // const [searchCode, setSearchCode] = useState<any>("");
    // const [searchLoading, setSearchLoading] = useState<boolean>(false);
    // const [searchResults, setSearchResults] = useState<any>([]);

    const { data: getSector, mutate: mutateGetSector } = useGetSector();

    const { mutate: mutateGetInvoice, isLoading: loadingRef } = useGetInvoice();
    const { mutate: orgMutate, isLoading: isLoadingOrg } = useGetMdaBySector();

    useEffect(() => {
        mutateGetSector();
    }, [mutateGetSector]);

    React.useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, []);

    const filterList = (data: any) => {
        if (searchWhere === 'sector') {
            var filteredData = getSector?.filter((listItem: any) => {
                return listItem.description.toLowerCase()?.includes(data[searchWhere]?.toLowerCase());
            });
            return filteredData;
        }
        if (searchWhere === 'organization') {
            return selectedOrg.filter(
                (listItem: any) =>
                    listItem?.registeredName?.toLowerCase()?.includes(data[searchWhere]?.toLowerCase()) ||
                    listItem?.mdaCode?.toLowerCase()?.includes(data[searchWhere]?.toLowerCase()) ||
                    listItem?.mdaAdminCode?.toLowerCase()?.includes(data[searchWhere]?.toLowerCase()),
            );
        }

        if (searchWhere === 'subOrganization') {
            return selectedSubOrg.filter((listItem: any) =>
                listItem?.name?.toLowerCase()?.includes(data[searchWhere]?.toLowerCase()),
            );
        }
    };

    const Where = (value: string, e: any) => {
        setSearchWhere(value);
        setProceedData({
            mda: '',
            serviceType: '',
            paymentType: '',
        });
        setDropDown(false);
        if (value === 'sector') {
            setData({ ...data, sector: e, organization: '', subOrganization: '' });
            setShowSector(true);
            setShowOrganization(false);
            setShowSubOrganization(false);
            setSelected({});
            setPaymentType('revenue');
        }
        if (value === 'organization') {
            setData({ ...data, organization: e, subOrganization: '' });
            setShowSector(false);
            setShowOrganization(true);
            setShowSubOrganization(false);
            setSelected({});
            setPaymentType('revenue');
        }
        if (value === 'subOrganization') {
            setData({ ...data, subOrganization: e });
            setShowSector(false);
            setShowOrganization(false);
            setShowSubOrganization(true);
            // setSelected({});
            setPaymentType('revenue');
        }
    };

    const showALL = (current: any) => {
        setDropDown(true);
        setSearchWhere(current);
        toggleDropdown();
    };

    const toggleDropdown = () => {
        setDropDown(!dropDown);
    };

    const onClickOutsideListener = () => {
        if (dropDown) {
            toggleDropdown();
            document.removeEventListener('click', onClickOutsideListener);
        }
    };

    useEffect(() => {});

    const updateSector = (value: any) => {
        setProceedData({ mda: '', serviceType: '', paymentType: '' });
        setSelectedSector(value);
        orgMutate(value, {
            onSuccess: (res: any) => {
                setSelectedOrg(res);
            },
        });

        setData({
            ...data,
            sector: value,
            organization: '',
            subOrganization: '',
        });
        setShowSector(false);
        setDropDown(false);
        setSelected({});
    };

    const updateOrganization = (value: any) => {
        setSelectedSubOrg(value?.children);
        setData({
            ...data,
            organization: value.registeredName,
            subOrganization: '',
        });
        setShowOrganization(false);
        setDropDown(false);

        if (value.services.length > 0) {
            setSelected(value);
            chooseMda(value);
        } else {
            setSelected({});
        }
    };

    const updateSubOrganization = (value: any) => {
        setSelected(value);
        setData({
            ...data,
            subOrganization: value?.registeredName,
        });
        setShowSubOrganization(false);
        setDropDown(false);
    };

    // const emptyFilledData = () => {
    //   setData({
    //     sector: "",
    //     organization: "",
    //     subOrganization: "",
    //   });
    //   setProceedData({ mda: "", serviceType: "", paymentType: "" });
    //   setSelected({});
    //   setPaymentType("");
    // };

    const chooseMda = (value: any) => {
        // emptyFilledData();
        setProceedData({
            ...proceedData,
            mdaId: value?.id,
            mdaName: value?.registeredName,
            serviceType: '',
            paymentType: '',
            serviceId: '',
            serviceName: '',
            amount: '',
        });
        setSelected(value);
        setData({
            sector: value?.sector?.code,
            organization: value?.registeredName,
            subOrganization: '',
            //subOrganization: value?.children,
        });
    };

    const Proceed = () => {
        if (refNo.length > 0) {
            mutateGetInvoice(refNo, {
                onSuccess(data) {
                    if (data?.invoice?.status.toLowerCase() === 'paid') {
                        ErrorToast('Invoice Already Paid');
                        return;
                    }
                    navigate('/invoice', {
                        state: data,
                    });
                },
                onError(error: any) {
                    if (error.response?.status === 404) {
                        ErrorToast('Reference  number not found');
                        return;
                    }
                    ErrorToast(error.response.data.message);
                },
            });

            return;
        } else {
            navigate('/revenue', {
                state: {
                    ...proceedData,
                    mdaName: proceedData?.mdaName,
                    mdaId: proceedData?.mdaId,
                    serviceType: proceedData?.serviceType,
                    serviceId: proceedData?.serviceId,
                    serviceName: proceedData?.serviceName,
                    amount: proceedData?.amount,
                    customFields: proceedData?.customFields,
                    payComps: proceedData?.paymentComponents,
                },
            });
        }
    };

    return (
        <div>
            <div className="my-10 mb-20">
                <Container>
                    <Row className="gy-10">
                        <Col xs={12} md={5} lg={5} className="">
                            <div className="card border-0 p-8 min-h-349e ">
                                <span className="text-dark fs-5 fw-bolder">Search Directory</span>
                                <>
                                    <div className="mt-1">
                                        <div className="col-lg-4 col-form-label py-0 input-height">
                                            {data?.sector?.length !== 0 && (
                                                <label className="fw-bold fs-9">Sector</label>
                                            )}
                                        </div>

                                        <div
                                            className={`form-control form-control-solid pe-0 ${
                                                data?.sector?.length !== 0 ? 'border-success' : ''
                                            }`}
                                            onClick={() => showALL('sector')}
                                        >
                                            <img src={receipt} alt="" className="w-1r1 h-1r1 d-none d-lg-inline" />

                                            <InputField
                                                name="Sector"
                                                value={data.sector}
                                                placeholder="Search Sector *"
                                                onChange={(e) => Where('sector', e.target.value)}
                                                className={'no-outline ps-2 text-dark fs-8 w-80'}
                                                // disabled={true}
                                            />

                                            <img src={arrowDown} alt="" className="w-1r1 h-1r1" />
                                        </div>

                                        {/* <div className="text-end">
                                            <span
                                                className="text-success fw-bolder fs-9  cursor-pointer"
                                                onClick={() => showALL('sector')}
                                            >
                                                Show All
                                            </span>
                                        </div> */}

                                        {dropDown && searchWhere === 'sector' && (
                                            <>
                                                <div
                                                    className="drop d-flex flex-column justify-content-center overflow-auto"
                                                    onMouseLeave={() => {
                                                        document.addEventListener('click', onClickOutsideListener);
                                                    }}
                                                >
                                                    {getSector?.map((value: any, index: any) => {
                                                        return (
                                                            <div
                                                                className={`h-2r mda-border cursor-pointer`}
                                                                key={index}
                                                                onClick={() => updateSector(value.description)}

                                                                //${selected?.code === value?.code ? "bg-warning-alt" : ""}
                                                            >
                                                                <div className="fs-9 lh-xxl text-dark ps-2">
                                                                    {value.description}
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </>
                                        )}

                                        {showSector && data?.sector?.length !== 0 && (
                                            <ul className="dd-menu ps-1">
                                                {filterList(data)?.map((value: any, index: any) => {
                                                    return (
                                                        <li
                                                            className={`h-2r border-bottom border-gray-light my-1  rounded-1 cursor-pointer`}
                                                            key={index}
                                                            onClick={() => updateSector(value)}
                                                        >
                                                            <div className="fs-9 lh-xxl text-dark ms-2">
                                                                {value.description}
                                                            </div>

                                                            <li className="divider" />
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        )}
                                    </div>
                                    {isLoadingOrg ? (
                                        <div className="shine h5 w5 my-5"></div>
                                    ) : (
                                        data?.sector?.length !== 0 &&
                                        selectedSector.length !== 0 &&
                                        !showSector && (
                                            <div className="mt-1">
                                                <div className="col-lg-12 col-form-label py-0 input-height">
                                                    {data.organization.length !== 0 && (
                                                        <label className="fw-bold fs-9">Organization / code</label>
                                                    )}
                                                </div>

                                                <div
                                                    className={`form-control form-control-solid pe-0 ${
                                                        data.organization.length !== 0 ? 'border-success' : ''
                                                    }`}
                                                >
                                                    <img
                                                        src={userGreen}
                                                        alt=""
                                                        className="w-1r1 h-1r1 d-none d-lg-inline"
                                                    />
                                                    <input
                                                        type="text"
                                                        value={data.organization}
                                                        placeholder="Search Organization / code"
                                                        className={'no-outline text-dark ps-2 fs-8 w-80'}
                                                        onChange={(e) => Where('organization', e.target.value)}
                                                    />

                                                    <img src={searchIcon} alt="" className="w-1r1 h-1r1" />
                                                </div>

                                                <div className="text-end">
                                                    <span
                                                        className="text-success fw-bolder fs-9  cursor-pointer"
                                                        onClick={() => showALL('organization')}
                                                    >
                                                        Show All
                                                    </span>
                                                </div>

                                                {dropDown && searchWhere === 'organization' && (
                                                    <>
                                                        <div
                                                            className="drop d-flex flex-column justify-content-center overflow-auto"
                                                            onMouseLeave={() => {
                                                                document.addEventListener(
                                                                    'click',
                                                                    onClickOutsideListener,
                                                                );
                                                            }}
                                                        >
                                                            {selectedOrg?.map((value: any, index: any) => {
                                                                return (
                                                                    <div
                                                                        className={`h-2r mda-border cursor-pointer`}
                                                                        key={index}
                                                                        onClick={() => updateOrganization(value)}

                                                                        //${selected?.code === value?.code ? "bg-warning-alt" : ""}
                                                                    >
                                                                        <div className="fs-9 lh-xxl text-dark ps-2">
                                                                            {value?.mdaAdminCode || value.mdaCode} -{' '}
                                                                            {value.registeredName}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </>
                                                )}

                                                {showOrganization && data.organization.length !== 0 && (
                                                    <ul className="dd-menu ps-1">
                                                        {filterList(data)?.map((value: any, index: any) => {
                                                            return (
                                                                <li
                                                                    className={`h-2r border-bottom border-gray-light my-1  rounded-1 cursor-pointer }`}
                                                                    key={index}
                                                                    onClick={() => updateOrganization(value)}
                                                                >
                                                                    <div className="fs-9 lh-xxl text-dark ms-2">
                                                                        {value?.mdaAdminCode || value.mdaCode} -{' '}
                                                                        {value.registeredName}
                                                                    </div>

                                                                    <li className="divider" />
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                )}
                                            </div>
                                        )
                                    )}

                                    {data?.sector?.length !== 0 &&
                                        data.organization.length !== 0 &&
                                        selectedOrg.length !== 0 &&
                                        !showOrganization && (
                                            <div className="mt-1">
                                                <div className="col-lg-12 col-form-label py-0 input-height">
                                                    {data?.subOrganization?.length !== 0 && (
                                                        <label className=" fw-bold fs-9">Sub-Organization / code</label>
                                                    )}
                                                </div>

                                                <div
                                                    className={`form-control form-control-solid pe-0 ${
                                                        data.subOrganization.length !== 0 ? 'border-success' : ''
                                                    }`}
                                                >
                                                    <img
                                                        src={userPlus}
                                                        alt=""
                                                        className="w-1r1 h-1r1 d-none d-lg-inline"
                                                    />
                                                    <input
                                                        type="text"
                                                        value={data.subOrganization}
                                                        placeholder="Search Sub-Organization / code"
                                                        className={'no-outline text-dark fs-8 w-80 ps-2'}
                                                        onChange={(e) => Where('subOrganization', e.target.value)}
                                                    />

                                                    <img src={searchIcon} alt="search" className="w-1r1 h-1r1" />
                                                </div>

                                                <div className="text-end">
                                                    <span
                                                        className="text-success fw-bolder fs-9  cursor-pointer"
                                                        onClick={() => showALL('subOrganization')}
                                                    >
                                                        Show All
                                                    </span>
                                                </div>

                                                {dropDown && searchWhere === 'subOrganization' && (
                                                    <>
                                                        <div
                                                            className="drop d-flex flex-column justify-content-center overflow-auto"
                                                            onMouseLeave={() => {
                                                                document.addEventListener(
                                                                    'click',
                                                                    onClickOutsideListener,
                                                                );
                                                            }}
                                                        >
                                                            {selectedSubOrg.map((value: any, index: any) => {
                                                                return (
                                                                    <div
                                                                        className={`h-2r mda-border cursor-pointer`}
                                                                        key={index}
                                                                        onClick={() => updateSubOrganization(value)}
                                                                    >
                                                                        <div className="fs-9 lh-xxl text-dark ps-2">
                                                                            {value?.mdaAdminCode || value.mdaCode} -{' '}
                                                                            {value.registeredName}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </>
                                                )}

                                                {showSubOrganization && data.subOrganization?.length !== 0 && (
                                                    <ul className="dd-menu ps-1">
                                                        {filterList(data)?.map((value: any, index: any) => {
                                                            return (
                                                                <li
                                                                    className={`h-2r border-bottom border-gray-light my-1  rounded-1 cursor-pointer`}
                                                                    key={index}
                                                                    onClick={() => updateSubOrganization(value)}
                                                                >
                                                                    <div className="fs-9 lh-xxl text-dark ms-2">
                                                                        {value.name}
                                                                    </div>

                                                                    <li className="divider" />
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                )}
                                            </div>
                                        )}
                                </>
                            </div>
                        </Col>

                        <Col xs={12} md={7} lg={7} className="">
                            <div
                                className={`card   border-0 p-8 ${
                                    Object.keys(selected).length === 0 ? 'h-349e' : 'min-h-492e'
                                } `}
                            >
                                <span className="text-dark fs-5 fw-bolder">Details</span>

                                {Object.keys(selected).length === 0 ? (
                                    <div>
                                        <div className="d-flex flex-column m-2">
                                            <span>{selected?.name}</span>
                                            <span className="text-muted fs-9">No Results</span>
                                        </div>
                                        <hr className="rule text-gray-800 opacity-100" />
                                    </div>
                                ) : (
                                    <div>
                                        <div className="d-flex flex-column m-2">
                                            <span className="text-muted fs-9">MDA Name</span>
                                            <span className="text-muted fs-8">
                                                {data?.sector && `${data?.sector}`}
                                                {data?.organization && `/ ${data?.organization}`}
                                                {data?.subOrganization && `/ ${data?.subOrganization}`}
                                                <span className="text-dark fs-9">
                                                    {selected?.name && `/ ${selected?.name}`}
                                                </span>
                                            </span>
                                        </div>
                                        <hr className="rule text-gray-800 opacity-100" />

                                        <div className=" d-flex flex-column m-2">
                                            <span className="text-muted fs-9">MDA Code</span>
                                            <span>{selected?.mdaAdminCode || selected.mdaCode}</span>
                                        </div>

                                        <hr className="rule text-gray-800 opacity-100" />

                                        <div className="m-2">
                                            <span className="text-dark fs-8">Choose Payment Type</span>
                                        </div>

                                        <div className="m-2">
                                            <div>
                                                <div className="d-flex  flex-column  justify-content-between  mt-5">
                                                    <>
                                                        <div
                                                            className="row min-w-25 ms-2 cursor-pointer"
                                                            onClick={() => {
                                                                setRefNo('');
                                                                setProceedData({
                                                                    ...proceedData,
                                                                    serviceType: '',
                                                                    serviceId: '',
                                                                });
                                                                setPaymentType('revenue');
                                                            }}
                                                        >
                                                            <span className="form-check form-check-custom form-check-alt">
                                                                <img
                                                                    alt=""
                                                                    src={`${
                                                                        payment === 'revenue' ? checkedFilled : checked
                                                                    }`}
                                                                    className="w-1r1 h-1r1"
                                                                />
                                                                <span className="text-dark fs-9 ms-2">
                                                                    Revenue Payment
                                                                </span>
                                                            </span>
                                                        </div>

                                                        {payment === 'revenue' && (
                                                            <RevenueComponent
                                                                selected={selected}
                                                                setProceedData={setProceedData}
                                                                proceedData={proceedData}
                                                            />
                                                        )}

                                                        <div
                                                            className="row min-w-25 ms-2 cursor-pointer"
                                                            onClick={() => {
                                                                setRefNo('');
                                                                setProceedData({
                                                                    ...proceedData,
                                                                    serviceType: '',
                                                                });
                                                                setPaymentType('invoice');
                                                            }}
                                                        >
                                                            <span className="form-check form-check-custom form-check-alt">
                                                                <img
                                                                    alt=""
                                                                    src={`${
                                                                        payment === 'invoice' ? checkedFilled : checked
                                                                    }`}
                                                                    className="w-1r1 h-1r1"
                                                                />
                                                                <span className="text-dark fs-9 ms-2">
                                                                    E-invoice Payments
                                                                </span>
                                                            </span>
                                                        </div>

                                                        {payment === 'invoice' && (
                                                            <InvoiceComponent
                                                                receipt={receipt}
                                                                refNo={refNo}
                                                                setRefNo={setRefNo}
                                                            />
                                                        )}
                                                    </>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className="btn-pay">
                                    <button
                                        id="kt_password_cancel "
                                        className={`btn ${
                                            Object.keys(selected).length === 0 ? ' btn-secondary' : 'btn-success'
                                        } text-white px-7 py-2 h-44 w-min-110 rounded-1`}
                                        onClick={Proceed}
                                        disabled={
                                            Object.keys(selected).length === 0 ||
                                            (proceedData.serviceType.length === 0 && refNo.length === 0)
                                        }
                                    >
                                        {!loadingRef && <span className="indicator-label fs-base">Pay</span>}

                                        {loadingRef && (
                                            <span className="indicator-progress d-flex justify-content-center  align-items-center">
                                                <span className="d-none d-md-block">Please wait... </span>
                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export { MdaDirectoryPayment };
