import { Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useMdaActivate, useMdaDetails } from "../../../../auth/hooks/useUser";
import { filter } from "core/assets/icons";
import { Download } from "app/components/download";
import { default as Moment, default as moment } from "moment";
import { formatRequestEnums } from "core/helpers";

interface Step1Props {
  data: any;
}

const ReceivablesOverdue: React.FC<Step1Props> = ({ data }) => {
  const dataSource: any[] = [];
  const dataSourceForExcel: any[] = [
    [
      "Service Type",
      "Item Type",
      "Government Payment Reference (GPR)",
      "Amount",
      "Initiation Date",
      "Status",
      "Time",
    ],
  ];

  const columns = [
    {
      title: "S/N",
      dataIndex: "sn",
      key: "sn",
      width: "30",
    },
    {
      title: "MDA Name",
      dataIndex: "mdaName",
      key: "mdaName",
      width: "180",
    },
    {
      title: "Service Type",
      dataIndex: "serviceType",
      key: "serviceType",
      width: "180",
    },
    {
      title: "Item Type",
      dataIndex: "itemType",
      key: "itemType",
      width: "60",
    },
    {
      title: "Government Payment Reference (GRR)",
      dataIndex: "gpr",
      key: "gpr",
      width: "60",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: "80",
    },
    {
      title: "Initiation Date",
      dataIndex: "initiationDate",
      key: "initiationDate",
      width: "80",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "42",
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      width: "42",
    },
  ];

  for (let i = 0; i < data?.length; i++) {
   //console.log(data[i]);

    const row = {
      sn: i + 1,
      serviceType: data[i]?.serviceType,
      mdaName: data[i]?.organizationName,
      itemType: data[i]?.itemType,
      gpr: data[i]?.grr,
      amount: data[i]?.amount
        ? data[i]?.amount.toLocaleString("en", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : "0.00",
      initiationDate: Moment(data[i]?.initiationDate).format("MMMM Do YYYY"),
      status: formatRequestEnums(data[i]?.status),
      time: Moment(data[i]?.initiationDate).format("h:mm a"),
    };
    dataSource.push(row);
    dataSourceForExcel.push(Object.values(row).slice(1));
  }

  return (
    <div className={"mdaPayableRow4"}>
      <div className="d-flex justify-content-end  mb-9 me-4">
        <Download
          dataSourceForExcel={dataSourceForExcel}
          dataSource={dataSource}
          excelFileTitle={"Collection Inflow for MDA"}
          pdfFileTitle={"Collection Inflow for MDA"}
          excelPageTitle={"Collection Inflow for MDA"}
          grandTotalFor={{
            transactionAmount: "Transaction Amount",
            processingFee: "Processing Fee",
            vatProcessingFee: "VAT Processing Fee",
            totalProcessingFee: "Total Processing Fee",
            netAmount: "Net Amount",
            cumulativeNetAmount: "Cumulative Net Amount",
          }}
        />
      </div>

      {/* <div>Filter by:</div>

        <div className={"mdaPayableSelectOptions"}>
          <Select
              dropdownMatchSelectWidth={false}
              defaultValue="Payment Type"
              style={{ width: 140 }}
              options={[{ value: "pension", label: "Pension" }]}
          />

          <Select
              dropdownMatchSelectWidth={false}
              defaultValue="Payment Option"
              style={{ width: 152 }}
              options={[
                { value: "bulk", label: "Bulk" },
                { value: "single", label: "Single" },
              ]}
          />

          <button>
            <span>Apply Filter</span>
            <img src={filter} alt="" />
          </button>
        </div> */}

      <div>
        <Table
          dataSource={dataSource}
          bordered
          columns={columns}
          scroll={{ x: "1000px" }}
        />
      </div>
    </div>
  );
};

export default ReceivablesOverdue;
