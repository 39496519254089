import React, { useState } from 'react';
import '../../../../App.css';
import coatOfArm from 'core/assets/imgs/dashboard/coatOfArm.png';
import userAvatar from 'core/assets/imgs/dashboard/avatar.png';
import {Layout} from "../layout";

const BusinessPreference = () => {
    const [currentTab, setCurrentTab] = useState('notificationSettings');

    function changeTab(tab) {
        setCurrentTab(tab);
    }

    function changeTabMobile() {
        setCurrentTab(currentTab === 'notificationSettings' ? 'security2FA' : 'notificationSettings' );
    }

  return (
      <>
      <div className={'businessPreference'}>
          <div className={'breadcrumb'}>
              <div>Setup & Administration</div>
              <svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z" fill="#1F8505"/>
              </svg>
              <div>Business Preferences</div>
          </div>

          <div className={'navCards'}>
              <div className={'navCard'} onClick={() => changeTab('notificationSettings')}>
                  <div className={'navCardTitle'} style={{color: '#FBCB38'}}>Notification Settings</div>
                  <div className={'navCardDescription'}>Set Email notifications for login and transactional activities  </div>
              </div>
              <div className={'navCard'} onClick={() => changeTab('security2FA')}>
                  <div className={'navCardTitle'} style={{color: '#EE151D'}}>Security - 2FA</div>
                  <div className={'navCardDescription'}>Enable OTP via email or Google Authenticator and Password settings</div>
              </div>
          </div>

          <div className={'navCardsMobile'}>
              <select value={currentTab} onChange={changeTabMobile}>
                  <option value="notificationSettings">Notification Settings</option>
                  <option value="security2FA">Security - 2FA</option>
              </select>
          </div>

          <div className={'notificationSettings'} style={{display: currentTab === 'notificationSettings' ? 'grid' : 'none'}}>
              <div className={'security2FAHeader'}>
                  <div>Notification Settings</div>
              </div>

              <div className={'security2FARow'}>
                  <div>
                      <div>Email Notification For Login</div>
                      <span>These are notifications for all login and forgot password</span>
                  </div>
                  <div>
                      <div>
                          <svg width="24" height="13" viewBox="0 0 24 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect x="0.75" y="0.75" width="22.5" height="11.5" rx="5.75" fill="#E8E8E8"/>
                              <circle cx="7.5" cy="6.5" r="3.5" fill="#666666"/>
                              <rect x="0.75" y="0.75" width="22.5" height="11.5" rx="5.75" stroke="#666666" strokeWidth="1.5"/>
                          </svg>
                          <span>Email</span>
                          <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M8.4375 8.9375H9V12.875H9.5625M15.75 9.5C15.75 13.2279 12.7279 16.25 9 16.25C5.27208 16.25 2.25 13.2279 2.25 9.5C2.25 5.77208 5.27208 2.75 9 2.75C12.7279 2.75 15.75 5.77208 15.75 9.5Z" stroke="#5E718D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                              <path d="M9 7.25C9.46599 7.25 9.84375 6.87224 9.84375 6.40625C9.84375 5.94026 9.46599 5.5625 9 5.5625C8.53401 5.5625 8.15625 5.94026 8.15625 6.40625C8.15625 6.87224 8.53401 7.25 9 7.25Z" fill="#5E718D"/>
                          </svg>
                      </div>

                      <div>
                          <svg width="24" height="13" viewBox="0 0 24 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect x="0.75" y="0.75" width="22.5" height="11.5" rx="5.75" fill="#E8E8E8"/>
                              <circle cx="7.5" cy="6.5" r="3.5" fill="#666666"/>
                              <rect x="0.75" y="0.75" width="22.5" height="11.5" rx="5.75" stroke="#666666" strokeWidth="1.5"/>
                          </svg>
                          <span>Push Notification</span>
                          <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M8.4375 8.9375H9V12.875H9.5625M15.75 9.5C15.75 13.2279 12.7279 16.25 9 16.25C5.27208 16.25 2.25 13.2279 2.25 9.5C2.25 5.77208 5.27208 2.75 9 2.75C12.7279 2.75 15.75 5.77208 15.75 9.5Z" stroke="#5E718D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                              <path d="M9 7.25C9.46599 7.25 9.84375 6.87224 9.84375 6.40625C9.84375 5.94026 9.46599 5.5625 9 5.5625C8.53401 5.5625 8.15625 5.94026 8.15625 6.40625C8.15625 6.87224 8.53401 7.25 9 7.25Z" fill="#5E718D"/>
                          </svg>
                      </div>
                  </div>
              </div>

              <div className={'security2FARow'}>
                  <div>
                      <div>Email Notification For Transactions</div>
                      <span>These are notifications for all Transaction Activities</span>
                  </div>
                  <div>
                      <div>
                          <svg width="24" height="13" viewBox="0 0 24 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect x="0.75" y="0.75" width="22.5" height="11.5" rx="5.75" fill="#E8E8E8"/>
                              <circle cx="7.5" cy="6.5" r="3.5" fill="#666666"/>
                              <rect x="0.75" y="0.75" width="22.5" height="11.5" rx="5.75" stroke="#666666" strokeWidth="1.5"/>
                          </svg>
                          <span>Email</span>
                          <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M8.4375 8.9375H9V12.875H9.5625M15.75 9.5C15.75 13.2279 12.7279 16.25 9 16.25C5.27208 16.25 2.25 13.2279 2.25 9.5C2.25 5.77208 5.27208 2.75 9 2.75C12.7279 2.75 15.75 5.77208 15.75 9.5Z" stroke="#5E718D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                              <path d="M9 7.25C9.46599 7.25 9.84375 6.87224 9.84375 6.40625C9.84375 5.94026 9.46599 5.5625 9 5.5625C8.53401 5.5625 8.15625 5.94026 8.15625 6.40625C8.15625 6.87224 8.53401 7.25 9 7.25Z" fill="#5E718D"/>
                          </svg>
                      </div>

                      <div>
                          <svg width="24" height="13" viewBox="0 0 24 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect x="0.75" y="0.75" width="22.5" height="11.5" rx="5.75" fill="#E8E8E8"/>
                              <circle cx="7.5" cy="6.5" r="3.5" fill="#666666"/>
                              <rect x="0.75" y="0.75" width="22.5" height="11.5" rx="5.75" stroke="#666666" strokeWidth="1.5"/>
                          </svg>
                          <span>Push Notification</span>
                          <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M8.4375 8.9375H9V12.875H9.5625M15.75 9.5C15.75 13.2279 12.7279 16.25 9 16.25C5.27208 16.25 2.25 13.2279 2.25 9.5C2.25 5.77208 5.27208 2.75 9 2.75C12.7279 2.75 15.75 5.77208 15.75 9.5Z" stroke="#5E718D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                              <path d="M9 7.25C9.46599 7.25 9.84375 6.87224 9.84375 6.40625C9.84375 5.94026 9.46599 5.5625 9 5.5625C8.53401 5.5625 8.15625 5.94026 8.15625 6.40625C8.15625 6.87224 8.53401 7.25 9 7.25Z" fill="#5E718D"/>
                          </svg>
                      </div>
                  </div>
              </div>
          </div>

          <div className={'security2FA'} style={{display: currentTab === 'security2FA' ? 'grid' : 'none'}}>
              <div className={'security2FAHeader'}>
                  <div>Security - 2FA</div>
              </div>

              <div className={'security2FARow'}>
                  <div>
                      <div>Enable 2 Factor Authentication or OTP to Email for all User Login Transaction Activity</div>
                      <span>On enabling the 2FA or OTP,  all users will be asked for additional code during sign-in and Transaction. This code will be obtained from Google Authenticator App or via OTP code sent to their email.</span>
                  </div>
                  <div>
                      <div>
                          <svg width="24" height="13" viewBox="0 0 24 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect x="0.75" y="0.75" width="22.5" height="11.5" rx="5.75" fill="#A5CE9B"/>
                              <circle cx="16.5" cy="6.5" r="3.5" fill="#01903C"/>
                              <rect x="0.75" y="0.75" width="22.5" height="11.5" rx="5.75" stroke="#01903C" strokeWidth="1.5"/>
                          </svg>

                          <span>Google Authenticator</span>
                      </div>

                      <div>
                          <svg width="24" height="13" viewBox="0 0 24 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect x="0.75" y="0.75" width="22.5" height="11.5" rx="5.75" fill="#A5CE9B"/>
                              <circle cx="16.5" cy="6.5" r="3.5" fill="#01903C"/>
                              <rect x="0.75" y="0.75" width="22.5" height="11.5" rx="5.75" stroke="#01903C" strokeWidth="1.5"/>
                          </svg>
                          <span>Email</span>
                      </div>
                  </div>
              </div>

              <div className={'security2FARow'}>
                  <div>
                      <div>Password Expiry Timeframe</div>
                      <span>Users password will have an expiration timeframe once define.</span>
                  </div>
                  <div>
                      <div>Password Expiry Timeframe (Days)</div>

                      <div>
                          <select>
                              <option value="">10</option>
                              <option value="">20</option>
                              <option value="" selected>30</option>
                          </select>
                      </div>
                  </div>
              </div>


              <div className={'security2FARow'}>
                  <div>
                      <div>Password Re-use</div>
                      <span>Password can be re-use if enabled.</span>
                  </div>
                  <div>
                      <div>
                          <svg width="24" height="13" viewBox="0 0 24 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect x="0.75" y="0.75" width="22.5" height="11.5" rx="5.75" fill="#E8E8E8"/>
                              <circle cx="7.5" cy="6.5" r="3.5" fill="#666666"/>
                              <rect x="0.75" y="0.75" width="22.5" height="11.5" rx="5.75" stroke="#666666" strokeWidth="1.5"/>
                          </svg>

                          <span>Off / On</span>
                      </div>

                  </div>
              </div>

              <div className={'security2FARow'}>
                  <div>
                      <div>Password length - minimum</div>
                      <span>Users password should be minimum of 8 characters.</span>
                  </div>
                  <div>
                      <div>
                          <svg width="24" height="13" viewBox="0 0 24 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect x="0.75" y="0.75" width="22.5" height="11.5" rx="5.75" fill="#E8E8E8"/>
                              <circle cx="7.5" cy="6.5" r="3.5" fill="#666666"/>
                              <rect x="0.75" y="0.75" width="22.5" height="11.5" rx="5.75" stroke="#666666" strokeWidth="1.5"/>
                          </svg>

                          <span>Off / On</span>
                      </div>

                  </div>
              </div>

              <div className={'security2FARow'}>
                  <div>
                      <div>Password characters</div>
                      <span>Users password should contain at least a number, symbol/special character, uppercase letter, lowercase letter</span>
                  </div>
                  <div>
                      <div>
                          <svg width="24" height="13" viewBox="0 0 24 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect x="0.75" y="0.75" width="22.5" height="11.5" rx="5.75" fill="#E8E8E8"/>
                              <circle cx="7.5" cy="6.5" r="3.5" fill="#666666"/>
                              <rect x="0.75" y="0.75" width="22.5" height="11.5" rx="5.75" stroke="#666666" strokeWidth="1.5"/>
                          </svg>

                          <span>Off / On</span>
                      </div>

                  </div>
              </div>


          </div>


      </div>
      </>
  );
};

export default BusinessPreference;
