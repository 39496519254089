import { FetchAndUnmask } from 'core/helpers';
import { createServiceMethod } from 'core/helpers/CreateServiceMethod';

const outflowService = {
    generateBatchId: createServiceMethod(`/services/organization/api/organizations/requests/generate-batch-id`),
    userBankDetails: createServiceMethod(`/services/organization/api/organizations/${FetchAndUnmask('orgId')}/banks`),
    createSinglePaymentRequest: createServiceMethod(`/services/organization/api/organizations/requests/initiate-request/async?request-type=payroll_payments`, 'post'),
};

export { outflowService };
