import { Dropdown, Input, MenuProps, Select, Space } from "antd";
import { Download } from "app/components/download";
import { TableComponent } from "app/components/table/Table";
import { FetchAndUnmask, LoaderContainer, formatRequestEnums } from "core/helpers";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Breadcrumb } from "../components/Breadcrumb";
import {
  useGetProjects,
  useProjectSummary,
  useUpdateProjectStatus,
} from "../hooks/hooks";
import { ProjectSuccessModal } from "../components/ProjectSuccessModal";
import { ConfirmationModal } from "app/components/modal/ConfirmationModal";

const links = [
  {
    name: "Project",
  },
];

const MDAProjectHome = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<any>("");
  const lazydayUser = JSON.parse(FetchAndUnmask("lazyday_user") as string);
  const [commenceModal, setcommenceModal] = useState<boolean>(false);
  const [shouldUpdateProjectStatus, setshouldUpdateProjectStatus] =
    useState<boolean>(false);
  const [selectedRecord, setSelectedRecord] = useState<any>();

  const {
    data: projectData,
    mutate: mutateProject,
    isLoading: isLoadingProject,
  } = useGetProjects();

  const {
    data: projectSummary,
    mutate: mutateProjectSummary,
    isLoading: isLoadingProjectSummary,
  } = useProjectSummary();

  const {
    mutate: mutateUpdateProjectStatus,
    isLoading: isLoadingUpdateProjectStatus,
  } = useUpdateProjectStatus();

  useEffect(() => {
    mutateProjectSummary();
  }, [mutateProjectSummary]);

  const dataSource: any[] = [];
  const columns = [
    {
      title: "S/N",
      dataIndex: "sn",
      key: "sn",
      width: "30",
    },

    {
      title: "Project ID",
      dataIndex: "projectID",
      key: "projectID",
      width: "30",
    },

    {
      title: "Project Name",
      dataIndex: "projectName",
      key: "projectName",
      width: "180",
    },

    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "60",
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "60",
    },

    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      width: "80",
    },

    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      width: "80",
    },

    {
      title: "Action",
      key: "action",
      render(text: any, record: any) {
        const items: MenuProps["items"] = [
          {
            label: (
              <button
                className="btn-clear"
                onClick={() => navigate("view/" + record.id)}
              >
                View Details
              </button>
            ),
            key: "1",
          },
          {
            label:
              record.status.toLowerCase() === "commenced" ? (
                <button
                  className={`btn-clear  ${
                    record.status.toLowerCase() === "approved"
                      ? ""
                      : "cursor-disabled"
                  }`}
                  onClick={() => {
                    setSelectedRecord(record);
                    setshouldUpdateProjectStatus(true);
                  }}
                >
                  Close
                </button>
              ) : (
                <button
                  className={`btn-clear  ${
                    record.status.toLowerCase() === "approved"
                      ? ""
                      : "cursor-disabled"
                  }`}
                  onClick={() => {
                    setSelectedRecord(record);
                    setshouldUpdateProjectStatus(true);
                  }}
                  disabled={!(record.status.toLowerCase() === "approved")}
                >
                  Commence
                </button>
              ),
            key: "2",
          },
          {
            label: <div>Manage Users</div>,
            key: "3",
          },
          {
            label: <div>Make Payment</div>,
            key: "3",
          },
        ];

        return (
          <Space wrap>
            <Dropdown menu={{ items }} placement="bottom" arrow>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
              >
                <path d="M480-160q-33 0-56.5-23.5T400-240q0-33 23.5-56.5T480-320q33 0 56.5 23.5T560-240q0 33-23.5 56.5T480-160Zm0-240q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm0-240q-33 0-56.5-23.5T400-720q0-33 23.5-56.5T480-800q33 0 56.5 23.5T560-720q0 33-23.5 56.5T480-640Z" />
              </svg>
            </Dropdown>
          </Space>
        );
      },
    },
  ];

  for (let i = 0; i < projectData?.data?.length; i++) {
    const currentData = projectData.data[i];

    const datad = {
      key: i.toString(),
      sn: i + 1,
      id: currentData?.id,
      projectID: currentData?.projectId,
      projectName: currentData?.name,
      description: currentData?.description,
      status: formatRequestEnums(currentData?.status),
      startDate: "N/A", //currentData?.createdDate,
      endDate: "N/A",
    };
    dataSource.push(datad);
  }

  const commenceProject = () => {
    const dataToSend = {
      id: selectedRecord.id,
      status: "COMMENCED",
    };

    setshouldUpdateProjectStatus(false);
    mutateUpdateProjectStatus(dataToSend, {
      onSuccess: () => {
        setcommenceModal(true);
      },
      onError: (err: any) => {},
    });
  };

  const CloseProject = () => {
    const dataToSend = {
      id: selectedRecord.id,
      status: "CLOSED",
    };

    setshouldUpdateProjectStatus(false);
    mutateUpdateProjectStatus(dataToSend, {
      onSuccess: () => {
        setcommenceModal(true);
      },
      onError: (err: any) => {},
    });
  };

  return (
    <>
      <div className={"adminBody"}>
        <Breadcrumb links={links} />

        {(isLoadingProject ||
          isLoadingProjectSummary ||
          isLoadingUpdateProjectStatus) && <LoaderContainer />}

        <div className="project-content-container">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <Download
                excelFileTitle={"dd"}
                pdfFileTitle={"dd"}
                excelPageTitle={"ddd"}
                dataSource={dataSource}
              />
            </div>
            <div>
              <button
                className="bg-success text-white fs-8 p-2 border-0 rounded-1 "
                onClick={() => navigate("create")}
              >
                Create New Project
              </button>
            </div>
          </div>

          <div>
            <div className="header-container">
              <span className="text-dark2 fs-5 fw-bolder">
                {lazydayUser?.registeredName} Projects
              </span>
            </div>

            <div className="header-container align-items-end">
              <div>
                <span className="text-muted fs-9">
                  Total Number of Projects
                </span>
                <div>
                  <span className="text-success fw-boldest">
                    {projectSummary?.totalProjects}
                  </span>
                </div>
              </div>
            </div>

            <div className="header-container flex-row justify-content-between">
              <div>
                <div>
                  <span className="text-muted fs-9">
                    Projects Awaiting Approval
                  </span>
                </div>
                <div>
                  <span className="text-muted fs-9 fw-boldest">
                    {projectSummary?.totalAwaitingApprovalProjects}
                  </span>
                </div>
              </div>

              <div>
                <div>
                  <span className="text-muted fs-9">Approved Projects</span>
                </div>
                <div>
                  <span className="text-muted fs-9 fw-boldest">
                    {projectSummary?.totalApprovedProjects}
                  </span>
                </div>
              </div>

              <div>
                <div>
                  <span className="text-muted fs-9">Commenced Project</span>
                </div>
                <div>
                  <span className="text-muted fs-9 fw-boldest">
                    {projectSummary?.totalCommencedProjects}
                  </span>
                </div>
              </div>

              <div>
                <div>
                  <span className="text-muted fs-9">Closed Projects</span>
                </div>
                <div>
                  <span className="text-muted fs-9 fw-boldest">
                    {projectSummary?.totalClosedProjects}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex align-items-center gap-20">
            <div className="d-flex gap-5 align-items-center">
              <div>Filter by:</div>
              <div>
                <Select
                  style={{ width: 150 }}
                  options={[
                    { value: "", label: "Status" },
                    { value: "Awaiting Approval", label: "Awaiting Approval" },
                    { value: "approved", label: "Approved" },
                    { value: "commenced", label: "Commenced" },
                    { value: "closed", label: "Closed" },
                  ]}
                  popupMatchSelectWidth={false}
                  // onChange={(e) => setStatus(e)}
                  showSearch
                  placeholder="Status"
                  filterOption={(input, option: any) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0 ||
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  className="h-41 w-133px bg-gray-800 text-gray-500 rounded-1"
                />
              </div>
            </div>

            <div className={"mdaDirectorySelectOptions"}>
              <Input
                placeholder={"Search by Project Name or By Project ID "}
                className="w-400px h-44 fs-8 bg-gray-800 text-gray-500"
                allowClear
                suffix={
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.4431 16.4438L20.9995 21.0001M18.75 10.875C18.75 15.2242 15.2242 18.75 10.875 18.75C6.52576 18.75 3 15.2242 3 10.875C3 6.52576 6.52576 3 10.875 3C15.2242 3 18.75 6.52576 18.75 10.875Z"
                      stroke="#666666"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                }
                value={searchValue}
                onChange={(e: any) => setSearchValue?.(e.target.value)}
              />
            </div>
          </div>

          <div>
            <TableComponent
              dataSource={dataSource}
              columns={columns}
              isLoading={isLoadingProject}
              mutateTable={mutateProject}
            />
          </div>
        </div>

        <ProjectSuccessModal
          show={commenceModal}
          setShow={setcommenceModal}
          done={() => setcommenceModal(false)}
        />

        <ConfirmationModal
          show={shouldUpdateProjectStatus}
          setShow={setshouldUpdateProjectStatus}
          proceed={() =>
            selectedRecord.status.toLowerCase() === "commenced"
              ? CloseProject()
              : commenceProject()
          }
        />
      </div>
    </>
  );
};

export { MDAProjectHome };
