import React, {useState} from "react";
import {useFormik} from "formik";
import {Preview} from "../../../auth/step/Preview";
import { StepOne } from "./steps/stepOne";
import { StepTwo } from "./steps/stepTwo";

const initialValues = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    officialEmail: "",
};

const finalStep = 2;


// const StepOne: React.FC<Step1Props> = ({ handleNextStep }) => {}
const CreateUsers = () => {
    const [step, setStep] = useState(1);
    const [selectedOption, setSelectedOption] = useState("");
    const [dropdowns, setDropdowns] = useState({
        // "mda-to

    });

    const [userLogin, setUserLogin] = useState("");

    const [stepOneData, setStepOneData] = useState([])


    const handleNextStep = () => {
        if (step === finalStep) {
            //console.log(step);
        } else {
            setStep(step + 1);
            //console.log(step);
        }
    };
    const handlePreviousStep = () => {
        setStep(step - 1);
    };

    const formik = useFormik({
        initialValues,
        // validationSchema: Schema,
        onSubmit: async (values) => {
           //console.log(values);
            
        },
    });

    const handleOptionChange = (event) => {
        const value = event.target.value;
        setSelectedOption(value);

        // Show the dropdown corresponding to the selected radio button
        setDropdowns(prevDropdowns => ({
            ...prevDropdowns,
            [value]: true
        }));
    };

    const handleCheckboxChange = (event) => {
        // Handle checkbox changes
    };


    return (
        <div>
            {
                step !== 3 ? (
                    <div className={"createUser"}>
                        <div className="  m-auto my-11 responsive-card overflow-none d-flex flex-column">
                            <div className="d-flex align-items-center">
                  <span
                      className={`fw-bold fs-9 ${
                          step >= 1 ? 'touched-step' : 'unTouched-step'
                      }`}
                  >
                    1
                  </span>

                                <span
                                    className={`mx-1 ${
                                        step >= 1 ? 'touched-line' : 'unTouched-line'
                                    }`}
                                />

                                <span
                                    className={`fw-bold fs-9 ${
                                        step >= 2 ? 'touched-step' : 'unTouched-step'
                                    }`}
                                >
                    2
                  </span>

                                <span
                                    className={`mx-1  ${
                                        step === 3 ? 'touched-line' : 'unTouched-line'
                                    }`}
                                />

                                <span
                                    className={`fw-bold fs-9 ${
                                        step === 3 ? 'touched-step' : 'unTouched-step'
                                    }`}
                                >
                    3
                  </span>
                            </div>

                            <div className="d-flex justify-content-between my-4">
                                <span className="fs-9 ">Step 1 of 3</span>

                                <span className="fs-9 ">Step 2 of 3</span>

                                <span className="fs-9 ">Step 3 of 3</span>
                            </div>
                        </div>

                        
                        {step === 1 &&
                            <StepOne handleNextStep={handleNextStep} setUserLogin={setUserLogin} setStepOneData={setStepOneData} />
                        }
                     

                        {step === 2 &&   <StepTwo handleNextStep={handleNextStep} userLogin={userLogin} stepOneData={stepOneData}  /> }
                      

                        {step === 3 &&  <div className={"userRoles"}>
                            <div className={"userRolesTitle"}>User Roles & Resources</div>

                            <div className={"userRolesDesc"}>
                                This section is to assign roles to users
                            </div>

                            <div className={"userRolesRadioButton"}>
                                <label>
                                    <input
                                        style={{marginRight: '15px'}}
                                        type="radio"
                                        value="mda-top-official"
                                        checked={selectedOption === "mda-top-official"}
                                        onChange={handleOptionChange}
                                    />
                                    MDA Top Official
                                </label>
                                <label>
                                    <input
                                        style={{marginRight: '15px'}}
                                        type="radio"
                                        value="mda-admin"
                                        checked={selectedOption === "mda-admin"}
                                        onChange={handleOptionChange}
                                    />
                                    MDA Admin
                                </label>
                                <label>
                                    <input
                                        style={{marginRight: '15px'}}
                                        type="radio"
                                        value="mda-collection-officer"
                                        checked={selectedOption === "mda-collection-officer"}
                                        onChange={handleOptionChange}
                                    />
                                    MDA Collection Officer
                                </label>
                                <label>
                                    <input
                                        style={{marginRight: '15px'}}
                                        type="radio"
                                        value="mda-payment-officer"
                                        checked={selectedOption === "mda-payment-officer"}
                                        onChange={handleOptionChange}
                                    />
                                    MDA Payment Officer
                                </label>
                                {dropdowns["mda-payment-officer"] && (
                                    <div className="checkbox-dropdown">
                                        <label>
                                            <input type="checkbox" value="rvp"/>
                                            Retail/Vendor Payment
                                        </label>
                                        <label>
                                            <input type="checkbox" value="billPayment"/>
                                            Bill Payment
                                        </label>
                                        <label>
                                            <input type="checkbox" value="payrollPayment"/>
                                            Payroll Payment
                                        </label>
                                        <label>
                                            <input type="checkbox" value="payrollPayment"/>
                                            Payroll Payment
                                        </label>
                                        <label>
                                            <input type="checkbox" value="taxPayment"/>
                                            Tax Payment
                                        </label>
                                    </div>
                                )}
                            </div>

                            <div className={"userRolesNextButton"}>
                                <button
                                    className=""
                                    // onClick={() => {
                                    //     setSuccessModal(false);
                                    //     setDataSource([]);
                                    //     navigate("/admin/payment/reprocess-unpaid-payments");
                                    // }}
                                >
                                    Save Changes
                                </button>
                            </div>
                        </div> }
                       

                    </div>
                ): (
                    <Preview
                        handleNextStep={handleNextStep}
                        handlePreviousStep={handlePreviousStep}
                        step={step}
                    />
                )
            }
        </div>
    );
};

export default CreateUsers;
