import * as Yup from "yup";
import { phoneRegExp } from "core/helpers";

export const Schema = Yup.object().shape({
  mda: Yup.string().required("MDA is required"),
  service: Yup.string().required("Service is required"),
  amount: Yup.string().required("Amount is required"),
  // description: Yup.string().required("Description is required"),
});

export const Schema2 = Yup.object().shape({
  mda: Yup.string().required("MDA is required"),
  service: Yup.string().required("Service is required"),
  amount: Yup.string().required("Amount is required"),
  // description: Yup.string().required("Description is required"),
  fullName: Yup.string().required("Full Name is required"),
  email: Yup.string().email("Wrong email format").required("Email is required"),
  email2: Yup.string()
    .oneOf([Yup.ref("email"), null], "Emails don't match!")
    .email("Wrong email format")
    .required("Email is required"),

  phone: Yup.string()
    .required("Phone Number is required")
    .matches(phoneRegExp, "Phone number is not valid"),
});
export const initialValues = {
  mda: "",
  service: "",
  amount: "",
  description: "",
  fullName: "",
  email: "",
  email2: "",
  phone: "",
};
