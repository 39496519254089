import React from "react";

import { arrowRight, about as AboutImg } from "core/assets/imgs";
import ReadMore from "core/helpers/ReadMore";

const AboutPage = () => {

  document.title = 'Login to portal'; 
  return (
    <div>
      <div className="section about" id="about">
        <div className="section-center about-center">
          <article className="about-info">
            <div className="about-title">
              <span className="about-header fs-2x">Get to know us</span>
            </div>
            <div className="d-flex flex-column">
              <span className="fs-base">
                <ReadMore
                  text="This is the FGN Treasury Portal where you can, as a Payer,
                  make revenue payments to the Federal Government via various
                  electronic channels as offered by the CBN approved Payment
                  Service Providers on the platform. The Treasury Portal is the
                  backbone for recording and processing all financial
                  transactions initiated by all MDAs and provides various
                  significant benefits including improved monitoring of the
                  Government’s cash position, better fiscal reporting, and
                  availability of comprehensive data for budget
                  conceptualization. Its integration to approved Payment PSSPs
                  ensures greater optimization of the TSA Initiative by
                  providing multiple payment options for seamless transactions
                  processing."
                  maxLength={290}
                />
              </span>
            </div>

            <button className="btn btn-success rounded-1 home-btn  mt-10">
              <span className="fs-5">
              <img
                  src={arrowRight}
                  className="icon-style ms-2"
                  alt="arrowRight"
                />
                Get Started
               
              </span>
            </button>
          </article>

          <article className="about-img d-none d-block-lg">
            <img src={AboutImg} className="about-img" alt="AboutImg" />
          </article>
        </div>
      </div>
    </div>
  );
};

export { AboutPage };
