import { Tag } from "antd";
import { Download } from "app/components/download";
import { TableComponent } from "app/components/table/Table";
import { infoBlue, searchCollection } from "core/assets/icons";
import moment from "moment";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import {
  useGetRevenueValidation,
  useSetRevenueValidationStatus,
} from "../Payments/hooks/usePayment";
import { debounce } from "lodash";

const RevenueValidation = () => {
  const [search, setSearch] = useState<string>("");
  const [searchFilterValue, setSearchFilterValue] = useState<string>("");
  const {
    data: getRevenueValidationData,
    mutate: mutateRevenueValidationData,
    isLoading: loadingRev,
  } = useGetRevenueValidation();

  const {
    data: setRevenueValidationStatusData,
    mutate: mutateSetRevenueValidationStatus,
    isLoading: loadingRevStatus,
  } = useSetRevenueValidationStatus();

  const debouncedSetSearchFilterValue = React.useCallback(
    debounce((value) => setSearchFilterValue(value), 1000),
    []
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    debouncedSetSearchFilterValue(event.target.value);
  };

  const dataSource: any[] = [];
  const dataSourceForExcel: any[] = [
    [
      // "ID",
      "Full Name",
      "Phone Number",
      "Government Revenue Reference (GRR)",
      "Amount",
      "Service",
      "Payment Date",
      "Payment Reference",
      "Payment Status",
      "Service Status",
    ],
  ];
  const downloadSource: any[] = [];

  const [showConfirm, setConfirm] = useState<any>(false);
  const [currentId, setCurrentId] = useState<any>("");
  const proceedToModal = (id: any) => {
    setCurrentId(id);
    setConfirm(true);
  };

  const Submit = () => {
    setConfirm(false);
    mutateSetRevenueValidationStatus(currentId, {
      onSuccess: (response: any) => {},
      onError: (err: any) => {},
      onSettled: () => {
        setCurrentId("");
      },
    });
  };

  const confrimModal = () => {
    return (
      <Modal
        dialogClassName="  m-auto px-5 modal-card"
        aria-hidden="true"
        show={showConfirm}
        onHide={() => setConfirm(false)}
        animation
        centered
        size="lg"
        backdrop={"static"}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="rounded">
          <div className="m-3 mx-15">
            <div className=" d-flex flex-column align-items-center ">
              <img src={infoBlue} alt="" className="w-50px h-50px my-5" />

              <span className="text-center fs-base text-dark">
                Are you sure you want to mark this service as delivered
              </span>
            </div>
            <div className="d-flex justify-content-between mt-10">
              <button
                id="kt_password_cancel "
                type="button"
                className="btn btn-danger px-7 py-2 text-alt-red h-44 w-110 center-all "
                onClick={() => setConfirm(false)}
              >
                <span className="indicator-label fs-base ">Cancel</span>
              </button>
              <button
                className=" btn btn-success h-44 w-110 center-all"
                type="button"
                onClick={() => Submit()}
              >
                <span className="indicator-label fs-base ">Submit</span>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  // const filteredData = getRevenueValidationData?.data?.filter((item: any) =>
  //   //   item?.id?.toLowerCase().includes(search?.toLowerCase()) ||
  //   item?.invoiceNumber?.toLowerCase().includes(search?.toLowerCase())
  // );

  getRevenueValidationData?.data?.forEach((res, index) => {
    var msgText = "";
    switch (res.status.toLowerCase()) {
      case "new":
        msgText = "Outstanding";
        break;
      case "paid":
        msgText = "Paid";
        break;
      case "partly_paid":
        msgText = "pending";
        break;
      default:
        msgText = "";
        break;
    }

    const data = {
      sn: index + 1,
      fullName: res.payer.fullName,
      phoneNumber: res.payer.phone,
      governmentRevenueReference: res.invoiceNumber,

      amount: res.amount.toLocaleString("en", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      service: res.serviceName,
      paymentDate:
        msgText === "Paid"
          ? (res?.paymentHistory &&
              moment(
                res.paymentHistory[res.paymentHistory.length - 1]?.paymentDate
              ).format("MMMM Do YYYY, h:mm a")) ||
            "N/A"
          : "N/A",
      paymentReference: res?.governmentRevenueReference || "N/A",
      paymentStatus: res.status,
      "payment Status": msgText,
      serviceStatus: res.delivered ? "Delivered" : "Not Delivered",
      all: res,
    };

    const keysToRemove = ["all", "paymentStatus"];

    const filteredObj = Object.fromEntries(
      Object.entries(data).filter(([key]) => !keysToRemove.includes(key))
    );

    dataSource.push(data);
    downloadSource.push(filteredObj);
    dataSourceForExcel.push(Object.values(filteredObj).slice(1));
  });

  const columns = [
    {
      title: "S/N",
      dataIndex: "sn",
      key: "sn",
      width: "40",
      sorter: (a, b) => a.sn - b.sn,
    },

    {
      title: "Government Revenue Reference (GRR)",
      dataIndex: "governmentRevenueReference",
      key: "governmentRevenueReference",
      width: "100",
    },

    {
      title: "Full Name",
      dataIndex: "fullName",
      key: "fullName",
      width: "77",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: "180",
    },
    {
      title: "Payment Reference",
      dataIndex: "paymentReference",
      key: "paymentReference",
      width: "180",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: "120",
    },
    {
      title: "Service",
      dataIndex: "service",
      key: "service",
      width: "120",
    },
    {
      title: "Payment Date",
      dataIndex: "paymentDate",
      key: "paymentDate",
      // width: '120'
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      render: (_, { paymentStatus }) => {
        var buttonBg = "";
        var buttonText = "";
        var msgText = "";
        switch (paymentStatus.toLowerCase()) {
          case "new":
            buttonBg = "bg-alt-red";
            buttonText = "text-danger";
            msgText = "Outstanding";
            break;
          case "paid":
            buttonBg = "bg-light-success";
            buttonText = "text-success";
            msgText = "Paid";
            break;
          case "partly_paid":
            buttonBg = "bg-pending";
            buttonText = "text-pending";
            msgText = "pending";
            break;
          default:
            buttonBg = "bg-white";
            buttonText = "text-dark";
            msgText = "";
            break;
        }

        return (
          <>
            <Tag
              key={paymentStatus}
              className={` border-0  rounded-3 w-125px h-24px center-all ${buttonBg}`}
            >
              <span className={`fs-9 ${buttonText}`}> {msgText}</span>
            </Tag>
          </>
        );
      },
    },
    {
      title: "Service Status",
      dataIndex: "all",
      key: "all",
      render: (_, info) =>
        info.all.delivered ? (
          <div className={"fs-9 fw-bold label-text"}>Service Delivered</div>
        ) : (
          <span
            className={` text-success underline bg-white ${
              info.all.status.toLowerCase() === "paid"
                ? "cursor-pointer"
                : "cursor-disabled"
            }`}
            onClick={() => {
              info.all.status.toLowerCase() === "paid" &&
                proceedToModal(info.all.invoiceNumber);
            }}
          >
            Mark as Delivered
          </span>
        ),
    },
  ];
  return (
    <div className={"revenueValidation "}>
      <div className={"revenueValidationRow1"}>
        <div className={"revenueValidationBreadcrumb"}>
          <div>Collections</div>
          <svg
            width="10"
            height="13"
            viewBox="0 0 10 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
              fill="#1F8505"
            />
          </svg>
          <div>Revenue Validation</div>
        </div>

        {/* <div>
              <Select
                defaultValue="Last 30 Days"
                style={{ width: 162 }}
                options={[
                  { value: "last60Days", label: "Last 60 Days" },
                  { value: "last90Days", label: "Last 90 Days" },
                ]}
              />

              <div>
                <Dropdown>
                  <Button
                    type="ghost"
                    className="dashboard-over-btn"
                    size="large"
                  >
                    <div className="btn-content-overview">
                      <div className="btn-text-overview"> Last 30 Days</div>
                      <div>
                        {" "}
                        <DownOutlined />
                      </div>
                    </div>
                  </Button>
                </Dropdown>
              </div>

              <div>
                <span className="data-range-text-overview">Date Range:</span>
                <span className="data-from-text-overview">From</span>
                <DatePicker
                  defaultValue={dayjs("2015/01/01", dateFormat)}
                  format={dateFormat}
                  className="datepicker"
                />
                <span className="data-from-text-overview">To</span>
                <DatePicker
                  defaultValue={dayjs("2015/01/01", dateFormat)}
                  format={dateFormat}
                  className="datepicker"
                />{" "}
              </div>
            </div> */}
      </div>

      <div className={"revenueValidationRow2 px-7"}>
        {/*<div>*/}
        {/*  <Download dataSourceForExcel={dataSourceForExcel} dataSource={dataSource} excelFileTitle={'Net Cash'}*/}
        {/*            pdfFileTitle={'Net Cash'} excelPageTitle={'Net Cash'}*/}
        {/*            grandTotalFor={{*/}
        {/*              receiptsPayments: 'Inflow(CR)/Outflow(DR)',*/}
        {/*              balance: 'Balance'*/}
        {/*            }} />*/}
        {/*</div>*/}

        <div>
          <div>Revenue Validation</div>

          <Download
            dataSourceForExcel={dataSourceForExcel}
            dataSource={downloadSource}
            excelFileTitle={"Revenue Validation Report"}
            pdfFileTitle={"Revenue Validation Report"}
            excelPageTitle={"Revenue Validation Report"}
          />
        </div>

        <div>
          <div className="form-control form-control-solid pe-1 collection-search ">
            <input
              type="text"
              placeholder="Enter GRR"
              className={"no-outline text-muted fs-8 w-90"}
              onChange={handleSearchChange}
              value={search}
            />

            <img src={searchCollection} alt="search" className="icon-style" />
          </div>

          {/* <div>
                <button>Search</button>
              </div>

              <div>
                <button>Reset All</button>
              </div> */}
        </div>

        <div style={{ overflowX: "auto" }}>
          <TableComponent
            dataSource={dataSource}
            columns={columns}
            isLoading={loadingRev}
            mutateTable={mutateRevenueValidationData}
            searchFilter={searchFilterValue && `grr=${searchFilterValue}`}
          />
        </div>
      </div>
      {confrimModal()}
    </div>
  );
};

export default RevenueValidation;
