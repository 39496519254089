import * as Yup from "yup";

export const ProjectSchema1 = Yup.object().shape({
  projectId: Yup.string().required("Project Id is required"),
  projectName: Yup.string().required("project Name is required"),
  projectBudget: Yup.number().required("Project Budget is required"),
  projectDescription: Yup.string()
    .min(3)
    .max(150)
    .required("Project Description is required"),
});

export const ProjectOagfSchema1 = Yup.object().shape({
  projectId: Yup.string().required("Project Id is required"),
  projectName: Yup.string().required("project Name is required"),
  projectBudget: Yup.number().required("Project Budget is required"),
  projectDescription: Yup.string()
    .min(3)
    .max(150)
    .required("Project Description is required"),
  oagf: Yup.boolean(),
  mda: Yup.string(),

  // mdaInvoled: Yup.array()
  // .of(
  //   Yup.object({
  //     id: Yup.string().required("id is required"),
  //     name: Yup.string().required("name is required"),
  //   })

  mdaInvoled: Yup.array().when("oagf", {
    is: (oagf: boolean) => oagf === true,
    then: Yup.array()
      .min(1, "At least one mda should be selected.")
      .required("User list is required."),
    otherwise: Yup.array().notRequired(),
  }),
});

export const ProjectSchema2 = Yup.object().shape({
  //bankAccount: Yup.string().required("Bank Account is required"),
  bankArray: Yup.object({
    id: Yup.string().nullable(),
    bankName: Yup.string().required("bank Name is required"),
    accountNumber: Yup.string().required("account Number is required"),
    dailyLimit: Yup.number().nullable(),
    orgId: Yup.string().required("account Number is required"),
    balance: Yup.number().required("daily Limit is required"),
    accountType: Yup.object({
      id: Yup.string().nullable(),
      code: Yup.string().required("bank Name is required"),
      description: Yup.string().required("account Number is required"),
    }),
  }).required("Bank Account is required"),
});

export const ProjectSchema3 = Yup.object({
  users: Yup.array()
    .of(
      Yup.object({
        id: Yup.string().required("id is required"),
        login: Yup.string().required("login is required"),
        name: Yup.string().required("name is required"),
      })
    )
    .min(3, "At least three users are required.")
    .required("User list is required."),
});

export const ProjectSchema5 = Yup.object({
  projectId: Yup.string().required("Project Id is required"),
  projectName: Yup.string().required("project Name is required"),
  projectBudget: Yup.number().required("Project Budget is required"),
  projectDescription: Yup.string()
    .min(3)
    .max(150)
    .required("Project Description is required"),
  bankArray: Yup.object({
    id: Yup.string().nullable(),
    bankName: Yup.string().required("bank Name is required"),
    accountNumber: Yup.string().required("account Number is required"),
    dailyLimit: Yup.number().required("daily Limit is required"),
    orgId: Yup.string().required("account Number is required"),
    balance: Yup.number().required("daily Limit is required"),
    accountType: Yup.object({
      id: Yup.string().nullable(),
      code: Yup.string().required("bank Name is required"),
      description: Yup.string().required("account Number is required"),
    }),
  }).required("Bank Account is required"),
  users: Yup.array()
    .of(
      Yup.object({
        id: Yup.string().required("id is required"),
        login: Yup.string().required("login is required"),
        name: Yup.string().required("name is required"),
      })
    )
    .min(3, "At least three users are required.")
    .required("User list is required."),
});

export const projectInitialValues = {
  projectId: "",
  projectName: "",
  projectBudget: 0,
  projectDescription: "",
  bankArray: {},
  users: [],
  oagf: false,
  mdaInvoled: [],
  mda:''
};

export const projectStep1InitialValues = {
  projectId: "",
  projectName: "",
  projectBudget: 0,
  projectDescription: "",
  oagf: false,
  mdaInvoled: [],
};
