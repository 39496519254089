import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
//import { checked, checkedFilled } from "core/assets/icons";
import { checkCircle, receipt } from 'core/assets/imgs';
import {
    //useInvoiceRequest,
    useReceiptByInvoice,
    useReceiptByPayment,
} from './hooks/useRevenuePayment';
import { ErrorHandler } from 'core/helpers';
import { InputField } from 'app/components/formInputs/InputFields';

const ReceiptsGenerate: React.FC = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);
    const { mutate: receiptPayment } = useReceiptByPayment();
    const { mutate: receiptInvoice } = useReceiptByInvoice();
    const [refNo, setRefNo] = useState<any>('');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [findBy, setFindBy] = useState<string>('invoice');

    React.useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, []);

    const done = () => {
        setShow(false);
        navigate('/');
    };

    const SuccessModal = () => {
        return (
            <Modal
                dialogClassName=" m-auto px-9 modal-card"
                aria-hidden="true"
                show={show}
                onHide={() => setShow(false)}
                animation
                centered
                backdrop={'static'}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body className="rounded">
                    <div className="d-flex flex-column align-items-center m-10">
                        <img src={checkCircle} alt="receipt" className="w-50px h-50px my-5" />

                        <span className="text-dark text-center fs-3">Message sent </span>
                        <span className="text-muted text-center fs-9 my-5">
                            Please check your registered email for a copy of your regenerated invoice.
                        </span>

                        <button className="btn btn-success px-7 py-2 h-44 w-110 rounded-1 my-3" onClick={done}>
                            <span className="indicator-label text-White">Done</span>
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    };

    const handleChange = (event: any) => {
        const { value } = event.target;
        // Limit the input length to 10 characters
        const limitedValue = value.slice(0, 10);
        setRefNo(limitedValue);
    };

    const handleSubmit = () => {
        if (findBy === 'payment') {
            setLoading(true);
            receiptPayment(refNo, {
                onSuccess: () => {
                    setShow(true);
                    setLoading(false);
                },
                onError: (err: any) => {
                    setLoading(false);
                    ErrorHandler(err);
                },
            });
        }

        if (findBy === 'invoice') {
            setLoading(true);
            receiptInvoice(refNo, {
                onSuccess: () => {
                    setShow(true);
                    setLoading(false);
                },
                onError: (err: any) => {
                    setLoading(false);
                    ErrorHandler(err);
                },
            });
        }
    };

    useEffect(() => {
        setRefNo('');
    }, [findBy]);

    return (
        <div>
            <div className="pb-15 pt-2 my-20">
                <div className=" card border-0 shadow  m-auto p-11 responsive-card">
                    <span className="text-dark fs-3 fw-bolder  text-center">Invoice & Receipt</span>
                    <div className="form align-middle  gx-0 gy-5 mt-5">
                        {/* <div className="d-flex  mt-1  col-12">
                            <div
                                className="row min-w-25  cursor-pointer col-6"
                                onClick={() => {
                                    setFindBy('invoice');
                                }}
                            >
                                <span className="form-check form-check-custom form-check-alt">
                                    <img
                                        src={`${findBy === 'invoice' ? checkedFilled : checked}`}
                                        className="w-1r1 h-1r1"
                                    />
                                    <span className="text-dark fs-8 ms-2">Get Invoice</span>
                                </span>
                            </div>
                            <div
                                className="row min-w-25  cursor-pointer col-6"
                                onClick={() => {
                                    setFindBy('payment');
                                }}
                            >
                                <span className="form-check form-check-custom form-check-alt">
                                    <img
                                        src={`${findBy === 'payment' ? checkedFilled : checked}`}
                                        className="w-1r1 h-1r1"
                                    />
                                    <span className="text-dark fs-8 ms-2">Get Receipt</span>
                                </span>
                            </div>
                        </div> */}
                        <div className="">
                            <div className="mt-1">
                                <div className="col-lg-8 col-form-label py-0 input-height">
                                    {refNo.length !== 0 && (
                                        <label className="required fw-bold fs-9">
                                            Enter {` ${findBy === 'invoice' ? 'GRR' : 'reference'}`}
                                            {/* id */}
                                        </label>
                                    )}
                                </div>

                                <div className={`form-control form-control-solid`}>
                                    <img src={receipt} alt="invoice" className="icon-style" />
                                    <InputField
                                        type={'number'}
                                        value={refNo}
                                        name={`${findBy === 'invoice' ? 'GRR' : 'reference'}`}
                                        onChange={handleChange}
                                        placeholder={`${findBy === 'invoice' ? 'Enter GRR' : 'Enter reference id'}*`}
                                    />
                                </div>

                                <div className="fv-plugins-message-container input-height">
                                    <div className="fv-help-block fs-9 text-danger">
                                        {refNo.length === 0 &&
                                            findBy === 'invoice' &&
                                            'Government Revenue Reference (GRR) is required'}
                                        {refNo.length === 0 && findBy === 'payment' && 'Reference id is required'}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="">
                            <button
                                id="kt_password_cancel "
                                type="button"
                                className="btn btn-white me-2 px-7 py-2"
                                onClick={done}
                            >
                                <span className="indicator-label text-danger underline-danger">Cancel</span>
                            </button>
                        </div> */}

                        <div className=" d-flex  align-items-center justify-content-center mt-5">
                            <button
                                id="kt_password_cancel "
                                type="submit"
                                className={`btn ${
                                    refNo?.length < 10 || loading ? 'btn-secondary' : 'btn-success'
                                } text-white px-7 py-2 h-44 w-min-110 rounded-1`}
                                disabled={refNo?.length < 10 || loading}
                                onClick={handleSubmit}
                            >
                                {/* !isLoading */}
                                {!loading && <span className="indicator-label fs-base">Send</span>}
                                {/* isLoading */}
                                {loading && (
                                    <span className="indicator-progress d-flex justify-content-center  align-items-center">
                                        <span className="d-none d-md-block">Please wait... </span>
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <SuccessModal />
        </div>
    );
};

export { ReceiptsGenerate };
