export const InvoiceComponent = ({ receipt, refNo, setRefNo }) => (
    <div className="ms-8 mda-ref">
        <div className=" ">
            <div className="col-lg-8 col-form-label py-0 input-height">
                {refNo.length !== 0 && <label className="required fw-bold fs-9">Enter Reference number</label>}
            </div>

            <div className={`form-control form-control-solid pe-0 ${refNo.length === 0 ? '' : 'border-success'}`}>
                <img src={receipt} alt="" className="icon-style" />

                <input
                    type="text"
                    placeholder="Enter Reference number*"
                    className={'no-outline ps-4 text-dark fs-9'}
                    onChange={(e) => setRefNo(e.target.value)}
                />
            </div>
        </div>
    </div>
);
