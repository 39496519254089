import React, {useState} from 'react';
import '../../../../../App.css';
import {Layout} from '../../layout';
import {useFormik} from "formik";
import * as Yup from "yup";
import {Modal} from "antd";
import {useNavigate} from "react-router-dom";


const Schema = Yup.object().shape({
    fundingAccount: Yup.string().required('Funding Account is required'),
    typeOfTax: Yup.string().required('Type of tax is required'),
});

const initialValues = {
    fundingAccount: '',
    typeOfTax: '',
    payerTin: '',
    documentNumber: '',
    amount: '',
};

const KeyInTaxPayments = () => {
    const [successModal, setSuccessModal] = useState(false);


    const navigate = useNavigate();

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: async (values) => {

        },
    });

    let fundingAccount = [
        {
            id: '',
            description: 'Select Funding Account',
        },
        {
            id: '1',
            description: 'First Bank of Nigeria PLC (099***5545)',
        },
    ];

    let typeOfTax = [
        {
            id: '',
            description: 'Select Type of Tax',
        },
        {
            id: '1',
            description: 'Business Premises Registration Fees',
        },
    ];

    return (
        <>
            <div className={'keyInTaxPayments'}>
                <div className={'taxPaymentsBreadcrumb'}>
                    <div>Payments</div>
                    <svg
                        width="10"
                        height="13"
                        viewBox="0 0 10 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                            fill="#1F8505"
                        />
                    </svg>
                    <div>Tax Payments</div>

                    <svg
                        width="10"
                        height="13"
                        viewBox="0 0 10 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                            fill="#1F8505"
                        />
                    </svg>

                    <div>Key-In Tax Payment</div>
                </div>

                <div>
                    <div>Key-In Tax Payment</div>

                    <div>
                        <div className={`mt-1`}>
                            <div className="col-form-label py-0 input-height">
                                {formik.values.fundingAccount.length !== 0 && (
                                    <label className="required fw-bold fs-9">
                                        Select Funding Account
                                    </label>
                                )}
                            </div>

                            <div className={`form-control form-control-solid`}>
                                <select
                                    className={`no-outline fs-8 ${
                                        formik.values.fundingAccount !== ''
                                            ? 'text-dark'
                                            : 'text-muted'
                                    } `}
                                    {...formik.getFieldProps('fundingAccount')}
                                >
                                     <option value="">Select Funding Account</option>
                                    {fundingAccount &&
                                        fundingAccount?.map((array: any, i: any) => {
                                            return (
                                                <option value={array?.description} key={i}>
                                                    {array?.description}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>

                            <div className="fv-plugins-message-container input-height">
                                <div className="fv-help-block fs-9 text-danger">
                                    {formik.touched.fundingAccount &&
                                        formik.errors.fundingAccount &&
                                        formik.errors.fundingAccount}
                                </div>
                            </div>
                        </div>

                        <div className={`mt-1`}>
                            <div className="col-form-label py-0 input-height">
                                {formik.values.typeOfTax.length !== 0 && (
                                    <label className="required fw-bold fs-9">
                                        Select Type of Tax
                                    </label>
                                )}
                            </div>

                            <div className={`form-control form-control-solid`}>
                                <select
                                    className={`no-outline fs-8 ${
                                        formik.values.typeOfTax !== ''
                                            ? 'text-dark'
                                            : 'text-muted'
                                    } `}
                                    {...formik.getFieldProps('typeOfTax')}
                                >
                                    <option value=''>Select Type of Tax</option>
                                    {typeOfTax &&
                                        typeOfTax?.map((array: any, i: any) => {
                                            return (
                                                <option value={array?.description} key={i}>
                                                    {array?.description}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>

                            <div className="fv-plugins-message-container input-height">
                                <div className="fv-help-block fs-9 text-danger">
                                    {formik.touched.typeOfTax &&
                                        formik.errors.typeOfTax &&
                                        formik.errors.typeOfTax}
                                </div>
                            </div>
                        </div>

                        <div className={`mt-1`}>
                            <div className="col-form-label py-0 input-height">
                                {formik.values.payerTin.length !== 0 && (
                                    <label className="required fw-bold fs-9">
                                        Payer TIN
                                    </label>
                                )}
                            </div>

                            <div className={`form-control form-control-solid`}>
                                <input
                                    className={`no-outline fs-8 ${
                                        formik.values.payerTin !== ''
                                            ? 'text-dark'
                                            : 'text-muted'
                                    } `}
                                    {...formik.getFieldProps('payerTin')}
                                    placeholder="Payer TIN"
                                >
                                </input>
                            </div>

                            <div className="fv-plugins-message-container input-height">
                                <div className="fv-help-block fs-9 text-danger">
                                    {formik.touched.payerTin &&
                                        formik.errors.payerTin &&
                                        formik.errors.payerTin}
                                </div>
                            </div>
                        </div>

                        <div className={`mt-1`}>
                            <div className="col-form-label py-0 input-height">
                                {formik.values.documentNumber.length !== 0 && (
                                    <label className="required fw-bold fs-9">
                                        Document number
                                    </label>
                                )}
                            </div>

                            <div className={`form-control form-control-solid`}>
                                <input
                                    className={`no-outline fs-8 ${
                                        formik.values.documentNumber !== ''
                                            ? 'text-dark'
                                            : 'text-muted'
                                    } `}
                                    {...formik.getFieldProps('documentNumber')}
                                    placeholder="Document number"
                                >
                                </input>
                            </div>

                            <div className="fv-plugins-message-container input-height">
                                <div className="fv-help-block fs-9 text-danger">
                                    {formik.touched.documentNumber &&
                                        formik.errors.documentNumber &&
                                        formik.errors.documentNumber}
                                </div>
                            </div>
                        </div>

                        <div className={`mt-1`}>
                            <div className="col-form-label py-0 input-height">
                                {formik.values.amount.length !== 0 && (
                                    <label className="required fw-bold fs-9">
                                        Amount
                                    </label>
                                )}
                            </div>

                            <div className={`form-control form-control-solid`}>
                                <input
                                    className={`no-outline fs-8   ${
                                        formik.values.amount !== ''
                                            ? 'text-dark'
                                            : 'text-muted'
                                    } `}
                                    {...formik.getFieldProps('amount')}
                                    placeholder="Amount"
                                />
                            </div>

                            <div className="fv-plugins-message-container input-height">
                                <div className="fv-help-block fs-9 text-danger">
                                    {formik.touched.amount &&
                                        formik.errors.amount &&
                                        formik.errors.amount}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        {
                            formik.isValid && formik.dirty ?
                                <button
                                    onClick={() => setSuccessModal(true)}
                                >
                                    Submit
                                </button> :
                                <button
                                    disabled
                                    style={{background: '#CCCCCC'}}
                                >
                                    Submit
                                </button>
                        }

                    </div>
                </div>



                <Modal
                    centered
                    style={{width: '500px', maxWidth: '100%'}}
                    open={successModal}
                    onOk={() => {
                        setSuccessModal(false);

                    }}
                    onCancel={() => setSuccessModal(false)}
                    footer={null}
                >
                    <div className={'rvpConfirmationModal'}>
                        <svg style={{justifySelf: 'center'}} width="40" height="40" viewBox="0 0 40 40" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M28.5937 15.3125L17.1354 26.25L11.4062 20.7812M38.75 20C38.75 30.3553 30.3553 38.75 20 38.75C9.64466 38.75 1.25 30.3553 1.25 20C1.25 9.64466 9.64466 1.25 20 1.25C30.3553 1.25 38.75 9.64466 38.75 20Z"
                                stroke="#FBCB38" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>

                        {/*<div className={'rvpConfirmationModalTitle'}>Successful!</div>*/}

                        <div style={{textAlign: 'center'}}>
                            Your transaction has been sent to the reviewer group for action
                        </div>

                        <div className={'rvpConfirmationModalActionButton'}>
                            <button className="btn btn-success px-7 py-2 h-44 w-150 rounded-1 " onClick={() => {
                                setSuccessModal(false);
                                navigate("/admin/payment/tax-payments")
                            }}>
                                <span className="indicator-label fs-base text-White">Done</span>
                            </button>
                        </div>
                    </div>
                </Modal>
            </div>
        </>
    );
};

export default KeyInTaxPayments;
