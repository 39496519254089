import { useFormik } from "formik";
import { useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import * as Yup from "yup";
import {
  NewOAGFLogoSvg as OAGF,
  facebook,
  instagram,
  twitter,
  youtube,
} from "core/assets/imgs";
import { WhicHome } from "core/helpers";

const profileDetailsSchema = Yup.object().shape({
  email: Yup.string().email("Wrong email format").required("Email is required"),
});

const initialValues = {
  email: "",
};

const Footer = () => {
  const [loading, setLoading] = useState(false);
  const { pathname } = useLocation();

  const formik = useFormik({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: (values) => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    },
  });

  const WhereToNavigate = () => {
    if (WhicHome() === "mdaHome") {
      return "/";
    } else {
      return "/mda-home";
    }
  };
  const ToRouteHome = () => {
    if (WhicHome() === "mdaHome") {
      if (pathname === "/mda-home") {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
      return "/mda-home";
    } else {
      if (pathname === "/" && window.location.hash !== "") {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
      return "/";
    }
  };

  const HelpRoute = () => {
    if (WhicHome() === "mdaHome") {
      if (pathname === "/help-center") {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
      return `/help-center`;
    } else {
      return `#help`;
    }
  };

  return (
    <div>
      <footer className="footer">
        <div className="footer-container">
          <div>
            <img src={OAGF} alt="Logo" className="nav-logo" />
          </div>
          <div className="footer-center">
            <span className="footer-quick-link">Discover</span>
            <span className="my-3 d-flex align-items-center footer-quick-link-text fs-9 ">
              <Link to="/auth/login" style={{ color: "#fff" }}>
                Log in
              </Link>
            </span>
            <span className="my-3 d-flex align-items-center footer-quick-link-text fs-9 ">
              <Link to="/auth/register" style={{ color: "#fff" }}>
                Register
              </Link>
            </span>
            <span className="my-3 d-flex align-items-center footer-quick-link-text fs-9 ">
              <Link to={ToRouteHome()} style={{ color: "#fff" }}>
                Home
              </Link>
            </span>
            <span className="my-3 d-flex align-items-center footer-quick-link-text fs-9 ">
              <HashLink style={{ color: "#fff" }} to={"#benefits"}>
                Benefits
              </HashLink>
            </span>
            <span className="my-3 d-flex align-items-center footer-quick-link-text fs-9 ">
              <HashLink style={{ color: "#fff" }} to={"#about"}>
                About
              </HashLink>
            </span>
          </div>

          <div className="footer-center">
            <span className="footer-quick-link">Legal & Support</span>
            <span className="my-3 d-flex align-items-center footer-quick-link-text fs-9 ">
              <Link to={"/termsConditions"} style={{ color: "#fff" }}>
                Terms & Conditions
              </Link>
            </span>
            <span className="my-3 d-flex align-items-center footer-quick-link-text fs-9 ">
              <Link to={"/privacyPolicy"} style={{ color: "#fff" }}>
                Privacy Policy
              </Link>
            </span>
            <span className="my-3 d-flex align-items-center footer-quick-link-text fs-9 ">
              <Link to={HelpRoute()} style={{ color: "#fff" }}>
                Help Center
              </Link>
            </span>

            <div className="mt-9">
              <span className="footer-quick-link mt-9">Useful Link</span>

              <span className="my-3 d-flex align-items-center footer-quick-link-text fs-9 cursor-pointer ">
                <Link to={WhereToNavigate()} style={{ color: "#fff" }}>
                  {WhicHome() === "userHome" ? "MDA Portal" : "Payer's Portal"}
                </Link>
              </span>
            </div>
          </div>
          <div className="footer-center">
            <span className="footer-quick-link">Get In Touch</span>

            <span className="my-3 d-flex align-items-center">
              <i className=" text-success fs-6 fab fa fa-envelope"></i>
              <span className="footer-quick-link-text fs-9 ms-1">
                support@treasuryportal.gov.ng
              </span>
            </span>

            <span className="my-3 d-flex align-items-center">
              <i className=" text-success fs-6 fab fa fa-phone"></i>
              <span className="footer-quick-link-text fs-9 ms-1">
                +234-703-892-8017
              </span>
            </span>

            <span className="my-3 d-flex ">
              <i className=" text-success fs-6 fab fa fa-map-marker"></i>
              <span className="footer-quick-link-text fs-9 ms-1">
                Office of the Accountant General of the Federation, Treasury
                House, Plot 1570, Samuel Ladoke Akintola Boulevard, Garki 2
                900103, Abuja, Federal Capital Territory
              </span>
            </span>
          </div>

          <div>
            {" "}
            <ul className="social-icons-footer">
              <a href="#" className="social-icon-footer">
                <img src={facebook} alt="facebook" width="50" height="50" />
              </a>{" "}
              <a href="#" className="social-icon-footer">
                <img src={twitter} alt="facebook" width="50" height="50" />
              </a>
              <a href="#" className="social-icon-footer">
                <img src={instagram} alt="facebook" width="50" height="50" />
              </a>
              <a href="#" className="social-icon-footer">
                <img src={youtube} alt="facebook" width="50" height="50" />
              </a>{" "}
            </ul>
          </div>
        </div>

        <footer>
          <div className="footer-bottom">
            <span className="footer-bottom-div ">
              Copyright © 2023&nbsp;
              <span style={{ color: "#1F8505", cursor: "pointer" }}>
                Office of the Accountant General of the Federation.
              </span>{" "}
              All rights reserved.
            </span>
          </div>
        </footer>
      </footer>
    </div>
  );
};

export { Footer };
