import { isOagfAdmin } from "core/helpers";
import MdaPaymentApproved from "./MDA/MdaPaymentApproved";
import OagfPaymentApproved from "./OAGF/OagfPaymentApproved";

const PaymentApproved = () => {
  return (
    <>{isOagfAdmin() ? <OagfPaymentApproved /> : <MdaPaymentApproved />}</>
  );
};

export { PaymentApproved };
