import React from "react";

import { edit } from "core/assets/icons";
import { formatMoneyLocal, isEmpty } from "core/helpers";

interface PreviewProps {
  goBack: () => void;
  step: any;
  formik: any;
  workflow: any;
  setStep: (e: number) => void;
  usersList: any;
  setPreviewMode: (e: boolean) => void;
}

const Preview: React.FC<PreviewProps> = ({
  goBack,
  step,
  formik,
  workflow,
  setStep,
  usersList,
  setPreviewMode,
}) => {
  const TableUserWork: React.FC<any> = () => {
    return (
      <table className="table">
        <thead>
          <tr className="fs-8 text-start">
            <th>ID</th>
            <th>Process Type</th>
            <th>Initiator</th>
            <th>Reviewer</th>
            <th>Approver </th>
            {/* <th>Action</th> */}
          </tr>
        </thead>
        <tbody>
          {workflow?.map((userFlow, i) => {
           //console.log(userFlow.roles);

            return (
              <tr key={i} className="fs-8 text-start">
                <td> {i + 1} </td>
                <td>{userFlow.cat}</td>
                {userFlow.roles.map((user, i) => {
                  return (
                    <td>
                      {user.users.map((u, i) => {
                        return (
                          <div>
                            <div>{u.name}</div>
                          </div>
                        );
                      })}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const TableUsers: React.FC<any> = () => {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Phone Number</th>
            <th>Email </th>
          </tr>
        </thead>
        <tbody>
          {formik?.values?.users?.map((userData, i) => {
            const userFulldata = usersList.find(
              (item: { id: any }) => item.id === userData.id
            );
            return (
              <tr key={i} className="fs-8">
                <td> {i + 1} </td>
                <td>{userFulldata.firstName}</td>
                <td>{userFulldata.lastName}</td>
                <td>{userFulldata.phoneNumber}</td>
                <td>{userFulldata.email}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      noValidate
      className="form align-middle  gx-0 gy-5 mt-3"
    >
      <div className="preview-container" style={{ background: "#F9F9F9" }}>
        <div className="card m-auto project-preview-card pt-0">
          <div className="d-flex justify-content-center align-items-center preview-header">
            <span className="text-success fs-2 fw-bold lh-lg">PREVIEW</span>
          </div>
          <div>
            <div className="d-flex justify-content-between  px-5">
              <div className="d-flex justify-content-center align-items-center">
                <span className="text-dark fs-5 fw-bolder lh-lg">
                  Basic Details
                </span>
              </div>

              <div
                className="d-flex justify-content-center align-items-center bg-edit rounded-1 h-35e w-49e cursor-pointer"
                onClick={() => {
                  setPreviewMode(true);
                  setStep(1);
                }}
              >
                <span className="text-white fs-8 fw-bold">Edit</span>
                <img src={edit} alt="edit" className=" ms-3 w-11r5 h-11r5" />
              </div>
            </div>

            <hr className="text-muted h-1px" />
            <div className="px-5">
              <div className="d-flex justify-content-between py-3">
                <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                  Project ID
                </span>
                <span className="text-dark fs-8 fw-bold text-end">
                  {formik?.values?.projectId}
                </span>
              </div>

              <div className="d-flex justify-content-between py-3">
                <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                  Project Name
                </span>
                <span className="text-dark fs-8 fw-bold text-end">
                  {formik?.values?.projectName}
                </span>
              </div>

              <div className="d-flex justify-content-between py-3">
                <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                  Description
                </span>
                <span className="text-dark fs-8 fw-bold text-end">
                  {formik?.values?.projectDescription}
                </span>
              </div>

              <div className="d-flex justify-content-between py-3">
                <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                  Project Budget
                </span>
                <span className="text-dark fs-8 fw-bold text-end">
                  ₦{formatMoneyLocal(formik?.values?.projectBudget)}
                </span>
              </div>

              {/* <div className="d-flex justify-content-between py-3">
                <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                  MDAs Involved
                </span>
                <span className="text-dark fs-8 fw-bold text-end">
                  {formik?.formik?.organization}
                </span>
              </div> */}
            </div>
          </div>
        </div>

        <div className="card m-auto project-preview-card">
          <div className="d-flex justify-content-between px-5">
            <div className="d-flex justify-content-center align-items-center">
              <span className="text-dark fs-5 fw-bolder lh-lg">
                Bank Account
              </span>
            </div>

            <div
              className="d-flex justify-content-center align-items-center bg-edit rounded-1 h-35e px-3 cursor-pointer"
              onClick={() => {
                setPreviewMode(true);
                setStep(2);
              }}
            >
              <span className="text-white fs-8 fw-bold">
                Change Bank Account
              </span>
              <img src={edit} alt="edit" className=" ms-3 w-11r5 h-11r5" />
            </div>
          </div>
          <hr className="text-muted h-1px" />
          <div className="px-5">
            <div className="d-flex justify-content-between py-3">
              <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                Bank Name
              </span>
              <span className="text-dark fs-8 fw-bold text-end">
                {formik?.values?.bankArray?.bankName}
              </span>
            </div>

            <div className="d-flex justify-content-between py-3">
              <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                Account Number
              </span>
              <span className="text-dark fs-8 fw-bold text-end">
                {formik?.values?.bankArray?.accountNumber}
              </span>
            </div>

            <div className="d-flex justify-content-between py-3">
              <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                Account Classification
              </span>
              <span className="text-dark fs-8 fw-bold text-end">
                {formik?.values?.bankArray?.accountType?.description}
              </span>
            </div>

            <div className="d-flex justify-content-between py-3">
              <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                Account Name
              </span>
              <span className="text-dark fs-8 fw-bold text-end">
                {formik?.values?.bankArray?.accountName}
              </span>
            </div>

            <div className="d-flex justify-content-between py-3">
              <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                Daily Transaction Limit
              </span>
              <span className="text-dark fs-8 fw-bold text-end">
                ₦{formatMoneyLocal(formik?.values?.bankArray?.dailyLimit)}
              </span>
            </div>
          </div>
        </div>

        <div className="card m-auto p-5 project-preview-card">
          <div className="d-flex justify-content-between ">
            <div className="d-flex justify-content-center align-items-center">
              <span className="text-dark fs-5 fw-bolder lh-lg">
                User Details
              </span>
            </div>

            <div
              className="d-flex justify-content-center align-items-center bg-edit rounded-1 h-35e w-49e cursor-pointer"
              onClick={() => {
                setPreviewMode(true);
                setStep(3);
              }}
            >
              <span className="text-white fs-8 fw-bold">Edit</span>
              <img src={edit} alt="edit" className=" ms-3 w-11r5 h-11r5" />
            </div>
          </div>
          <hr className="text-muted h-1px" />
          <div className="mt-5">
            <TableUsers />
          </div>
        </div>

        <div className="card m-auto p-5 project-preview-card">
          <div className="d-flex justify-content-between ">
            <div className="d-flex justify-content-center align-items-center">
              <span className="text-dark fs-5 fw-bolder lh-lg">
                Approval Workflow Definition
              </span>
            </div>

            <div
              className="d-flex justify-content-center align-items-center bg-edit rounded-1 h-35e w-49e cursor-pointer"
              onClick={() => {
                setPreviewMode(true);
                setStep(4);
              }}
            >
              <span className="text-white fs-8 fw-bold">Edit</span>
              <img src={edit} alt="edit" className=" ms-3 w-11r5 h-11r5" />
            </div>
          </div>
          <hr className="text-muted h-1px" />

          <div className="mt-5">
            <TableUserWork />
          </div>
        </div>

        <div className="d-flex  justify-content-center mt-5">
          <div className="">
            <button
              id="kt_password_cancel "
              type="submit"
              className={` btn ${
                !(formik.isValid && formik.dirty) || isEmpty(workflow)
                  ? "btn-secondary"
                  : "btn-success"
              }
                 text-white px-15 py-2 h-44 w-min-110 rounded-1`}
              disabled={!(formik.isValid && formik.dirty) || isEmpty(workflow)}
            >
              <span className="indicator-label fs-base">Submit</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export { Preview };
