import React from "react";

import {
  arrowRight,
  about as AboutImg,
  userAboutImg,
} from "core/assets/imgs";
import ReadMore from "core/helpers/ReadMore";
import { useNavigate } from "react-router-dom";

const UserAboutPage = () => {
  const navigate = useNavigate()
  document.title = 'Login to portal'; 

  React.useEffect(() => {
    // Override the document title for this specific page
    document.title = 'Custom Page Title';
  }, []);
  return (
    <div>
      <div className=" section-mobile about bg-grey" id="about">
        <div className="section-center about-center">
          <article className="about-info">
            <div className="about-title">
              <span className="about-header fs-2x">About Us</span>
            </div>
            <div className="">
              <span className="fs-base">
                <ReadMore
                  text="This is the FGN Treasury Portal where you can, as a Payer,
                  make revenue payments to the Federal Government via various
                  electronic channels as offered by the CBN approved Payment
                  Service Providers on the platform. The Treasury Portal is the
                  backbone for recording and processing all financial
                  transactions initiated by all MDAs and provides various
                  significant benefits including improved monitoring of the
                  Government’s cash position, better fiscal reporting, and
                  availability of comprehensive data for budget
                  conceptualization. Its integration to approved Payment PSSPs
                  ensures greater optimization of the TSA Initiative by
                  providing multiple payment options for seamless transactions
                  processing."
                  maxLength={290}
                />
              </span>
            </div>

            <div className="pt-25">
              <button className="btn btn-success rounded-1 userHome-btn  " onClick={() => navigate('/payment')}>
                <span className="fs-5">
                  Pay Now
                  <img
                    src={arrowRight}
                    className="icon-style ms-2"
                    alt="arrowRight"
                  />
                </span>
              </button>
            </div>
          </article>

          <article className="about-img d-none d-block-lg">
            <img src={userAboutImg} className="about-img" alt="AboutImg" />
          </article>
        </div>
      </div>
    </div>
  );
};

export { UserAboutPage };
