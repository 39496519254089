import {Download} from "app/components/download";
import {Table} from "antd";
import React from "react";

export const RoleList = ({
                             dataSourceForExcel,
                             dataSource,
                             columns,
                             handleActionClick
                         }) => {
    return (
        <>
            <div>
                <div className={"roleResourceManagementBreadcrumb"}>
                    <div>Setup & Administration</div>

                    <svg width="10" height="13" viewBox="0 0 10 13" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                            fill="#1F8505"/>
                    </svg>

                    <div>Role/Resource Management</div>
                </div>
                <div className={'roleResourceManagementDiv'}>
                    <div className="d-flex justify-content-end  mb-9 me-4">
                        <Download
                            dataSourceForExcel={dataSourceForExcel}
                            dataSource={dataSource}
                            excelFileTitle={"Collection Inflow for MDA"}
                            pdfFileTitle={"Collection Inflow for MDA"}
                            excelPageTitle={"Collection Inflow for MDA"}
                            grandTotalFor={{
                                amount: "Transaction Amount"
                            }}/>
                    </div>

                    <div className={'rrmRow2'}>
                        <div>LIST OF ALL ROLES ({dataSource?.length})</div>
                        <div className={'createNewRoleButton'}>
                            <button
                                onClick={() => handleActionClick('create')}
                            >
                                Create New Role
                            </button>
                        </div>
                    </div>

                    <div>
                        <Table
                            dataSource={dataSource}
                            // bordered
                            columns={columns}
                            scroll={{x: "700px"}}/>
                    </div>
                </div>
            </div>
        </>
    );
};
