import { useEffect } from "react";
// import { withRouter } from "react-router-dom";

const ScrollToTop = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return null;
};
export default ScrollToTop;

export const ScrollUp = (container) => {
  // const container = containerRef.current;

  if (container) {
    const start = container.scrollTop;
    const change = -container.scrollTop;
    const duration = 500; // Animation duration in milliseconds
    const startTime = performance.now();

    const animateScroll = (timestamp: number) => {
      const currentTime = timestamp - startTime;
      const easing = easeInOutQuad(currentTime, start, change, duration);
      container.scrollTop = easing;

      if (currentTime < duration) {
        requestAnimationFrame(animateScroll);
      }
    };

    requestAnimationFrame(animateScroll);
  }

  // Easing function for smooth scrolling
  const easeInOutQuad = (t: number, b: number, c: number, d: number) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };
};
