import React, { useEffect, useState } from "react";
import "../../../App.css";
import { Layout } from "../admin/layout";
import { Table } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useRequestReviewed } from "../payments/hooks/useRevenuePayment";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import {
  useRequestOnboardingSendVerificatoinCode,
  useVerificationcode,
  useVerificationSinglecode,
} from "../auth/hooks/useUser";
import { getBankName } from "../../services/utilityService";
import OTPInput from "react-otp-input";
import { close_red, infoBlue } from '../../../core/assets/icons';
import { MetroSpinner } from 'react-spinners-kit';
import { FetchAndUnmask } from "core/helpers";

const RequestAwaitingReview = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [showReject, setShowReject] = useState<boolean>(false);
  const navigate = useNavigate();
  const [showRejection, setRejection] = useState<boolean>(false);
  const [reasonForReject, SetReasonForReject] = useState<string>('');
  const orgUser: any | null = FetchAndUnmask("lazyday_user");
  const orgUserParser = JSON.parse(orgUser);

  const [show, setShow] = useState<boolean>(false);

  const Schema = Yup.object().shape({
    expenseType: Yup.string().required("Expense type is required"),
    amountToPay: Yup.string().required("Amount to pay is required"),
    accountToMakePaymentFrom: Yup.string().required(
      "Account to make paymentFrom is required"
    ),
    accountNumber: Yup.string().required("Account number is required"),
    bankName: Yup.string().required("Bank name is required"),
    accountName: Yup.string().required("Account name is required"),
    // emailAddress: Yup.string().required("Email address is required"),
    // phoneNumber: Yup.string().required("Phone number is required"),
    // description: Yup.string().required("Description is required"),
  });

  const initialValues = {
    expenseType: "",
    amountToPay: "",
    accountToMakePaymentFrom: "",
    accountNumber: "",
    bankName: "",
    accountName: "",
    emailAddress: "",
    phoneNumber: "",
    description: "",
  };
  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: async (values) => {},
  });
  const {
    data: reviewData,
    mutate: mutateReview,
    isLoading: isLoadingReview,
  } = useRequestReviewed();

  const [otp, setOtp] = useState("");

  // const [formData, setFormData] = useState({
  //   input1: '',
  //   input2: '',
  //   input3: '',
  //   input4: '',
  //   input5: '',
  //   input6: '',
  // });

  const [sId, setSid] = useState("");

  // const handleChange = (event: any) => {
  //   event.preventDefault();
  //   const { name, value } = event.target;
  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  // };

  const {
    data: sendVerifyData,
    mutate: sendVerifyMuute,
    isLoading: isVerfyLoading,
  } = useRequestOnboardingSendVerificatoinCode();

  const sendVerification = () => {
    sendVerifyMuute(orgUserParser?.organizationUser.email, {
      onSuccess: (res: any) => {
        setShow(true);
      },
    });
  };

  const {
    data: verifyCode,
    mutate: mutateVerify,
    isLoading: loadingVerify,
  } = useVerificationSinglecode();

  const submitVerification = (id) => {
    // const combinedData = Object.values(formData).join('');
    // combinedData.toString();

    const payload = {
      otp: otp,
    };

   //console.log(id);

    mutateVerify(payload, {
      onSuccess: (res: any) => {
        setShow(false);
        mutateReview(
          { id, type: "APPROVED" },
          {
            onSuccess: (res: any) => {
              setShowSuccess(true);
            },
          }
        );
      },
    });
  };

  const review = (id: any, toApproval) => {
    setSid(id);

    if (toApproval) {
      sendVerification();
    } else {
       sendVerification();
      // mutateReview(
      //   { id, type: "APPROVED" },
      //   {
      //     onSuccess: (res: any) => {
      //       setShowSuccess(true);
      //     },
      //   }
      // );
    }
  };

      const [wModal, setWModal] = useState<number>(1);

    const [rejectid, setRejectid] = useState<any>('');
  const cancelRejectModal = () => {
    setRejection(false);
    SetReasonForReject('');
    setRejectid('');
     setWModal(1);
  };


    const ShowRejectModal = (id: any) => {
    setRejection(true);
    setShow(false);
      setRejectid(id);
       setWModal(1);
  };

    const [loader, setLoader] = useState<boolean>(false);

   const RejectionModal = () => {
    return (
      <Modal
        dialogClassName="  m-auto px-5 modal-card"
        aria-hidden="true"
        show={showRejection}
        onHide={() => cancelRejectModal()}
        animation
        centered
        size="lg"
        backdrop={'static'}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="rounded">
          <div className="m-6 mx-5">
            {wModal === 1 ? (
              <>
                <div
                  className="d-flex justify-content-end cursor-pointer"
                  onClick={() => cancelRejectModal()}
                >
                  <img src={close_red} alt="" className="w-24px h-24px" />
                </div>
                <div className="mt-1">
                  <div className="col-form-label input-height d-flex justify-content-start">
                    <span className=" fw-bolder fs-8e text-dark">
                      Reason(s) for rejection
                    </span>
                  </div>
                  <div className={``}>
                    <span className="required fs-9 text-gray-500">Message</span>
                    <textarea
                      placeholder=""
                      className={
                        'text-gray-500 fs-8 form-control form-control-solid'
                      }
                      value={reasonForReject}
                      rows={5}
                      onChange={(e) => SetReasonForReject(e.target.value)}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-end my-5">
                  <button
                    type="button"
                    className={` btn ${
                      reasonForReject.trim().length < 5
                        ? 'btn-secondary'
                        : 'btn-success'
                    } h-35px w-100px rounded-1  center-all  `}
                    onClick={() => setWModal(2)}
                    disabled={reasonForReject.trim().length < 5}
                  >
                    <span className="indicator-label fs-10 text-white ">
                      Submit
                    </span>
                  </button>
                </div>
              </>
            ) : (
              <>
                {loader ? (
                  <div className="d-flex justify-content-center align-items-center my-10">
                    <MetroSpinner color="#1f8505" />
                  </div>
                ) : (
                  <div className="">
                    <div className=" d-flex flex-column align-items-center ">
                      <img
                        src={infoBlue}
                        alt=""
                        className="w-50px h-50px my-5"
                      />

                      <span className="text-center fs-base text-dark">
                        Are you sure you want to reject this request
                      </span>
                    </div>
                    <div className="d-flex justify-content-around mt-10">
                      <button
                        id="kt_password_cancel "
                        type="button"
                        className="btn btn-danger px-7 py-2 text-alt-red h-44 w-110 center-all "
                        onClick={() => cancelRejectModal()}
                      >
                        <span className="indicator-label fs-base ">Cancel</span>
                      </button>
                      <button
                        className=" btn btn-success h-44 w-110 center-all"
                        type="button"
                        onClick={() => {
                          reject(rejectid);
                        }}
                      >
                        <span className="indicator-label fs-base ">Submit</span>
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const reject = (id: any) => {
    setLoader(true);
    mutateReview(
      { id, type: 'REJECTED', reason: reasonForReject },
      {
        onSuccess: (res: any) => {
          setLoader(false);
          setRejection(false);
          setShowReject(true);
        },
      }
    );
  };

  const onNext = () => {
    navigate("/admin");
  };

  const RejectModal = () => {
    return (
      <Modal
        dialogClassName=" m-auto px-7 modal-card-success"
        aria-hidden="true"
        show={showReject}
        onHide={() => setShowReject(false)}
        animation
        centered
        size="lg"
        // backdrop={'static'}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="rounded">
          <div className="">
            <div className=" d-flex flex-column align-items-center ">
              <div className="mt-5 mb-3">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.1562 12.1875L13.2812 18.75L9.84375 15.4688M26.25 15C26.25 21.2132 21.2132 26.25 15 26.25C8.7868 26.25 3.75 21.2132 3.75 15C3.75 8.7868 8.7868 3.75 15 3.75C21.2132 3.75 26.25 8.7868 26.25 15Z"
                    stroke="#61CD85"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>

              <div className="email-verification-container mb-3">
                <span className="email-verification-text">Rejected!!</span>
              </div>

              <div className="email-verification-container mb-5">
                <span className="email-verification-body">
                  The requests have been successfully rejected
                </span>
              </div>
            </div>

            <div className=" d-flex justify-content-center align-items-center mt-3 pe-9 ps-9 ">
              <button className="otp-button " onClick={() => onNext()}>
                <span className="otp-button-text">Ok</span>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const SuccessModal = () => {
    return (
      <Modal
        dialogClassName=" m-auto px-7 modal-card-success"
        aria-hidden="true"
        show={showSuccess}
        onHide={() => setShowSuccess(false)}
        animation
        centered
        size="lg"
        backdrop={"static"}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="rounded">
          <div className="">
            <div className=" d-flex flex-column align-items-center ">
              <div className="mt-5 mb-3">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.1562 12.1875L13.2812 18.75L9.84375 15.4688M26.25 15C26.25 21.2132 21.2132 26.25 15 26.25C8.7868 26.25 3.75 21.2132 3.75 15C3.75 8.7868 8.7868 3.75 15 3.75C21.2132 3.75 26.25 8.7868 26.25 15Z"
                    stroke="#61CD85"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>

              <div className="email-verification-container mb-3">
                <span className="email-verification-text">Approved!</span>
              </div>

              <div className="email-verification-container mb-5">
                <span className="email-verification-body">
                  {dataget?.data.currentLevel === 1
                    ? `Your payment request with GPR ${dataget?.data?.content?.data?.batchId}  has been sent to the Approver Group for approval`
                    : ` Your payment request with GPR ${dataget?.data?.content?.data?.batchId} has been sent to the payment gateway for processing `}
                </span>
              </div>
            </div>

            <div className=" d-flex justify-content-center align-items-center mt-3 pe-9 ps-9 ">
              <button className="otp-button " onClick={() => onNext()}>
                <span className="otp-button-text">Ok</span>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const location = useLocation();
  const dataget = location.state;
  const data = dataget?.data?.content?.data?.paymentInstructions;

  const dataSource: any[] = [];

  const processingFee = 100 * Number(data.length)
  let totalPayableSum =0


  for (let i = 0; i < data?.length; i++) {

totalPayableSum = totalPayableSum + data[i]?.amount

    const datar = {
      key: i.toString(),
      sn: (1 + i).toString(),
      batchNo: dataget?.data.content.data.batchId,
      referenceId: dataget?.data.id,
      paymentType: data[i]?.paymentType?.description,
      totalPayable: data[i]?.amount,
      fundingAccount: data[i]?.sourceAccount,
      beneficiaryName: data[i]?.destAccountName || data[i]?.destinationName,
      beneficiaryAccount: data[i]?.destinationAccount,
      beneficiaryBank: getBankName(data[i]?.destinationBankAccountCode),
    };

    dataSource.push(datar);
  }

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
   //console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: "S/N",
      dataIndex: "sn",
      key: "sn",
    },
    // {
    //   title: "Batch ID",
    //   dataIndex: "batchNo",
    //   key: "batchNo",
    // },
    {
      title: "Reference ID",
      dataIndex: "referenceId",
      key: "referenceId",
    },
    {
      title: "Payment Type",
      dataIndex: "paymentType",
      key: "paymentType",
    },
    {
      title: "Total Payable",
      dataIndex: "totalPayable",
      key: "totalPayable",
    },
    {
      title: "Funding Account",
      dataIndex: "fundingAccount",
      key: "fundingAccount",
    },
    {
      title: "Beneficiary Name",
      dataIndex: "beneficiaryName",
      key: "beneficiaryName",
    },
    {
      title: "Beneficiary Account",
      dataIndex: "beneficiaryAccount",
      key: "beneficiaryAccount",
    },
    {
      title: "beneficiaryBank",
      dataIndex: "beneficiaryBank",
      key: "beneficiaryBank",
    },
  ];

      function formatToNaira(amount) {
  return new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(amount);
}

  return (
    <>
      <div className={"reviewerDashboard"}>
           <div className={"psRow1"}>
            <div>{/*Payment Summary*/}</div>

            <div>
              <div>₦{(totalPayableSum + processingFee).toLocaleString()}</div>
              <div>Grand total payable</div>
            </div>

            <div>
              <div>
                  <div className={'psTitle'}>Payment Type</div>
                  <div className={'psValue'}>{data['0']?.paymentType?.description}</div>
              </div>

              <div>
                  <div className={'psTitle'}>Batch Number</div>
                  <div className={'psValue'}>{dataget?.data.content.data.batchId}</div>
              </div>

              <div>
                  <div className={'psTitle'}>Transaction Count</div>
                  <div className={'psValue'}>{dataSource.length}</div>
              </div>

              <div>
                  <div className={'psTitle'}>Total Amount Payable</div>
              <div className={'psValue'}>₦ {formatToNaira(totalPayableSum)}</div>
              </div>

              <div>
                  <div className={'psTitle'}>Processing Fee</div>
              <div className={'psValue'}>₦ {processingFee}</div>
              </div>
            </div>
              </div>
        <div className={"reviewerTableContainer"}>
          
          <div className={"reviewerTableDownload"}>
         
              </div>
          <div className={"reviewerTableActionButton"}>
            <div>
              {/* <button  style={{background: '#D2E7CD', color: '#666666'}} onClick={() => setFilterModal(true)} className={'addFilters'}>
                              <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M13.0011 14H16.0011V11H18.0011V14H21.0011V16H18.0011V19H16.0011V16H13.0011V14ZM11.0011 16.88C11.0411 17.18 10.9411 17.5 10.7211 17.71C10.3211 18.1 9.69111 18.1 9.30111 17.71L5.29111 13.7C5.18204 13.5934 5.09911 13.463 5.04881 13.319C4.9985 13.175 4.98217 13.0213 5.00111 12.87V7.75L0.211108 1.62C0.0487161 1.41153 -0.0245586 1.14726 0.00729554 0.88493C0.0391497 0.622602 0.173543 0.383546 0.381108 0.22C0.571108 0.08 0.781108 0 1.00111 0H15.0011C15.2211 0 15.4311 0.08 15.6211 0.22C15.8287 0.383546 15.9631 0.622602 15.9949 0.88493C16.0268 1.14726 15.9535 1.41153 15.7911 1.62L11.0011 7.75V16.88ZM3.04111 2L7.00111 7.07V12.58L9.00111 14.58V7.05L12.9611 2H3.04111Z" fill="#666666"/>
                              </svg>

                              <span>Add Filters</span>
                          </button> */}
            </div>

            <div className="d-flex justify-content-between py-3">
              <button
                className="btn btn-success px-7 py-2 h-44 w-110 rounded-1 "
                onClick={() => {
                  review(dataget?.data.id, dataget.data.toApproval);
                }}
              >
                <span className="indicator-label fs-base text-White">
                  Approve
                </span>
              </button>

              <button
                className="btnpx-7 py-2 h-44 w-110 rounded-1 ms-9"
                style={{ background: "red", border: 0, color: "whitesmoke" }}
                onClick={() => {
                  // reject(dataget?.data.id);

                   ShowRejectModal(dataget?.data?.id);
                }}
              >
                <span className="indicator-label fs-base text-White">
                  Reject
                </span>
              </button>
            </div>
          </div>
          <div>
            <Table dataSource={dataSource} columns={columns} />
          </div>
        </div>
      </div>
      {/* <RejectModal /> */}

      {RejectModal()}
{     RejectionModal()}
      {SuccessModal()}

      <Modal
        dialogClassName=" m-auto px-7 modal-card-signup"
        aria-hidden="true"
        show={show}
        onHide={() => setShow(false)}
        animation
        centered
        size="lg"
        // backdrop={"static"}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="rounded">
          <div className="">
            <div className=" d-flex flex-column align-items-center ">
              <div className="mt-5">
                <svg
                  width="21"
                  height="19"
                  viewBox="0 0 21 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.5 7V16.75C1.5 16.9489 1.57902 17.1397 1.71967 17.2803C1.86032 17.421 2.05109 17.5 2.25 17.5H18.75C18.9489 17.5 19.1397 17.421 19.2803 17.2803C19.421 17.1397 19.5 16.9489 19.5 16.75V7M1.5 7L10.5 1L19.5 7M1.5 7L8.86363 12.25M19.5 7L12.1363 12.25M8.86363 12.25L1.73126 17.2881M8.86363 12.25L12.1363 12.25M19.2688 17.2881L12.1363 12.25"
                    stroke="#FBCB38"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>

              <div className="email-verification-container">
                <span className="email-verification-text">
                  Email Verification
                </span>
              </div>

              <div className="email-verification-container">
                <div className="email-verification-body">
                  <div>Please enter the 6-digit code sent to</div>
                  <div> {orgUserParser?.organizationUser.email} </div>
                </div>
              </div>

              {/* <div className="otp-box mt-5">
                <input
                  type="text"
                  maxLength={1}
                  name="input1"
                  onChange={handleChange}
                  value={formData.input1}
                />
                <input
                  type="text"
                  maxLength={1}
                  name="input2"
                  onChange={handleChange}
                  value={formData.input2}
                />
                <input
                  type="text"
                  maxLength={1}
                  name="input3"
                  onChange={handleChange}
                  value={formData.input3}
                  className="space"
                />
                <input
                  type="text"
                  maxLength={1}
                  name="input4"
                  onChange={handleChange}
                  value={formData.input4}
                />

                <input
                  type="text"
                  maxLength={1}
                  name="input5"
                  onChange={handleChange}
                  value={formData.input5}
                />

                <input
                  type="text"
                  maxLength={1}
                  name="input6"
                  onChange={handleChange}
                  value={formData.input6}
                />
              </div> */}

              <OTPInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderInput={(props) => <input {...props} />}
                containerStyle={"otp-box mt-5"}
                inputStyle={"w-100"}
                placeholder="------"
              />

              <div className="email-verification-container mt-2">
                <span className="resend-code-text" onClick={sendVerification}>
                  Resend Code
                </span>
              </div>
            </div>

            <div className=" d-flex flex-column align-items-center mt-5 ">
              <button
                className={`otp-button center-all ${
                  otp.length !== 6 ? "btn bg-gray-500" : ""
                } `}
                onClick={() => submitVerification(dataget?.data.id)}
                disabled={
                  otp.length !== 6
                    ? true
                    : isVerfyLoading || loadingVerify
                    ? true
                    : false
                }
              >
                {isVerfyLoading || loadingVerify ? (
                  <span className="otp-button-text">Loading..</span>
                ) : (
                  <span className="otp-button-text">Verify</span>
                )}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RequestAwaitingReview;
