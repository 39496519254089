import React from 'react';
import '../../../../../App.css';
import { Tabs, Breadcrumb } from 'antd';
import PayByFileUpload from './PayByFileUpload';

const BulkPayments = () => {

    return (
        <>
            <div className={'bulkPayments'}>
                <div className={'bulkpaymentsBreadcrumb'}>
                    <Breadcrumb>
                        <Breadcrumb.Item>Payments</Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <svg
                                width="10"
                                height="13"
                                viewBox="0 0 10 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                                    fill="#1F8505"
                                />
                            </svg>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>Retail/Vendor Payments</Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <svg
                                width="10"
                                height="13"
                                viewBox="0 0 10 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                                    fill="#1F8505"
                                />
                            </svg>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>Bulk Payments</Breadcrumb.Item>
                    </Breadcrumb>
                </div>

                <div className={'bpTabs'}>
                    <Tabs defaultActiveKey="pay-by-file-upload">
                        <Tabs.TabPane tab="Pay by File Upload" key="pay-by-file-upload">
                            <PayByFileUpload/>
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            </div>
        </>
    );
};

export default BulkPayments;
