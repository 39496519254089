const Steps = ({ step }: { step: number }) => (
    <div className="m-auto my-7e responsive-card overflow-none d-flex flex-column">
        <div className="d-flex align-items-center">
            <span className={`fw-bold fs-9 ${step >= 1 ? 'touched-step' : 'unTouched-step'}`}>1</span>

            <span className={`mx-1 ${step >= 2 ? 'touched-line' : 'unTouched-line'}`} />

            <span className={`fw-bold fs-9 ${step >= 2 ? 'touched-step' : 'unTouched-step'}`}>2</span>

            <span className={`mx-1 ${step >= 3 ? 'touched-line' : 'unTouched-line'}`} />

            <span className={`fw-bold fs-9 ${step >= 3 ? 'touched-step' : 'unTouched-step'}`}>3</span>
        </div>
        <div className="d-flex justify-content-between my-4">
            <span className="fs-9">Step 1 of 3</span>
            <span className="fs-9">Step 2 of 3</span>
            <span className="fs-9">Step 3 of 3</span>
        </div>
    </div>
);

export default Steps;
