import { ErrorToast } from "../components/toast";

export function exportHTMLDocument(data: any, title?: string, grandTotalFor?: any) {
    if (data.length === 0) {
        ErrorToast("No records are available to generate an HTML Document");
        return;
    }

    data.forEach(obj => {
        if (obj.hasOwnProperty('key')) {
            delete obj['key'];
        }
    });

    const grandTotalData = {};
    const grandTotalForKeys = Object.keys(grandTotalFor);

    grandTotalForKeys.forEach(key => {
        grandTotalData[key] = {
            name: grandTotalFor[key],
            totalAmount: 0
        };
    });

    data.forEach(record => {
        grandTotalForKeys.forEach(key => {
            grandTotalData[key].totalAmount += Number(record[key]?.toString()?.split('.')[0].replace(/\D/g, ""));
        });
    });

    let grandTotalValues = "";
    let grandTotalTitles = "";

    grandTotalForKeys.forEach(key => {
        grandTotalValues += `<td><div style="padding: 5px 10px">${grandTotalData[key].totalAmount.toLocaleString("en", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        })}</div></td>`;
        grandTotalTitles += `<th><div style="padding: 5px 10px">${grandTotalData[key].name}</div></th>`;
    });

    const tableHeader = Object.keys(data[0]).map(word =>
        word.replace(/([a-z])([A-Z])/g, "$1 $2").toUpperCase()
    );

    const headerHtmlCode = tableHeader
        .map(word => `<th><div style="padding-inline: 20px">${word}</div></th>`)
        .join("");

    const recordHtmlCode = data
        .map(record => {
            const singleRecordHTMLString = Object.entries(record)
                .map(entry => `<td><div>${entry[1]}</div></td>`)
                .join("");

            return `<tr>${singleRecordHTMLString}</tr>`;
        })
        .join("");

    const htmlCode = `
    <style>
        body {
            margin: 0;
            padding: 0;
        }
        table {
            border-collapse: collapse;
            table-layout: fixed;
            word-wrap: break-word;
        }
        
        thead {
            background: #1F8505;
        }
        
        tr:nth-child(even) {
            background: rgba(128, 128, 128, 0.2);
            border-collapse: collapse;
        }
        
        th, td {
            text-align: center;
            white-space: nowrap;
            border-collapse: collapse;
        }
        
        th > div {
            color: rgba(255, 255, 255, 0.9);
            padding: 15px 20px;
        }
        
        .grandTotal {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
        }
        
        td > div {
            padding: 13px 20px;
        }
    </style>
    <h1 style="color: #1F8505; margin: 10px; text-align: center">
      ${title?.split('-')[0] ? title.split('-')[0] : ""}
    </h1>
    <h2 style="color: #1F8505; margin: 10px; text-align: center; font-size: 20px">
      ${title?.split('-')[1] ? title.split('-')[1] : ""}
    </h2>
    <h3 style="color: #1F8505; margin: 10px; text-align: center">
        Generated on ${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}
    </h3>
    <div class="grandTotal" style="display: ${grandTotalValues ? 'flex' : 'none'}">
        <div>
            <h3 style="color: #1F8505; margin: 30px; text-align: center">Grand Totals: </h3>
        </div>
        <div>
            <table>
                <thead style="color: rgba(255, 255, 255, 0.9);"><tr>${grandTotalTitles}</tr></thead>
                <tbody style="background: rgba(128, 128, 128, 0.2); color: black"><tr>${grandTotalValues}</tr></tbody>
            </table>
        </div>
    </div>
    
    <div style="width: 100%; overflow-x: auto">
        <table>
          <thead>
            <tr>${headerHtmlCode}</tr>
          </thead>
          <tbody>${recordHtmlCode}</tbody>
        </table>
    </div>
  `;

    const a = document.createElement("a");
    a.setAttribute("href", "data:text/html," + encodeURIComponent(htmlCode));
    a.setAttribute("download", `${title}.html`);
    document.body.appendChild(a);
    a.click();
}
