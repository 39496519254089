/* eslint-disable react-hooks/exhaustive-deps */
import { LoaderContainer, isEmpty } from "core/helpers";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumb } from "../components/Breadcrumb";
import { ProjectSuccessModal } from "../components/ProjectSuccessModal";
import { ProjectSchema1 } from "../components/Schema";
import { useGetProjectsById, useEditProject } from "../hooks/hooks";
import "../project.scss";

const links = [
  {
    name: "Project",
  },
  {
    name: "View Details",
  },
];

interface BasicDetails {
  projectId: string;
  projectName: string;
  projectBudget: number;
  projectDescription: string;
}

export const BasicDetailsEdit: React.FC = () => {
  let { id } = useParams();

  const navigate = useNavigate();

  const [show, setShow] = useState<boolean>(false);

  const {
    data: projectDetails,
    mutate: mutateDetails,
    isLoading: isLoadingDetails,
  } = useGetProjectsById();

  const initialValues: BasicDetails = {
    projectId: projectDetails?.projectId,
    projectName: projectDetails?.name,
    projectBudget: projectDetails?.budget,
    projectDescription: projectDetails?.description,
  };

  const { mutate: mutateEditProject, isLoading: editProjectLoading } =
    useEditProject();

  const formik = useFormik({
    initialValues,
    validationSchema: ProjectSchema1,

    onSubmit: async (values) => {
      const dataToSend = {
        // organization: {
        //   id: lazydayUser.id,
        //   name: lazydayUser.registeredName,
        // },
        id,
        projectId: formik.values.projectId,
        name: formik.values.projectName,
        budget: formik.values.projectBudget,
        description: formik.values.projectDescription,

        //only for oagf
        // orgInvolved: true,
        // involvedOrganizations: [
        //   {
        //     id: "string",
        //     name: "string",
        //   },
        // ],
        //stop
      };

      mutateEditProject(dataToSend, {
        onSuccess: () => {
          setShow(true);
        },
        onError: (err: any) => {},
      });
    },
  });

  useEffect(() => {
    mutateDetails(id, {
      onSuccess: () => {},
    });
  }, []);

  useEffect(() => {
    formik.setValues({
      projectId: projectDetails?.projectId,
      projectName: projectDetails?.name,
      projectBudget: projectDetails?.budget,
      projectDescription: projectDetails?.description,
    });
  }, [projectDetails]);

  return (
    <div className={"adminBody"}>
      {(editProjectLoading || isLoadingDetails) && <LoaderContainer />}

      <Breadcrumb links={links} />

      {projectDetails && (
        <div className="create-content-container">
          <div className="create-content">
            <p className="fs-8 fw-bolder text-dark2 mb-0 text-center">
              Edit Basic Details
            </p>

            <form
              onSubmit={formik.handleSubmit}
              noValidate
              className="form align-middle  gx-0 gy-5 mt-3"
            >
              <div className="mt-1 ">
                <div className="col-lg-12 col-form-label py-0 input-height">
                  <label className="fw-bold fs-9 required">Project ID</label>
                </div>

                <div
                  className={`form-control form-control-solid border-0 form-control-disabled`}
                >
                  <input
                    type="text"
                    placeholder="Project ID"
                    className={"no-outline text-dark fs-8"}
                    {...formik.getFieldProps("projectId")}
                    disabled
                  />
                </div>
                <div className="fv-plugins-message-container input-height" />
              </div>

              <div className="mt-1">
                <div className="col-lg-12 col-form-label py-0 input-height">
                  {formik.values.projectName?.length !== 0 && (
                    <label className="fw-bold fs-9 required">
                      Project Name
                    </label>
                  )}
                </div>

                <div className={`form-control form-control-solid `}>
                  <input
                    type="text"
                    placeholder="Project Name"
                    className={"no-outline text-dark fs-8"}
                    {...formik.getFieldProps("projectName")}
                  />
                </div>
                <div className="fv-plugins-message-container input-height">
                  <div className="fv-help-block fs-9 text-danger">
                    {formik.touched.projectName &&
                      formik.errors.projectName &&
                      formik.errors.projectName}
                  </div>
                </div>
              </div>

              <div className="mt-1">
                <div className="col-lg-12 col-form-label py-0 input-height">
                  {formik.values.projectBudget > 0 && (
                    <label className="fw-bold fs-9 required">
                      Project Budget
                    </label>
                  )}
                </div>

                <div className={`form-control form-control-solid`}>
                  <NumericFormat
                    type="text"
                    prefix="₦"
                    thousandsGroupStyle="thousand"
                    thousandSeparator=","
                    className="no-outline text-dark fs-8"
                    placeholder="Project Budget"
                    allowNegative={false}
                    allowLeadingZeros={false}
                    onValueChange={(event) => {
                      formik.setFieldValue("projectBudget", event.floatValue);

                      if (event.floatValue === projectDetails?.budget) {
                        formik.setFieldTouched("projectBudget", false);

                        const { projectBudget, ...rest } = formik.touched;

                        formik.setTouched(rest);
                      } else {
                        formik.setFieldTouched("projectBudget", true);
                      }
                    }}
                    value={formik.values.projectBudget}
                  />
                </div>

                <div className="fv-plugins-message-container input-height">
                  <div className="fv-help-block fs-9 text-danger">
                    {formik.errors.projectBudget && formik.errors.projectBudget}
                  </div>
                </div>
              </div>

              <div className="mt-1">
                <div className="col-lg-12 col-form-label py-0 input-height">
                  {formik.values.projectDescription?.length !== 0 && (
                    <span className="fw-bold fs-9 required">
                      Project Description
                    </span>
                  )}
                </div>

                <textarea
                  placeholder="Project Description"
                  className={
                    "text-dark fw-normal fs-8 form-control form-control-solid bg-admin-body"
                  }
                  {...formik.getFieldProps("projectDescription")}
                  rows={5}
                />

                <div className="fv-plugins-message-container input-height">
                  <div className="fv-help-block fs-9 text-danger">
                    {formik.touched.projectDescription &&
                      formik.errors.projectDescription &&
                      formik.errors.projectDescription}
                  </div>
                </div>
              </div>

              <div className="d-flex  justify-content-center mt-5">
                <button
                  id="kt_password_cancel "
                  type="submit"
                  className={`btn ${
                    !formik.isValid ||
                    !formik.dirty ||
                    !formik.touched ||
                    isEmpty(formik.touched)
                      ? "btn-secondary"
                      : "btn-success"
                  } text-white px-15 py-2 h-44 w-min-110 rounded-1`}
                  disabled={
                    !formik.isValid || !formik.dirty || isEmpty(formik.touched)
                  }
                >
                  <span className="indicator-label fs-8">Update</span>
                </button>
              </div>
            </form>

            <ProjectSuccessModal
              show={show}
              setShow={setShow}
              done={() => navigate(-1)}
            />
          </div>
        </div>
      )}
    </div>
  );
};
