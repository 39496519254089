import { NumberFormatValues, NumericFormat, OnValueChange } from 'react-number-format';

export interface NumericInputsProps {
    options?: Array<{ value: string; label: string }>; // Adjust types based on your data structure
    selected?: { value: string; label: string } | null;
    inputValue?: string;
    type?: 'text' | 'tel' | 'password';
    value: number | string | null;
    onValueChange?: OnValueChange;
    isAllowed?: (values: NumberFormatValues) => boolean;
    placeholder?: string;
    thousandSeparator?: boolean | string;
    decimalSeparator?: string;
    allowedDecimalSeparators?: Array<string>;
    thousandsGroupStyle?: 'thousand' | 'lakh' | 'wan' | 'none';
    decimalScale?: number;
    fixedDecimalScale?: boolean;
    allowNegative?: boolean;
    allowLeadingZeros?: boolean;
    suffix?: string;
    prefix?: string;
    disabled?:boolean
    isNumberInput?:boolean
    className?: string
}

const NumericInput: React.FC<NumericInputsProps> = ({
    value,
    prefix = '₦',
    type = 'text',
    isAllowed,
    onValueChange,
    placeholder,
    thousandsGroupStyle = 'thousand',
    thousandSeparator = ',',
    allowNegative = false,
    allowLeadingZeros = false,
    disabled,
    decimalScale,
    isNumberInput=false,
    className
}) => (
    <NumericFormat
        type={type}
        prefix={isNumberInput ? undefined : prefix}
        thousandsGroupStyle={isNumberInput ? undefined : thousandsGroupStyle}
        thousandSeparator={isNumberInput ? undefined : thousandSeparator}
        value={value}
        className={className || "no-outline text-dark ps-4 fs-8"}
        placeholder={placeholder}
        allowNegative={allowNegative}
        onValueChange={onValueChange}
        isAllowed={isAllowed}
        allowLeadingZeros={allowLeadingZeros}
        disabled={disabled || false}
        decimalScale={decimalScale}
    />
);

export { NumericInput };
