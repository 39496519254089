import React from "react";
import {Tabs} from "antd";
import EditExistingUser from "./EditExistingUser";
import AllUserEdit from "./AllUserEdit";
import { isOagfAdmin } from "core/helpers";


const EditExistingUserCard = () => {
    return (
            <div className={'userManagementTab'}>
                <Tabs>
                    {isOagfAdmin() &&
                    <Tabs.TabPane tab="Edit Details for All Existing Users" key="all-user">
                        <AllUserEdit/>
                    </Tabs.TabPane>}

                    <Tabs.TabPane tab="Edit Details for Existing MDA Users" key="edit-user">
                        <EditExistingUser/>
                    </Tabs.TabPane>
                </Tabs>
            </div>
    );
};

export default EditExistingUserCard;
