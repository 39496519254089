export const RevenueComponent = ({ selected, setProceedData, proceedData }) => (
    <ul className="list-style">
        {selected?.services?.map((value: any, index: any) => {
            if (!value.active) {
                return null;
            }
            return (
                <div
                    key={index}
                    className="d-flex align-items-center h-2r py-2 mx-4 cursor-pointer"
                    onClick={() =>
                        setProceedData((prevData) => ({
                            ...prevData,
                            ...value,
                            serviceType: value.description,
                            serviceId: value.id,
                            serviceName: value.description,
                            amount: value.amount,
                        }))
                    }
                >
                    <span className="form-check form-check-custom ">
                        <input
                            type="checkbox"
                            className="form-check-input w-1r h-1r rounded-1 form-check-input-alt"
                            name={value.description}
                            checked={proceedData?.serviceId === value.id}
                            value={value.description}
                            onChange={(e) =>
                                setProceedData((prevData) => ({
                                    ...prevData,
                                    ...value,
                                    serviceType: e.target.value,
                                    serviceId: value.id,
                                    serviceName: e.target.value,
                                    amount: value.amount,
                                }))
                            }
                        />
                        <span className="text-muted fs-9 ms-3">{value?.description}</span>
                    </span>
                </div>
            );
        })}
    </ul>
);

