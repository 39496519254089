import React, { useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
  // labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
  datasets: [
    {
      label: "# of Votes",
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        "#1F8505",
        "#E7E7E7",
        "#3EC5E3",
        "#FBCB38",
        "#FBD0D1",
        "#A5CE9B",
      ],
    },
  ],
};

export const PieChat = ({ dataC, setMdaList }) => {
  const chartData = {
    labels: dataC?.map((organization) => organization.registeredName),
    datasets: [
      {
        data: dataC?.map((organization) => organization.totalRevenue),
        backgroundColor: [
          "#1F8505",
          "#E7E7E7",
          "#3EC5E3",
          "#FBCB38",
          "#FBD0D1",
          "#A5CE9B",
          // Add more colors if you have more data points
        ],
      },
    ],
  };

  const chartOptions = {
    plugins: {
      legend: {
        display: false, // Hide the legend (including the labels on top of the chart)
      },
    },
  };

  


  

  useEffect(() => {
  const labels = chartData?.labels;
    setMdaList(labels);
  }, [dataC]);


  const chartWidth = 100; // Set the desired width
  const chartHeight = 100; // Set the desired height
  return (
    <div className="w-150px h-150px">
      <Doughnut
        options={chartOptions}
        width={chartWidth}
        height={chartHeight}
        data={chartData}
      />
      {/* <ul>
        {labels?.map((label, index) => (
          <li key={index}>{label}</li>
        ))}
      </ul> */}
    </div>
  );
};

// export function App() {
// }
