import qs from "qs";

export const getPaginationParams = (params: any) => {
  if (params.pagination?.current > 1) {
    const data = {
      size: params.pagination?.pageSize,
      page: params.pagination?.current - 1,
    };

    return qs.stringify(data);
  } else {

    const data = {
      size: params.pagination?.pageSize,
      page: 0,
    };
    return qs.stringify(data);
  }
};

export const getPaginationParamsDefault = (params: any) => {
  const data = {
    size: params.pagination?.pageSize,
    ...(params.pagination?.current > 1 && {
      page: params.pagination?.current - 1,
    }),
  };

  return qs.stringify(data);
};