import { Delete, plus } from "core/assets/icons";
import { isEmpty } from "core/helpers";
import Multiselect from "multiselect-react-dropdown";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

interface CreateStep4Props {
  formik: any;
  goBack: () => void;
  ProcessTypes: any;
  setWorkflow: any;
  workflow: any;
  setUsersArray: (e: any) => void;
  usersArray: any;
  selectedProcessTypes: any;
  setSelectedProcessTypes: (e: any) => void;
  setStep: (e: number) => void;
}

const TableUserWork: React.FC<any> = (data) => {
  return (
    <table className="table my-10">
      <thead>
        <tr>
          <th>ID</th>
          <th>Process Type</th>
          <th>Initiator</th>
          <th>Reviewer</th>
          <th>Approver </th>
          {/* <th>Action</th> */}
        </tr>
      </thead>
      <tbody>
        {data?.map((userFlow, i) => {
          return (
            <tr key={i}>
              <td> {i + 1} </td>
              <td>{userFlow.cat}</td>
              {userFlow.roles.map((user, i) => {
                return (
                  <td>
                    {user.users.map((u, i) => {
                      return (
                        <div>
                          <div>{u?.name}</div>
                        </div>
                      );
                    })}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export const CreateStep4: React.FC<CreateStep4Props> = ({
  formik,
  goBack,
  ProcessTypes,
  setWorkflow,
  workflow,
  setUsersArray,
  usersArray,
  selectedProcessTypes,
  setSelectedProcessTypes,
  setStep,
}) => {
  const [selectedUserArray, setSelectedUserArray] = useState<any>([]);
  const [userProcessType, setUserProcessType] = useState<any>([]);
  const [currentSection, setCurrentSection] = useState("");
  const [showUserList, setShowUserList] = useState(false);
  const multiselectRef = React.createRef<any>();

  const [selectedUser, setSelectedUser] = useState<any>([]);
  const [selectedUsers, setSelectedUsers] = useState<any>([]);

  const [selectedUsersList, setSelectedUsersList] = useState<any>([]);
  const [allowPreview, setAllowPreview] = useState<boolean>(false);
  const [tableData, setUserTableData] = useState<any>([]);

  const updateOrAddSelectedUser = (newData: any) => {
    setSelectedUsersList((prevData) => {
      const index = prevData.findIndex((obj) => obj.where === currentSection);
      const setnewData = { where: currentSection, workflow: newData };

      if (index !== -1) {
        // Update existing data
        return prevData.map((obj, i) =>
          i === index ? { ...obj, ...setnewData } : obj
        );
      } else {
        // Add new data
        return [...prevData, setnewData];
      }
    });
  };

  const updateSelectUser = (name: any, fulldata: any) => {
    const selectedUserData = name;
    // If the user is already in the temporary users array, remove it
    if (selectedUser.includes(selectedUserData)) {
      setSelectedUser(
        selectedUser.filter((tempUser) => tempUser !== selectedUserData)
      );
      setSelectedUsers(
        selectedUsers.filter((item) => item.name !== selectedUserData)
      );
    } else {
      // Otherwise, add it to the temporary users array
      setSelectedUser([...selectedUser, selectedUserData]);
      setSelectedUsers([...selectedUsers, fulldata]);
    }
  };

  const onSelectProcessType = (event) => {
    setUserProcessType(event);
  };

  const previewChanges = () => {
    const result = selectedUsersList.reduce(
      (acc: { [key: string]: string[] }, obj) => {
        const { workflow, where } = obj;
        if (!acc[where]) {
          acc[where] = [];
        }
        acc[where].push(...workflow);
        return acc;
      },
      {}
    );

    const roles = Object.entries(result).map(([name, users]) => ({
      name,
      users,
    }));
    
    const types: string[] = userProcessType.map((obj: any) => {
      return {
        ...obj,
        roles,
      };
    });

    setUserTableData(types);
    setWorkflow(types);
    setUsersArray(selectedUsersList);
    setSelectedProcessTypes(userProcessType);
    setAllowPreview(true);
    setSelectedUsersList([]);
    setUserProcessType([]);
    multiselectRef.current.resetSelectedValues();
  };

  const editChanges = () => {
    setSelectedUsersList(usersArray);
    setUserProcessType(selectedProcessTypes);
    setAllowPreview(false);
  };

  const ProcessTypesList = ProcessTypes
  ?.filter((item: any) => {
    const description = item.description?.toLowerCase();
    return description !== 'collections' && description !== 'mda creation' && description !== 'project creation' && description !== 'user administration"';
  })
  ?.map((item: any) => ({
    cat: item?.description,
    key: item?.code,
  }));

  const onSubmitSelectUser = (where: any) => {
    updateOrAddSelectedUser(selectedUsers);
    setSelectedUsers([]);

    setSelectedUserArray([
      ...selectedUserArray,
      { user: selectedUser, where: where },
    ]);
    setSelectedUser([]);
    setShowUserList(false);
  };

  const removeNameObject = (where: any, indexToRemove: any) => {
    setSelectedUsersList((prevData) => {
      return prevData.map((obj) => {
        if (obj.where === where) {
          obj.workflow = obj.workflow.filter(
            (item: any, index: any) => index !== indexToRemove
          );
        }
        return obj;
      });
    });
  };

  const getSelectedWorkflow = (where: string) => {
    setCurrentSection(where);

    const filteredData = selectedUsersList.filter(
      (item) => item.where === where
    );

    const nameNames = filteredData.map((item) =>
      item.workflow.map((n) => n.name)
    );

    setSelectedUser(nameNames.flat());
    setSelectedUsers(filteredData[0]?.workflow ?? []);

    setShowUserList(true);
  };

  const userList = (where: any) => {
    return (
      <Modal
        dialogClassName=" m-auto px-7 modal-card-signup"
        aria-hidden="true"
        show={showUserList}
        onHide={() => setShowUserList(false)}
        animation
        centered
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="rounded">
          <div className="">
            {formik?.values?.users?.map((value: any, index: any) => {
              return (
                <div
                  key={index}
                  className="d-flex align-items-center  h-2r border-bottom border-gray-800 my-2 mx-4 cursor-pointer"
                  onClick={() => {
                    updateSelectUser(value.name, value);
                  }}
                >
                  <input
                    className="form-check-input w-1r h-1r rounded-0"
                    type="checkbox"
                    value={value?.name}
                    checked={selectedUser.includes(value.name)}
                    onChange={(e: any) =>
                      updateSelectUser(e.target.value, value)
                    }
                  />
                  <span className="fs-9 ms-2 mb-2">{value?.name}</span>
                </div>
              );
            })}

            <div className=" d-flex flex-column align-items-center mt-5 ">
              <button
                className={`btn btn-alt-success
                     text-white px-7 py-2 h-42 w-84 rounded-1`}
                disabled={selectedUser.length < 1}
                onClick={() => onSubmitSelectUser(where)}
              >
                <span className="indicator-label fs-base">Ok</span>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  function checkWorkflowValidity() {
    const isValidLength = selectedUsersList?.length >= 3;

    const isValidNames = selectedUsersList?.every(
      (item) => item.workflow.length >= 1
    );

    return isValidLength && isValidNames;
  }

  useEffect(() => {
    setSelectedUsersList(usersArray);
    setUserProcessType(selectedProcessTypes);
  }, []);

  return (
    <>
      <div className="create-content">
        <p className="fs-8 fw-bolder text-dark2 mb-0">Workflow Definition</p>

        <span className="text-gray-500 fs-9">
          Assign the users in the previous step to process types and give them
          roles (initiator, reviewer and approver).
        </span>

        <form
          onSubmit={formik.handleSubmit}
          noValidate
          className="form align-middle  gx-0 gy-5 mt-3"
        >
          <div className="">
            <div className="col-lg-6 col-form-label py-0 input-height my-1">
              <label className="required fw-bold fs-9">Process Type</label>
            </div>
            <Multiselect
              displayValue="cat"
              ref={multiselectRef}
              onKeyPressFn={function noRefCheck() {}}
              onRemove={onSelectProcessType}
              onSearch={function noRefCheck() {}}
              onSelect={onSelectProcessType}
              selectedValues={userProcessType}
              disable={allowPreview}
              hidePlaceholder={true}
              className="fs-10e rounded-1 "
              style={{
                chips: {
                  background: "#40A060",
                  minWidth: 158,
                  color: "white",
                  fontWeight: "500",
                  borderRadius: 3,
                },
                searchBox: {
                  borderColor: "#72D393",
                  background: "#F9F9F9",
                  fontSize: 10,
                  height: "100%",
                  padding: "1rem",
                },
                option: {
                  color: "#40A060",
                  background: "#F9F9F9",
                },
                optionContainer: {
                  // background:'red',
                },
              }}
              options={ProcessTypesList}
            />
          </div>

          <div>
            <div className="select-option mt-12 d-flex align-items-center ">
              <span className="text-muted required fs-base">Initiator</span>
              <button
                onClick={() => {
                  if (!isEmpty(userProcessType)) {
                    getSelectedWorkflow("initiator");
                  }
                }}
                disabled={isEmpty(userProcessType)}
                type="button"
                className="select-option-side d-flex justify-content-center align-items-center h-3r w-191 rounded-1 bg-white"
              >
                <img src={plus} alt="plus" className="w-1r h-1r" />
                <span className=" ms-3 text-success fs-9">Add Initiator</span>
              </button>
            </div>

            {selectedUsersList?.map(
              (item: { where: string; workflow: any[] }, i: any) => {
                if (item.where !== "initiator") {
                  return null;
                }

                return (
                  <React.Fragment key={i}>
                    {item.workflow.map((user, userIndex) => {
                      return (
                        <div
                          className="d-flex align-items-end ms-5"
                          key={userIndex}
                        >
                          <span className="bg-footer w-4r  rounded-1 h-44 negative-top" />
                          <div className=" d-flex h-24px align-items-center jusstify-content-evenly">
                            <div className=" d-flex align-items-center ">
                              <span className="bg-gray w-80e  rounded-1 h-4r d-flex align-items-end  negative-left " />
                              <span className="bg-success w-10r  rounded-3 h-10r d-flex align-items-end negative-left" />
                            </div>

                            <span className="ms-12 text-dark2 fs-base fluid-width text-wrap">
                              {user.name}
                            </span>
                            <span
                              onClick={() =>
                                removeNameObject(item.where, userIndex)
                              }
                            >
                              <img
                                src={Delete}
                                alt="deleteIcon"
                                className="ms-4 w-1r h-1r"
                              />
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </React.Fragment>
                );
              }
            )}
          </div>

          {userProcessType["0"]?.cat === "Collections" ? (
            ""
          ) : (
            <div>
              <div className="select-option mt-12 d-flex align-items-center ">
                <span className="text-muted required fs-base">Reviewer</span>
                <button
                  onClick={() => {
                    if (!isEmpty(userProcessType)) {
                      getSelectedWorkflow("reviewer");
                    }
                  }}
                  disabled={isEmpty(userProcessType)}
                  type="button"
                  className="select-option-side d-flex justify-content-center align-items-center h-3r w-191 rounded-1 bg-white"
                >
                  <img src={plus} alt="plus" className="w-1r h-1r" />
                  <span className=" ms-3 text-success fs-9">Add Reviewer</span>
                </button>
              </div>

              {selectedUsersList?.map(
                (item: { where: string; workflow: any[] }, i: any) => {
                  if (item.where !== "reviewer") {
                    return null;
                  }

                  return (
                    <React.Fragment key={i}>
                      {item.workflow.map((user, userIndex) => {
                        return (
                          <div
                            className="d-flex align-items-end ms-5"
                            key={userIndex}
                          >
                            <span className="bg-footer w-4r  rounded-1 h-44 negative-top" />
                            <div className=" d-flex h-24px align-items-center jusstify-content-evenly">
                              <div className=" d-flex align-items-center ">
                                <span className="bg-gray w-80e  rounded-1 h-4r d-flex align-items-end  negative-left " />
                                <span className="bg-success w-10r  rounded-3 h-10r d-flex align-items-end negative-left" />
                              </div>

                              <span className="ms-12 text-dark2 fs-base fluid-width text-wrap">
                                {user.name}
                              </span>
                              <span
                                onClick={() =>
                                  removeNameObject(item.where, userIndex)
                                }
                              >
                                <img
                                  src={Delete}
                                  alt="deleteIcon"
                                  className="ms-4 w-1r h-1r"
                                />
                              </span>
                            </div>
                          </div>
                        );
                      })}
                    </React.Fragment>
                  );
                }
              )}
            </div>
          )}

          {userProcessType["0"]?.cat === "Collections" ? (
            ""
          ) : (
            <div>
              <div className="select-option mt-12 d-flex align-items-center ">
                <span className="text-muted required fs-base">Approver</span>
                <button
                  onClick={() => {
                    if (!isEmpty(userProcessType)) {
                      getSelectedWorkflow("approver");
                    }
                  }}
                  disabled={isEmpty(userProcessType)}
                  type="button"
                  className="select-option-side d-flex justify-content-center align-items-center h-3r w-191 rounded-1 bg-white"
                >
                  <img src={plus} alt="plus" className="w-1r h-1r" />
                  <span className=" ms-3 text-success fs-9">Add Approver</span>
                </button>
              </div>

              {selectedUsersList?.map(
                (item: { where: string; workflow: any[] }, i: any) => {
                  if (item.where !== "approver") {
                    return null;
                  }

                  return (
                    <React.Fragment key={i}>
                      {item.workflow.map((user, userIndex) => {
                        return (
                          <div
                            className="d-flex align-items-end ms-5"
                            key={userIndex}
                          >
                            <span className="bg-footer w-4r  rounded-1 h-44 negative-top" />
                            <div className=" d-flex h-24px align-items-center jusstify-content-evenly">
                              <div className=" d-flex align-items-center ">
                                <span className="bg-gray w-80e  rounded-1 h-4r d-flex align-items-end  negative-left " />
                                <span className="bg-success w-10r  rounded-3 h-10r d-flex align-items-end negative-left" />
                              </div>

                              <span className="ms-12 text-dark2 fs-base fluid-width text-wrap">
                                {user.name}
                              </span>
                              <span
                                onClick={() =>
                                  removeNameObject(item.where, userIndex)
                                }
                              >
                                <img
                                  src={Delete}
                                  alt="deleteIcon"
                                  className="ms-4 w-1r h-1r"
                                />
                              </span>
                            </div>
                          </div>
                        );
                      })}
                    </React.Fragment>
                  );
                }
              )}
            </div>
          )}

          {!allowPreview && (
            <div className="d-flex  justify-content-between mt-5">
              <div className="">
                <button
                  id="kt_password_cancel "
                  type="button"
                  className="btn btn-white border-success   text-success px-11 py-2 h-44 w-min-110 rounded-1` "
                  onClick={goBack}
                >
                  <span className="indicator-label fs-base ">Previous</span>
                </button>
              </div>

              <div className="">
                <button
                  id="kt_password_cancel "
                  type="submit"
                  className={`btn ${
                    !checkWorkflowValidity() ? "btn-secondary" : "btn-success"
                  } text-white px-15 py-2 h-44 w-min-110 rounded-1`}
                  disabled={!checkWorkflowValidity()}
                  onClick={() => previewChanges()}
                >
                  <span className="indicator-label fs-base">Next</span>
                </button>
              </div>
            </div>
          )}
        </form>

        {userList(currentSection)}
      </div>

      {allowPreview && (
        <>
          {TableUserWork(tableData)}
          <div className="user-workflow-btn">
            <div className="">
              <button
                type="button"
                className="btn btn-white border-success   text-success px-11 py-2 h-44 w-min-110 rounded-1` "
                onClick={() => editChanges()}
              >
                <span className="indicator-label fs-base ">Previous</span>
              </button>
            </div>

            <div className="">
              <button
                type="button"
                className={`btn btn-success text-white px-15 py-2 h-44 w-min-110 rounded-1`}
                onClick={() => setStep(5)}
              >
                <span className="indicator-label fs-base">Next</span>
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};
