import React, { useEffect, useState } from "react";
import "../../../App.css";
import { Layout } from "./layout";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  useGetOverViewOagf,
  useGetActivityLog,
  useGettop10Organizations,
  useGettoptop5mdasectors,
  useGettoptop5mdaServices,
} from "./Payments/hooks/usePayment";
import ReviewerDashboard from "../Reviewer/ReviewerDashboard";
import { FlapperSpinner } from "react-spinners-kit";
import { eyeDisable, eyeVisible } from "core/assets/icons";
import { useGetAwaitingRequest } from "../payments/hooks/useRevenuePayment";
import { PieChat } from "core/helpers/Pie";
import { useGetLedgerAccounts } from "../auth/hooks/useUser";

const dateFormat = "YYYY/MM/DD";

const { RangePicker } = DatePicker;

const OAGFOverview = () => {
  const navigate = useNavigate();

  const moveToInActiveService = () => {
    navigate("/admin/oagf-overview/service-activation");
  };

  const { data, mutate, isLoading } = useGetOverViewOagf();
  const [mdaList, setMdaList] = useState<any[]>([]);
  const [orgName, setOrgName] = useState<string>("");
  const [payType, setPayType] = useState<string>("");
  const [searchf, setSearchf] = useState<string>("");
  const {
    data: dataLog,
    mutate: mutateLog,
    isLoading: loadingLog,
  } = useGetActivityLog();

  const {
    data: dataTop10Organizations,
    mutate: mutateTop10Organizations,
    isLoading: loadingTop10Organizations,
  } = useGettop10Organizations();

  ////console.log(dataTop10Organizations?.[0]?.top10Organizations);

  const {
    data: dataToptop5mdasectors,
    mutate: mutateToptop5mdasectors,
    isLoading: loadingToptop5mdasectors,
  } = useGettoptop5mdasectors();

  const {
    data: dataToptop5mdaServices,
    mutate: mutateToptop5mdaServices,
    isLoading: loadingToptop5mdaServices,
  } = useGettoptop5mdaServices();

  const {
    data: awaitingRequest,
    mutate: mutateRequests,
    isLoading: requestLoading,
  } = useGetAwaitingRequest();

  const {
    data: glAccountList,
    mutate: glMutate,
    isLoading: glLoading,
  } = useGetLedgerAccounts();

  useEffect(() => {
    mutate();
    // mutateRequests();
    const { startDate, endDate } = getStartAndEndOfDay();
    setDateRange([dayjs(startDate), dayjs(endDate)]);
    mutateLog(`start-date=${startDate}&end-date=${endDate}`);
    mutateTop10Organizations();
    mutateToptop5mdasectors();
    mutateToptop5mdaServices();
    glMutate(`size=0`)
  }, []);

  const goToReport = () => {
    navigate("/admin/reports/payment-report");
  };

  const moveToMdaActive = () => {
    navigate("/admin/mda-directory");
  };

  const capitalizeFirstTwoLetters = (str: string) => {
    if (str.length < 2) {
      return str.toUpperCase();
    }

    const firstTwoLetters = str.substring(0, 2); // Extract the first two letters
    const capitalizedFirstTwoLetters = firstTwoLetters.toUpperCase(); // Capitalize the first two letters

    return capitalizedFirstTwoLetters;
  };

  const getStartAndEndOfDay = () => {
    const currentDate = new Date(); // Get the current date and time

    // Format the start date
    const startYear = currentDate.getFullYear();
    const startMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
    const startDay = String(currentDate.getDate()).padStart(2, "0");
    const startHour = "00";
    const startMinute = "00";
    const startSecond = "00";
    const startDate = `${startYear}-${startMonth}-${startDay}T${startHour}:${startMinute}:${startSecond}`;

    // Format the end date
    const endYear = currentDate.getFullYear();
    const endMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
    const endDay = String(currentDate.getDate()).padStart(2, "0");
    const endHour = "23";
    const endMinute = "59";
    const endSecond = "00";
    const endDate = `${endYear}-${endMonth}-${endDay}T${endHour}:${endMinute}:${endSecond}`;

    return {
      startDate,
      endDate,
    };
  };

  const [dateRange, setDateRange] = useState<any>([]);
  const handleDateRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      setDateRange(dates);
      const startDate = moment(new Date(dates[0]).toISOString()).format(
        "YYYY-MM-DDTHH:mm:ss"
      );
      const endDate = moment(new Date(dates[1]).toISOString()).format(
        "YYYY-MM-DDTHH:mm:ss"
      );
      mutateLog(`start-date=${startDate}&end-date=${endDate}`);
    } else {
      setDateRange([]);
    }
  };

  const [showBalance, SetShowBalance] = useState<boolean>(true);
  return (
    <>
      {loadingLog || requestLoading || glLoading ? (
        <div className="loading-container">
          <FlapperSpinner />
        </div>
      ) : (
        <div className={"OagfOverviewPage"}>
          <div className={"opRow1"}>
            <div>Overview</div>
            <div>
              <span className="data-range-text-overview">Date Range:</span>
              {/* <span className="data-from-text-overview">From</span>
              <DatePicker
                defaultValue={dayjs("2015/01/01", dateFormat)}
                format={dateFormat}
                className="datepicker"
              />
              <span className="data-from-text-overview">To</span>
              <DatePicker
                defaultValue={dayjs("2015/01/01", dateFormat)}
                format={dateFormat}
                className="datepicker"
              /> */}
              <Space direction="vertical" size={12}>
                <RangePicker
                  showTime
                  className="py-2"
                  value={dateRange}
                  onChange={handleDateRangeChange}
                  allowClear
                />
              </Space>
            </div>
          </div>

          <div className={"opRow2"}>
            <div
              className="cursor-pointer"
              onClick={() => SetShowBalance(!showBalance)}
            >
              <img src={showBalance ? eyeDisable : eyeVisible} />

              {showBalance ? (
                <span>Hide Balance</span>
              ) : (
                <span>Show Balance</span>
              )}
            </div>
            <div>
              <div>Total Balance:</div>
              <div>
                {showBalance ? (
                  data?.inflow ? (
                    (data?.inflow - data?.outflow)?.toLocaleString("en-NG", {
                      style: "currency",
                      currency: "NGN",
                    })
                  ) : (
                    "₦0"
                  )
                ) : (
                  <span>₦******</span>
                )}
              </div>
            </div>
          </div>

          <div className={"opRow3"}>
            <div className={"opRow3Card"}>
              <div>Number of Registered MDAs</div>
              <div> {data?.numOrgs} </div>
              <div>
                <button onClick={() => moveToMdaActive()}>View List</button>
              </div>
            </div>

            <div className={"opRow3Card"}>
              <div>List of MDAs Services</div>
              <div>{data?.numServices} </div>
              <div>
                <button onClick={() => moveToInActiveService()}>
                  View List
                </button>
              </div>
            </div>

            <div className={"opRow3Card"}>
              <div>List of Charts of Accounts</div>
              <div>{glAccountList?.totalSize}</div>
              <div>
                <button onClick={() => navigate("/admin/oagf-overview/chart-of-account")}>View List</button>
              </div>
            </div>

          </div>

          <div className={"opRow4"}>
            <div>Balances</div>

            <div>
              <div className={"opRow4CardDiv"}>
                <div className={"opRow4CardDiv1"}>
                  <div>
                    <div>
                      <svg
                        width="24"
                        height="14"
                        viewBox="0 0 24 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.5 13L22.5 7.75M7.5 13L1.5 7.75M16.5 1L22.5 6.25M7.5 1L1.5 6.25M2.25 1H21.75C22.1642 1 22.5 1.33579 22.5 1.75V12.25C22.5 12.6642 22.1642 13 21.75 13H2.25C1.83579 13 1.5 12.6642 1.5 12.25V1.75C1.5 1.33579 1.83579 1 2.25 1ZM15 7C15 8.65685 13.6569 10 12 10C10.3431 10 9 8.65685 9 7C9 5.34315 10.3431 4 12 4C13.6569 4 15 5.34315 15 7Z"
                          stroke="#1F8505"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <div>Today</div>
                  </div>
                  <div>Total Inflow</div>
                  <div>
                    <span className="fs-4">
                      {data?.inflow
                        ? data?.inflow?.toLocaleString("en-NG", {
                            style: "currency",
                            currency: "NGN",
                          })
                        : "₦0"}
                    </span>
                  </div>
                  <div>
                    <button onClick={goToReport}>View Report</button>
                  </div>
                </div>

                <div className={"opRow4CardDiv2"}>
                  <div>
                    <div>
                      <svg
                        width="24"
                        height="14"
                        viewBox="0 0 24 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.5 13L22.5 7.75M7.5 13L1.5 7.75M16.5 1L22.5 6.25M7.5 1L1.5 6.25M2.25 1H21.75C22.1642 1 22.5 1.33579 22.5 1.75V12.25C22.5 12.6642 22.1642 13 21.75 13H2.25C1.83579 13 1.5 12.6642 1.5 12.25V1.75C1.5 1.33579 1.83579 1 2.25 1ZM15 7C15 8.65685 13.6569 10 12 10C10.3431 10 9 8.65685 9 7C9 5.34315 10.3431 4 12 4C13.6569 4 15 5.34315 15 7Z"
                          stroke="#1F8505"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <div>Today</div>
                  </div>
                  <div>Total Outflow</div>
                  <div>
                    <span className="fs-4">
                      {data?.outflow
                        ? data?.outflow?.toLocaleString("en-NG", {
                            style: "currency",
                            currency: "NGN",
                          })
                        : "₦0"}
                    </span>
                  </div>
                  <div>
                    <button onClick={goToReport}>View Report</button>
                  </div>
                </div>

                <div className={"opRow4CardDiv3"}>
                  <div>
                    <div>
                      <svg
                        width="24"
                        height="14"
                        viewBox="0 0 24 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.5 13L22.5 7.75M7.5 13L1.5 7.75M16.5 1L22.5 6.25M7.5 1L1.5 6.25M2.25 1H21.75C22.1642 1 22.5 1.33579 22.5 1.75V12.25C22.5 12.6642 22.1642 13 21.75 13H2.25C1.83579 13 1.5 12.6642 1.5 12.25V1.75C1.5 1.33579 1.83579 1 2.25 1ZM15 7C15 8.65685 13.6569 10 12 10C10.3431 10 9 8.65685 9 7C9 5.34315 10.3431 4 12 4C13.6569 4 15 5.34315 15 7Z"
                          stroke="#1F8505"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <div>Yesterday</div>
                  </div>
                  <div>Total Closing Balance</div>
                  <div>
                    <span className="fs-4">
                      {data?.inflow
                        ? (data?.inflow - data?.outflow)?.toLocaleString(
                            "en-NG",
                            {
                              style: "currency",
                              currency: "NGN",
                            }
                          )
                        : "₦0"}
                    </span>
                  </div>
                </div>

                <div className={"opRow4CardDiv4"}>
                  <div>
                    <div>
                      <svg
                        width="24"
                        height="14"
                        viewBox="0 0 24 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.5 13L22.5 7.75M7.5 13L1.5 7.75M16.5 1L22.5 6.25M7.5 1L1.5 6.25M2.25 1H21.75C22.1642 1 22.5 1.33579 22.5 1.75V12.25C22.5 12.6642 22.1642 13 21.75 13H2.25C1.83579 13 1.5 12.6642 1.5 12.25V1.75C1.5 1.33579 1.83579 1 2.25 1ZM15 7C15 8.65685 13.6569 10 12 10C10.3431 10 9 8.65685 9 7C9 5.34315 10.3431 4 12 4C13.6569 4 15 5.34315 15 7Z"
                          stroke="#1F8505"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <div>Today</div>
                  </div>
                  <div>Total Opening balance</div>
                  <div>
                    <span className="fs-4">
                      {data?.inflow
                        ? (data?.inflow - data?.outflow)?.toLocaleString(
                            "en-NG",
                            { style: "currency", currency: "NGN" }
                          )
                        : "₦0"}
                    </span>
                  </div>
                </div>
              </div>

              <div className={"opRow4ActivityDiv"}>
                <div className="actv-fixed">Activity Log</div>

                <div className="h-95 overflow-y-hide pb-2">
                  {dataLog?.map((data, i) => {
                    //console.log("demmeme\n", data);

                    return (
                      <div className={"opRow4Activity"} key={i}>
                        <div className="capitalizeFirstTwoLetters">
                          <span className="capitalizeText">
                            {capitalizeFirstTwoLetters(data.createdBy)}
                          </span>
                        </div>
                        <div>
                          <div className={"opRow4ActivityLog"}>
                            {data.description}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className={"opRow5"}>
            <div>Insights</div>

            <div>
              <div className={"opRow5Card1"}>
                <div>
                  <div>Top 10 performing MDAs by revenue collected</div>
                  <div>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.875 9.875V14.25C14.875 14.4158 14.8092 14.5747 14.6919 14.6919C14.5747 14.8092 14.4158 14.875 14.25 14.875H1.75C1.58424 14.875 1.42527 14.8092 1.30806 14.6919C1.19085 14.5747 1.125 14.4158 1.125 14.25V9.875M4.71875 6.59461L8 9.875L11.2812 6.59461M8 1.125V9.8727"
                        stroke="#5E718D"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div>

                <PieChat
                  setMdaList={setMdaList}
                  dataC={dataTop10Organizations?.[0]?.top10Organizations}
                />

                {mdaList?.map((top10, i) => {
                  ////console.log(dataTop10Organizations);

                  return (
                    <div key={i} className={"opPerformingMda mt-4"}>
                      {" "}
                      {top10}{" "}
                    </div>
                  );
                })}
              </div>

              <div className={"opRow5Card2"}>
                <div className={"opRow5SectorCard"}>
                  <div>
                    <div>Top 5 performing MDA sectors</div>
                    <div>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.875 9.875V14.25C14.875 14.4158 14.8092 14.5747 14.6919 14.6919C14.5747 14.8092 14.4158 14.875 14.25 14.875H1.75C1.58424 14.875 1.42527 14.8092 1.30806 14.6919C1.19085 14.5747 1.125 14.4158 1.125 14.25V9.875M4.71875 6.59461L8 9.875L11.2812 6.59461M8 1.125V9.8727"
                          stroke="#5E718D"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </div>

                  {dataToptop5mdasectors?.map((top10Mda, i) => {
                    return (
                      <div key={i} className={"opPerformingSector"}>
                        {top10Mda.code}
                      </div>
                    );
                  })}
                </div>

                <div className={"opRow5RegionCard"}>
                  <div>
                    <div>Top 5 performing service</div>
                    <div>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.875 9.875V14.25C14.875 14.4158 14.8092 14.5747 14.6919 14.6919C14.5747 14.8092 14.4158 14.875 14.25 14.875H1.75C1.58424 14.875 1.42527 14.8092 1.30806 14.6919C1.19085 14.5747 1.125 14.4158 1.125 14.25V9.875M4.71875 6.59461L8 9.875L11.2812 6.59461M8 1.125V9.8727"
                          stroke="#5E718D"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </div>

                  {dataToptop5mdaServices?.map((top10Serv, i) => {
                    return (
                      <div key={i} className={"opPerformingServices"}>
                        {top10Serv.name}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <ReviewerDashboard
            requestList={awaitingRequest}
            mutate={mutateRequests}
            searchFilter={setSearchf}
              orgName={orgName}
              setOrgName={setOrgName}
              payType = {payType}
              setPayType = {setPayType}
          />
        </div>
      )}
    </>
  );
};

export default OAGFOverview;
