import React, { useState } from "react";

interface Props {
  text: string;
  maxLength: number;
}

const ReadMore: React.FC<Props> = ({ text, maxLength }) => {
  const [isTruncated, setIsTruncated] = useState(true);

  const toggleIsTruncated = () => {
    setIsTruncated(!isTruncated);
  };

  if (text.length <= maxLength) {
    return <>{text}</>;
  }

  return (
    <>
      {isTruncated ? `${text.slice(0, maxLength)}...` : text}
      <span onClick={toggleIsTruncated} className="text-success">
        {isTruncated ? "Read More" : "Read Less"}
      </span>
    </>
  );
};

export default ReadMore;
