import Multiselect from "multiselect-react-dropdown";
import React from "react";
import { NumericFormat } from "react-number-format";
interface CreateStep1Props {
  formik: any;
  isOAGFAdmin: boolean;
  mdaList: any[];
}

export const CreateStep1: React.FC<CreateStep1Props> = ({
  formik,
  isOAGFAdmin,
  mdaList,
}) => {
  const multiselectRef = React.createRef<any>();

  const onSelectProcessType = (event) => {
    formik.setFieldValue("mdaInvoled", event);
  };

  const MdaS = mdaList
    ?.map((mdaData: any) => {
      if (!mdaData.active || mdaData.oagf) {
        return null;
      }
      return {
        name: mdaData?.registeredName,
        id: mdaData?.id,
      };
    })
    .filter((mdaSList) => mdaSList !== null);

  return (
    <div className="create-content">
      <p className="fs-8 fw-bolder text-dark2 mb-0">Basic Details</p>

      <span className="text-gray-500 fs-9">
        Please provide the basic information for the project
      </span>

      <form
        onSubmit={formik.handleSubmit}
        noValidate
        className="form align-middle  gx-0 gy-5 mt-3"
      >
        <div className="mt-1">
          <div className="col-lg-12 col-form-label py-0 input-height">
            {formik.values.projectId.length !== 0 && (
              <label className="fw-bold fs-9 required">Project ID</label>
            )}
          </div>

          <div className={`form-control form-control-solid`}>
            <input
              type="text"
              placeholder="Project ID"
              className={"no-outline text-dark fs-8"}
              {...formik.getFieldProps("projectId")}
            />
          </div>
          <div className="fv-plugins-message-container input-height">
            <div className="fv-help-block fs-9 text-danger">
              {formik.touched.projectId &&
                formik.errors.projectId &&
                formik.errors.projectId}
            </div>
          </div>
        </div>

        <div className="mt-1">
          <div className="col-lg-12 col-form-label py-0 input-height">
            {formik.values.projectName.length !== 0 && (
              <label className="fw-bold fs-9 required">Project Name</label>
            )}
          </div>

          <div className={`form-control form-control-solid `}>
            <input
              type="text"
              placeholder="Project Name"
              className={"no-outline text-dark fs-8"}
              {...formik.getFieldProps("projectName")}
            />
          </div>
          <div className="fv-plugins-message-container input-height">
            <div className="fv-help-block fs-9 text-danger">
              {formik.touched.projectName &&
                formik.errors.projectName &&
                formik.errors.projectName}
            </div>
          </div>
        </div>

        <div className="mt-1">
          <div className="col-lg-12 col-form-label py-0 input-height">
            {formik.values.projectBudget > 0 && (
              <label className="fw-bold fs-9 required">Project Budget</label>
            )}
          </div>

          <div className={`form-control form-control-solid`}>
            <NumericFormat
              type="text"
              prefix="₦"
              thousandsGroupStyle="thousand"
              thousandSeparator=","
              className="no-outline text-dark fs-8"
              placeholder="Project Budget"
              allowNegative={false}
              allowLeadingZeros={false}
              onValueChange={(event) =>
                formik.setFieldValue("projectBudget", event.floatValue)
              }
              value={formik.values.projectBudget}
            />
          </div>

          <div className="fv-plugins-message-container input-height">
            <div className="fv-help-block fs-9 text-danger">
              {formik.errors.projectBudget && formik.errors.projectBudget}
            </div>
          </div>
        </div>

        <div className="mt-1">
          <div className="col-lg-12 col-form-label py-0 input-height">
            {formik.values.projectDescription.length !== 0 && (
              <span className="fw-bold fs-9 required">Project Description</span>
            )}
          </div>

          <textarea
            placeholder="Project Description"
            className={
              "text-dark fw-normal fs-8 form-control form-control-solid bg-admin-body"
            }
            {...formik.getFieldProps("projectDescription")}
            rows={5}
          />

          <div className="fv-plugins-message-container input-height">
            <div className="fv-help-block fs-9 text-danger">
              {formik.touched.projectDescription &&
                formik.errors.projectDescription &&
                formik.errors.projectDescription}
            </div>
          </div>
        </div>

        {isOAGFAdmin && (
          <div className="my-5">
            <div>
              <div className="d-flex align-items-center">
                <input
                  className="form-check-input w-1r h-1r rounded-0 cursor-pointer"
                  type="checkbox"
                  checked={formik.values.oagf}
                  {...formik.getFieldProps("oagf")}
                />
                <span className="fs-9 ms-2 mb-2">MDA Involved</span>
              </div>
            </div>

            {formik.values.oagf && (
              <div className="my-3">
                <Multiselect
                  displayValue="name"
                  ref={multiselectRef}
                  onKeyPressFn={function noRefCheck() {}}
                  onRemove={onSelectProcessType}
                  onSearch={function noRefCheck() {}}
                  onSelect={onSelectProcessType}
                  selectedValues={formik.values.mdaInvoled}
                  hidePlaceholder={true}
                  className="fs-10e rounded-1 "
                  style={{
                    chips: {
                      background: "#40A060",

                      color: "white",
                      fontWeight: "500",
                      borderRadius: 3,
                    },
                    searchBox: {
                      borderColor: "#72D393",
                      background: "#F9F9F9",
                      fontSize: 10,
                      height: "100%",
                      padding: "1rem",
                    },
                    option: {
                      color: "#40A060",
                      background: "#F9F9F9",
                    },
                    optionContainer: {
                      // background:'red',
                    },
                  }}
                  options={MdaS}
                />
              </div>
            )}
          </div>
        )}

        <div className="d-flex  justify-content-center mt-5">
          <button
            id="kt_password_cancel "
            type="submit"
            className={`btn ${
              !(formik.isValid && formik.dirty)
                ? "btn-secondary"
                : "btn-success"
            } text-white px-15 py-2 h-44 w-min-110 rounded-1`}
            disabled={!(formik.isValid && formik.dirty)}
          >
            <span className="indicator-label fs-8">Next</span>
          </button>
        </div>
      </form>
    </div>
  );
};
