export const FormatMoney = (value: number): string => {
  const suffixes = ["", "K", "M", "B", "T", "Q"];
  const suffixNum = Math.floor(("" + value).length / 3); 

  let formattedValue = parseFloat(
    (suffixNum !== 0 ? value / Math.pow(1000, suffixNum) : value).toFixed(2)
  );
  if (formattedValue % 1 !== 0) {
    formattedValue = parseFloat(formattedValue.toFixed(1));
  }

  return `₦${formattedValue.toLocaleString()}${suffixes[suffixNum]}`;
};

export const NoCurrencyFormatMoney = (number: number): string => {
  if (number === 0) return "0";

  const value = Math.abs(number); // Handle negative numbers
  const suffixes = ["", "K", "M", "B", "T", "Q"];
  const suffixNum = Math.floor(Math.log10(value) / 3); // Determine the suffix index using logarithms

  const scaledValue = value / Math.pow(1000, suffixNum);

  // Convert to string and strip unnecessary trailing zeros
  const formattedValue = scaledValue.toString();

  const formattedNumber = `${formattedValue}${suffixes[suffixNum]}`;
  return number < 0 ? `-${formattedNumber}` : formattedNumber; // Reattach negative sign if needed
};

export const formatMoneyLocal = (valueNumber: number) => {
  var FormatData = "0.00";

  if (valueNumber) {
    FormatData = Number(valueNumber).toLocaleString("en", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  } 

  return FormatData;
};

