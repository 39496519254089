import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './app/App';
import './core/assets/sass/style.scss';
import './index.css';
import reportWebVitals from './reportWebVitals';

declare global {
    interface Window {
        ENV: {
            apiUrl: string;
            logoutTime: number,
        };
    }
}
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const PUBLIC_URL = 'base_url';

if (window.self === window.top) {
    root.render(
        // <React.StrictMode>
        <App basename={PUBLIC_URL} />,
        // </React.StrictMode>
    );
}

// Performance metrics
reportWebVitals();
