import { FlapperSpinner } from "react-spinners-kit";
import { Layout } from "../../../layout";
import {
  useMdaActivate,
  useMdaDetails,
  useOagfCollectionAnalyticsServices,
} from "../../../../auth/hooks/useUser";
import { DatePicker, Select, Table } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Download } from "app/components/download";
import { filter } from "core/assets/icons";
import { getWeekRange } from "core/helpers";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const { RangePicker } = DatePicker;

const dateFormat = "YYYY/MM/DD";
const TopServices = () => {
  const { isLoading } = useMdaDetails();
  const navigate = useNavigate()

  const {
    data: activateData,
    mutate: activateMutate,
    isLoading: activateLoading,
  } = useMdaActivate();

  const {
    data: top10Data,
    mutate: top10Mutate,
    isLoading: isLoadingTop10,
  } = useOagfCollectionAnalyticsServices();

  const [dateRange, setDateRange] = useState<any>([]);
  const handleDateRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      setDateRange(dates);
      var date1 = moment(new Date(dates[0]).toISOString()).format("YYYY-MM-DD");
      var date2 = moment(new Date(dates[1]).toISOString()).format("YYYY-MM-DD");
      top10Mutate({ startDate: date1, endDate: date2 });
    } else {
      setDateRange([]);
    }
  };

  useEffect(() => {
    const { startDate, endDate } = getWeekRange();
    setDateRange([dayjs(startDate), dayjs(endDate)]);
    const startDateData = moment(startDate).format("YYYY-MM-DD");
    const endDateData = moment(endDate).format("YYYY-MM-DD");

    top10Mutate({ startDate: startDateData, endDate: endDateData });
  }, []);

  const dataSource: any[] = [];
  const dataSourceForExcel: any[] = [[]];

  const columns = [
    {
      title: "S/N",
      dataIndex: "sn",
      key: "sn",
      width: "30",
    },
    {
      title: "Service",
      dataIndex: "service",
      key: "service",
      width: "180",
    },
    {
      title: "MDA Name",
      dataIndex: "mdaName",
      key: "mdaName",
      width: "180",
    },
    {
      title: "Revenue",
      dataIndex: "revenue",
      key: "revenue",
      width: "80",
    },
  ];
  var totalAmount = 0;

  for (let i = 0; i < top10Data?.length; i++) {
    var tAmount = top10Data[i]?.amount;

    if (tAmount) {
      totalAmount += tAmount;
    }
    const row = {
      sn: i + 1,
      service: top10Data[i].serviceName,
      mdaName: top10Data[i].mdaName,
      revenue: top10Data[i]?.amount
      ? top10Data[i]?.amount.toLocaleString("en", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : "0.00"
    };
    dataSource.push(row);
  }

  return (
    <>
      {isLoading || activateLoading || isLoadingTop10 ? (
        <div className="loading-container">
          <FlapperSpinner /> {/* Replace with your loading indicator */}
        </div>
      ) : (
        <div className={"topMda"}>
          <div className={"topMdaBreadcrumb d-flex justify-content-between"}>
            <div className="d-flex align-items-center gap-3">
              <div className="fs-base">Analytics</div>

              <svg
                width="10"
                height="13"
                viewBox="0 0 10 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                  fill="#1F8505"
                />
              </svg>

              <div
                className="cursor-pointer"
                onClick={() => navigate("/admin/analytics/collection-view")}
              >
                Collection View
              </div>

              <svg
                width="10"
                height="13"
                viewBox="0 0 10 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                  fill="#1F8505"
                />
              </svg>

              <div className="fs-base">Top 10 Services</div>
            </div>

            <div className="mx-5">
              <span className="data-range-text-overview  me-1 ">
                Date Range:
              </span>

              <RangePicker
                className="py-1 fs-8 fw-bold datepicker w-250px"
                value={dateRange}
                onChange={handleDateRangeChange}
                allowClear
                size={"small"}
              />
            </div>
          </div>

          <div className={"topMdaRow2"}>
            <div>TOP 10 SERVICES BY REVENUE GENERATED</div>

            <div className="d-flex justify-content-end  mb-9 me-4">
              <Download
                dataSourceForExcel={dataSourceForExcel}
                dataSource={dataSource}
                excelFileTitle={"TOP 10 SERVICES BY REVENUE GENERATE"}
                pdfFileTitle={"TOP 10 SERVICES BY REVENUE GENERATE"}
                excelPageTitle={"TOP 10 SERVICES BY REVENUE GENERATE"}
                grandTotalFor={{
                  transactionAmount: "Transaction Amount",
                  processingFee: "Processing Fee",
                  vatProcessingFee: "VAT Processing Fee",
                  totalProcessingFee: "Total Processing Fee",
                  netAmount: "Net Amount",
                  cumulativeNetAmount: "Cumulative Net Amount",
                }}
              />
            </div>

            <div>
              <div>TOTAL:</div>
              <div> &#x20A6;{totalAmount?.toLocaleString()} </div>
            </div>

            <Table
              dataSource={dataSource}
              bordered
              columns={columns}
              scroll={{ x: "800px" }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default TopServices;
