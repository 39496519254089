import {
  FetchAndUnmask,
  getPaginationParams,
  getPaginationParamsDefault,
  isEmpty,
} from "core/helpers";

export const fetchTableData = (
  mutate: any,
  tableParams: any,
  setTableParams: any,
  searchFilter?: any,
  onSuccess?: () => void
) => {
  mutate(`${getPaginationParamsDefault(
      tableParams
    )}${isEmpty(searchFilter) ? "" : "&" + searchFilter}`, {
    onSuccess: (res: { totalSize: number }) => {
      setTableParams({
        pagination: {
          ...tableParams.pagination,
          total: res?.totalSize,
        },
        onSuccess,
      });
    },
  });
};

export const fetchTableDataReset = (
  mutate: any,
  tableParams: any,
  setTableParams: any,
  resetFilterdata: any
) => {
  mutate(`${getPaginationParamsDefault(
      tableParams
    )}`, {
    onSuccess: (res: { totalSize: number }) => {
      resetFilterdata();
      setTableParams({
        pagination: {
          ...tableParams.pagination,
          current: 1,
          total: res?.totalSize,
        },
      });
    },
  });
};
