import { ErrorHandler } from "core/helpers";
import { useMutation } from "react-query";
import {
  editProjectDetails,
  getProjectSummary,
  getProjects,
  getProjectsById,
  updateProjectStatus,
} from "./service";

export const useGetProjects = (): any => {
  return useMutation((filter: string) => getProjects(filter), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
};

export const useGetProjectsById = (): any => {
  return useMutation((id: string) => getProjectsById(id), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
};

export function useEditProject(): any {
  return useMutation((values) => editProjectDetails(values), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useUpdateProjectStatus(): any {
  return useMutation((values) => updateProjectStatus(values), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useProjectSummary(): any {
  return useMutation(() => getProjectSummary(), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}
