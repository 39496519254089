import CryptoJS from "crypto-js";

export const MaskAndSave = (key, data) => {
  try {
    const dataString = JSON.stringify(data);
    const encryptedData = CryptoJS.AES.encrypt(dataString, `${process.env.REACT_APP_STORAGE_KEY}`).toString();
    const encodedData = btoa(encryptedData);
    sessionStorage.setItem(key, encodedData);
  } catch (error) {}
};

export const FetchAndUnmask = (key) => {
  try {
    const encodedData = sessionStorage.getItem(key);

    if (encodedData) {
      const encryptedData = atob(encodedData);
      const decryptedData = CryptoJS.AES.decrypt(encryptedData, `${process.env.REACT_APP_STORAGE_KEY}`).toString(
        CryptoJS.enc.Utf8
      );
      const data = JSON.parse(decryptedData);
      return data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};
