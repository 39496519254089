import { convertToFirstLetterCap } from "./FormatText";

export function formatRequestEnums(word: string) {
  let status: string = "";

  switch (word) {
    case "AWAITING_APPROVAL":
      status = "Awaiting Approval";
      break;

    case "AWAITINGAPPROVAL":
      status = "Awaiting Approval";
      break;

    case "APPROVED":
      status = "Approved";
      break;

    case "REJECTED":
      status = "Rejected";
      break;

    case "FAILED":
      status = "Failed";
      break;

    case "NEW":
      status = "New";
      break;

      case "PARTLY_PAID":
        status = "Partly Paid";
        break;

    default:
      status = word ? convertToFirstLetterCap(word) : "";
      break;
  }
  return status;
}
