import { extractAndCapitalize } from "core/helpers";
import { Modal } from "react-bootstrap";

export const ReviewUserCreationModal = ({
  showUsersCreation,
  setShowUsersCreation,
  ShowApproval,
  ShowRejectModal,
  userModalData,
}) => {
  return (
    <Modal
      dialogClassName="m-auto px-5 modal-card-service"
      aria-hidden="false"
      show={showUsersCreation}
      onHide={() => setShowUsersCreation(false)}
      animation
      centered
      size="lg"
      // backdrop={"static"}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Body className="rounded">
        <div className="m-10">
          <div>
            <div className="d-flex justify-content-center py-3 mb-9">
              <span className="sev-cen">User Creation</span>
            </div>

            <div className="d-flex justify-content-between py-3">
              <span className="sev-pr">User Details</span>
            </div>

            <div className="d-flex justify-content-between py-3">
              <span className="text-muted fs-9 fw-bold text-start min-w-25">
                First Name
              </span>
              <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                {userModalData?.data?.content?.data?.firstName}
              </span>
            </div>

            <div className="d-flex justify-content-between py-3">
              <span className="text-muted fs-9 fw-bold text-start min-w-25">
                Last Name
              </span>
              <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                {userModalData?.data?.content?.data?.lastName}
              </span>
            </div>

            <div className="d-flex justify-content-between py-3">
              <span className="text-muted fs-9 fw-bold text-start min-w-25">
                Phone Number
              </span>
              <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                {userModalData?.data?.content?.data?.phoneNumber}
              </span>
            </div>

            <div className="d-flex justify-content-between py-3">
              <span className="text-muted fs-9 fw-bold text-start min-w-25">
                Email
              </span>
              <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                {userModalData?.data?.content?.data?.email}
              </span>
            </div>

            <div className="d-flex justify-content-between py-3">
              <span className="text-muted fs-9 fw-bold text-start min-w-25">
                Organization Name
              </span>
              <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                {userModalData?.data?.content?.data?.organizationName}
              </span>
            </div>
          </div>
          <hr className="text-muted h-1px" />

          <div>
            <div className="d-flex justify-content-between py-3">
              <span className="sev-pr">User Roles</span>
            </div>

            {userModalData?.data?.content?.data?.businessGroups.map((d, i) => {
              return (
                <div key={i} className="d-flex justify-content-between py-3">
                  <span className="text-muted fs-9 fw-bold text-start min-w-25">
                    {extractAndCapitalize(d.code)}
                  </span>
                  <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                    {d.description}
                  </span>
                </div>
              );
            })}
          </div>

          <hr className="text-muted h-1px" />

          <div
            className="d-flex justify-content-center py-3"
            style={{ gap: "2rem" }}
          >
            <button
              className="btn btn-success px-7 py-2 h-44 w-110 rounded-1 "
              onClick={() => {
                ShowApproval(userModalData);
              }}
            >
              <span className="indicator-label fs-base text-White">
                {/* {modalData?.data.currentLevel === 2 ? 'Approve' : 'Review'} */}
                Approve
              </span>
            </button>

            <button
              className="btnpx-7 py-2 h-44 w-110 rounded-1 "
              onClick={() => {
                ShowRejectModal(userModalData?.data.id);
              }}
              style={{ background: "red", border: 0, color: "whitesmoke" }}
            >
              <span className="indicator-label fs-base text-White">Reject</span>
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
