export function getCurrentDate(): string {
  const months: string[] = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const currentDate: Date = new Date();
  const day: number = currentDate.getDate();
  const month: string = months[currentDate.getMonth()];
  const year: number = currentDate.getFullYear();

  return `${day} ${month} ${year}`;
}

export function getBankName(bankCode: any): any {
    
  const bankNameAndCode = {
    'Access Bank': '044',
    'Access Bank (Diamond)': '063',
    // "ALAT by WEMA": "035",
    'ASO Savings and Loans': '401',
    'Bowen Microfinance Bank': '50931',
    'CEMCS Microfinance Bank': '50823',
    'Citibank Nigeria': '023',
    'Ecobank Nigeria': '050',
    'Ekondo Microfinance Bank': '562',
    Eyowo: '50126',
    'Fidelity Bank Nigeria': '070',
    'First Bank of Nigeria': '011',
    'First City Monument Bank': '214',
    'FSDH Merchant Bank Limited': '501',
    'Globus Bank': '103',
    'Guaranty Trust Bank': '058',
    'Hackman Microfinance Bank': '51251',
    'Hasal Microfinance Bank': '50383',
    'Heritage Bank Plc': '030',
    'Ibile Microfinance Bank': '51244',
    'Jaiz Bank': '301',
    'Keystone Bank Limited': '082',
    'Kuda Bank': '50211',
    'Lagos Building Investment Company Plc.': '90052',
    'One Finance': '565',
    'Parallex Bank': '526',
    'Parkway - ReadyCash': '311',
    'Polaris Bank Limited': '076',
    'Providus Bank': '101',
    'Rubies MFB': '125',
    'Sparkle Microfinance Bank': '51310',
    'Stanbic IBTC Bank Nigeria Limited': '221',
    'Standard Chartered Bank Nigeria': '068',
    'Sterling Bank': '232',
    'Suntrust Bank': '100',
    'TAJ Bank': '302',
    'TCF MFB': '51211',
    'Titan Bank': '102',
    'Union Bank of Nigeria': '032',
    'United Bank for Africa': '033',
    'Unity Bank Plc': '215',
    'VFD': '566',
    'Wema Bank': '035',
    'Zenith Bank': '057',
    'United Capital Plc.': '601',
    'ADH': '999001',
  };

  const bankKeys = Object.keys(bankNameAndCode);
  for (const key of bankKeys) {
    if (String(bankNameAndCode[key]) === String(bankCode)) {
      return key;
    }
  }

  return "N/A";
}

export function getAmountFilterOptions(
  amounts: any[],
  noOfRecords: number
): any[] {
  const amountFilterOptionArray: any[] = [];
  const minAmount: number = Math.round(Math.min(...amounts));
  const maxAmount: number = Math.round(Math.max(...amounts));
  const numberOfAmountFilterOption: number = noOfRecords;
  const amountRange: number = Math.round(
    (maxAmount - (minAmount - 1)) / numberOfAmountFilterOption
  );
  let currentMinAmount: number = minAmount;
  for (let x: number = 0; x < numberOfAmountFilterOption; x++) {
    const option: any = {
      value: `${currentMinAmount}-${currentMinAmount + amountRange}`,
      label: `₦${currentMinAmount.toLocaleString("en", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })} - ₦${(currentMinAmount + amountRange).toLocaleString("en", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    };
    currentMinAmount = currentMinAmount + amountRange;
    amountFilterOptionArray.push(option);
  }

  return amountFilterOptionArray;
}
