import * as Yup from "yup";

export const AddBankInitialValues = {
  bankName: "Central Bank Of Nigeria (CBN)",
  accountNumber: "",
  accountName:'',
  dailyLimit: null,
  openingBalance: null,
  classification: "",
  mda: "",
  currency: "",
};

export const AddBankSchema = Yup.object().shape({
  bankName: Yup.string().required("Bank Name is required"),
  accountNumber: Yup.string().required("Account Number is required"),
  classification: Yup.string().required("Account Classification is required"),
  dailyLimit: Yup.number().required("Daily Limit is required"),
  openingBalance: Yup.number().required("Opening Balance is required"),
  currency: Yup.string().required("Currency is required"),
  mda: Yup.string().required("MDA is required"),
  accountName: Yup.string().required("Account Name is required"),
});
