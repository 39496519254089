import axios, { AxiosResponse } from "axios";

// import { any } from '../../../shared/types';
import { axiosInstance } from "../../../axiosInstance";
import { useUser } from "../../../app/pages/auth/hooks/useUser";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, Link, redirect } from "react-router-dom";
import { ErrorToast, SuccessToast } from "app/components/toast";

interface UseAuth {
  signin: (user: any) => Promise<void>;
  signup: (email: string, password: string) => Promise<void>;
  signout: () => void;
}

type anyResponse = { any: any };
type ErrorResponse = { message: string };
type AuthResponseType = anyResponse | ErrorResponse | any;

export function useAuth(): UseAuth {
  const SERVER_ERROR = "There was an error contacting the server.";
  const { clearUser, updateUser } = useUser();

  async function authServerCall(
    urlEndpoint: string,
    value: any
  ): Promise<void> {
    try {
      const { data, status }: AxiosResponse<AuthResponseType> =
        await axiosInstance({
          url: urlEndpoint,
          method: "POST",
          data: value,
          headers: { "Content-Type": "application/json" },
        });

      if (status === 400) {
        const title = "message" in data ? data.message : "Unauthorized";

        ErrorToast("Unauthorized !");
        return;
      }

      if (status === 200) {
        updateUser(data);
        // toast.success("Data saved successfully!");
        SuccessToast("Data saved successfully!");

        redirect("/help-center");
      }
    } catch (errorResponse: any) {
      //console.log(errorResponse);

      const title =
        axios.isAxiosError(errorResponse) &&
        errorResponse?.response?.data?.error
          ? errorResponse?.response?.data?.error.message
          : SERVER_ERROR;
      ErrorToast(title);
    }
  }

  async function signin(user: any): Promise<void> {
    authServerCall("/api/authenticate", user);
  }
  async function signup(email: string, password: string): Promise<void> {
    authServerCall("/user", email);
  }

  function signout(): void {
    // clear any from stored any data
    clearUser();
    SuccessToast("Success Notification !");
  }

  // Return the any object and auth methods
  return {
    signin,
    signup,
    signout,
  };
}
