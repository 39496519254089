import { Modal } from 'antd';
import { InputForm } from 'app/components/formInputs/InputForm';
import { NumericForm } from 'app/components/formInputs/NumericForm';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useCreateSinglePaymentRequest, useGenerateBatchId, useGetUserBank } from '../hooks/usePayment';

const Schema = Yup.object().shape({
    fundingAccount: Yup.string().required('Funding Account is required'),
    beneficiaryFirstName: Yup.string().required('Beneficiary First Name is required'),
    beneficiaryLastName: Yup.string().required('Beneficiary Last Name is required'),
    staffCustomerId: Yup.string().required('Staff Customer ID is required'),
    amount: Yup.mixed()
        .required('Amount is required')
        .test('is-positive', 'Amount must be a positive number', (value) => value > 0),
    employeeContribution: Yup.mixed()
        .nullable()
        .test('is-positive', 'Employee Contribution must be a positive number', (value) => !value || value > 0),
    employerContribution: Yup.mixed()
        .nullable()
        .test('is-positive', 'Employer Contribution must be a positive number', (value) => !value || value > 0),
    voluntaryContribution: Yup.mixed()
        .nullable()
        .test('is-positive', 'Voluntary Contribution must be a positive number', (value) => !value || value > 0),
    pfaCode: Yup.string().nullable(),
    pensionPin: Yup.string().nullable(),
    beneficiaryEmail: Yup.string().email('Invalid email format').nullable(),
    beneficiaryPhoneNumber: Yup.string()
        .matches(/^[0-9]{10}$/, 'Phone number must be 10 digits')
        .nullable(),
});


const initialValues = {
    fundingAccount: '',
    beneficiaryFirstName: '',
    beneficiaryLastName: '',
    staffCustomerId: '',
    amount: '',
    employeeContribution: '',
    employerContribution: '',
    voluntaryContribution: '',
    pfaCode: '',
    pensionPin: '',
    beneficiaryEmail: '',
    beneficiaryPhoneNumber: '',
};

const SingleKeyIn = () => {
    const [successModal, setSuccessModal] = useState(false);

    const navigate = useNavigate();

    const { mutate, isLoading } = useCreateSinglePaymentRequest();

    const { data: batchId } = useGenerateBatchId();

    const { data: bankData } = useGetUserBank();

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: async (values) => {
            const accountData = values.fundingAccount.split('-');

            mutate(
                {
                    data: {
                        batchId: batchId,
                        accountType: {
                            code: accountData[2],
                            value: accountData[3],
                        },
                        narration: 'Pension',
                        expenseType: 'Pension',
                        paymentType: {
                            code: 'pensionPayments',
                            description: 'Pension Payments',
                        },
                        paymentInstructions: [
                            {
                                sourceAccount: accountData[0],
                                beneficiaryFirstName: values.beneficiaryFirstName,
                                beneficiaryLastName: values.beneficiaryLastName,
                                staffCustomerId: values.staffCustomerId,
                                amount: values.amount,
                                employeeContribution: values.employeeContribution,
                                employerContribution: values.employerContribution,
                                voluntaryContribution: values.voluntaryContribution,
                                pfaCode: values.pfaCode,
                                pensionPin: values.pensionPin,
                                beneficiaryEmail: values.beneficiaryEmail,
                                beneficiaryPhoneNumber: values.beneficiaryPhoneNumber,
                                sourceBankAccountCode: accountData[2],
                                sourceName: accountData[1],
                                paymentType: {
                                    code: 'pensionPayments',
                                    description: 'Pension Payments',
                                },
                                destAccountName: `${values.beneficiaryFirstName}  ${values.beneficiaryLastName}`,
                            },
                        ],
                    },
                },
                {
                    onSuccess: (res: any) => {
                        setSuccessModal(true);
                    },
                },
            );
        },
    });

    return (
        <div className={'pensionPayments'}>
            <div className={'pensionPaymentsBreadcrumb'}>
                <div>Payments</div>
                <svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                        fill="#1F8505"
                    />
                </svg>
                <div>Pension Payments</div>

                <svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                        fill="#1F8505"
                    />
                </svg>
                <div>Key-In</div>
            </div>

            <div className={'singleKeyIn'}>
                <div className={'singleKeyInRow1'}>
                    <div onClick={() => navigate('/admin/payment/pension-payments')}>
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_7037_76293)">
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M18 11.8125H9.63312L13.4763 7.96937L12.5 7L7 12.5L12.5 18L13.4694 17.0306L9.63312 13.1875H18V11.8125Z"
                                    fill="#1F8505"
                                />
                            </g>
                            <rect
                                x="0.75"
                                y="1.25"
                                width="22.5"
                                height="22.5"
                                rx="11.25"
                                stroke="#1F8505"
                                strokeWidth="1.5"
                            />
                            <defs>
                                <clipPath id="clip0_7037_76293">
                                    <rect y="0.5" width="24" height="24" rx="12" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>

                    <div>Pension payments</div>
                </div>

                <div className={'singleKeyInForm'}>
                    <div className={'selectFundingDiv'}>
                        <div>Select funding account</div>

                        <div>
                            <div className={`mt-1`}>
                                <div className={`form-control form-control-solid`}>
                                    <select
                                        className={`no-outline fs-8 ${
                                            formik.values.fundingAccount !== '' ? 'text-dark' : 'text-muted'
                                        } `}
                                        {...formik.getFieldProps('fundingAccount')}
                                    >
                                        <option value="">Select account to make payment from</option>

                                        {bankData?.map((array: any, i: any) => {
                                            return (
                                                <option
                                                    value={`${array.accountNumber}-${array.accountName}-${array.accountType.code}-${array.accountType.description}`}
                                                    key={`${array.accountNumber}- ${i}`}
                                                >
                                                    {array?.accountName}- {array?.accountNumber} - ₦
                                                    {array?.balance.toLocaleString('en-US', {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    })}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>

                            <div></div>
                        </div>
                    </div>

                    <div className={'beneficiaryDetails'}>
                        <div>Beneficiary Details</div>

                        <div className={'detailsRow'}>
                            <InputForm
                                formik={formik}
                                {...formik.getFieldProps('beneficiaryFirstName')}
                                Label="First name"
                                formikName="beneficiaryFirstName"
                                className="no-outline text-dark fs-8"
                            />

                            <InputForm
                                formik={formik}
                                {...formik.getFieldProps('beneficiaryLastName')}
                                Label="Last name"
                                formikName="beneficiaryLastName"
                                className="no-outline text-dark fs-8"
                            />
                        </div>

                        <div className={'detailsRow'}>
                            <InputForm
                                formik={formik}
                                {...formik.getFieldProps('staffCustomerId')}
                                Label="Staff/Customer ID"
                                formikName="staffCustomerId"
                                className="no-outline text-dark fs-8"
                                labelContainerClassName="col-lg-12 "
                            />

                            <NumericForm
                                decimalScale={2}
                                allowLeadingZeros
                                onValueChange={(event) => formik.setFieldValue('amount', event.floatValue)}
                                className="no-outline text-dark fs-8"
                                formik={formik}
                                Label="Amount payable"
                                formikName="amount"
                                labelContainerClassName="col-lg-12 "
                                {...formik.getFieldProps('amount')}
                            />
                        </div>

                        <div className={'detailsRow'}>
                            <NumericForm
                                decimalScale={2}
                                allowLeadingZeros
                                onValueChange={(event) =>
                                    formik.setFieldValue('employeeContribution', event.floatValue)
                                }
                                className="no-outline text-dark fs-8"
                                formik={formik}
                                Label="Employee contribution"
                                formikName="employeeContribution"
                                labelContainerClassName="col-lg-12 "
                                {...formik.getFieldProps('employeeContribution')}
                            />

                            <NumericForm
                                decimalScale={2}
                                allowLeadingZeros
                                onValueChange={(event) =>
                                    formik.setFieldValue('employerContribution', event.floatValue)
                                }
                                className="no-outline text-dark fs-8"
                                formik={formik}
                                Label="Employer contribution"
                                formikName="employerContribution"
                                labelContainerClassName="col-lg-12 "
                                {...formik.getFieldProps('employerContribution')}
                            />
                        </div>

                        <div className={'detailsRow'}>
                            <NumericForm
                                decimalScale={2}
                                allowLeadingZeros
                                onValueChange={(event) =>
                                    formik.setFieldValue('voluntaryContribution', event.floatValue)
                                }
                                className="no-outline text-dark fs-8"
                                formik={formik}
                                Label="Voluntary contribution"
                                formikName="voluntaryContribution"
                                labelContainerClassName="col-lg-12 "
                                {...formik.getFieldProps('voluntaryContribution')}
                            />
                        </div>
                    </div>

                    <div className={'beneficiaryDetails'}>
                        <div>Additional information</div>

                        <div className={'detailsRow'}>
                            <InputForm
                                formik={formik}
                                {...formik.getFieldProps('pfaCode')}
                                Label="PFA code"
                                formikName="pfaCode"
                                className="no-outline text-dark fs-8"
                                labelContainerClassName="col-lg-12 "
                            />

                            <InputForm
                                formik={formik}
                                {...formik.getFieldProps('pensionPin')}
                                Label="Pension PIN"
                                formikName="pensionPin"
                                className="no-outline text-dark fs-8"
                                labelContainerClassName="col-lg-12 "
                            />
                        </div>

                        <div className={'detailsRow'}>
                            <InputForm
                                formik={formik}
                                {...formik.getFieldProps('beneficiaryEmail')}
                                Label="Email"
                                formikName="beneficiaryEmail"
                                className="no-outline text-dark fs-8"
                                labelContainerClassName="col-lg-12"
                            />

                            <InputForm
                                formik={formik}
                                {...formik.getFieldProps('beneficiaryPhoneNumber')}
                                Label="Phone number"
                                formikName="beneficiaryPhoneNumber"
                                className="no-outline text-dark fs-8"
                                labelContainerClassName="col-lg-12"
                                type="number"
                            />
                        </div>
                    </div>
                </div>

                <div className={'userDetailsNextButton'}>
                    <button
                        id="kt_password_cancel "
                        onClick={() => formik.handleSubmit()}
                        className={`btn nav-btn-Proceed ${
                            !(formik.isValid && formik.dirty) ? 'btn-secondary' : 'btn-success'
                        } text-white px-7 py-2`}
                        disabled={!(formik.isValid && formik.dirty && !isLoading)}
                    >
                        {!isLoading && <span className="indicator-label">Proceed</span>}
                        {isLoading && (
                            <span className="indicator-progress" style={{ display: 'block' }}>
                                Please wait...{' '}
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        )}
                    </button>
                </div>
            </div>

            <Modal
                centered
                style={{ width: '500px', maxWidth: '100%' }}
                open={successModal}
                onOk={() => {
                    navigate('/admin/payment/pension-payments');
                    setSuccessModal(false);
                }}
                onCancel={() => {
                    navigate('/admin/payment/pension-payments');
                    setSuccessModal(false);
                }}
                footer={null}
            >
                <div className={'ppSuccessModal'}>
                    <svg
                        style={{ justifySelf: 'center' }}
                        width="50"
                        height="50"
                        viewBox="0 0 50 50"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M33.5937 20.3125L22.1354 31.25L16.4062 25.7812M43.75 25C43.75 35.3553 35.3553 43.75 25 43.75C14.6447 43.75 6.25 35.3553 6.25 25C6.25 14.6447 14.6447 6.25 25 6.25C35.3553 6.25 43.75 14.6447 43.75 25Z"
                            stroke="#FBCB38"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>

                    <div className={'ppSuccessModalTitle'}>Successful!</div>

                    <div className={'ppSuccessModalDesc'}>
                        Your transaction with GPR {batchId} has been sent to the reviewer group for action.
                    </div>

                    <div className={'ppSuccessModalActionButton'}>
                        <button
                            onClick={() => {
                                navigate('/admin/payment/pension-payments');
                                setSuccessModal(false);
                            }}
                        >
                            Done
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default SingleKeyIn;
