export function convertToFirstLetterCap(word) {
  const formatWord = word
    ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    : "";
  return formatWord;
}

export const extractAndCapitalize = (str) => {
  // Split the input string by '#' and take the first part
  const parts = str.split("#");
  if (parts.length > 0) {
    const extractedText = parts[0].trim();
    // Replace underscores with spaces, capitalize the first letter
    let ff =
      extractedText.replace(/_/g, " ").charAt(0).toUpperCase() +
      extractedText.slice(1);

    return ff.replace(/_/g, " ");
  } else {
    return "";
  }
};

export const convertToTitlesCase = (input: string): string => {
  // Step 1: Convert snake_case to space-separated words
  let words = input?.split("_");

  // Step 2: Handle camelCase and further split if necessary
  const camelCaseWords: string[] = [];
  words?.forEach((word) => {
    let tempWord = "";
    for (let i = 0; i < word.length; i++) {
      if (
        i > 0 &&
        word[i] === word[i].toUpperCase() &&
        word[i - 1] !== word[i - 1].toUpperCase()
      ) {
        // Add a space before the capital letter if the previous letter was not a capital letter
        camelCaseWords.push(tempWord);
        tempWord = word[i];
      } else {
        tempWord += word[i];
      }
    }
    if (tempWord) {
      camelCaseWords.push(tempWord);
    }
  });

  // Step 3: Capitalize the first letter of each word
  const capitalizedWords = camelCaseWords.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );

  // Step 4: Join the words with a space
  return capitalizedWords.join(" ");
};

export const convertToTitleCase = (word: string): string => {
  let status: string = "";

  switch (word) {
    case "paye_payment":
      status = "PAYE Payment";
      break;

    case "wht_payment":
      status = "WHT Payment";
      break;

    case "contract_payment":
      status = "Contract Payment";
      break;

    case "vat_payment":
      status = "VAT Payment";
      break;

    case "pensionPayments":
      status = "Pension Payments";
      break;

    case "RetailVendor":
      status = "Retail Vendor";
      break;

    default:
      status = convertToTitlesCase(word);
      break;
  }

  return status;
};
