import { isOagfAdmin } from "core/helpers";
import { OAGFProjectHome } from "./OAGF/ProjectHome";
import { MDAProjectHome } from "./MDA/ProjectHome";
import './project.scss'



const Project = () => {
  return <>{isOagfAdmin() ? <OAGFProjectHome /> : <MDAProjectHome />}</>;
};

export { Project };
