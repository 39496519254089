import React, {useState} from 'react';
import '../../../../../App.css';
import {Layout} from '../../layout';
import {Modal, Tabs} from "antd";
import PayByFileUpload from "../RetailVendorPayment/PayByFileUpload";
import PayByAccumulationOfEntries from "../RetailVendorPayment/PayByAccumulationOfEntries";




const PayAsYouEarnTax = () => {
    const [payeFile, setPayeFile] = useState<string>();
    const [successModal, setSuccessModal] = useState(false);



    function extractData($event: any) {
        const file = $event.target.files[0]
        setPayeFile(file.name)
    }

    return (
        <>
            <div className={'vat'}>
                <div className={'vatBreadcrumb'}>
                    <div>Payments</div>
                    <svg
                        width="10"
                        height="13"
                        viewBox="0 0 10 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                            fill="#1F8505"
                        />
                    </svg>
                    <div>Tax Payments</div>

                    <svg
                        width="10"
                        height="13"
                        viewBox="0 0 10 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                            fill="#1F8505"
                        />
                    </svg>

                    <div>Bulk Tax Upload</div>

                    <svg
                        width="10"
                        height="13"
                        viewBox="0 0 10 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                            fill="#1F8505"
                        />
                    </svg>

                    <div>PAYE</div>
                </div>

                <div>
                    <div>PAYE Upload from Excel</div>

                    <div>
                        <div>File</div>

                        <label>
                            <div>Browse...</div>
                            <div>{payeFile ? payeFile : 'No file selected'}</div>
                            <input hidden id="input" type="file" accept="text/csv" onChange={extractData}/>
                        </label>

                        <div>Accepted file types are .csv, .xls, .xlxs</div>
                    </div>

                    <div>
                        <button onClick={() => setSuccessModal(true)}>Upload</button>
                    </div>
                </div>


                <Modal
                    centered
                    style={{width: '500px', maxWidth: '100%' }}
                    open={successModal}
                    onOk={() => {
                        setSuccessModal(false);

                    }}
                    onCancel={() => setSuccessModal(false)}
                    footer={null}
                >
                    <div className={'rvpConfirmationModal'}>
                        <svg style={{justifySelf: 'center'}} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.1562 9.1875L10.2812 15.75L6.84375 12.4688M23.25 12C23.25 18.2132 18.2132 23.25 12 23.25C5.7868 23.25 0.75 18.2132 0.75 12C0.75 5.7868 5.7868 0.75 12 0.75C18.2132 0.75 23.25 5.7868 23.25 12Z" stroke="#61CD85" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>

                        {/*<div className={'rvpConfirmationModalTitle'}>Successful!</div>*/}

                        <div style={{textAlign: 'center'}}>
                            Your transaction has been sent to the reviewer group for action
                        </div>

                        <div className={'rvpConfirmationModalActionButton'}>
                            <button className="btn btn-success px-7 py-2 h-44 w-150 rounded-1 " onClick={() => {
                                setSuccessModal(false);
                            }}>
                                <span className="indicator-label fs-base text-White">Done</span>
                            </button>
                        </div>
                    </div>
                </Modal>
            </div>
        </>
    );
};

export default PayAsYouEarnTax;
