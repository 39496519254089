import { getBankName } from "app/services/utilityService";
import { Modal } from "react-bootstrap";

export const ReviewModal = ({
  show,
  setShow,
  ShowApproval,
  ShowRejectModal,
  modalData,
  isLoadingReview
}) => {
  return (
    <Modal
      dialogClassName="m-auto px-5 modal-card"
      aria-hidden="false"
      show={show}
      onHide={() => setShow(false)}
      animation
      centered
      size="lg"
      // backdrop={"static"}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Body className="rounded">
        <div className="m-10">
          <div>
            <div className="d-flex justify-content-between py-3 mb-9">
              <span className="text-muted fs-9 fw-bold text-start min-w-25">
                PAYMENT DETAILS
              </span>
            </div>

            <div className="d-flex justify-content-between py-3">
              <span className="text-muted fs-9 fw-bold text-start min-w-25">
                GPR
              </span>
              <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                {modalData?.data.content?.data.batchId}
              </span>
            </div>

            <div className="d-flex justify-content-between py-3">
              <span className="text-muted fs-9 fw-bold text-start min-w-25">
                Reference ID
              </span>
              <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                {modalData?.data.id}
              </span>
            </div>

            <div className="d-flex justify-content-between py-3">
              <span className="text-muted fs-9 fw-bold text-start min-w-25">
                Payment Type
              </span>
              <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                {
                  modalData?.data?.content?.data?.paymentInstructions["0"]
                    ?.paymentType?.description
                }
              </span>
            </div>

            <div className="d-flex justify-content-between py-3">
              <span className="text-muted fs-9 fw-bold text-start min-w-25">
                Total Payable
              </span>
              <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                {
                  modalData?.data?.content?.data?.paymentInstructions["0"]
                    ?.amount
                }
              </span>
            </div>

            <div className="d-flex justify-content-between py-3">
              <span className="text-muted fs-9 fw-bold text-start min-w-25">
                Funding Account
              </span>
              <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                {
                  modalData?.data?.content?.data?.paymentInstructions["0"]
                    ?.sourceAccount
                }
              </span>
            </div>

            <div className="d-flex justify-content-between py-3">
              <span className="text-muted fs-9 fw-bold text-start min-w-25">
                Beneficiary Name
              </span>
              <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                {
                  modalData?.data?.content?.data?.paymentInstructions["0"]
                    ?.destAccountName
                }
              </span>
            </div>

            {modalData?.data?.content?.data?.paymentInstructions["0"]
              ?.destinationAccount && (
              <div className="d-flex justify-content-between py-3">
                <span className="text-muted fs-9 fw-bold text-start min-w-25">
                  Beneficiary Account
                </span>
                <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                  {
                    modalData?.data?.content?.data?.paymentInstructions["0"]
                      ?.destinationAccount
                  }
                </span>
              </div>
            )}

            {modalData?.data?.content?.data?.paymentInstructions["0"]
              ?.destinationBankAccountCode && (
              <div className="d-flex justify-content-between py-3">
                <span className="text-muted fs-9 fw-bold text-start min-w-25">
                  Beneficiary Bank
                </span>
                <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                  {getBankName(
                    modalData?.data?.content?.data?.paymentInstructions["0"]
                      ?.destinationBankAccountCode
                  ) ||
                    modalData?.data?.content?.data?.paymentInstructions["0"]
                      ?.destinationName}
                </span>
              </div>
            )}

            {modalData?.data?.content?.data?.paymentInstructions["0"]
              ?.staffCustomerId && (
              <div className="d-flex justify-content-between py-3">
                <span className="text-muted fs-9 fw-bold text-start min-w-25">
                  Staff Customer Id
                </span>
                <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                  {
                    modalData?.data?.content?.data?.paymentInstructions["0"]
                      ?.staffCustomerId
                  }
                </span>
              </div>
            )}

            {modalData?.data?.content?.data?.paymentInstructions["0"]
              ?.pfaCode && (
              <div className="d-flex justify-content-between py-3">
                <span className="text-muted fs-9 fw-bold text-start min-w-25">
                  PFA Code
                </span>
                <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                  {
                    modalData?.data?.content?.data?.paymentInstructions["0"]
                      ?.pfaCode
                  }
                </span>
              </div>
            )}

            {modalData?.data?.content?.data?.paymentInstructions["0"]
              ?.pensionPin && (
              <div className="d-flex justify-content-between py-3">
                <span className="text-muted fs-9 fw-bold text-start min-w-25">
                  Pension Pin
                </span>
                <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                  {
                    modalData?.data?.content?.data?.paymentInstructions["0"]
                      ?.pensionPin
                  }
                </span>
              </div>
            )}

            {modalData?.data?.content?.data?.paymentInstructions["0"]
              ?.contractAmount && (
              <div className="d-flex justify-content-between py-3">
                <span className="text-muted fs-9 fw-bold text-start min-w-25">
                  Contract Amount
                </span>
                <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                  {
                    modalData?.data?.content?.data?.paymentInstructions["0"]
                      ?.contractAmount
                  }
                </span>
              </div>
            )}

            {modalData?.data?.content?.data?.paymentInstructions["0"]?.vat && (
              <div className="d-flex justify-content-between py-3">
                <span className="text-muted fs-9 fw-bold text-start min-w-25">
                  VAT(%)
                </span>
                <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                  {(modalData?.data?.content?.data?.paymentInstructions["0"]
                    ?.vat /
                    100) *
                    modalData?.data?.content?.data?.paymentInstructions["0"]
                      ?.amount}
                </span>
              </div>
            )}

            {modalData?.data?.content?.data?.paymentInstructions["0"]?.from && (
              <div className="d-flex justify-content-between py-3">
                <span className="text-muted fs-9 fw-bold text-start min-w-25">
                  FROM(Date)
                </span>
                <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                  {
                    modalData?.data?.content?.data?.paymentInstructions["0"]
                      ?.from
                  }
                </span>
              </div>
            )}

            {modalData?.data?.content?.data?.paymentInstructions["0"]?.to && (
              <div className="d-flex justify-content-between py-3">
                <span className="text-muted fs-9 fw-bold text-start min-w-25">
                  TO(Date)
                </span>
                <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                  {modalData?.data?.content?.data?.paymentInstructions["0"]?.to}
                </span>
              </div>
            )}
          </div>
          <hr className="text-muted h-1px" />

          <div className="d-flex justify-content-between py-3">
            <button
              className={`btn btn-success px-7 py-2 h-44 w-110 rounded-1 ${
                isLoadingReview ? "btn bg-gray-500 cursor-disabled" : ""
              } `}
              onClick={() => {
                ShowApproval(modalData);
              }}
              disabled={isLoadingReview}
            >
              <span className="indicator-label fs-base text-White">
                {/* {modalData?.data.currentLevel === 2 ? 'Approve' : 'Review'} */}
                Approve
              </span>
            </button>

            <button
              className={`btnpx-7 py-2 h-44 w-110 rounded-1 ${
                isLoadingReview ? "cursor-disabled" : ""
              } `}
              onClick={() => {
                ShowRejectModal(modalData?.data?.id);
              }}
              disabled={isLoadingReview}
              style={{ background: "red", border: 0, color: "whitesmoke" }}
            >
              <span className="indicator-label fs-base text-White">Reject</span>
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
