import { FlapperSpinner } from "react-spinners-kit";

export const Loader = () => {
  return (
    <div className="loader-container" data-testid='loader'>
      <FlapperSpinner />
    </div>
  );
};

export const LoaderContainer = () => {
  return (
    <div className="loading-container" data-testid='loading-container'>
      <FlapperSpinner />
    </div>
  );
};
