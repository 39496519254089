import React from "react";
import { Link } from "react-router-dom";
import {
  MdaDirectory,
  arrowUpCircle,
  eInvoice,
  receiptGeneration,
  revenuePayment,
} from "core/assets/imgs";

const Payment: React.FC = () => {
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  console.log('ddd',window.ENV)

  return (
    <div>
      <div>
        <div className="section-center hero-center payment-links-body bg-white rounded-1">
          <div className="d-flex align-items-center  justify-content-center py-3 ">
            <div className="welcome-body">
              <span className="fs-5 fw-bold text-dark">
                <span className="text-success">Pay</span> to Federal Government
                of Nigeria
              </span>
            </div>
          </div>

          <div className="hero-info">
            <div className="quick-links">
              <Link to={"/revenue"}>
                <div className="img-div d-flex flex-column align-items-center  justify-content-center">
                  <img src={revenuePayment} alt="revenue-payment" />
                  <span>
                    Revenue Payments
                    <img
                      src={arrowUpCircle}
                      alt="arrow"
                      className="arrow-style ms-2"
                    />
                  </span>
                </div>
              </Link>
              <Link to={"/invoice"}>
                <div className="img-div d-flex flex-column align-items-center  justify-content-center">
                  <img src={eInvoice} alt="e-invoice" />
                  <span>
                    Electronic Invoice Payments
                    <img
                      src={arrowUpCircle}
                      alt="arrow"
                      className="arrow-style ms-2"
                    />
                  </span>
                </div>
              </Link>
              <Link to={"/mda"}>
                <div className="img-div d-flex flex-column align-items-center  justify-content-center">
                  <img src={MdaDirectory} alt="revenuePayment" />
                  <span>
                    Directory
                    <img
                      src={arrowUpCircle}
                      alt="arrow"
                      className="arrow-style ms-2"
                    />
                  </span>
                </div>
              </Link>

              <Link to={"/receipts"}>
                <div className="img-div d-flex flex-column align-items-center  justify-content-center">
                  <img src={receiptGeneration} alt="receiptGeneration" />
                  <span>
                    e-Invoice/Receipt Generation
                    <img
                      src={arrowUpCircle}
                      alt="arrow"
                      className="arrow-style ms-2"
                    />
                  </span>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Payment };
