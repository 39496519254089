import { ErrorToast } from 'app/components/toast';
import { isEmpty, LoaderContainer } from 'core/helpers';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PaymentSummary from '../components/PaymentSummary';
import SuccessModal from '../components/SuccessModal';
import {
    useConfirmPayment,
    useCreateInvoice,
    useGetMdaWithService,
    useGetServiceList,
    usePaymentList,
} from '../hooks/useRevenuePayment';
import { CreateInvoice } from '../interface/revenueInterface';
import { initialValues, Schema, Schema2 } from './components/Schema';
import Step1 from './components/Step1';
import Step2 from './components/Step2';
import Steps from './components/Steps';

const RevenuePayment: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [step, setStep] = useState<number>(1);
    const [show, setShow] = useState<boolean>(false);
    const [paramsData, setParamsData] = useState<boolean>(false);
    const [customFieldsData, setCustomFieldsData] = useState<any>([]);
    const [customFields, setCustomFields] = useState<any>({});
    const [disableNext, setDisableNext] = useState<boolean>(false);
    const [disableAmount, setDisabledAmount] = useState<any>(false);
    const [servicePaymComps, setServicePaymComps] = useState<any>([]);
    const [priceOptions, setPriceOptions] = useState<any>([]);
    const [priceFixed, setPriceOptionsFixed] = useState<any>(false);
    const [selectedOptions, setSelectedOptions] = useState<any>([]);
    const [totalAmount, setTotalAmount] = useState<any>(0);
    const [currentService, setCurrentService] = useState<any>([]);
    const [allowPart, setAllowPart] = useState<boolean>(false);
    const [partLock, setPartLock] = useState<boolean>(false);
    const [partAmount, setPartAmount] = useState<number | undefined>(0);
    const [importantData, setImportantData] = useState<any>({});
    const { data: mdaList, mutate: mdaMutate } = useGetMdaWithService();
    const { data: paymentList, mutate: mutatePayList, isLoading: isLoadingPayList } = usePaymentList();
    useEffect(() => {
        mutatePayList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [step]);
    const [serviceList, setServiceList] = useState<any>([]);
    // const {  isLoading: isUpdating } = useSubmitPaymentRev();
    const [invoiceData, setInvoiceData] = useState<any>([]);
    const createInvoiceMutation = useCreateInvoice();
    const getServiceListMutation = useGetServiceList();
    const { mutate: paymentMutate, isLoading: isLoadingConfirmPayment } = useConfirmPayment();

    // useEffect(() => {
    //     if (data) {
    //         setStep(3);
    //     }
    // }, [mutate, data]);

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const [metadata, setMetaData] = useState<any>([]);

    const construct_custom = () => {
        Object.values(customFieldsData).forEach((val: any) => {
            let isArray = Array.isArray(customFields[val?.code]);
            let isObject = typeof customFields[val?.code] === 'object';
            let arrayForValue = isArray && customFields[val?.code]?.map((item: any) => item.label).join(', ');

            setMetaData((oldValue: any) => [
                ...oldValue,
                {
                    field: val?.label,
                    value: isArray ? arrayForValue : isObject ? customFields[val?.code].label : customFields[val?.code],
                    selectOptions: isArray ? customFields[val?.code] : [],
                },
            ]);
        });
    };

    const [payCompData, setPayCompData] = useState<any>([]);

    const construct_PaymentComponents = () => {
        const hasAllValue = selectedOptions.some((obj: { id: string }) => obj.id === 'all');
        if (hasAllValue) {
            setPayCompData(servicePaymComps);
        } else {
            if (!disableAmount && selectedOptions.length !== 0) {
                const newArray = selectedOptions.map((obj: { [x: string]: any; value: any }) => {
                    // Create a new object without the 'value' property
                    const { value, ...rest } = obj;
                    return rest;
                });
                setPayCompData(newArray);
                return;
            } else if (!disableAmount && selectedOptions.length === 0) {
                setPayCompData([
                    {
                        ...servicePaymComps[0],
                        amount: formik.values.amount,
                    },
                ]);
                return;
            } else if (selectedOptions.length === 1) {
                setPayCompData(selectedOptions);
                return;
            } else {
                setPayCompData(servicePaymComps);
            }
        }
    };

    const formik = useFormik({
        initialValues,
        validationSchema: step === 1 ? Schema : Schema2,
        onSubmit: async (values) => {
            if (step === 1) {
                construct_custom();
                construct_PaymentComponents();
                setStep(2);
            } else {
                //var currentDate = new Date();
                //var futureDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 2, currentDate.getDate());
                // var expiry_date = futureDate.toISOString().split('.')[0];
                const dataToSend: CreateInvoice = {
                    organization: {
                        id: formik.values.mda,
                        name: importantData.mdaName,
                    },
                    service: {
                        id: formik.values.service,
                        name: importantData.serviceName,
                        glAccountName: importantData.glAccountName,
                    },
                    payer: {
                        email: formik.values.email,
                        phone: formik.values.phone,
                        fullName: formik.values.fullName,
                        customValues: metadata,
                        paymentComponentList: payCompData,
                    },
                    amountInfo: {
                        amount: formik.values.amount,
                        description: formik.values.description,
                    },
                    paymentComponentList: payCompData,
                };

                createInvoiceMutation.mutate(dataToSend, {
                    onError: (err: any) => {
                        if (err.response) {
                            if (!isEmpty(err.response.statusText)) {
                                ErrorToast(err.response.statusText);
                            } else {
                                ErrorToast(err.response.data.message);
                            }
                        } else {
                            ErrorToast(err.message);
                        }
                    },
                    onSuccess: (response) => {
                        setImportantData({
                            ...importantData,
                            invoice: response?.invoice?.invoiceNumber,
                        });
                        setInvoiceData(response);
                        setStep(3);
                    },
                });
            }
        },
    });

    useEffect(() => {
        formik.validateForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step]);

    useEffect(() => {
        if (!location?.state) {
            formik.setFieldValue('service', '');
            formik.setFieldValue('amount', '');
            formik.setFieldValue('description', '');
            setDisabledAmount(false);
            setServicePaymComps([]);
            setPriceOptions([]);
            setPriceOptionsFixed(false);
            setTotalAmount('');
            setSelectedOptions([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.mda]);

    useEffect(() => {
        const { state } = location || {};

        // Early return if state is not valid
        if (!state || !state.mdaName?.length || !state.serviceType?.length) return;

        setParamsData(true);
        formik.setFieldValue('mda', state.mdaId);
        formik.setFieldValue('service', state.serviceId);

        setImportantData({
            mdaName: state.mdaName,
            serviceName: state.serviceName,
            glAccountName: state.glAccountName,
        });

        setCurrentService(state);
        setServicePaymComps(state.paymentComponents);

        if (state.amount > 0) {
            formik.setFieldValue('amount', state.amount);
            setAllowPart(state.installPayment);
            setPriceOptionsFixed(true);
        }

        if (state.customFields?.length > 0) {
            setCustomFieldsData(state.customFields);
        }

        if (state.paymentComponents?.length > 1) {
            const totalPriceAmount = state.paymentComponents.reduce(
                (total: any, item: { amount: any }) => total + item.amount,
                0,
            );
            const priceList = state.paymentComponents.map((item: { name: any }) => ({
                ...item,
                value: item.name,
            }));

            // Add 'All Items' option if multi-select is enabled
            if (state.multiSelect) {
                priceList.unshift({
                    id: 'all',
                    amount: totalPriceAmount,
                    name: 'All Items',
                    value: 'All',
                });
            }

            setPriceOptions(priceList);
            setPriceOptionsFixed(state.fixedAmount);
        } else {
            setDisabledAmount(!!state.amount);
        }
    }, []);

    const done = () => {
        navigate('/');
    };

    const GetServicesList = async (selected: any) => {
        setAllowPart(false);
        setCustomFields({});
        setCustomFieldsData([]);
        if (selected !== null) {
            const selectedRecord = mdaList?.data?.find((option: { id: any }) => option.id === selected);
            formik.setFieldValue('mda', selected);
            setImportantData({
                ...importantData,
                mdaName: selectedRecord?.name,
            });

            getServiceListMutation.mutate(selected, {
                onError: (error: any) => {
                    ErrorToast(error);
                    setServiceList([]);
                },
                onSuccess: (response) => {
                    setServiceList(response);
                },
            });
        } else {
            formik.setFieldValue('mda', '');
        }
    };

    const setAmount = (event: any) => {
        formik.setFieldValue('amount', '');
        formik.setFieldValue('description', '');
        setServicePaymComps([]);
        setPriceOptionsFixed(false);
        setSelectedOptions([]);
        setCustomFields({});
        setCustomFieldsData([]);
        setAllowPart(false);
        setPartAmount(0);
        if (event.target.value === '') {
            formik.setFieldValue('amount', '');
            formik.setFieldValue('description', '');
            setDisabledAmount(false);
        }
        const selectedRecord = serviceList.find((option: { id: any }) => option.id === event.target.value);

        setAllowPart(selectedRecord?.installPayment);
        setCurrentService(selectedRecord);
        setServicePaymComps(selectedRecord?.paymentComponents);
        formik.setFieldValue('service', event.target.value);
        setImportantData({
            ...importantData,
            serviceName: selectedRecord?.description,
            glAccountName: selectedRecord?.glAccountName,
        });
        setPriceOptionsFixed(selectedRecord?.fixedAmount);

        if (selectedRecord?.paymentComponents?.length <= 1) {
            if (selectedRecord?.amount) {
                formik.setFieldValue('amount', selectedRecord?.amount);
                setDisabledAmount(true);
            } else {
                setDisabledAmount(false);
            }
        } else {
            let priceList: any = [];
            let totalPriceAmount = 0;
            selectedRecord?.paymentComponents.forEach((item: any) => {
                totalPriceAmount += item.amount;
            });

            selectedRecord?.paymentComponents.forEach((item: any) => {
                var list = {
                    ...item,
                    value: item?.name,
                };
                priceList.push(list);
            });

            if (selectedRecord?.multiSelect) {
                var all = {
                    id: 'all',
                    amount: totalPriceAmount,
                    name: 'All Items',
                    value: 'All',
                };
                priceList.unshift(all);
            }

            setPriceOptions(priceList);
        }

        if (selectedRecord?.customFields?.length > 0) {
            setCustomFieldsData(selectedRecord?.customFields);
        }
    };

    const goBackStep = () => {
        setMetaData([]);
        setStep(1);
    };

    const handleChange = (selectedOption: any) => {
        let total: any = null;
        let allArray: any = [];
        let justAll: any = selectedOption === null ? [] : [selectedOption];

        if (!currentService?.multiSelect) {
            justAll?.forEach((item: any) => {
                if (item?.value === 'All') {
                    while (allArray.length > 0) {
                        allArray.pop();
                    }
                    setIsMenuOpen(false);
                    setTotalAmount(Number(item?.amount));
                    allArray.push(item);
                } else {
                    let index = allArray.findIndex((obj: any) => obj.value.toLowerCase() === 'all');

                    if (index !== -1) {
                        allArray.splice(index, 1);
                    }

                    total += item.amount;
                    setTotalAmount(Number(total));

                    allArray.push(item);
                }
            });
        } else {
            selectedOption?.forEach((item: any) => {
                if (item.value === 'All') {
                    if (Number(item.amount) === 0) {
                        allArray = servicePaymComps;
                    } else {
                        while (allArray.length > 0) {
                            allArray.pop();
                        }
                        allArray.push(item);
                    }
                    setIsMenuOpen(false);
                    setTotalAmount(Number(item.amount));
                } else {
                    let index = allArray.findIndex((obj: any) => obj.value.toLowerCase() === 'all');

                    if (index !== -1) {
                        allArray.splice(index, 1);
                    }

                    total += item.amount;
                    setTotalAmount(Number(total));

                    allArray.push(item);
                }
            });
        }

        setSelectedOptions(allArray);

        if (!currentService?.multiSelect) {
            if (justAll?.length === 0) {
                setTotalAmount('');
            }
        } else {
            if (selectedOption?.length === 0) {
                setTotalAmount('');
            }
        }
    };

    const updateAmount = (name: string, newAmount: any) => {
        setSelectedOptions((prevArray: any) =>
            prevArray.map((obj: any) => {
                if (obj.name === name) {
                    return { ...obj, amount: Number(newAmount.value) };
                }
                return obj;
            }),
        );
    };

    useEffect(() => {
        let total: any = 0;
        selectedOptions.forEach((item: any) => {
            total += item.amount;
        });
        setTotalAmount(Number(total));
    }, [selectedOptions]);

    useEffect(() => {
        if (currentService?.paymentComponents?.length > 1) {
            formik.setFieldValue('amount', totalAmount);
        } else {
            if (!location?.state?.amount) {
                formik.setFieldValue('amount', '');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalAmount]);

    const allAmountsGreaterThanZero = selectedOptions.every((obj: any) => obj.amount > 0);

    useEffect(() => {
        if (allowPart && priceFixed) {
            setPartLock(true);
        } else {
            setPartLock(false);
        }
    }, [allowPart, priceFixed]);

    useEffect(() => {
        const mandatoryFields = customFieldsData?.filter((field: { optional: string }) => !field.optional);
        const mandatoryCodes = mandatoryFields.map((field: { code: string }) => field.code);

        const areMandatoryFieldsFilled = mandatoryCodes.every(
            (code: string) => customFields.hasOwnProperty(code) && customFields[code] !== '',
        );

        const shouldDisableNext =
            (currentService?.paymentComponents?.length > 1 && selectedOptions.length === 0) ||
            !allAmountsGreaterThanZero ||
            (allowPart && priceFixed && (partAmount === undefined || Number(partAmount) <= 0)) ||
            !areMandatoryFieldsFilled;

        setDisableNext(shouldDisableNext);
    }, [
        customFields,
        customFieldsData,
        selectedOptions,
        partAmount,
        currentService?.paymentComponents?.length,
        allAmountsGreaterThanZero,
        allowPart,
        priceFixed,
    ]);

    const [loader, setLoader] = useState<boolean>(true);

    useEffect(() => {
        setLoader(isLoadingConfirmPayment || createInvoiceMutation.isLoading || isLoadingPayList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createInvoiceMutation.isLoading, isLoadingConfirmPayment, isLoadingPayList]);

    return (
        <div className="m-vh-95">
            {loader ? (
                <LoaderContainer />
            ) : (
                <div>
                    <div className="pb-15 pt-2">
                        <Steps step={step} />

                        {step === 1 && (
                            <Step1
                                formik={formik}
                                done={() => done()}
                                paramsData={paramsData}
                                GetServicesList={(e: any) => GetServicesList(e)}
                                location={location}
                                mdaList={mdaList}
                                servicePaymComps={servicePaymComps}
                                setAmount={(e: any) => setAmount(e)}
                                isUpdating={createInvoiceMutation.isLoading}
                                serviceList={serviceList}
                                handleChange={(e: any) => handleChange(e)}
                                currentService={currentService}
                                selectedOptions={selectedOptions}
                                priceFixed={priceFixed}
                                updateAmount={(name: string, newAmount: any) => updateAmount(name, newAmount)}
                                customFieldsData={customFieldsData}
                                customFields={customFields}
                                partLock={partLock}
                                disableAmount={disableAmount}
                                totalAmount={totalAmount}
                                setCustomFields={(e) => setCustomFields(e)}
                                disableNext={disableNext}
                                fetchService={getServiceListMutation.isLoading}
                                partAmount={partAmount}
                                setPartAmount={(e) => setPartAmount(e)}
                                priceOptions={priceOptions}
                                isMenuOpen={isMenuOpen}
                                setIsMenuOpen={(e) => setIsMenuOpen(e)}
                                mutate={mdaMutate}
                            />
                        )}

                        {step === 2 && (
                            <Step2
                                formik={formik}
                                goBackStep={() => goBackStep()}
                                loading={createInvoiceMutation.isLoading}
                            />
                        )}

                        {step === 3 && (
                            <PaymentSummary
                                invoiceData={invoiceData}
                                setStep={() => setStep(2)}
                                setShow={() => setShow(true)}
                                paymentMutate={paymentMutate}
                                setInvoiceData={(e: any) => setInvoiceData(e)}
                                amount={partAmount}
                                setAmount={setPartAmount}
                                payListData={paymentList}
                            />
                        )}
                    </div>

                    <SuccessModal
                        show={show}
                        setShow={() => setShow(true)}
                        invoiceData={invoiceData}
                        done={() => done()}
                    />
                </div>
            )}
        </div>
    );
};

export { RevenuePayment };
