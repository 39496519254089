import React, {useState} from 'react';
import '../../../../../App.css';
import {Layout} from '../../layout';
import {useNavigate} from "react-router-dom";
import {useFormik} from "formik";
import * as Yup from "yup";


const Schema = Yup.object().shape({
    transactionRefNo: Yup.string().required('Transaction Reference Number is required'),
    batchNo: Yup.string().required('Transaction Reference Number is required'),
});

const initialValues = {
    transactionRefNo: '',
    batchNo: '',
};

const ReprocessUnpaidPayments = () => {
    const [selectedOption, setSelectedOption] = useState("");
    const [transactionRefNo, setTransactionRefNo] = useState('');
    const [batchNo, setBatchNo] = useState('');


    const navigate = useNavigate();

    const handleSubmit = () => {
        if (selectedOption === "failed-transaction") {
            navigate("/admin/payment/failed-transaction");
        } else if (selectedOption === "rejected-transaction") {
            navigate("/admin/payment/rejected-payments");
        }
    };

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: async (values) => {

        },
    });

    return (
        <>
            <div className={'reprocessUnpaidPayments'}>
                <div className={'rupBreadcrumb'}>
                    <div>Payments</div>
                    <svg
                        width="10"
                        height="13"
                        viewBox="0 0 10 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                            fill="#1F8505"
                        />
                    </svg>
                    <div>Reprocess Unpaid Payments</div>
                </div>

                <div className={'rupRadioButtons'}>
                    <div className={'tpText'}>Reprocess Unpaid Payments</div>

                    <div className={'typeOfTransaction'}>Select the type of transaction you want to reprocess</div>

                    <label className={'radioLabel'}>
                        <input
                            style={{marginRight: '15px'}}
                            type="radio"
                            value="failed-transaction"
                            checked={selectedOption === "failed-transaction"}
                            onChange={handleOptionChange}
                        />
                        Failed Transaction (Earlier processed but returned unpaid)
                    </label>

                    {/* {selectedOption === "failed-transaction" && (
                        <div className="mt-1">
                            <div className="col-form-label py-0 input-height">
                                {formik.values.transactionRefNo.length !== 0 && (
                                    <label className="required inputLabel">
                                        Transaction Reference Number/Batch Number
                                    </label>
                                )}
                            </div>
                            <div className={`form-control form-control-solid`}>
                                <input
                                    type="text"
                                    placeholder="Enter Transaction Reference Number/Batch Number*"
                                    className={'no-outline text-dark fs-8'}
                                    {...formik.getFieldProps('transactionRefNo')}
                                    // value={transactionRefNo}
                                    // onChange={(e) => setTransactionRefNo(e.target.value)}
                                />
                            </div>
                            <div className="fv-plugins-message-container input-height">
                                <div className="fv-help-block fs-9 text-danger">
                                    {formik.touched.transactionRefNo &&
                                        formik.errors.transactionRefNo &&
                                        formik.errors.transactionRefNo}
                                </div>
                            </div>
                        </div>
                    )} */}

                    <label className={'radioLabel'}>
                        <input
                            style={{marginRight: '15px'}}
                            type="radio"
                            value="rejected-transaction"
                            checked={selectedOption === "rejected-transaction"}
                            onChange={handleOptionChange}
                        />
                        Rejected Transaction (Earlier rejected by an approval officer)
                    </label>

                    {/* {selectedOption === "rejected-transaction" && (
                        <div className={`mt-1`}>
                            <div className="col-form-label py-0 input-height">
                                {formik.values.batchNo.length !== 0 && (
                                    <label className="required inputLabel">
                                        Transaction Reference Number/Batch Number
                                    </label>
                                )}
                            </div>
                            <div className={`form-control form-control-solid`}>
                                <input
                                    type="text"
                                    placeholder="Enter Transaction Reference Number/Batch Number*"
                                    className={'no-outline text-dark fs-8'}
                                    {...formik.getFieldProps('batchNo')}
                                    // value={batchNo}
                                    // onChange={(e) => setBatchNo(e.target.value)}
                                />
                            </div>

                            <div className="fv-plugins-message-container input-height">
                                <div className="fv-help-block fs-9 text-danger">
                                    {formik.touched.batchNo &&
                                        formik.errors.batchNo &&
                                        formik.errors.batchNo}
                                </div>
                            </div>
                        </div>
                    )} */}

                    <div className={'tpProceedButton'}>
                        <button
                            style={{
                                background: selectedOption ? '#1F8505' : '#666666',
                                cursor: selectedOption ? 'default' : 'pointer',
                            }}
                            onClick={handleSubmit}
                            disabled={selectedOption ? false : true}
                        >
                            Proceed
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ReprocessUnpaidPayments;
