import { Modal } from "react-bootstrap";

export const ReviewServiceModal = ({
  showServices,
  setShowService,
  ShowApproval,
  ShowRejectModal,
  serviceModalData,
}) => {
  return (
    <Modal
      dialogClassName="m-auto px-5 modal-card-service"
      aria-hidden="false"
      show={showServices}
      onHide={() => setShowService(false)}
      animation
      centered
      size="lg"
      // backdrop={"static"}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Body className="rounded">
        <div className="m-10">
          <div>
            <div className="d-flex justify-content-center py-3 mb-9">
              <span className="sev-cen">Service Details</span>
            </div>

            <div className="d-flex justify-content-between py-3">
              <span className="sev-pr">Service Profile</span>
            </div>

            <div className="d-flex justify-content-between py-3">
              <span className="text-muted fs-9 fw-bold text-start min-w-25">
                Service ID
              </span>
              <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                {serviceModalData?.data?.id}
              </span>
            </div>

            <div className="d-flex justify-content-between py-3">
              <span className="text-muted fs-9 fw-bold text-start min-w-25">
                Service Name / Label
              </span>
              <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                {serviceModalData?.data?.content?.data?.description}
              </span>
            </div>

            <div className="d-flex justify-content-between py-3">
              <span className="text-muted fs-9 fw-bold text-start min-w-25">
                Service List Type
              </span>
              <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                {serviceModalData?.data?.content?.data.multiSelect
                  ? "Multiple"
                  : "Single"}
              </span>
            </div>

            <div className="d-flex justify-content-between py-3">
              <span className="text-muted fs-9 fw-bold text-start min-w-25">
                Payment Notification Email Address
              </span>
              <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                {serviceModalData?.data?.content?.data?.notificationEmail}
              </span>
            </div>
          </div>
          <hr className="text-muted h-1px" />

          <div className="d-flex justify-content-between py-3">
            <span className="sev-pr">Prices & Charges</span>
          </div>
          <div className="table-responsive mb-1">
            <table className="table align-middle  gx-0 gy-1 mt-1">
              <thead className="bg-white">
                <tr>
                  <th className="min-w-100px text-start text-muted fs-9 bg-white border-0 fw-bold ps-0">
                    Item Name
                  </th>
                  <th className="min-w-100px text-start text-muted fs-9 bg-white border-0 fw-bold">
                    Amount
                  </th>
                  <th className="min-w-100px text-start text-muted fs-9 bg-white border-0 fw-bold">
                    Collection Account
                  </th>
                </tr>
              </thead>
              <tbody>
                {serviceModalData?.data?.content?.data?.paymentComponents?.map(
                  (array: any, index: React.Key | null | undefined) => {
                    return (
                      <tr key={index}>
                        <td className="text-dark fs-9  text-start border-0 capitaliseText ps-0 fw-bold ">
                          {array.name}
                        </td>
                        <td className="text-dark fs-9  text-start capitaliseText border-0 fw-bold">
                          {array.amount === 0
                            ? "Not Fixed"
                            : `₦${Number(array.amount).toLocaleString("en", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}`}
                        </td>
                        <td className="text-dark fs-9 text-start border-0 fw-bold">
                          {array?.destAccount?.accountNumber} -{" "}
                          {array?.destAccount?.accountName}
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>

          {/* <div className="d-flex justify-content-between py-3">
              <span className="text-muted fs-9 fw-bold text-start min-w-25">
                Value Added Tax (VAT)
              </span>
              <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                {serviceModalData?.data?.content?.data?.vatRequired
                  ? 'Included'
                  : '  Not included'}
              </span>
            </div>

            <div className="d-flex justify-content-between py-3">
              <span className="text-muted fs-9 fw-bold text-start min-w-25">
                Processing fee
              </span>
              <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                Included
              </span>
            </div>

            <div className="d-flex justify-content-between py-3">
              <span className="text-muted fs-9 fw-bold text-start min-w-25">
                Beneficiary pays processing fee
              </span>
              <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                {' '}
                {serviceModalData?.data?.content?.data?.payerToPay
                  ? 'Yes'
                  : 'No'}{' '}
              </span>
            </div> */}

          <div className="d-flex justify-content-between py-3">
            <span className="text-muted fs-9 fw-bold text-start min-w-25">
              Instalmental payment
            </span>
            <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
              {serviceModalData?.data?.content?.data?.installPayment
                ? "Allowed"
                : "  Not allowed"}
            </span>
          </div>

          {serviceModalData?.data?.content?.data?.customFields.length !== 0 && (
            <>
              <hr className="text-muted h-1px" />

              <div className="d-flex justify-content-between py-3">
                <span className="sev-pr">More Information</span>
              </div>
              <div className="d-flex justify-content-between py-3">
                <span className="text-muted fs-9 fw-bold text-start min-w-25">
                  Field name
                </span>
                <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                  Data type
                </span>
                <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                  Character count
                </span>
                <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                  Optional
                </span>
              </div>

              {serviceModalData?.data?.content?.data?.customFields.map(
                (data, i) => {
                  return (
                    <div
                      className="d-flex justify-content-between py-3"
                      key={i}
                    >
                      <span className="text-muted fs-9 fw-bold text-start min-w-25 capitaliseText">
                        {data?.label}
                      </span>
                      <span className="text-dark fs-9 fw-bold text-end capitaliseText">
                        {data?.customFieldType?.type}
                      </span>
                      <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                        {data?.customFieldType?.limit
                          ? data?.customFieldType?.limit
                          : "-"}
                      </span>
                    </div>
                  );
                }
              )}
            </>
          )}

          {serviceModalData?.data?.content?.data?.websiteUrl.length !== 0 &&
            serviceModalData?.data?.content?.data?.notificationUrl.length !==
              0 && (
              <>
                <hr className="text-muted h-1px" />
                <div className="d-flex justify-content-between py-3">
                  <span className="sev-pr">Integration</span>
                </div>
              </>
            )}
          {serviceModalData?.data?.content?.data?.websiteUrl.length !== 0 && (
            <div className="d-flex justify-content-between py-3">
              <span className="text-muted fs-9 fw-bold text-start min-w-25">
                Website URL
              </span>
              <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                {serviceModalData?.data?.content?.data?.websiteUrl}
              </span>
            </div>
          )}
          {serviceModalData?.data?.content?.data?.notificationUrl.length !==
            0 && (
            <div className="d-flex justify-content-between py-3">
              <span className="text-muted fs-9 fw-bold text-start min-w-25">
                Notification URL
              </span>
              <span className="text-dark fs-9 fw-bold text-end overflow-hidden">
                {serviceModalData?.data?.content?.data?.notificationUrl}
              </span>
            </div>
          )}

          <div
            className="d-flex justify-content-center py-3"
            style={{ gap: "2rem" }}
          >
            <button
              className="btn btn-success px-7 py-2 h-44 w-110 rounded-1 "
              onClick={() => {
                ShowApproval(serviceModalData);
              }}
            >
              <span className="indicator-label fs-base text-White">
                {/* {modalData?.data.currentLevel === 2 ? 'Approve' : 'Review'} */}
                Approve
              </span>
            </button>

            <button
              className="btnpx-7 py-2 h-44 w-110 rounded-1 "
              onClick={() => {
                ShowRejectModal(serviceModalData?.data.id);
              }}
              style={{ background: "red", border: 0, color: "whitesmoke" }}
            >
              <span className="indicator-label fs-base text-White">Reject</span>
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
