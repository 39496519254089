export const getStartAndEndOfDay = () => {
  const currentDate = new Date(); // Get the current date and time

  // Format the start date
  const startYear = currentDate.getFullYear();
  const startMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
  const startDay = String(currentDate.getDate()).padStart(2, "0");
  const startHour = "00";
  const startMinute = "00";
  const startSecond = "00";
  const startDate = `${startYear}-${startMonth}-${startDay}T${startHour}:${startMinute}:${startSecond}`;

  // Format the end date
  const endYear = currentDate.getFullYear();
  const endMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
  const endDay = String(currentDate.getDate()).padStart(2, "0");
  const endHour = "23";
  const endMinute = "59";
  const endSecond = "00";
  const endDate = `${endYear}-${endMonth}-${endDay}T${endHour}:${endMinute}:${endSecond}`;

  return {
    startDate,
    endDate,
  };
};

export const getWeekRange = () => {
  const currentDate = new Date(); // Get the current date and time
  
  // Calculate the start date by subtracting 7 days from the current date
  const startDate = new Date(currentDate);
  startDate.setDate(startDate.getDate() - 7);

  // Format the start date
  const startYear = startDate.getFullYear();
  const startMonth = String(startDate.getMonth() + 1).padStart(2, "0");
  const startDay = String(startDate.getDate()).padStart(2, "0");
  const startHour = "00";
  const startMinute = "00";
  const startSecond = "00";
  const formattedStartDate = `${startYear}-${startMonth}-${startDay}T${startHour}:${startMinute}:${startSecond}`;

  // Format the end date (which is today's date)
  const endYear = currentDate.getFullYear();
  const endMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
  const endDay = String(currentDate.getDate()).padStart(2, "0");
  const endHour = "23";
  const endMinute = "59";
  const endSecond = "00";
  const endDate = `${endYear}-${endMonth}-${endDay}T${endHour}:${endMinute}:${endSecond}`;

  return {
    startDate: formattedStartDate,
    endDate,
  };
};

export const getCustomRange = (number: any) => {
  const currentDate = new Date(); // Get the current date and time

  // Format the start date
  const startYear = currentDate.getFullYear();
  const startMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
  const startDay = String(currentDate.getDate() - number).padStart(2, "0");
  const startHour = "00";
  const startMinute = "00";
  const startSecond = "00";
  const startDate = `${startYear}-${startMonth}-${startDay}T${startHour}:${startMinute}:${startSecond}`;

  // Format the end date
  const endYear = currentDate.getFullYear();
  const endMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
  const endDay = String(currentDate.getDate()).padStart(2, "0");
  const endHour = "23";
  const endMinute = "59";
  const endSecond = "00";
  const endDate = `${endYear}-${endMonth}-${endDay}T${endHour}:${endMinute}:${endSecond}`;

  return {
    startDate,
    endDate,
  };
};
