import { DatePicker, Table } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loader, getWeekRange, isEmpty } from "core/helpers";
import { Download } from "app/components/download";
import { useGetCollections } from "../../../../auth/hooks/useUser";
import { fetchMdaTableData, fetchMdaTableDataReset } from "./usefulComponents";

const { RangePicker } = DatePicker;

const MdaRejectedItem = () => {
  const navigate = useNavigate();
  const [tableParams, setTableParams] = useState<any>({
    pagination: {
      current: 1,
      pageSize: 20,
    },
  });
  const [dateRefreshed, setDateRefreshed] = useState<any>(false);
  const [dateRange, setDateRange] = useState<any>([]);
  const { data: collectionData, mutate, isLoading } = useGetCollections();

  useEffect(() => {
    if (!dateRefreshed) {
      if (!isEmpty(dateRange)) {
        var date1 = moment(new Date(dateRange[0]).toISOString()).format(
          "YYYY-MM-DD"
        );
        var date2 = moment(new Date(dateRange[1]).toISOString()).format(
          "YYYY-MM-DD"
        );
        fetchMdaTableData(
          "FAILED",
          mutate,
          tableParams,
          setTableParams,
          date1,
          date2
        );
      } else {
        const { startDate, endDate } = getWeekRange();
        setDateRange([dayjs(startDate), dayjs(endDate)]);
        const startDateData = moment(startDate).format("YYYY-MM-DD");
        const endDateData = moment(endDate).format("YYYY-MM-DD");
        fetchMdaTableData(
          "FAILED",
          mutate,
          tableParams,
          setTableParams,
          startDateData,
          endDateData
        );
      }
    }
  }, [JSON.stringify(tableParams.pagination.current)]);

  const handleDateRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      setDateRange(dates);
      var date1 = moment(new Date(dates[0]).toISOString()).format("YYYY-MM-DD");
      var date2 = moment(new Date(dates[1]).toISOString()).format("YYYY-MM-DD");
      fetchMdaTableDataReset(
        "FAILED",
        mutate,
        tableParams,
        setTableParams,
        setDateRefreshed,
        date1,
        date2
      );
    } else {
      setDateRange([]);
    }
  };

  const handleTableChange = (pagination) => {
    setDateRefreshed(false);
    setTableParams({
      pagination,
    });
  };

  const dataSource: any[] = [];
  const dataSourceForExcel: any[] = [
    [
      "MDA Name",
      "Government Revenue Reference (GRR)",
      "Service",
      "Amount",
      "Date & Time Stamp",
    ],
  ];

  const columns = [
    {
      title: "S/N",
      dataIndex: "sn",
      key: "sn",
      width: "30",
    },
    {
      title: "MDA Name",
      dataIndex: "mdaName",
      key: "mdaName",
      width: "180",
    },
    {
      title: "Government Revenue Reference (GRR)",
      dataIndex: "grr",
      key: "grr",
      width: "60",
    },
    {
      title: "Service",
      dataIndex: "service",
      key: "service",
      width: "80",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: "80",
    },
    {
      title: "Date & Time Stamp",
      dataIndex: "dateTime",
      key: "dateTime",
      width: "80",
    },
  ];

  var totalAmount = 0;

  for (let i = 0; i < collectionData?.data?.length; i++) {
   //console.log(collectionData?.data[i]?.service?.name);

    var tAmount = collectionData?.data[i]?.amount;

    if (tAmount) {
      totalAmount += tAmount;
    }

    const row = {
      sn: i + 1,
      mdaName: collectionData?.data[i]?.organizationName,
      grr: collectionData?.data[i]?.governmentRevenueReference,
      service: collectionData?.data[i]?.serviceName,
      amount: collectionData?.data[i]?.amount
        ? collectionData?.data[i]?.amount.toLocaleString("en", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : "0.00",
      dateTime: moment(collectionData?.data[i]?.invoice?.dueDate).format(
        "MMMM Do YYYY, h:mm a"
      ),
    };
    dataSource.push(row);

    dataSourceForExcel.push(Object.values(row).slice(1));
  }

  return (
    <div className={"outstandingPayment"}>
      <div className={"outstandingPaymentRow1"}>
        <div className={"outstandingPaymentBreadcrumb"}>
          <div>Analytics</div>

          <svg
            width="10"
            height="13"
            viewBox="0 0 10 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
              fill="#1F8505"
            />
          </svg>

          <div
            className="cursor-pointer"
            onClick={() => navigate("/admin/analytics/collection-view")}
          >
            Collection View
          </div>


          <svg
            width="10"
            height="13"
            viewBox="0 0 10 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
              fill="#1F8505"
            />
          </svg>

          <div>Rejected Item</div>
        </div>

        <div>
          <span className="data-range-text-overview">Date Range:</span>
          <RangePicker
            className="py-1 fs-8 fw-bold datepicker w-250px"
            value={dateRange}
            onChange={handleDateRangeChange}
            allowClear
            size={"small"}
          />
        </div>
      </div>

      <div className={"outstandingPaymentRow2"}>
        <div>REJECTED ITEM</div>

        <div className="d-flex justify-content-end  mb-9 me-4">
          <Download
            dataSourceForExcel={dataSourceForExcel}
            dataSource={dataSource}
            excelFileTitle={"Rejected Inflow for MDA"}
            pdfFileTitle={"Rejected Inflow for MDA"}
            excelPageTitle={"Rejected Inflow for MDA"}
            grandTotalFor={{
              transactionAmount: "Transaction Amount",
              processingFee: "Processing Fee",
              vatProcessingFee: "VAT Processing Fee",
              totalProcessingFee: "Total Processing Fee",
              netAmount: "Net Amount",
              cumulativeNetAmount: "Cumulative Net Amount",
            }}
          />
        </div>

        <div>
          <div>TOTAL:</div>
          <div> &#x20A6;{totalAmount?.toLocaleString()} </div>
        </div>

        {/* <div>
              <div className={"filterButton"}>
                <button>
                  <img src={filter} alt="" />
                  <span>Apply Filter</span>
                </button>
              </div>

              <div>
                <button>Reset All</button>
              </div>
            </div> */}

        <div className="d-flex justify-content-center">
          <Table
            dataSource={dataSource}
            bordered
            columns={columns}
            scroll={{ x: "1000px" }}
            rowKey={(record) => record?.sn}
            pagination={tableParams.pagination}
            loading={{
              spinning: !!isLoading,
              indicator: <Loader />,
            }}
            onChange={(e) => handleTableChange(e)}
          />
        </div>
      </div>
    </div>
  );
};

export default MdaRejectedItem;
