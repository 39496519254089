import { AxiosResponse } from "axios";
import { axiosInstance } from "axiosInstance";

// Utility function to create service methods
export const createServiceMethod = (
    url: string | ((...args: any[]) => string),
    method: 'get' | 'post' = 'get',
    transformResponse?: (response: AxiosResponse<any>) => any,
) => {
    return async (params: any = {}): Promise<any | null> => {
        // Determine the actual URL
        const resolvedUrl = typeof url === 'function' ? url(params) : url;
        const response: AxiosResponse<any> = await axiosInstance[method](resolvedUrl, { ...params });
        return transformResponse ? transformResponse(response) : response.data;
    };
};