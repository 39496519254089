import { edit } from "core/assets/imgs";
import React from "react";

interface PreviewServiceDetailsProps {
  formik: any;
  fields: any;
  forApproval?: boolean;
  isMoreInfo: boolean;
  inputsAmount: any;
  inputs: any;
  isIntegrated: boolean;
  setConfirm: (e: any) => void;
  reset: () => void;
  rejectApproval?: any;
}
export const PreviewServiceDetails: React.FC<PreviewServiceDetailsProps> = ({
  formik,
  fields,
  inputsAmount,
  isMoreInfo,
  forApproval,
  inputs,
  isIntegrated,
  reset,
  setConfirm,
  rejectApproval,
}) => {
  return (
    <div>
      <div className="d-flex justify-content-between ">
        <div className="d-flex justify-content-center align-items-center">
          <span className="text-dark fs-5 fw-bolder lh-lg">Preview</span>
        </div>

        <div
          className="d-flex justify-content-center align-items-center bg-edit px-5 rounded-1 h-35e w-79e cursor-pointer"
          onClick={() => {
            reset();
          }}
        >
          <span className=" text-white fs-8 fw-bold mx-3 lh-sm">Edit</span>
          <img src={edit} alt="edit" className="w-11r5 h-11r5" />
        </div>
      </div>

      <>
        <div className="my-9">
          <span className="fs-8 fw-bolder">Create Service Profile</span>
        </div>
        <div className="d-flex justify-content-between py-3">
          <span className="text-black fs-8 fw-bold text-start min-w-25">
            Service Name / Label
          </span>
          <span className="text-dark fs-8  text-end">
            {formik.values.label}
          </span>
        </div>

        <div className="d-flex justify-content-between py-3">
          <span className="text-black fs-8 fw-bold text-start min-w-25">
            Item List Type
          </span>
          <span className="text-dark fs-8  text-end">
            {formik?.values?.type}
          </span>
        </div>

        <div className="d-flex justify-content-between py-3">
          <span className="text-black fs-8 fw-bold text-start min-w-25">
            GL Account
          </span>
          <span className="text-dark fs-8  text-end">
            {formik?.values?.glAccountName}
          </span>
        </div>

        <div className="d-flex justify-content-between py-3">
          <span className="text-black fs-8 fw-bold text-start min-w-25">
            Payment Notification Email Address
          </span>
          <span className="text-dark fs-8  text-end">
            {formik?.values?.paymentEmail}
          </span>
        </div>

        <div className="d-flex justify-content-between py-3">
          <span className="text-black fs-8 fw-bold text-start min-w-25">
            Revenue Code
          </span>
          <span className="text-dark fs-8 text-end">
            {" "}
            {fields?.revenueCode?.length > 0 ? fields?.revenueCode : "N/A"}
          </span>
        </div>
      </>

      <div className="my-10">
        <div className="d-flex flex-column">
          <span className="fs-8 fw-bolder mt-5">Setup Prices & Charges</span>

          {formik.values.type === "multiple" ? (
            <>
              {/* <div className="d-flex justify-content-between py-3">
                                <span className="text-black fs-8 fw-bolder text-start min-w-25">
                                  Information
                                </span>
                                <span className="text-dark fs-8  text-end"></span>
                              </div> */}
              <hr className="my-1 text-muted" />
              <div className="table-responsive mb-4">
                <table className="table align-middle  gx-0 gy-1 mt-1">
                  <thead className="bg-white">
                    <tr>
                      <th className="min-w-100px text-start text-black fs-8 bg-white border-0 fw-bold ps-0">
                        Item Name
                      </th>
                      <th className="min-w-100px text-start text-black fs-8 bg-white border-0 fw-bold">
                        Amount
                      </th>
                      <th className="min-w-100px text-start text-black fs-8 bg-white border-0 fw-bold">
                        Collection Account
                      </th>
                      {!fields.multipleSelect && (
                        <th className="min-w-80px text-start text-black fs-8 bg-white border-0 fw-bold">
                          Total payable
                        </th>
                      )}
                    </tr>
                  </thead>

                  <tbody>
                    {inputsAmount.map((array: any, index: any) => {
                      return (
                        <tr key={index}>
                          <td className="text-dark fs-8  text-start border-0 capitaliseText ps-0">
                            {array.itemName}
                          </td>
                          <td className="text-dark fs-8  text-start capitaliseText border-0">
                            {Number(array.amount) < 1
                              ? "N/A"
                              : `₦ ${Number(array.amount).toLocaleString("en", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}`}
                          </td>

                          <td className="text-dark fs-8  text-start border-0">
                            {array.collectionAccount}
                          </td>

                          {!fields.multipleSelect && (
                            <td className="text-dark fs-8  text-start border-0">
                              <div className="my-2 fs-9 text-dark">
                                &#x20A6;
                                {(Number(array.amount) + 107.5).toLocaleString(
                                  "en",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                              </div>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {fields.multipleSelect && (
                  <div className="mt-2 fs-8 text-dark">
                    <span className="fw-bolder">Note :</span> Processing fee of
                    100 naira & VAT of 7.5% is applied to each payment
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-between py-3">
                <span className="text-black fs-8 fw-bold text-start min-w-25">
                  Service Amount
                </span>
                <span className="text-dark fs-8  text-end">
                  {Number(fields.amount) === 0
                    ? "Not Specified"
                    : `₦ ${Number(fields.amount).toLocaleString()}`}
                </span>
              </div>
              {Number(fields.amount) > 0 && (
                <div className="my-1 fs-9 text-dark">
                  <span className="fw-bold">Note :</span> Processing fee = 100
                  naira & VAT is 7.5% ... total payable ={" "}
                  {Number(fields.amount) + 107.5} naira
                </div>
              )}
              <hr className="my-1 text-muted" />
            </>
          )}
        </div>
      </div>

      {/* <>
                      <div className="my-10">
                        <div className="d-flex justify-content-between py-3">
                          <span className="text-black fs-8 fw-bolder text-start min-w-25">
                            Value Added Tax (VAT)
                          </span>
                          <span className="text-dark fs-8  text-end"></span>
                        </div>
                        <hr className="my-1 text-muted" />
                        <div className="d-flex justify-content-between py-3">
                          <span className="text-black fs-8  text-start min-w-25">
                            Tick the box if VAT is charged on the Service
                          </span>
                          <span className="text-dark fs-8  text-end">
                            {fields.vat ? "Yes" : "No"}
                          </span>
                        </div>
                        Comment this out
                        <div className="d-flex justify-content-between py-3">
                          <span className="text-black fs-8  text-start min-w-25">
                            Beneficiary to pay
                          </span>
                          <span className="text-dark fs-8  text-end">
                            {fields.beneficiaryVatPay ? "Yes" : "No"}
                          </span>
                        </div>
                        Comment this out end
                      </div>

                      <div className="my-10">
                        <div className="d-flex justify-content-between py-3">
                          <span className="text-black fs-8 fw-bolder text-start min-w-25">
                            Processing Fee
                          </span>
                          <span className="text-dark fs-8  text-end"></span>
                        </div>
                        <hr className="my-1 text-muted" />

                        <div className="d-flex justify-content-between py-3">
                          <span className="text-black fs-8  text-start min-w-25">
                            Processing Fee
                          </span>
                          <span className="text-dark fs-8  text-end">
                            {fields.processingFee ? "Yes" : "No"}
                          </span>
                        </div>

                        <div className="d-flex justify-content-between py-3">
                          <span className="text-black fs-8  text-start min-w-25">
                            Beneficiary to pay
                          </span>
                          <span className="text-dark fs-8  text-end">
                            {fields.beneficiaryFeePay ? "Yes" : "No"}
                          </span>
                        </div>
                      </div>
                    </> */}

      <div className="my-10">
        <div className="d-flex justify-content-between py-3">
          <span className="text-black fs-8 fw-bolder text-start min-w-25">
            More Payment Settings
          </span>
          <span className="text-dark fs-8  text-end"></span>
        </div>
        <hr className="my-1 text-muted" />

        <div className="d-flex justify-content-between py-3">
          <span className="text-black fs-8  text-start min-w-25">
            Make Payments in Installments
          </span>
          <span className="text-dark fs-8  text-end">
            {fields.installmental ? "Yes" : "No"}
          </span>
        </div>

        {/* <div className="d-flex justify-content-between py-3">
                    <span className="text-black fs-8  text-start min-w-25">
                      Authorize Direct Debit on Service
                    </span>
                    <span className="text-dark fs-8  text-end">
                      {fields.authorizeDebit ? "Yes" : "No"}
                    </span>
                  </div> */}
      </div>
      {formik.values.type === "single" && (
        <div className="my-10">
          <div className="d-flex justify-content-between py-3">
            <span className="text-black fs-8 fw-bolder text-start min-w-25">
              Setup Collection Account
            </span>
          </div>
          <div className="d-flex justify-content-between py-3">
            <span className="text-black fs-8  text-start min-w-25">
              Select Account
            </span>
            <span className="text-dark fs-8  text-end">
              {formik.values.collectionAccount}
            </span>
          </div>
          <hr className="my-1 text-muted" />
        </div>
      )}
      {isMoreInfo && (
        <>
          <div className="d-flex justify-content-between py-3">
            <span className="text-black fs-8 fw-bolder text-start min-w-25">
              Information
            </span>
            <span className="text-dark fs-8  text-end"></span>
          </div>
          <hr className="my-1 text-muted" />
          <div className="table-responsive mb-4">
            <table className="table align-middle  gx-0 gy-1 mt-1">
              <thead className="bg-white">
                <tr>
                  <th className="min-w-100px text-start text-black fs-8 bg-white border-0 fw-bold">
                    {" "}
                    Field Name
                  </th>
                  <th className="min-w-100px text-start text-black fs-8 bg-white border-0 fw-bold">
                    Data Type
                  </th>
                  <th className="min-w-100px text-start text-black fs-8 bg-white border-0 fw-bold">
                    Character Count
                  </th>
                  <th className="min-w-100px text-start text-black fs-8 bg-white border-0 fw-bold">
                    Optional
                  </th>
                </tr>
              </thead>

              <tbody>
                {inputs.map((array: any, index: any) => {
                  return (
                    <tr key={index}>
                      <td className="text-dark fs-8  text-start border-0 capitaliseText">
                        {array.fieldName}
                      </td>
                      <td className="text-dark fs-8  text-start capitaliseText border-0">
                        {array.dataType === "singleSelect"
                          ? "Single Item from a List"
                          : array.dataType === "multipleSelect"
                          ? "Multiple Item from a List"
                          : array.dataType}
                      </td>
                      <td className="text-dark fs-8  text-start border-0">
                        {array.charaterCount ? array.charaterCount : "-"}
                      </td>
                      <td className="text-dark fs-8  text-start border-0 capitaliseText">
                        {array.optional ? "Yes" : "No"}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      )}

      {isIntegrated && (
        <>
          <div className="my-10">
            <div className="d-flex justify-content-between py-3">
              <span className="text-black fs-8 fw-bolder text-start min-w-25">
                Integration
              </span>
              <span className="text-dark fs-8  text-end"></span>
            </div>
            <hr className="my-1 text-muted" />

            <div className="d-flex justify-content-between py-3">
              <span className="text-black fs-8  text-start min-w-25">
                Website URL
              </span>
              <span className="text-dark fs-8  text-end">
                {/* {fields.installmental ? "Yes" : "No"} */}
                {fields.websiteUrl}
              </span>
            </div>

            <div className="d-flex justify-content-between py-3">
              <span className="text-black fs-8  text-start min-w-25">
                Notification URL
              </span>
              <span className="text-dark fs-8  text-end">
                {/* {fields.authorizeDebit ? "Yes" : "No"} */}
                {fields.notificationUrl}
              </span>
            </div>
          </div>
          <hr className="mt-20 text-muted" />
        </>
      )}

      <div className="d-flex ">
        <button
          className={`btn ${
            forApproval ? " btn-danger" : "btn-white border-success"
          } rounded-1  w-180 h-41 center-all`}
          type="button"
          onClick={() => {
            forApproval ? rejectApproval?.() : reset();
          }}
        >
          <span
            className={`fs-8 ${forApproval ? "text-white " : "text-success "}`}
          >
            {forApproval ? "Reject" : "Back"}
          </span>
        </button>

        <button
          className="btn btn-success rounded-1 w-180 h-41 center-all mx-5  border-collection-button"
          type="button"
          onClick={() => setConfirm(true)}
        >
          <span className="fs-8">
            {forApproval ? "Approve" : "Send For Approval"}
          </span>
        </button>
      </div>
    </div>
  );
};
