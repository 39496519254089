import { HTMLInputTypeAttribute } from 'react';

interface InputFieldProps {
    placeholder?: string;
    disabled?: boolean;
    type?: HTMLInputTypeAttribute | undefined;
    value: string;
    name?: string;
    onChange: (e) => void;
    className?: string;
    maxLength?:number
}

const InputField: React.FC<InputFieldProps> = ({
    value,
    name = '',
    type = 'text',
    onChange,
    placeholder,
    className,
    disabled,
    maxLength,
}) => {
    // Prevent the spinner (up/down arrow and wheel) behavior
    const handleWheel = (event) => {
        event.preventDefault(); // Disable mouse wheel scroll on number input
    };

    const handleKeyDown = (event: { key: string; preventDefault: () => void }) => {
        if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
            event.preventDefault(); // Disable arrow keys (up and down) for changing value
        }
    };

    return (
        // eslint-disable-next-line jsx-a11y/no-redundant-roles
        <input
            type={type}
            id={name}
            placeholder={placeholder}
            className={className || 'no-outline ps-4 text-dark fs-8'}
            onChange={onChange}
            value={value}
            name={name}
            onWheel={handleWheel}
            onKeyDown={handleKeyDown}
            disabled={disabled}
            role='textbox'
            maxLength={maxLength}
        />
        
    );
};

export { InputField };
