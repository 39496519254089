import { Dropdown, Input, MenuProps, Select, Space, Table } from "antd";
import { Download } from "app/components/download";
import { LoaderContainer, formatRequestEnums } from "core/helpers";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Breadcrumb } from "../components/Breadcrumb";
import { useGetProjects } from "../hooks/hooks";

const links = [
  {
    name: "Project",
  },
  {
    name: "Manage Users",
  },
];

export const ManageUsers = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<any>("");

  const {
    data: projectData,
    mutate: mutateProject,
    isLoading: isLoadingProject,
  } = useGetProjects();

  const dataSource: any[] = [];

  const columns = [
    {
      title: "S/N",
      dataIndex: "sn",
      key: "sn",
      width: "30",
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: "30",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "180",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: "60",
    },
    {
      title: "Official E-mail",
      dataIndex: "email",
      key: "email",
      width: "60",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "60",
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      width: "80",
    },

    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      width: "80",
    },

    {
      title: "Action",
      key: "action",
      render(text: any, record: any) {
        const items: MenuProps["items"] = [
          {
            label: (
              <button
                className="btn-clear"
                onClick={() => navigate("view/" + record.projectID)}
              >
                View
              </button>
            ),
            key: "1",
          },
          {
            label: (
              <button
                className="btn-clear"
                //onClick={() => navigate("view/" + record.projectID)}
              >
                Remove User
              </button>
            ),
            key: "2",
          },
        ];

        return (
          <Space wrap>
            <Dropdown menu={{ items }} placement="bottom" arrow>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
              >
                <path d="M480-160q-33 0-56.5-23.5T400-240q0-33 23.5-56.5T480-320q33 0 56.5 23.5T560-240q0 33-23.5 56.5T480-160Zm0-240q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm0-240q-33 0-56.5-23.5T400-720q0-33 23.5-56.5T480-800q33 0 56.5 23.5T560-720q0 33-23.5 56.5T480-640Z" />
              </svg>
            </Dropdown>
          </Space>
        );
      },
    },
  ];

  const getStatusLength = (status: string) => {
    const projectStatus = projectData?.filter(
      (item: { status: string }) =>
        item.status.toLocaleLowerCase() === status.toLocaleLowerCase()
    );
    return projectStatus?.length;
  };

  useEffect(() => {
    mutateProject();
  }, [mutateProject]);

  for (let i = 0; i < projectData?.length; i++) {
    const currentData = projectData[i];

    const datad = {
      key: i.toString(),
      sn: i + 1,

      projectID: currentData?.projectId,
      projectName: currentData?.name,
      description: currentData?.description,
      status: formatRequestEnums(currentData?.status),
      startDate: "N/A", //currentData?.createdDate,
      endDate: "N/A",
    };
    dataSource.push(datad);
  }

  return (
    <>
      <div className={"adminBody"}>
        <div className={"breadcrumb"}>
          <Breadcrumb links={links} />
        </div>

        {isLoadingProject && <LoaderContainer />}

        <div className="project-content-container">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <Download
                excelFileTitle={"dd"}
                pdfFileTitle={"dd"}
                excelPageTitle={"ddd"}
                dataSource={dataSource}
              />
            </div>

            <div>
              <button
                className="bg-success text-white fs-8 p-2 border-0 rounded-1 "
                onClick={() => navigate("create")}
              >
                Add New Users
              </button>
            </div>
          </div>

          <div>
            <div className="header-container">
              <span className="text-dark2 fs-5 fw-bolder">
                {projectData?.name} Project Team
              </span>
            </div>

            <div className="header-container align-items-end">
              <div>
                <span className="text-muted fs-9">Total Number of Users</span>
                <div>
                  <span className="text-success fw-boldest">
                    {projectData?.users?.length}
                  </span>
                </div>
              </div>
            </div>

            <div className="header-container flex-row justify-content-between">
              <div>
                <div>
                  <span className="text-muted fs-9">Active Users</span>
                </div>
                <div>
                  <span className="text-muted fs-9 fw-boldest">
                    {getStatusLength("AWAITING_APPROVAL")}
                  </span>
                </div>
              </div>

              <div>
                <div>
                  <span className="text-muted fs-9">Approved Projects</span>
                </div>
                <div>
                  <span className="text-muted fs-9 fw-boldest">
                    {getStatusLength("APPROVED")}
                  </span>
                </div>
              </div>

              <div>
                <div>
                  <span className="text-muted fs-9">Commenced Project</span>
                </div>
                <div>
                  <span className="text-muted fs-9 fw-boldest">
                    {getStatusLength("commenced")}
                  </span>
                </div>
              </div>

              <div>
                <div>
                  <span className="text-muted fs-9">Closed Projects</span>
                </div>
                <div>
                  <span className="text-muted fs-9 fw-boldest">
                    {getStatusLength("closed")}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex align-items-center gap-20">
            <div className="d-flex gap-5 align-items-center">
              <div>Filter by:</div>
              <div>
                <Select
                  style={{ width: 150 }}
                  options={[
                    { value: "", label: "Status" },
                    { value: "Awaiting Approval", label: "Awaiting Approval" },
                    { value: "approved", label: "Approved" },
                    { value: "commenced", label: "Commenced" },
                    { value: "closed", label: "Closed" },
                  ]}
                  popupMatchSelectWidth={false}
                  // onChange={(e) => setStatus(e)}
                  showSearch
                  placeholder="Status"
                  filterOption={(input, option: any) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0 ||
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  className="h-41 w-133px bg-gray-800 text-gray-500 rounded-1"
                />
              </div>
            </div>

            <div className={"mdaDirectorySelectOptions"}>
              <Input
                placeholder={
                  "Search by Name, Phone Number or by official Email"
                }
                className="w-400px h-44 fs-8 bg-gray-800 text-gray-500"
                allowClear
                suffix={
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.4431 16.4438L20.9995 21.0001M18.75 10.875C18.75 15.2242 15.2242 18.75 10.875 18.75C6.52576 18.75 3 15.2242 3 10.875C3 6.52576 6.52576 3 10.875 3C15.2242 3 18.75 6.52576 18.75 10.875Z"
                      stroke="#666666"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                }
                value={searchValue}
                onChange={(e: any) => setSearchValue?.(e.target.value)}
              />
            </div>
          </div>

          <div>
            <Table
              dataSource={dataSource}
              bordered
              columns={columns}
              scroll={{ x: "1000px" }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
