import "app/pages/admin/Project/CreateProject/CreateProject.scss";
import { formatMoneyLocal } from "core/helpers";
import { Modal } from "react-bootstrap";

export const ViewProjectCreationModal = ({
  showProjectCreation,
  setShowProjectCreation,
  ShowApproval,
  ShowRejectModal,
  ProjectData,
  fullData,
}) => {
  const TableUserWork: React.FC<any> = () => {
    return (
      <table className="table">
        <thead>
          <tr className="fs-8 text-start">
            <th>ID</th>
            <th>Process Type</th>
            <th>Initiator</th>
            <th>Reviewer</th>
            <th>Approver </th>
            {/* <th>Action</th> */}
          </tr>
        </thead>
        <tbody>
          {ProjectData?.workflows?.map((userFlow, i) => {
            return (
              <tr key={i} className="fs-8 text-start">
                <td> {i + 1} </td>
                <td>{userFlow?.requesttype?.description}</td>
                {userFlow?.routes?.map((user, i) => {
                  return (
                    <td>
                      {user?.users?.map((u, i) => {
                        return (
                          <div>
                            <div>{u?.name}</div>
                          </div>
                        );
                      })}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const TableUsers: React.FC<any> = () => {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Phone Number</th>
            <th>Email </th>
          </tr>
        </thead>
        <tbody>
          {ProjectData?.users?.map((userData, i) => {
            return (
              <tr key={i} className="fs-8">
                <td> {i + 1} </td>
                <td>{userData.firstName}</td>
                <td>{userData.lastName}</td>
                <td>{userData.phoneNumber}</td>
                <td>{userData.email}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <Modal
      dialogClassName="m-auto px-5 modal-card-service w-500px"
      aria-hidden="false"
      show={showProjectCreation}
      onHide={() => setShowProjectCreation(false)}
      animation
      centered
      size="lg"
      // backdrop={"static"}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Body className="rounded">
        <div className="m-10">
          <div>
            <div
              className="preview-container"
              style={{ background: "#F9F9F9" }}
            >
              <div className="card m-auto project-preview-card w-600px pt-0">
                <div className="d-flex justify-content-center align-items-center preview-header">
                  <span className="text-success fs-2 fw-bold lh-lg">
                    Project Creation
                  </span>
                </div>
                <div>
                  <div className="d-flex justify-content-between  px-5">
                    <div className="d-flex justify-content-center align-items-center">
                      <span className="text-dark fs-5 fw-bolder lh-lg">
                        Basic Details
                      </span>
                    </div>
                  </div>

                  <hr className="text-muted h-1px" />
                  <div className="px-5">
                    <div className="d-flex justify-content-between py-3">
                      <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                        Project ID
                      </span>
                      <span className="text-dark fs-8 fw-bold text-end">
                        {ProjectData?.projectId}
                      </span>
                    </div>

                    <div className="d-flex justify-content-between py-3">
                      <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                        Project Name
                      </span>
                      <span className="text-dark fs-8 fw-bold text-end">
                        {ProjectData?.name}
                      </span>
                    </div>

                    <div className="d-flex justify-content-between py-3">
                      <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                        Description
                      </span>
                      <span className="text-dark fs-8 fw-bold text-end">
                        {ProjectData?.description}
                      </span>
                    </div>

                    <div className="d-flex justify-content-between py-3">
                      <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                        Project Budget
                      </span>
                      <span className="text-dark fs-8 fw-bold text-end">
                        ₦{formatMoneyLocal(ProjectData?.budget)}
                      </span>
                    </div>

                    {/* <div className="d-flex justify-content-between py-3">
                <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                  MDAs Involved
                </span>
                <span className="text-dark fs-8 fw-bold text-end">
                  {formik?.formik?.organization}
                </span>
              </div> */}
                  </div>
                </div>
              </div>

              <div className="card m-auto project-preview-card w-600px">
                <div className="d-flex justify-content-between px-5">
                  <div className="d-flex justify-content-center align-items-center">
                    <span className="text-dark fs-5 fw-bolder lh-lg">
                      Bank Account
                    </span>
                  </div>
                </div>
                <hr className="text-muted h-1px" />
                <div className="px-5">
                  <div className="d-flex justify-content-between py-3">
                    <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                      Bank Name
                    </span>
                    <span className="text-dark fs-8 fw-bold text-end">
                      {ProjectData?.bankAccount?.bankName}
                    </span>
                  </div>

                  <div className="d-flex justify-content-between py-3">
                    <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                      Account Number
                    </span>
                    <span className="text-dark fs-8 fw-bold text-end">
                      {ProjectData?.bankAccount?.accountNumber}
                    </span>
                  </div>

                  <div className="d-flex justify-content-between py-3">
                    <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                      Account Classification
                    </span>
                    <span className="text-dark fs-8 fw-bold text-end">
                      {ProjectData?.bankAccount?.accountType?.description}
                    </span>
                  </div>

                  <div className="d-flex justify-content-between py-3">
                    <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                      Account Name
                    </span>
                    <span className="text-dark fs-8 fw-bold text-end">
                      {ProjectData?.bankAccount?.accountName}
                    </span>
                  </div>

                  <div className="d-flex justify-content-between py-3">
                    <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                      Daily Transaction Limit
                    </span>
                    <span className="text-dark fs-8 fw-bold text-end">
                      ₦{formatMoneyLocal(ProjectData?.dailyLimit)}
                    </span>
                  </div>
                </div>
              </div>

              <div className="card m-auto p-5 project-preview-card w-600px">
                <div className="d-flex justify-content-between ">
                  <div className="d-flex justify-content-center align-items-center">
                    <span className="text-dark fs-5 fw-bolder lh-lg">
                      User Details
                    </span>
                  </div>
                </div>
                <hr className="text-muted h-1px" />
                <div className="mt-5">
                  <TableUsers />
                </div>
              </div>

              <div className="card m-auto p-5 project-preview-card w-600px">
                <div className="d-flex justify-content-between ">
                  <div className="d-flex justify-content-center align-items-center">
                    <span className="text-dark fs-5 fw-bolder lh-lg">
                      Approval Workflow Definition
                    </span>
                  </div>
                </div>
                <hr className="text-muted h-1px" />

                <div className="mt-5">
                  <TableUserWork />
                </div>
              </div>
            </div>
          </div>
          <div
            className="d-flex justify-content-center py-3"
            style={{ gap: "2rem" }}
          >
            <button
              className="btn btn-success px-7 py-2 h-44 w-110 rounded-1 "
              onClick={() => {
                ShowApproval(fullData);
              }}
            >
              <span className="indicator-label fs-base text-White">
                Approve
              </span>
            </button>

            <button
              className="btnpx-7 py-2 h-44 w-110 rounded-1 "
              onClick={() => {
                ShowRejectModal(fullData?.data.id);
              }}
              style={{ background: "red", border: 0, color: "whitesmoke" }}
            >
              <span className="indicator-label fs-base text-White">Reject</span>
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
