import { DatePicker, Select, Space, Table } from "antd";
import { Download } from "app/components/download";
import DropDown from "app/components/dropdown";
import { filter } from "core/assets/icons";
import { FetchAndUnmask, FormatDate } from "core/helpers";
import Moment from "moment";
import { useEffect, useState } from "react";
import { FlapperSpinner } from "react-spinners-kit";
import { useGetPaginatedAllMda } from "../../../payments/hooks/useRevenuePayment";
import { useOagfPaymentSummary } from "../../Payments/hooks/usePayment";

const { RangePicker } = DatePicker;
const OagfBalanceSheet = () => {
  const {
    isLoading,
    data: allPaymentSummary,
    mutate,
  } = useOagfPaymentSummary();

  const [allMdaData, setAllMdaData] = useState<any>([]);
  const [dateRange, setDateRange] = useState<any>([]);
  const filterSize = 10000;
  const {
    data: mdaNameList,
    mutate: mdaMutate,
    isLoading: allMdaLoading,
  } = useGetPaginatedAllMda();

  const dataSource: any[] = [];
  const dataSourceForExcel: any[] = [
    [
      "ID",
      "Date & Time",
      "Expenditure Head/Subhead",
      "Revenue Code",
      "Service",
      "GRR / GPR",
      "MDA Name",
      "Funding Bank",
      "Funding Account",
      "Receiving Bank",
      "Receiving Account",
      "Currency",
      "Purpose",
      "Amount",
      "Balance",
      "Incoming",
      "Sector",
      "Destination Bank Code",
      "Source Bank Code",
    ],
  ];
  const [allData, setAllData] = useState<any>([]);

  useEffect(() => {
    setAllMdaData(mdaNameList);
  }, [mdaNameList]);

  const lazydayUser = JSON.parse(FetchAndUnmask("lazyday_user") as string);

  useEffect(() => {
    mutate(`size=${filterSize}`, {
      onSuccess: (response: any) => {
        var filterData: any[] = [];
        for (let i = 0; i <= response?.length; i++) {
          if (response[i]) {
            const datad = {
              key: i.toString(),
              sn: i + 1,
              id: response[i]?.id,
              dateAndTime: response[i]?.dateTime
                ? Moment(response[i]?.dateTime).format("MMMM Do YYYY, h:mm a")
                : "",

              "Expenditure Head/Subhead": response[i].expenseType || "N/A",
              service: response[i]?.service,
              revCode: response[i]?.revCode,
              "GRR / GPR": response[i].incoming
                ? response[i].invoiceNumber || "N/A"
                : response[i].paymentReference || "N/A",
              mdaName: response[i]?.organizationName,
              fundingBank: response[i]?.fundingBank,
              fundingAccount: response[i]?.fundingAccount,
              receivingBank: response[i]?.receivingBank,
              receivingAccount: response[i]?.receivingAccount,
              currency: `NGN`,
              purpose: response[i]?.purpose,
              amount: response[i]?.amount,
              balance: response[i]?.balance,
              incoming: response[i]?.incoming,
              sector: response[i]?.sector?.code,
              destBankCode: response[i]?.destinationBankAccountCode,
              srcBankCode: response[i]?.sourceBankAccountCode,
            };
            filterData.push(datad);
            dataSourceForExcel.push(Object.values(datad).slice(2));
          }
        }

        setAllData(filterData);
      },
    });
  }, []);

  const columns = [
    {
      title: "S/N",
      dataIndex: "sn",
      key: "sn",
      width: "40",
    },
    {
      title: "Date & Time",
      dataIndex: "dateAndTime",
      key: "dateAndTime",
      //   width: "300",
    },
    {
      title: "MDA Name",
      dataIndex: "mdaName",
      key: "mdaName",
      //   width: "120",
    },

    {
      title: "Expenditure Head/Subhead",
      dataIndex: "Expenditure Head/Subhead",
      key: "Expenditure Head/Subhead",
      //   width: "100",
    },
    {
      title: "Service",
      dataIndex: "service",
      key: "service",
      //   width: "150",
    },

    {
      title: "Revenue Code",
      dataIndex: "revCode",
      key: "revCode",
      //   width: "120",
    },

    {
      title: "GRR / GPR",
      dataIndex: "GRR / GPR",
      key: "GRR / GPR",
      //   width: "120",
    },

    {
      title: "Funding Bank",
      dataIndex: "fundingBank",
      key: "fundingBank",
      //   width: "130",
    },
    {
      title: "Funding Account",
      dataIndex: "fundingAccount",
      key: "fundingAccount",
      //   width: "130",
    },
    {
      title: "Receiving Bank",
      dataIndex: "receivingBank",
      key: "receivingBank",
      //   width: "130",
    },
    {
      title: "Receiving Account",
      dataIndex: "receivingAccount",
      key: "receivingAccount",
      //   width: "130",
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      //   width: "130",
    },
    {
      title: "Purpose",
      dataIndex: "purpose",
      key: "purpose",
      //   width: "130",
    },

    {
      title: "Inflow(CR)/Outflow(DR)",
      dataIndex: "amount",
      render: (_, record: any) => (
        <span className={`${record.incoming ? "text-success" : "text-danger"}`}>
          {record.incoming ? "+" : "-"}{" "}
          {record?.amount?.toLocaleString("en", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </span>
      ),
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      // width: '130'
    },
  ];

  //allPaymentSummary
  for (let i = 0; i <= allPaymentSummary?.length; i++) {
    const currentData = allPaymentSummary[i];

    if (currentData) {
      const vat = Number((currentData.amount * 7.5) / 1000);
      const totalVat = 0 + vat;
      const netAmount = totalVat + currentData.amount;

      const datad = {
        key: i.toString(),
        sn: i + 1,
        id: currentData.id,
        dateAndTime: currentData.dateTime
          ? Moment(currentData?.dateTime).format("MMMM Do YYYY, h:mm a")
          : "N/A",
        "Expenditure Head/Subhead": currentData.expenseType || "N/A",
        revCode: currentData.revCode || "N/A",
        service: currentData.service || "N/A",
        "GRR / GPR": currentData.incoming
          ? currentData.invoiceNumber || "N/A"
          : currentData.paymentReference || "N/A",
        fundingBank: !currentData.incoming ? currentData?.fundingBank : "N/A",
        mdaName: currentData?.organizationName,
        fundingAccount: currentData.incoming
          ? "N/A"
          : currentData?.fundingAccount,
        receivingBank: currentData?.receivingBank,
        receivingAccount: currentData?.receivingAccount,
        currency: `NGN`,
        purpose: currentData.purpose,
        amount: currentData.amount,
        balance: currentData.balance,
        incoming: currentData.incoming,
        sector: currentData?.sector?.code,
        destBankCode: currentData?.destinationBankAccountCode,
        srcBankCode: currentData?.sourceBankAccountCode,
      };
      dataSource.push(datad);
      dataSourceForExcel.push(Object.values(datad).slice(2));
    }
  }

  // const paymentReferenceList = Array.from(
  //   new Set(
  //     allData
  //       .map((obj: any) => {
  //         if (obj["GRR / GPR"] === null || obj["GRR / GPR"] === undefined) {
  //           return null;
  //         }
  //         return obj["GRR / GPR"];
  //       })
  //       .filter((item: null) => item !== null)
  //   )
  // ).map((item) => ({
  //   value: item,
  //   label: item,
  // }));

  const grrList = Array.from(
    new Set(
      dataSource
        .map((obj: any) => {
          if (!obj.incoming) {
            return null;
          } else if (
            obj["GRR / GPR"] === null ||
            obj["GRR / GPR"] === undefined
          ) {
            return null;
          }
          return obj["GRR / GPR"];
        })
        .filter((item: null) => item !== null)
    )
  ).map((item) => ({
    value: item,
    label: item,
  }));

  const gprList = Array.from(
    new Set(
      dataSource
        .map((obj: any) => {
          if (obj.incoming) {
            return null;
          } else if (
            obj["GRR / GPR"] === null ||
            obj["GRR / GPR"] === undefined
          ) {
            return null;
          }
          return obj["GRR / GPR"];
        })
        .filter((item: null) => item !== null)
    )
  ).map((item) => ({
    value: item,
    label: item,
  }));

  const receivingBankList = Array.from(
    new Set(
      allData
        .map((obj: any) => {
          if (obj?.receivingBank === null || obj?.receivingBank === undefined) {
            return null;
          }
          return obj.receivingBank;
        })
        .filter((receivingBank: null) => receivingBank !== null)
    )
  ).map((receivingBank) => ({
    value: receivingBank,
    label: receivingBank,
  }));

  const receivingAccountList = Array.from(
    new Set(allData.map((obj: any) => obj.receivingAccount))
  ).map((receivingAccount) => ({
    value: receivingAccount,
    label: receivingAccount,
  }));

  const serviceList = Array.from(
    new Set(
      allData
        .map((obj: any) => {
          if (obj?.service === null || obj?.service === undefined) {
            return null;
          }
          return obj.service;
        })
        .filter((service: null) => service !== null)
    )
  ).map((service) => ({
    value: service,
    label: service,
  }));

  const fundingBankList = Array.from(
    new Set(
      allData
        .map((obj: any) => {
          if (obj?.fundingBank === null || obj?.fundingBank === undefined) {
            return null;
          }
          return obj.fundingBank;
        })
        .filter((fundingBank: null) => fundingBank !== null)
    )
  ).map((fundingBank) => ({
    value: fundingBank,
    label: fundingBank,
  }));

  const fundingAccountList = Array.from(
    new Set(
      allData
        .map((obj: any) => {
          if (obj?.fundingAccount === null) {
            return;
          }
          return obj.fundingAccount;
        })
        .filter((fundingAccount: null) => fundingAccount !== null)
    )
  ).map((fundingAccount) => ({
    value: fundingAccount,
    label: fundingAccount,
  }));

  const MDAName = Array.from(new Set(allMdaData?.map((obj: any) => obj))).map(
    (obj: any) => ({
      value: obj?.id, //id
      label: obj?.registeredName,
    })
  );

  const [account, setAccount] = useState<any>("");

  const [reference, setReference] = useState<any>("");
  const [grr, setGrr] = useState<string>("");
  const [amount, setAmount] = useState<any>("");
  const [fundingAccount, setFundingAccount] = useState<any>("");
  const [fundingBank, setFundingBank] = useState<any>("");
  const [serviceFilter, setServiceFilter] = useState<any>("");
  const [receivingBank, setReceivingBank] = useState<any>("");
  const [receivingAccount, setReceivingAccount] = useState<any>("");
  const [orgId, setOrgId] = useState<string>("");
  const [currency, setCurrency] = useState<string>("");
  const [refreshFilter, setRefreshFilter] = useState<boolean>(false);
  const [hasBnFiltered, setHasBnFiltered] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");

  const searchFilter = `size=${filterSize}&org-id=${orgId}&src-acct-num=${fundingAccount}&src-bank-code=${fundingBank}&dest-acct-num=${receivingAccount}&dest-bank-code=${receivingBank}&payment-ref=${reference}&service=${serviceFilter}&invoice-number=${grr}`;

  const fetchFilter = () => {
    mutate(searchFilter, {
      onSuccess: (response: any) => {
        setHasBnFiltered(true);
        setRefreshFilter(false);
      },
    });
  };

  const updateFilter = (value: string, fn: any) => {
    fn(value);
    if (value.length === 0 && !refreshFilter && hasBnFiltered) {
      setRefreshFilter(true);
      setHasBnFiltered(false);
    } else {
      setRefreshFilter(false);
      setHasBnFiltered(false);
    }
  };

  useEffect(() => {
    if (refreshFilter) {
      fetchFilter();
    }
  }, [refreshFilter]);

  const resetFilter = () => {
    let filter = `size=${filterSize}`;
    mutate(filter, {
      onSuccess: (response: any) => {
        setAccount("");
        setServiceFilter("");
        setReference("");
        setGrr("");
        setAmount("");
        setFundingAccount("");
        setFundingBank("");
        setServiceFilter("");
        setReceivingBank("");
        setReceivingAccount("");
        setCurrency("");
      },
    });
  };

  const handleDateRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      setDateRange(dates);
      var date1 = Moment(new Date(dates[0]).toISOString()).format("YYYY-MM-DD");
      setStartDate(date1);
      var date2 = Moment(new Date(dates[1]).toISOString()).format("YYYY-MM-DD");

      setEndDate(date2);
    } else {
      setDateRange([]);
    }
  };
  return (
    <>
      <>
        {isLoading || allMdaLoading ? (
          <div className="loading-container">
            <FlapperSpinner />
          </div>
        ) : (
          <div className={"mdaBalanceSheetPage"}>
            <div className={"mdaBalanceSheetRow1"}>
              <div className={"mdaBalSheetBreadcrumb"}>
                <div>Reports</div>
                <svg
                  width="10"
                  height="13"
                  viewBox="0 0 10 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                    fill="#1F8505"
                  />
                </svg>
                <div>Net Cash</div>
              </div>

              <div>
                <Select
                  dropdownMatchSelectWidth={false}
                  defaultValue="Last 30 Days"
                  style={{ width: 162 }}
                  options={[
                    { value: "last60Days", label: "Last 60 Days" },
                    { value: "last90Days", label: "Last 90 Days" },
                  ]}
                />

                {/*<div>*/}
                {/*    <Dropdown>*/}
                {/*        <Button*/}
                {/*            type="ghost"*/}
                {/*            className="dashboard-over-btn"*/}
                {/*            size="large"*/}
                {/*        >*/}
                {/*            <div className="btn-content-overview">*/}
                {/*                <div className="btn-text-overview"> Last 30 Days</div>*/}
                {/*                <div>*/}
                {/*                    {' '}*/}
                {/*                    <DownOutlined />*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </Button>*/}
                {/*    </Dropdown>*/}
                {/*</div>*/}

                <div>
                  <span className="data-range-text-overview">Date Range:</span>
                  <span className="data-from-text-overview">From</span>
                  <Space direction="vertical" size={12}>
                    <RangePicker
                      className="py-1 fs-8 fw-bold"
                      value={dateRange}
                      onChange={handleDateRangeChange}
                      allowClear
                      size={"small"}
                    />
                  </Space>

                  {/* <DatePicker
                  defaultValue={dayjs("2015/01/01", dateFormat)}
                  format={dateFormat}
                  className="datepicker"
                />
                <span className="data-from-text-overview">To</span>
                <DatePicker
                  defaultValue={dayjs("2015/01/01", dateFormat)}
                  format={dateFormat}
                  className="datepicker"
                />{" "} */}
                </div>
              </div>
            </div>

            <div className={"mdaBalanceSheetRow2"}>
              <div className={"openingBalCard"}>
                <div>
                  <svg
                    width="16"
                    height="10"
                    viewBox="0 0 16 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 9L15 5.5M5 9L1 5.5M11 1L15 4.5M5 1L1 4.5M1.5 1H14.5C14.7761 1 15 1.22386 15 1.5V8.5C15 8.77614 14.7761 9 14.5 9H1.5C1.22386 9 1 8.77614 1 8.5V1.5C1 1.22386 1.22386 1 1.5 1ZM10 5C10 6.10457 9.10457 7 8 7C6.89543 7 6 6.10457 6 5C6 3.89543 6.89543 3 8 3C9.10457 3 10 3.89543 10 5Z"
                      stroke="#EE151D"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className={"mdaBalSheetRow2-cardTitle"}>
                  Opening Balance
                </div>
                <div className={"mdaBalSheetRow2-cardValue"}>
                  N10,650,340.30
                </div>
              </div>

              <div className={"totalInflowCard"}>
                <div>
                  <svg
                    width="16"
                    height="10"
                    viewBox="0 0 16 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 9L15 5.5M5 9L1 5.5M11 1L15 4.5M5 1L1 4.5M1.5 1H14.5C14.7761 1 15 1.22386 15 1.5V8.5C15 8.77614 14.7761 9 14.5 9H1.5C1.22386 9 1 8.77614 1 8.5V1.5C1 1.22386 1.22386 1 1.5 1ZM10 5C10 6.10457 9.10457 7 8 7C6.89543 7 6 6.10457 6 5C6 3.89543 6.89543 3 8 3C9.10457 3 10 3.89543 10 5Z"
                      stroke="#1F8505"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className={"mdaBalSheetRow2-cardTitle"}>Total Inflow</div>
                <div className={"mdaBalSheetRow2-cardValue"}>
                  N10,650,340.30
                </div>
              </div>

              <div className={"totalOutflowCard"}>
                <div>
                  <svg
                    width="16"
                    height="10"
                    viewBox="0 0 16 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 9L15 5.5M5 9L1 5.5M11 1L15 4.5M5 1L1 4.5M1.5 1H14.5C14.7761 1 15 1.22386 15 1.5V8.5C15 8.77614 14.7761 9 14.5 9H1.5C1.22386 9 1 8.77614 1 8.5V1.5C1 1.22386 1.22386 1 1.5 1ZM10 5C10 6.10457 9.10457 7 8 7C6.89543 7 6 6.10457 6 5C6 3.89543 6.89543 3 8 3C9.10457 3 10 3.89543 10 5Z"
                      stroke="#FBCB38"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className={"mdaBalSheetRow2-cardTitle"}>Total Outflow</div>
                <div className={"mdaBalSheetRow2-cardValue"}>
                  N10,650,340.30
                </div>
              </div>

              <div className={"closingBalCard"}>
                <div>
                  <svg
                    width="16"
                    height="10"
                    viewBox="0 0 16 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 9L15 5.5M5 9L1 5.5M11 1L15 4.5M5 1L1 4.5M1.5 1H14.5C14.7761 1 15 1.22386 15 1.5V8.5C15 8.77614 14.7761 9 14.5 9H1.5C1.22386 9 1 8.77614 1 8.5V1.5C1 1.22386 1.22386 1 1.5 1ZM10 5C10 6.10457 9.10457 7 8 7C6.89543 7 6 6.10457 6 5C6 3.89543 6.89543 3 8 3C9.10457 3 10 3.89543 10 5Z"
                      stroke="#3EC5E3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className={"mdaBalSheetRow2-cardTitle"}>
                  Closing Balance
                </div>
                <div className={"mdaBalSheetRow2-cardValue"}>
                  N10,650,340.30
                </div>
              </div>
            </div>

            <div className={"mdaBalanceSheetRow3"}>
              <div>
                <span className="text-capitalize">
                  {lazydayUser?.registeredName}
                </span>
              </div>

              <div>
                <div>
                  <div>Report Date & Time</div>
                  <div>
                    <span>{FormatDate()}</span>
                  </div>
                </div>

                <div>
                  <div>Administrative Code</div>
                  <div>
                    <span>{lazydayUser?.mdaAdminCode}</span>
                  </div>
                </div>

                {/*<div>*/}
                {/*  <div>MDA Email</div>*/}
                {/*  <div>*/}
                {/*    <span>{lazydayUser?.contact?.email} </span>*/}
                {/*  </div>*/}
                {/*</div>*/}

                {/*<div>*/}
                {/*  <div>MDA Phone number</div>*/}
                {/*  <div>*/}
                {/*    <span>{lazydayUser?.contact?.phoneNumber}</span>*/}
                {/*  </div>*/}
                {/*</div>*/}
              </div>
            </div>

            <div className={"mdaBalanceSheetRow4"}>
              <div>
                <Download
                  dataSourceForExcel={dataSourceForExcel}
                  dataSource={dataSource}
                  excelFileTitle={
                    lazydayUser?.registeredName + " - OAGF Net Cash Report"
                  }
                  pdfFileTitle={
                    lazydayUser?.registeredName + " - OAGF Net Cash Report"
                  }
                  excelPageTitle={
                    lazydayUser?.registeredName + " - OAGF Net Cash Report"
                  }
                  grandTotalFor={{
                    receiptsPayments: "Inflow(CR)/Outflow(DR)",
                    balance: "Balance",
                  }}
                />
              </div>

              <div>Filter by:</div>

              <div className={"mdaBalanceSheetSelectOptions"}>

                <DropDown
                  mutate={mdaMutate}
                  value={orgId}
                  labelKey="registeredName"
                  valueKey="id"
                  apiData={mdaNameList}
                  onChange={(e) => updateFilter(e, setOrgId)}
                  width={250}
                  placeholder="Search MDA Name"
                  valueStyles={{
                    paddingLeft: "0.5rem",
                  }}
                  controlStyles={{
                    paddingLeft: "0.5rem",
                    paddingRight: "0.5rem",
                    height: 32,
                    display: "flex",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                  height={30}
                  loading={allMdaLoading}
                />

                <Select
                  dropdownMatchSelectWidth={false}
                  style={{ minWidth: 130 }}
                  options={[
                    { value: "", label: "Government Revenue Reference (GRR)" },
                    ...grrList,
                  ]}
                  showSearch
                  onChange={(e) => updateFilter(e, setGrr)}
                  value={grr}
                />

                <Select
                  dropdownMatchSelectWidth={false}
                  style={{ minWidth: 130 }}
                  options={[
                    { value: "", label: "Government Payment Reference (GPR)" },
                    ...gprList,
                  ]}
                  showSearch
                  onChange={(e) => updateFilter(e, setReference)}
                  value={reference}
                />

                {/* <Select
                dropdownMatchSelectWidth={false}
                  defaultValue="Amount Range"
                  //style={{ minWidth: 134 }}
                  options={[
                    { value: 10000, label: "100000 - 20000" },
                    { value: 30000, label: "300000 - 40000" },
                  ]}
                  onChange={(e) => setAmount(e)}
                /> */}

                <Select
                  dropdownMatchSelectWidth={false}
                  style={{ minWidth: 100 }}
                  options={[
                    { value: "", label: "Receiving Bank" },
                    ...receivingBankList,
                  ]}
                  onChange={(e) => updateFilter(e, setReceivingBank)}
                  showSearch
                  value={receivingBank}
                />

                <Select
                  dropdownMatchSelectWidth={false}
                  style={{ minWidth: 100 }}
                  options={[
                    { value: "", label: "Receiving Account" },
                    ...receivingAccountList,
                  ]}
                  onChange={(e) => updateFilter(e, setReceivingAccount)}
                  showSearch
                  value={receivingAccount}
                />

                <Select
                  dropdownMatchSelectWidth={false}
                  style={{ minWidth: 100 }}
                  options={[
                    { value: "", label: "Funding Bank" },
                    ...fundingBankList,
                  ]}
                  onChange={(e) => updateFilter(e, setFundingBank)}
                  showSearch
                  value={fundingBank}
                />

                <Select
                  dropdownMatchSelectWidth={false}
                  defaultValue="Funding Account"
                  style={{ minWidth: 100 }}
                  options={[
                    { value: "", label: "Funding Account" },
                    ...fundingAccountList,
                  ]}
                  onChange={(e) => updateFilter(e, setFundingAccount)}
                  showSearch
                  value={fundingAccount}
                />

                <Select
                  dropdownMatchSelectWidth={false}
                  style={{ minWidth: 100 }}
                  options={[{ value: "", label: "Service" }, ...serviceList]}
                  showSearch
                  value={serviceFilter}
                  onChange={(e) => updateFilter(e, setServiceFilter)}
                />

                <Select
                  dropdownMatchSelectWidth={false}
                  style={{ minWidth: 100 }}
                  options={[
                    { value: "", label: "Currency" },
                    { value: "NGN", label: "NGN" },
                  ]}
                  value={currency}
                  onChange={(e) => updateFilter(e, setCurrency)}
                />

                <button onClick={fetchFilter}>
                  <span>Apply Filter</span>
                  <img src={filter} alt=" " />
                </button>

                <div onClick={resetFilter} className="cursor-pointer">
                  <span className="fs-9 text-primary fw-bold">
                    Reset Filter
                  </span>
                </div>
              </div>

              <div style={{ overflowX: "auto" }}>
                <Table
                  dataSource={dataSource}
                  bordered
                  columns={columns}
                  scroll={{ x: "2500px" }}
                />
              </div>
            </div>
          </div>
        )}
      </>
    </>
  );
};

export default OagfBalanceSheet;
