import { components } from 'react-select';
import { clipboard, search } from 'core/assets/icons';
import { arrowDown, userCircle } from 'core/assets/imgs';

export const ValueContainer = (props) => {
    return (
        components.ValueContainer && (
            <components.ValueContainer {...props}>
                {!!props.children && (
                    <img src={clipboard} alt="" style={{ position: 'absolute', left: 1 }} className="icon-style" />
                )}
                {props.children}
            </components.ValueContainer>
        )
    );
};

export const MdaValueContainer = (props) => {
    return (
        components.ValueContainer && (
            <components.ValueContainer {...props}>
                {!!props.children && (
                    <img src={userCircle} alt="" style={{ position: 'absolute', left: 1 }} className="icon-style" />
                )}
                {props.children}
            </components.ValueContainer>
        )
    );
};
export const MdaValueNoIconContainer = (props) => {
    return (
        components.ValueContainer && <components.ValueContainer {...props}>{props.children}</components.ValueContainer>
    );
};
export const DropdownIndicator = (props) => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                {/* <i className="fa fa-search" aria-hidden="true" /> */}
                <img src={arrowDown} alt="" className="w-1r h-1r" />
            </components.DropdownIndicator>
        )
    );
};
export const searchIndicator = (props) => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <img src={search} alt="" className="w-1r h-1r me-5" />
            </components.DropdownIndicator>
        )
    );
};
export const getOptionLabel = (option: any, isMenuOpen: boolean) => {
    const combinedDisplayValue = `${option.name}${
        Number(option.amount) > 0 && isMenuOpen ? ` - ₦${Number(option.amount).toLocaleString()}` : ''
    }`;
    return combinedDisplayValue;
};

export const handlePartValueChange = (values, setPartAmount) => {
    const { floatValue } = values;
    setPartAmount(floatValue);
};

export const isAllowedNumeric = (values: any, maxAmount: number) => {
    const { floatValue, formattedValue } = values;

    if (floatValue == null) {
        return formattedValue === '';
    } else {
        return floatValue <= Number(maxAmount);
    }
};
export const onChangeCustomFields = (
    field: {
        customFieldType: { type: string; code: any };
        limit: any;
        code: any;
    },
    e: React.ChangeEvent<HTMLInputElement>,
    setCustomFields: any,
    customFields: any,
) => {
    const alphaRegex = /^[a-zA-Z]+$/;
    const alphanumericRegex = /^[a-zA-Z0-9]+$/;

    if (field.customFieldType?.type === 'alphaNumeric') {
        if (e.target.value.length === 0) {
            setCustomFields({
                ...customFields,
                [field?.code]: e.target.value,
            });
        } else {
            if (alphanumericRegex.test(e.target.value)) {
                setCustomFields({
                    ...customFields,
                    [field?.code]: e.target.value,
                });
            }
        }
    } else if (field.customFieldType?.type === 'alphabet') {
        if (e.target.value.length === 0) {
            setCustomFields({
                ...customFields,
                [field?.code]: e.target.value,
            });
        } else {
            if (alphaRegex.test(e.target.value)) {
                setCustomFields({
                    ...customFields,
                    [field?.code]: e.target.value,
                });
            }
        }
    } else if (field.customFieldType?.type === 'numeric') {
        const limitedValue = e.target.value.slice(0, Number(field?.limit));
        setCustomFields({
            ...customFields,
            [field?.code]: limitedValue,
        });
    } else {
        setCustomFields({
            ...customFields,
            [field?.code]: e.target.value,
        });
    }
};

export const getOptions = (option: any) => {
    const value = `${option.label}`;
    return value;
};
