import { useRequestReviewed } from "app/pages/payments/hooks/useRevenuePayment";
import { close_red, infoBlue } from "core/assets/icons";
import { checkCircle } from "core/assets/imgs";
import { FetchAndUnmask, LoaderContainer, isEmpty } from "core/helpers";
import { ScrollUp } from "core/helpers/ScrollTop";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import OTPInput from "react-otp-input";
import { useLocation, useNavigate } from "react-router-dom";
import { MetroSpinner } from "react-spinners-kit";
import * as Yup from "yup";
import "../../../../../App.css";
import {
  useGetLedgerAccount,
  useGetUserBankDetails,
  useRequestOnboardingSendVerificatoinCode,
  // useServiceDetails,
  // useUpdateService,
  useVerificationSinglecode,
} from "../../../auth/hooks/useUser";
import { PreviewServiceDetails } from "./component/PreviewServiceDetails";
import "./service.scss";

const initialValues = {
  finalApprover: false,
  label: "",
  serviceName: "",
  collectionAccount: "",
  type: "",
  paymentEmail: "",
  glAccountName: "",
  revenueCode: "",
};

export const ReviewerPreviewService: React.FC = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const serviceDetails = location?.state?.serviceDetails;

  const [step, setStep] = useState<number>(2);
  const [isMoreInfo, setMoreInfo] = useState<any>();
  const [isIntegrated, setIntegrated] = useState<any>();
  const [fields, setFields] = useState<any>({
    integration: "",
    revenueCode: "",
    amount: "",
    vat: true,
    processingFee: true,
    installmental: false,
    collectionAccount: false,
    // authorizeDebit: false,
    // beneficiaryVatPay: false,
    beneficiaryFeePay: true,
    websiteUrl: "",
    notificationUrl: "",
    multipleSelect: false,
    multipleFixedAmount: false,
  });

  const [showConfirm, setConfirm] = useState<any>(false);
  const [modalStep, SetModalStep] = useState<any>(1);
  const containerRef = useRef<HTMLDivElement>(null);

  const orgUser: any | null = FetchAndUnmask("lazyday_user");
  const orgUserParser = JSON.parse(orgUser);

  const Schema = Yup.object().shape({
    finalApprover: Yup.boolean(),
    label: Yup.string().required("Service Name / Label is required"),
    paymentEmail: Yup.string()
      .required("Payment Notification Email is required")
      .email("Payment notification email must be a valid mail address"),
    collectionAccount: Yup.string().required("Collection account is required"),
    type: Yup.string().required("List Type is required"),
    glAccountName: Yup.string().required("GL account is required"),
    revenueCode: Yup.string().when("finalApprover", {
      is: true,
      then: Yup.string().min(13).max(50).required("Revenue Code is required"),
      otherwise: Yup.string().notRequired(),
    }),
  });

  useEffect(() => {
    ScrollUp(containerRef.current);
  }, [step]);

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [step]);

  const {
    data: bankPayment,
    mutate: bankMutate,
    isLoading: bankListLoading,
  } = useGetUserBankDetails();

  const {
    //data: glAccountList,
    mutate: glMutate,
    isLoading: glLoading,
  } = useGetLedgerAccount();

  // const {
  //   data: editServiceResponse,
  //   mutate: editServiceMutate,
  //   isLoading: editServiceLoading,
  // } = useServiceDetails();

  // const {
  //   data: updateServiceResponse,
  //   mutate: updateServiceMutate,
  //   isLoading: updateServiceLoading,
  // } = useUpdateService();

  const [count, setCount] = useState<any>(0);
  const [inputs, setInputs] = useState<any>([]);

  const [metadata, setMetaData] = useState<any>([]);

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: async (values) => {},
  });

  const [approve, setApproval] = useState<boolean | null>(null);

  const formatRevenueCode = (value) => {
    // Remove non-alphanumeric characters
    const alphanumericValue = value.replace(/[^a-zA-Z0-9]/g, "");
    // Extract admin code
    const adminCode = alphanumericValue.substring(0, 13);
    // Extract category and product
    let category = "";
    let product = "";
    if (alphanumericValue.length > 13) {
      category = `-${alphanumericValue.substring(13, 18)}`;
    }
    if (alphanumericValue.length > 17) {
      product = `-${alphanumericValue.substring(18, 23)}`;
    }
    // Build the formatted revenue code
    return `${adminCode}${category}${product}`;
  };

  const handleRevenueCodeChange = (e) => {
    const inputValue = e.target.value.toUpperCase(); // Ensure uppercase
    const formattedValue = formatRevenueCode(inputValue);
    setRevenueCode(formattedValue);
  };

  const [showAddRev, setShowAddRev] = useState<boolean>(false);

  const confirmModal = () => {
    return (
      <Modal
        dialogClassName="  m-auto px-5 modal-card"
        aria-hidden="true"
        show={showConfirm}
        onHide={() => setConfirm(false)}
        animation
        centered
        size="lg"
        backdrop={"static"}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="rounded">
          <>
            {modalStep === 1 &&
              (isEmpty(serviceDetails.content.data.revCode) &&
              serviceDetails.awaitingGroup === "collections#approver" &&
              showAddRev ? (
                <>
                  <div className="mt-1">
                    <div className="col-form-label input-height d-flex justify-content-center">
                      <span className=" fw-bolder fs-8e text-dark">
                        Revenue Code
                      </span>
                    </div>
                    <div className={`form-control form-control-solid`}>
                      <input
                        type="text"
                        placeholder="Please enter revenue code"
                        className={"no-outline ps-0 text-dark fs-8"}
                        value={revenueCode}
                        onChange={handleRevenueCodeChange}
                        maxLength={50}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-around my-5">
                    <button
                      id="kt_password_cancel "
                      type="button"
                      className=" btn btn-white px-7 py-2 h-44 w-110 rounded-1 border-success "
                      onClick={() => setConfirm(false)}
                    >
                      <span className="indicator-label fs-base text-success ">
                        Back
                      </span>
                    </button>

                    <button
                      type="button"
                      className={` btn ${
                        revenueCode.length < 16
                          ? "btn-secondary"
                          : "btn-success"
                      } h-44 w-110 rounded-1  center-all  `}
                      onClick={() => setShowAddRev(false)}
                      disabled={revenueCode.length < 16}
                    >
                      <span className="indicator-label fs-base text-white ">
                        Proceed
                      </span>
                    </button>
                  </div>
                </>
              ) : (
                <div className="m-3 mx-15">
                  <div className=" d-flex flex-column align-items-center ">
                    <img src={infoBlue} alt="" className="w-50px h-50px my-5" />

                    <span className="text-center fs-base text-dark">
                      Are you sure you want to approve this request
                    </span>
                  </div>
                  <div className="d-flex justify-content-between mt-10">
                    <button
                      id="kt_password_cancel "
                      type="button"
                      className="btn btn-danger px-7 py-2 text-alt-red h-44 w-110 center-all "
                      onClick={() => {
                        setConfirm(false);
                      }}
                    >
                      <span className="indicator-label fs-base ">Cancel</span>
                    </button>
                    <button
                      className=" btn btn-success h-44 w-110 center-all"
                      type="button"
                      onClick={() =>
                        review(serviceDetails.id, serviceDetails.toApproval)
                      }
                    >
                      <span className="indicator-label fs-base ">Submit</span>
                    </button>
                  </div>
                </div>
              ))}

            {modalStep === 2 && (
              <div className="m-2 mx-10">
                <div className=" d-flex flex-column align-items-center ">
                  <img
                    src={checkCircle}
                    alt=""
                    className="w-50px h-50px my-5"
                  />

                  <span className="fw-bolder fs-5">Approved</span>

                  <span className="text-center fs-8 text-muted my-2">
                    Your request has been approved
                  </span>
                </div>
                <div className="d-flex center-all mt-5">
                  <button
                    className=" btn btn-success h-44 w-110 center-all rounded-1"
                    type="button"
                    onClick={() => {
                      navigate("/admin");
                      setConfirm(false);
                    }}
                  >
                    <span className="indicator-label fs-base ">Done</span>
                  </button>
                </div>
              </div>
            )}
          </>
        </Modal.Body>
      </Modal>
    );
  };

  const otpModal = () => {
    return (
      <Modal
        dialogClassName=" m-auto px-7 modal-card-signup"
        aria-hidden="true"
        show={showOtp}
        onHide={() => setShowOtp(false)}
        animation
        centered
        size="lg"
        // backdrop={"static"}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="rounded">
          <div className="">
            <div className=" d-flex flex-column align-items-center ">
              <div className="mt-5">
                <svg
                  width="21"
                  height="19"
                  viewBox="0 0 21 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.5 7V16.75C1.5 16.9489 1.57902 17.1397 1.71967 17.2803C1.86032 17.421 2.05109 17.5 2.25 17.5H18.75C18.9489 17.5 19.1397 17.421 19.2803 17.2803C19.421 17.1397 19.5 16.9489 19.5 16.75V7M1.5 7L10.5 1L19.5 7M1.5 7L8.86363 12.25M19.5 7L12.1363 12.25M8.86363 12.25L1.73126 17.2881M8.86363 12.25L12.1363 12.25M19.2688 17.2881L12.1363 12.25"
                    stroke="#FBCB38"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>

              <div className="email-verification-container">
                <span className="email-verification-text">
                  Email Verification
                </span>
              </div>

              <div className="email-verification-container">
                <div className="email-verification-body">
                  <div>Please enter the 6-digit code sent to</div>
                  <div> {orgUserParser?.organizationUser.email} </div>
                </div>
              </div>

              <OTPInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderInput={(props) => <input {...props} />}
                containerStyle={"otp-box mt-5"}
                inputStyle={"w-100"}
                placeholder="------"
              />

              <div className="email-verification-container mt-2">
                <span className="resend-code-text" onClick={sendVerification}>
                  Resend Code
                </span>
              </div>
            </div>

            <div className=" d-flex flex-column align-items-center mt-5 ">
              <button
                className={`otp-button center-all ${
                  otp.length !== 6 ? "btn bg-gray-500" : ""
                } `}
                onClick={() => submitVerification(serviceDetails.id)}
                disabled={
                  otp.length !== 6
                    ? true
                    : isVerfyLoading || loadingVerify
                    ? true
                    : false
                }
              >
                {isVerfyLoading || loadingVerify ? (
                  <span className="otp-button-text">Loading..</span>
                ) : (
                  <span className="otp-button-text">Verify</span>
                )}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const [inputsAmount, setInputsAmount] = useState<any>([
    {
      id: 1,
      itemName: "",
      amount: null,
      collectionAccount: "",
      code: "",
    },
  ]);

  const [showRejection, setRejection] = useState<boolean>(false);
  const [reasonForReject, SetReasonForReject] = useState<string>("");
  const [showOtp, setShowOtp] = useState<boolean>(false);
  const [otp, setOtp] = useState("");

  const [wModal, setWModal] = useState<number>(1);

  const [rejectId, setRejectId] = useState<any>("");
  const [showReject, setShowReject] = useState<boolean>(false);
  const [revenueCode, setRevenueCode] = useState<string>("");

  const {
    //data: sendVerifyData,
    mutate: sendVerifyMutate,
    isLoading: isVerfyLoading,
  } = useRequestOnboardingSendVerificatoinCode();

  const {
    //data: verifyCode,
    mutate: mutateVerify,
    isLoading: loadingVerify,
  } = useVerificationSinglecode();
  const {
    // data: reviewData,
    mutate: mutateReview,
    //isLoading: isLoadingReview,
  } = useRequestReviewed();

  const sendVerification = () => {
    sendVerifyMutate(orgUserParser?.organizationUser.email, {
      onSuccess: (res: any) => {
        setShowOtp(true);
        setConfirm(false);
      },
    });
  };

  const review = (id: any, toApproval) => {
    // toApproval.awaitingGroup === "collections#approver" &&
    // authorities.oagf

    setWModal(1);
    setApproval(false);
    setLoader(true);
    sendVerification();
  };

  const cancelRejectModal = () => {
    setRejection(false);
    SetReasonForReject("");
    setRejectId("");
    setWModal(1);
    navigate("/admin");
  };

  const ShowRejectModal = (id: any) => {
    setRejection(true);
    setShowOtp(false);
    setRejectId(id);
    setWModal(1);
  };

  const [loader, setLoader] = useState<boolean>(false);

  const RejectionModal = () => {
    return (
      <Modal
        dialogClassName="  m-auto px-5 modal-card"
        aria-hidden="true"
        show={showRejection}
        onHide={() => cancelRejectModal()}
        animation
        centered
        size="lg"
        backdrop={"static"}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="rounded">
          <div className="m-6 mx-5">
            {wModal === 1 ? (
              <>
                <div
                  className="d-flex justify-content-end cursor-pointer"
                  onClick={() => cancelRejectModal()}
                >
                  <img src={close_red} alt="" className="w-24px h-24px" />
                </div>
                <div className="mt-1">
                  <div className="col-form-label input-height d-flex justify-content-start">
                    <span className=" fw-bolder fs-8e text-dark">
                      Reason(s) for rejection
                    </span>
                  </div>
                  <div className={``}>
                    <span className="required fs-9 text-gray-500">Message</span>
                    <textarea
                      placeholder=""
                      className={
                        "text-gray-500 fs-8 form-control form-control-solid"
                      }
                      value={reasonForReject}
                      rows={5}
                      onChange={(e) => SetReasonForReject(e.target.value)}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-end my-5">
                  <button
                    type="button"
                    className={` btn ${
                      reasonForReject.trim().length < 5
                        ? "btn-secondary"
                        : "btn-success"
                    } h-35px w-100px rounded-1  center-all  `}
                    onClick={() => setWModal(2)}
                    disabled={reasonForReject.trim().length < 5}
                  >
                    <span className="indicator-label fs-10 text-white ">
                      Submit
                    </span>
                  </button>
                </div>
              </>
            ) : (
              <>
                {loader ? (
                  <div className="d-flex justify-content-center align-items-center my-10">
                    <MetroSpinner color="#1f8505" />
                  </div>
                ) : (
                  <div className="">
                    <div className=" d-flex flex-column align-items-center ">
                      <img
                        src={infoBlue}
                        alt=""
                        className="w-50px h-50px my-5"
                      />

                      <span className="text-center fs-base text-dark">
                        Are you sure you want to reject this request
                      </span>
                    </div>
                    <div className="d-flex justify-content-around mt-10">
                      <button
                        id="kt_password_cancel "
                        type="button"
                        className="btn btn-danger px-7 py-2 text-alt-red h-44 w-110 center-all "
                        onClick={() => cancelRejectModal()}
                      >
                        <span className="indicator-label fs-base ">Cancel</span>
                      </button>
                      <button
                        className=" btn btn-success h-44 w-110 center-all"
                        type="button"
                        onClick={() => {
                          reject(rejectId);
                        }}
                      >
                        <span className="indicator-label fs-base ">Submit</span>
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const submitVerification = (id: string) => {
    const payload = {
      otp: otp,
    };

    mutateVerify(payload, {
      onSuccess: (res: any) => {
        setShowOtp(false);
        mutateReview(
          { id, type: "APPROVED" , revenueCode},
          {
            onSuccess: (res: any) => {
              SetModalStep(2);
              setConfirm(true);
            },
          }
        );
      },
    });
  };

  const reject = (id: any) => {
    setLoader(true);
    mutateReview(
      { id, type: "REJECTED", reason: reasonForReject },
      {
        onSuccess: (res: any) => {
          setLoader(false);
          setRejection(false);
          setShowReject(true);
        },
      }
    );
  };

  const OnSuccess = (response: any) => {
    if (response?.length === 0) {
      navigate("/admin");
    } else {
      formik.setFieldValue("label", response?.description);
      formik.setFieldValue("paymentEmail", response?.notificationEmail);
      formik.setFieldValue("glAccountName", response?.glAccountName ?? "");
      formik.setFieldValue(
        "finalApprover",
        serviceDetails?.awaitingGroup === "collections#approver" &&
          orgUserParser?.oagf
      );

      if (
        response?.notificationUrl?.length > 0 ||
        response?.websiteUrl?.length > 0
      ) {
        setIntegrated(true);
      }
      if (response?.paymentComponents?.length > 1) {
        let multiplePayment: any = [];
        formik.setFieldValue("type", "multiple");
        formik.setFieldValue("collectionAccount", "multiple");

        setFields({
          ...fields,
          revenueCode: response?.revCode,
          amount: "",
          vat: response.vatRequired,
          processingFee: true, //response?.payerToPay,
          installmental: response.installPayment,
          collectionAccount: false,
          beneficiaryFeePay: true, //response.payerToPay,
          websiteUrl: response.websiteUrl,
          notificationUrl: response.notificationUrl,
          multipleSelect: response.multiSelect,
          multipleFixedAmount: response?.fixedAmount,
        });
        response?.paymentComponents?.map(
          (
            item: {
              id: any;
              name: any;
              amount: any;
              destAccount: { accountNumber: any };
            },
            index: any
          ) =>
            multiplePayment.push({
              id: item?.id !== null ? item?.id : index + 1,
              itemName: item?.name,
              amount: item?.amount ? item.amount : null,
              collectionAccount: item?.destAccount?.accountNumber,
              code: "",
            })
        );

        setInputsAmount(multiplePayment);
      } else {
        formik.setFieldValue("type", "single");

        setFields({
          ...fields,
          revenueCode: response?.revCode,
          amount: response?.amount,
          vat: true, //response.vatRequired,
          processingFee: true, //response?.payerToPay,
          installmental: response.installPayment,
          collectionAccount: false,
          beneficiaryFeePay: true, //response.payerToPay,
          websiteUrl: response.websiteUrl,
          notificationUrl: response.notificationUrl,
        });

        setTimeout(() => {
          formik.setFieldValue(
            "collectionAccount",
            response?.paymentComponents[0]?.destAccount?.accountNumber
          );
        }, 2000);
      }

      if (response?.customFields?.length > 0) {
        setCount(response?.customFields?.length);
        setMoreInfo(true);
        let customFieldsList: any = [];

        response?.customFields?.map(
          (
            item: {
              id: any;
              label: any;
              customFieldType: { type: any; selectOptions: any };
              limit: any;
              code: any;
              optional: any;
            },
            index: number
          ) =>
            customFieldsList.push({
              id: item?.id ? item.id : index + 1,
              fieldName: item?.label,
              dataType: item?.customFieldType?.type,
              characterCount: item?.limit,
              code: item.code,
              optional: item?.optional ? item?.optional : false,
              options:
                item?.customFieldType?.selectOptions?.length > 0
                  ? item?.customFieldType?.selectOptions?.map(
                      (item: { description: any }, index: number) => {
                        var demo = {
                          id: index + 1,
                          value: item?.description,
                        };
                        return demo;
                      }
                    )
                  : [],
            })
        );
        setInputs(customFieldsList);
      }

      setShowAddRev(isEmpty(response?.revCode));
    }
  };

  useEffect(() => {
    bankMutate();
    glMutate("fed");

    if (isEmpty(serviceDetails?.content?.data)) {
      navigate("/admin");
    } else {
      OnSuccess(serviceDetails?.content?.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceDetails?.content?.data]);

  return (
    <>
      {
        // editServiceLoading ||
        // updateServiceLoading ||
        bankListLoading || glLoading ? (
          <LoaderContainer />
        ) : (
          <>
            <div className={"adminBody"} ref={containerRef}>
              <div className={"breadcrumb"}>
                <div onClick={() => navigate(-1)} className="cursor-pointer">
                  Admin
                </div>
                <svg
                  width="10"
                  height="13"
                  viewBox="0 0 10 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 1.5744L0 11.4256C0 11.8254 0.236844 12.192 0.613658 12.3774C0.991157 12.5628 1.44706 12.5363 1.79669 12.3087L9.55942 7.25661C9.83645 7.07665 10.0007 6.78017 10 6.46438C9.99858 6.14825 9.83207 5.85271 9.55431 5.6742L1.79158 0.68791C1.44044 0.462617 0.986688 0.438175 0.610557 0.623958C0.235339 0.809997 0 1.1757 0 1.5744Z"
                    fill="#1F8505"
                  />
                </svg>
                <div>Collection Setup</div>
              </div>

              <div>
                <div className="card  collection-card bg-white border-0 m-auto ">
                  {step === 2 && (
                    <PreviewServiceDetails
                      formik={formik}
                      fields={fields}
                      isMoreInfo={isMoreInfo}
                      forApproval
                      inputsAmount={inputsAmount}
                      inputs={inputs}
                      isIntegrated={isIntegrated}
                      setConfirm={setConfirm}
                      reset={() => {
                        //setStep(1);
                      }}
                      rejectApproval={() => {
                        ShowRejectModal(serviceDetails?.id);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            {confirmModal()}
            {otpModal()}
            {RejectionModal()}
          </>
        )
      }
    </>
  );
};
