import { Select, Table } from "antd";
import Moment from "moment";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import OTPInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { MetroSpinner } from "react-spinners-kit";
import "../../../App.css";
import { close_red, filter, infoBlue } from "core/assets/icons";
import { FetchAndUnmask, formatRequestEnums, isEmpty, isOagfAdmin } from "core/helpers";
import {
  useGetProcessTypes,
  useRequestOnboardingSendVerificatoinCode,
  useVerificationSinglecode,
} from "../auth/hooks/useUser";
import {
  useGetPaginatedAllMda,
  useGetAwaitingRequest,
  useRequestReviewed,
} from "../payments/hooks/useRevenuePayment";
import { ReviewModal } from "./Component/ReviewModal";
import { ReviewServiceModal } from "./Component/ReviewServiceModal";
import { ReviewUserCreationModal } from "./Component/ReviewUserCreationModal";
import { ViewProjectCreationModal } from "./Component/ViewProjectCreationModal";
import DropDown from "app/components/dropdown";

const ReviewerDashboard = (Data: any) => {
  const { data, mutate, isLoading } = useGetAwaitingRequest();

  const { orgName, setOrgName, payType, setPayType } = Data;

  const {
    data: reviewData,
    mutate: mutateReview,
    isLoading: isLoadingReview,
  } = useRequestReviewed();

  const {
    data: mdaNameList,
    mutate: mdaMutate,
    isLoading: allMdaLoading,
  } = useGetPaginatedAllMda();

  const {
    data: ProcessTypes,
    mutate: mutateProcessTypes,
    isLoading: ProcessTypesLoading,
  } = useGetProcessTypes();

  const orgUser: any | null = FetchAndUnmask("lazyday_user");
  const [reasonForReject, SetReasonForReject] = useState<string>("");

  const orgUserParser = JSON.parse(orgUser);

  const [modalData, setModalData] = useState<any>(null);

  const [serviceModalData, setServiceModalData] = useState<any>(null);
  const [userModalData, setUserModalData] = useState<any>(null);

  const [projectModalData, setProjectModalData] = useState<any>(null);

  const [refreshFilter, setRefreshFilter] = useState<boolean>(false);
  const [hasBnFiltered, setHasBnFiltered] = useState<boolean>(false);

  const viewReport = (data) => {
    navigate(`/admin/reviewer/request-awaiting-review/1`, { state: data });
  };

  const viewPensionReport = (data) => {
    navigate(`/admin/reviewer/pension/1`, { state: data });
  };

  const viewVATReport = (data) => {
    navigate(`/admin/reviewer/vat/1`, { state: data });
  };

  const setModal = (data: any) => {
    let dataLen = data?.data?.content?.data?.paymentInstructions?.length;

   //console.log(dataLen);

    if (dataLen === 1) {
     //console.log(data);

      setModalData(data);
      setShow(true);
    }

    if (
      dataLen > 1 &&
      data?.data?.content?.data.paymentInstructions["0"].pfaCode === null &&
      data?.data?.content?.data.paymentInstructions["0"].vat === null
    ) {
      viewReport(data);
    }

    if (
      dataLen > 1 &&
      data?.data?.content?.data.paymentInstructions["0"].pfaCode !== null
    ) {
      viewPensionReport(data);
    }

    if (
      dataLen > 1 &&
      data?.data?.content?.data.paymentInstructions["0"].vat !== null
    ) {
      viewVATReport(data);
    }

    // } else {
    //
    // }

    if (data?.processType === "Collections") {
      navigate(
        `/admin/service-details/${data?.data?.id}`,

        {
          state: {
            serviceDetails: data.data,
          },
        }
      );
      // setShowService(true);
      // setServiceModalData(data);
      // if (data.data.content.data?.revCode) {
      //   setRevenueCode(data.data.content.data.revCode);
      // } else {
      //   setRevenueCode("");
      // }
    }

    if (data?.processType === "User Administration") {
      // setModalData(data);

      setUserModalData(data);
      setShowUsersCreation(true);
    }

    if (data?.data?.type?.code === "mda_creation") {
      navigate(
        `/admin/mda-directory/mda-details/${data?.data?.fromOrganization}`,
        { state: data }
      );
    }

    if (data?.processType === "Project Creation") {
      setProjectModalData(data);
      setShowProjectCreation(true);
    }
  };

  const getCollectionRules = (rule: any) => {
    const hashIndex: number = rule?.indexOf("#");
    const processedRule: string =
      hashIndex !== -1 ? rule.substring(0, hashIndex) : rule;

    if (
      processedRule.startsWith("collections") ||
      processedRule.startsWith("payroll_payments") ||
      processedRule.startsWith("user_administration")
    ) {
      const formattedRule: string = processedRule
        .replace(/_/g, " ")
        .replace(/(?:^|\s)\S/g, (match: string) => match.toUpperCase());

      return formattedRule;
    }

    return "";
  };

  const [allData, setAllData] = useState<any>([]);

  const MDAName = Array.from(new Set(allData?.map((obj: any) => obj))).map(
    (obj: any) => ({
      value: obj?.id,
      label: obj?.registeredName,
    })
  );

  useEffect(() => {
    mutateProcessTypes();
  }, []);

  useEffect(() => {
    setAllData(mdaNameList?.data);
  }, [mdaNameList]);

  const updateFilter = (value: string, fn: any) => {
    fn(value);
    if (value?.length === 0 && !refreshFilter && hasBnFiltered) {
      setRefreshFilter(true);
      setHasBnFiltered(false);
    } else {
      setRefreshFilter(false);
      setHasBnFiltered(false);
    }
  };

  const resetFilter = () => {
    setOrgName("");
    setPayType("");
    Data.searchFilter("");    
  };

  const fetchFilter = () => {
    let searchFilter = "";
    if (payType) searchFilter += `processType=${payType}&`;
    if (orgName) searchFilter += `mdaName=${orgName}&`;

    // Remove the trailing '&' if the searchFilter is not empty
    if (searchFilter.endsWith("&")) {
      searchFilter = searchFilter.slice(0, -1);
    }

    Data.searchFilter(searchFilter);
  };

  const dataSource: any[] = [];

  // Check if Data.requestList is not empty and iterate over it
  if (!isEmpty(Data.requestList)) {
    for (let i = 0; i < Data.requestList.length; i++) {
      const requestData = Data.requestList[i];
      const paymentInstructions =
        requestData.content?.data?.paymentInstructions;
      const typeOfRequest = requestData.type.code;
      let processType = "";

     //console.log(typeOfRequest);

      // Determine process type based on type of request
      switch (typeOfRequest) {
        case "collections":
        case "user_administration":
          if (!isEmpty(requestData)) {
            processType = getCollectionRules(requestData.awaitingGroup);
          }
          break;
        case "mda_creation":
        case "project_creation":
          processType = requestData.type.description;
          break;
        case "payroll_payments":
        case "vendor_payments":
        case "retail_payments":
        case "retail_vendor_payments":
        case "pensionPayments":
          processType = paymentInstructions[0]?.paymentType?.description ?? "";
          break;
        default:
          processType = "";
          break;
      }

      let totalAmount = 0;
      for (let j = 0; j < paymentInstructions?.length; j++) {
        totalAmount += paymentInstructions[j]?.amount ?? 0;
      }

      const formattedAmount = `₦${totalAmount.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`;

      const paymentOption = (() => {
        if (typeOfRequest === "collections") {
          return requestData.content.data?.revCode
            ? "Service Update"
            : "Service Creation";
        }
        if (typeOfRequest === "mda_creation") {
          return "MDA Creation";
        } else if (paymentInstructions?.length === 1) {
          return "Single Payment";
        } else if (paymentInstructions?.length > 1) {
          return "Bulk Payment";
        } else if (typeOfRequest === "user_administration") {
          return "User Creation";
        } else if (typeOfRequest === "project_creation") {
          return "Project Creation";
        } else {
          return "";
        }
      })();

      const datad = {
        key: i.toString(),
        id: i + 1,
        processType: processType,
        batchId: requestData.content.data?.batchId || requestData?.id,
        amount: formattedAmount === "₦0.00" ? "N/A" : formattedAmount,
        paymentOption: paymentOption,
        date: Moment(requestData.creationDate).format("MMMM Do YYYY"),
        time: Moment(requestData.creationDate).format("h:mm a"),
        status: formatRequestEnums(requestData.status),
        data: requestData,
      };

      dataSource.push(datad);
    }
  }

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Process Type",
      dataIndex: "processType",
      key: "processType",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Batch ID",
      dataIndex: "batchId",
      key: "batchId",
    },
    {
      title: "Request Type",
      dataIndex: "paymentOption",
      key: "paymentOption",
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <button
          className={"rdActionButton"}
          onClick={() => {
            setModal(record);
          }}
        >
          View Details
        </button>
      ),
    },
  ];
  const navigate = useNavigate();

  const [show, setShow] = useState<boolean>(false);

  const [show2, setShow2] = useState<boolean>(false);

  const [showServices, setShowService] = useState<boolean>(false);

  const [showUsersCreation, setShowUsersCreation] = useState<boolean>(false);

  const [showProjectCreation, setShowProjectCreation] =
    useState<boolean>(false);

  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [showReject, setShowReject] = useState<boolean>(false);

  const [otp, setOtp] = useState("");
  const [sId, setSid] = useState("");

  //   const processTypeList = Array.from(
  //   new Set(
  //     ProcessTypes
  //       .map((obj: any) => {
  //        //console.log(obj);

  //         return obj;
  //       })

  //   )
  // ).map((processType) => ({
  //   value: processType,
  //   label: convertToFirstLetterCap(processType),
  // }));

  const processTypeList = Array.from(
    new Set(ProcessTypes?.map((obj: any) => obj))
  ).map((obj: any) => ({
    value: obj?.code,
    label: obj?.description,
  }));

  const {
    data: sendVerifyData,
    mutate: sendVerifyMuute,
    isLoading: isVerfyLoading,
  } = useRequestOnboardingSendVerificatoinCode();

  const sendVerification = () => {
    setLoader(true);
    sendVerifyMuute(orgUserParser?.organizationUser.email, {
      onSuccess: (res: any) => {
        setShow2(true);
        setConfirm(false);
        setShowService(false);
        setShow(false);
      },
      onSettled: () => {
        setLoader(false);
      },
    });
  };

  const {
    data: verifyCode,
    mutate: mutateVerify,
    isLoading: loadingVerify,
  } = useVerificationSinglecode();

  const submitVerification = (id) => {
    const payload = {
      otp: otp,
    };
    setLoader(true);
    mutateVerify(payload, {
      onSuccess: (res: any) => {
        setOtp("");
        mutateReview(
          { id, type: "APPROVED", revenueCode },
          {
            onSuccess: (res: any) => {
              setShow2(false);
              setLoader(false);
              setShowSProveduccess(true);
              setShowService(false);
              setShowUsersCreation(false);
              setUserModalData(null);
              setProjectModalData(null);
            },
            onSettled: () => {
              setLoader(false);
            },
          }
        );
      },
      onError: () => {
        setLoader(false);
      },
    });
  };

  // const review = (id: any) => {
  //     mutateReview({id, type: "APPROVED"}, {

  //         onSuccess: (res: any) => {
  //             setShow(false)

  //             setShowSuccess(true)
  //             mutate()

  //             },

  //     })
  // }


  const review = (id: any, toApproval) => {
    setSid(id);
    ////console.log(toApproval);

    if (toApproval.toApproval) {
      var x: any = FetchAndUnmask("lazyday_user");

      var authorities = x !== null ? JSON.parse(x) : null;

      //console.log("This is the new oagf flag hi\n", authorities.oagf);

      //console.log(authorities?.organizationUser?.authorities);

      // const isAdmin = hasRoleOAGFAdmin(
      //   authorities?.organizationUser?.authorities
      // );

      //console.log(isAdmin);

      if (
        toApproval.awaitingGroup === "collections#approver" &&
        authorities.oagf
      ) {
        setWModal(1);
        setConfirm(true);
        setShowService(false);
        setApproval(false);
      } else {
        setApproval(false);
        sendVerification();
      }
    } else {
      setLoader(true);
      setApproval(false);
      sendVerification();

      // mutateReview(
      //   { id, type: 'APPROVED' },
      //   {
      //     onSuccess: (res: any) => {
      //       setLoader(false);
      //       setApproval(false);
      //       setShow(false);
      //       setShowService(false);
      //       setShowSuccess(true);
      //     },
      //   }
      // );
    }
  };

  const [rejectid, setRejectid] = useState<any>("");
  const cancelRejectModal = () => {
    setRejection(false);
    SetReasonForReject("");
    setRejectid("");
    setWModal(1);
  };
  const ShowRejectModal = (id: any) => {
    setWModal(1);
    setRejection(true);
    setShowService(false);
    setShowUsersCreation(false);
    setShowProjectCreation(false);
    setShow(false);
    setRejectid(id);
  };

  const [loader, setLoader] = useState<boolean>(false);

  const RejectionModal = () => {
    return (
      <Modal
        dialogClassName="  m-auto px-5 modal-card"
        aria-hidden="true"
        show={showRejection}
        onHide={() => cancelRejectModal()}
        animation
        centered
        size="lg"
        backdrop={"static"}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="rounded">
          <div className="m-6 mx-5">
            {wModal === 1 ? (
              <>
                <div
                  className="d-flex justify-content-end cursor-pointer"
                  onClick={() => cancelRejectModal()}
                >
                  <img src={close_red} alt="" className="w-24px h-24px" />
                </div>
                <div className="mt-1">
                  <div className="col-form-label input-height d-flex justify-content-start">
                    <span className=" fw-bolder fs-8e text-dark">
                      Reason(s) for rejection
                    </span>
                  </div>
                  <div className={``}>
                    <span className="required fs-9 text-gray-500">Message</span>
                    <textarea
                      placeholder=""
                      className={
                        "text-gray-500 fs-8 form-control form-control-solid"
                      }
                      value={reasonForReject}
                      rows={5}
                      onChange={(e) => SetReasonForReject(e.target.value)}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-end my-5">
                  <button
                    type="button"
                    className={` btn ${
                      reasonForReject.trim().length < 5
                        ? "btn-secondary"
                        : "btn-success"
                    } h-35px w-100px rounded-1  center-all  `}
                    onClick={() => setWModal(2)}
                    disabled={reasonForReject.trim().length < 5}
                  >
                    <span className="indicator-label fs-10 text-white ">
                      Submit
                    </span>
                  </button>
                </div>
              </>
            ) : (
              <>
                {loader ? (
                  <div className="d-flex justify-content-center align-items-center my-10">
                    <MetroSpinner color="#1f8505" />
                  </div>
                ) : (
                  <div className="">
                    <div className=" d-flex flex-column align-items-center ">
                      <img
                        src={infoBlue}
                        alt=""
                        className="w-50px h-50px my-5"
                      />

                      <span className="text-center fs-base text-dark">
                        Are you sure you want to reject this request
                      </span>
                    </div>
                    <div className="d-flex justify-content-around mt-10">
                      <button
                        id="kt_password_cancel "
                        type="button"
                        className="btn btn-danger px-7 py-2 text-alt-red h-44 w-110 center-all "
                        onClick={() => cancelRejectModal()}
                      >
                        <span className="indicator-label fs-base ">Cancel</span>
                      </button>
                      <button
                        className=" btn btn-success h-44 w-110 center-all"
                        type="button"
                        onClick={() => {
                          reject(rejectid);
                        }}
                      >
                        <span className="indicator-label fs-base ">Submit</span>
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const reject = (id: any) => {
    setLoader(true);
    mutateReview(
      { id, type: "REJECTED", reason: reasonForReject },
      {
        onSuccess: (res: any) => {
          setLoader(false);
          setRejection(false);
          setShowReject(true);
        },
      }
    );
  };

  const onNext = () => {
    setShowSuccess(false);
    setShowReject(false);
    setShowSProveduccess(false);
    Data.mutate();
  };

  const RejectModal = () => {
    return (
      <Modal
        dialogClassName=" m-auto px-7 modal-card-success"
        aria-hidden="true"
        show={showReject}
        onHide={() => setShowReject(false)}
        animation
        centered
        size="lg"
        backdrop={"static"}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="rounded">
          <div className="">
            <div className=" d-flex flex-column align-items-center ">
              <div className="mt-5 mb-3">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.1562 12.1875L13.2812 18.75L9.84375 15.4688M26.25 15C26.25 21.2132 21.2132 26.25 15 26.25C8.7868 26.25 3.75 21.2132 3.75 15C3.75 8.7868 8.7868 3.75 15 3.75C21.2132 3.75 26.25 8.7868 26.25 15Z"
                    stroke="#61CD85"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>

              <div className="email-verification-container mb-3">
                <span className="email-verification-text">Rejected!!</span>
              </div>

              <div className="email-verification-container mb-5">
                <span className="email-verification-body">
                  The requests have been successfully rejected
                </span>
              </div>
            </div>

            <div className=" d-flex justify-content-center align-items-center mt-3 pe-9 ps-9 ">
              <button className="otp-button " onClick={() => onNext()}>
                <span className="otp-button-text">Ok</span>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const [showProvedSuccess, setShowSProveduccess] = useState(false);
  const [showApproval, setApproval] = useState<boolean>(false);
  const [showConfirm, setConfirm] = useState<boolean>(false);
  const [showRejection, setRejection] = useState<boolean>(false);
  const [revenueCode, setRevenueCode] = useState<string>("");

  const cancelModal = () => {
    setConfirm(false);
    setRevenueCode("");
  };
  const [wModal, setWModal] = useState<number>(1);

  const handleRevenueCodeChange = (e) => {
    const inputValue = e.target.value.toUpperCase(); // Ensure uppercase
    const formattedValue = formatRevenueCode(inputValue);
    setRevenueCode(formattedValue);
  };

  const formatRevenueCode = (value) => {
    // Remove non-alphanumeric characters
    const alphanumericValue = value.replace(/[^a-zA-Z0-9]/g, "");
    // Extract admin code
    const adminCode = alphanumericValue.substring(0, 13);
    // Extract category and product
    let category = "";
    let product = "";
    if (alphanumericValue.length > 13) {
      category = `-${alphanumericValue.substring(13, 18)}`;
    }
    if (alphanumericValue.length > 17) {
      product = `-${alphanumericValue.substring(18, 23)}`;
    }
    // Build the formatted revenue code
    return `${adminCode}${category}${product}`;
  };

  const ConfrimModal = () => {
    return (
      <Modal
        dialogClassName="  m-auto px-5 modal-card"
        aria-hidden="true"
        show={showConfirm}
        onHide={() => setConfirm(false)}
        animation
        centered
        size="lg"
        backdrop={"static"}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="rounded">
          <div className="m-6 mx-11">
            {loader ? (
              <div className="d-flex justify-content-center align-items-center my-10">
                <MetroSpinner color="#1f8505" />
              </div>
            ) : wModal === 1 ? (
              <>
                <div className="mt-1">
                  <div className="col-form-label input-height d-flex justify-content-center">
                    <span className=" fw-bolder fs-8e text-dark">
                      Revenue Code
                    </span>
                  </div>
                  <div className={`form-control form-control-solid`}>
                    <input
                      type="text"
                      placeholder="Please enter revenue code"
                      className={"no-outline ps-0 text-dark fs-8"}
                      value={revenueCode}
                      onChange={handleRevenueCodeChange}
                      maxLength={50}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-around my-5">
                  <button
                    id="kt_password_cancel "
                    type="button"
                    className=" btn btn-white px-7 py-2 h-44 w-110 rounded-1 border-success "
                    onClick={() => cancelModal()}
                  >
                    <span className="indicator-label fs-base text-success ">
                      Back
                    </span>
                  </button>

                  <button
                    type="button"
                    className={` btn ${
                      revenueCode.length < 16 ? "btn-secondary" : "btn-success"
                    } h-44 w-110 rounded-1  center-all  `}
                    onClick={() => setWModal(2)}
                    disabled={revenueCode.length < 16}
                  >
                    <span className="indicator-label fs-base text-white ">
                      Proceed
                    </span>
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className=" d-flex flex-column align-items-center ">
                  <img src={infoBlue} alt="" className="w-50px h-50px my-5" />

                  <span className="text-center fs-base text-dark">
                    Are you sure you want to activate this service
                  </span>
                </div>
                <div className="d-flex justify-content-around mt-10">
                  <button
                    id="kt_password_cancel "
                    type="button"
                    className="btn btn-danger px-7 py-2 text-alt-red h-44 w-110 center-all "
                    onClick={() => cancelModal()}
                  >
                    <span className="indicator-label fs-base ">Cancel</span>
                  </button>
                  <button
                    className=" btn btn-success h-44 w-110 center-all"
                    type="button"
                    onClick={() => {
                      sendVerification();
                    }}
                  >
                    <span className="indicator-label fs-base ">Submit</span>
                  </button>
                </div>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const SuccessProvedModal = () => {
    return (
      <Modal
        dialogClassName=" m-auto px-7 modal-card-success"
        aria-hidden="true"
        show={showProvedSuccess}
        onHide={() => setShowSProveduccess(false)}
        animation
        centered
        size="lg"
        backdrop={"static"}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="rounded">
          <div className="">
            <div className=" d-flex flex-column align-items-center ">
              <div className="mt-5 mb-3">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.1562 12.1875L13.2812 18.75L9.84375 15.4688M26.25 15C26.25 21.2132 21.2132 26.25 15 26.25C8.7868 26.25 3.75 21.2132 3.75 15C3.75 8.7868 8.7868 3.75 15 3.75C21.2132 3.75 26.25 8.7868 26.25 15Z"
                    stroke="#61CD85"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>

              <div className="email-verification-container mb-3">
                <span className="email-verification-text">Approved!</span>
              </div>

              <div className="email-verification-container mb-5">
                {modalData?.data?.content?.data?.batchId ? (
                  <span className="email-verification-body">
                    {modalData?.data?.currentLevel === 1
                      ? `Your payment request with GPR ${modalData?.data?.content?.data?.batchId}  has been sent to the Approver Group for approval`
                      : ` Your payment request with GPR ${modalData?.data?.content?.data?.batchId} has been sent to the payment gateway for proccessing `}
                  </span>
                ) : (
                  <span className="email-verification-body">
                    {/* Your request has been sent to the OAGF for approval */}
                    Your request has been approved
                  </span>
                )}
              </div>
            </div>

            <div className=" d-flex justify-content-center align-items-center mt-3 pe-9 ps-9 ">
              <button className="otp-button " onClick={() => onNext()}>
                <span className="otp-button-text">Ok</span>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const SuccessModal = () => {
    return (
      <Modal
        dialogClassName=" m-auto px-7 modal-card-success"
        aria-hidden="true"
        show={showSuccess}
        onHide={() => setShowSuccess(false)}
        animation
        centered
        size="lg"
        backdrop={"static"}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="rounded">
          <div className="">
            <div className=" d-flex flex-column align-items-center ">
              <div className="mt-5 mb-3">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.1562 12.1875L13.2812 18.75L9.84375 15.4688M26.25 15C26.25 21.2132 21.2132 26.25 15 26.25C8.7868 26.25 3.75 21.2132 3.75 15C3.75 8.7868 8.7868 3.75 15 3.75C21.2132 3.75 26.25 8.7868 26.25 15Z"
                    stroke="#61CD85"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>

              <div className="email-verification-container mb-3">
                <span className="email-verification-text">Reviewed!</span>
              </div>

              <div className="email-verification-container mb-5">
                {serviceModalData?.data ? (
                  <span className="email-verification-body">
                    The requests has been sent to the Approver Group for
                    approval
                  </span>
                ) : (
                  // <span className="email-verification-body">
                  //   The requests with GPR {' '}
                  //   <strong>{modalData?.data.content?.data.batchId}</strong> has
                  //   been sent to the Approver Group for approval
                  //   </span>

                  <span className="email-verification-body">
                    The requests has been sent to the Approver Group for
                    approval
                  </span>
                )}
              </div>
            </div>

            <div className=" d-flex justify-content-center align-items-center mt-3 pe-9 ps-9 ">
              <button className="otp-button " onClick={() => onNext()}>
                <span className="otp-button-text">Ok</span>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const [approvalData, setApprovalData] = useState<any>({});

  const ShowApproval = (data: any) => {
    setApprovalData(data);
    setShowService(false);
    setShow(false);
    setShowUsersCreation(false);
    setShowProjectCreation(false);

    var x: any = FetchAndUnmask("lazyday_user");
    var authorities = x !== null ? JSON.parse(x) : null;
    if (
      data?.data?.awaitingGroup === "collections#approver" &&
      authorities.oagf
    ) {
      review(data?.data?.id, data?.data);
    } else {
      setApproval(true);
    }
  };

  const cancelShowApproval = () => {
    setApprovalData({});
    setApproval(false);
    setLoader(false);
  };

  const ApprovalModal = () => {
    return (
      <Modal
        dialogClassName="  m-auto px-5 modal-card"
        aria-hidden="true"
        show={showApproval}
        onHide={() => setApproval(false)}
        animation
        centered
        size="lg"
        backdrop={"static"}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="rounded">
          <div className="m-6 mx-5">
            {loader ? (
              <div className="d-flex justify-content-center align-items-center my-10">
                <MetroSpinner color="#1f8505" />
              </div>
            ) : (
              <div className="">
                <div className=" d-flex flex-column align-items-center ">
                  <img src={infoBlue} alt="" className="w-50px h-50px my-5" />

                  <span className="text-center fs-base text-dark">
                    Are you sure you want to approve this request
                  </span>
                </div>
                <div className="d-flex justify-content-around mt-10">
                  <button
                    id="kt_password_cancel "
                    type="button"
                    className="btn btn-danger px-7 py-2 text-alt-red h-44 w-110 center-all "
                    onClick={() => cancelShowApproval()}
                  >
                    <span className="indicator-label fs-base ">Cancel</span>
                  </button>
                  <button
                    className=" btn btn-success h-44 w-110 center-all"
                    type="button"
                    onClick={() => {
                      review(approvalData?.data?.id, approvalData?.data);
                    }}
                  >
                    <span className="indicator-label fs-base ">Proceed</span>
                  </button>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  };

      function formatToNaira(amount) {
  return new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(amount);
}

  return (
    // <>
    <div className={"reviewerDashboard"}>
      <div className={"reviewerTableContainer"}>
        <div className={"reviewerTableActionButton mb-9"}>
          <div>Requests Awaiting Action </div>
          {/* <div>
                          <Select
                              defaultValue=""
                              onChange={() => {}}
                              options={[
                                  {
                                      value: '',
                                      label: 'Payment Option',
                                  },
                                  {
                                      value: 'Bulk',
                                      label: 'Bulk',
                                  },
                                  {
                                      value: 'Single',
                                      label: 'Single',
                                  }
                              ]}
                          />

                        
                      </div> */}
        </div>
        <div>
          <div className={"rpsRow2-form"}>
            <div
              className={"ciSelectOptions"}
              style={{
                display: "flex",
                gap: "15px",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              {isOagfAdmin() && (
                <DropDown
                  mutate={mdaMutate}
                  labelKey={"registeredName"}
                  valueKey={"id"}
                  apiData={mdaNameList}
                  value ={orgName}
                  onChange={(e) => updateFilter(e, setOrgName)}
                  width={250}
                  placeholder="Select MDA Name"
                  valueStyles={{
                    paddingLeft: "0.5rem",
                  }}
                  controlStyles={{
                    paddingLeft: "0.5rem",
                    paddingRight: "0.5rem",
                    height: 32,
                    display: "flex",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                  height={30}
                  loading ={allMdaLoading}
                />
              )}

              <Select
                popupMatchSelectWidth={false}
                style={{ width: 150 }}
                options={[
                  { value: "", label: "Choose Process Type" },
                  ...processTypeList,
                ]}
                onChange={(e) => updateFilter(e, setPayType)}
                showSearch
                className="border border-gray-800 my-0 py-0 rounded-2"
                value={payType}
              />

              <button onClick={fetchFilter}>
                <span>Apply Filter</span>

                <img src={filter} alt="" />
              </button>

              <div onClick={resetFilter} className="cursor-pointer">
                <span className="fs-9 text-primary fw-bold">Reset Filter</span>
              </div>
            </div>
          </div>

          <Table dataSource={dataSource} columns={columns} />
        </div>
      </div>
      {SuccessModal()}
      {SuccessProvedModal()}
      {RejectModal()}
      {ConfrimModal()}
      {RejectionModal()}
      {ApprovalModal()}

      <ReviewModal
        show={show}
        setShow={setShow}
        ShowApproval={ShowApproval}
        ShowRejectModal={ShowRejectModal}
        modalData={modalData}
        isLoadingReview={isLoadingReview}
      />

      <ReviewServiceModal
        showServices={showServices}
        setShowService={setShowService}
        ShowApproval={ShowApproval}
        ShowRejectModal={ShowRejectModal}
        serviceModalData={serviceModalData}
      />

      <ReviewUserCreationModal
        showUsersCreation={showUsersCreation}
        setShowUsersCreation={setShowUsersCreation}
        ShowApproval={ShowApproval}
        ShowRejectModal={ShowRejectModal}
        userModalData={userModalData}
      />

      <ViewProjectCreationModal
        showProjectCreation={showProjectCreation}
        setShowProjectCreation={setShowProjectCreation}
        ShowApproval={ShowApproval}
        ShowRejectModal={ShowRejectModal}
        ProjectData={projectModalData?.data?.content?.data}
        fullData={projectModalData}
      />

      <Modal
        dialogClassName=" m-auto px-7 modal-card-signup"
        aria-hidden="true"
        show={show2}
        onHide={() => setShow2(false)}
        animation
        centered
        size="lg"
        // backdrop={"static"}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body className="rounded">
          {loader ? (
            <div className="d-flex justify-content-center align-items-center my-10">
              <MetroSpinner color="#1f8505" />
            </div>
          ) : (
            <div className="">
              <div className=" d-flex flex-column align-items-center ">
                <div className="mt-5">
                  <svg
                    width="21"
                    height="19"
                    viewBox="0 0 21 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.5 7V16.75C1.5 16.9489 1.57902 17.1397 1.71967 17.2803C1.86032 17.421 2.05109 17.5 2.25 17.5H18.75C18.9489 17.5 19.1397 17.421 19.2803 17.2803C19.421 17.1397 19.5 16.9489 19.5 16.75V7M1.5 7L10.5 1L19.5 7M1.5 7L8.86363 12.25M19.5 7L12.1363 12.25M8.86363 12.25L1.73126 17.2881M8.86363 12.25L12.1363 12.25M19.2688 17.2881L12.1363 12.25"
                      stroke="#FBCB38"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>

                <div className="email-verification-container">
                  <span className="email-verification-text">
                    Email Verification
                  </span>
                </div>

                <div className="email-verification-container">
                  <div className="email-verification-body">
                    <div>Please enter the 6-digit code sent to</div>
                    <div> {orgUserParser?.organizationUser.email} </div>
                  </div>
                </div>
                <OTPInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderInput={(props) => <input {...props} />}
                  containerStyle={"otp-box mt-5"}
                  inputStyle={"w-100"}
                  placeholder="------"
                />

                <div className="email-verification-container mt-2">
                  <span className="resend-code-text" onClick={sendVerification}>
                    Resend Code
                  </span>
                </div>
              </div>

              <div className=" d-flex flex-column align-items-center mt-5 ">
                <button
                  className={`otp-button center-all ${
                    otp.length !== 6 ? "btn bg-gray-500" : ""
                  } `}
                  onClick={() => {
                    submitVerification(
                      modalData?.data?.id
                        ? modalData?.data?.id
                        : userModalData?.data?.id
                        ? userModalData?.data?.id
                        : serviceModalData?.data?.id
                        ? serviceModalData?.data?.id
                        : projectModalData?.data?.id
                    );

                    //   submitVerification(
                    //   modalData?.data.id
                    //     ? modalData?.data.data.id
                    //     : serviceModalData?.data.id
                    // )
                  }}
                  disabled={
                    otp.length !== 6
                      ? true
                      : isVerfyLoading || loadingVerify
                      ? true
                      : false
                  }
                >
                  {isVerfyLoading || loadingVerify ? (
                    <span className="otp-button-text">Loading..</span>
                  ) : (
                    <span className="otp-button-text">Verify</span>
                  )}
                </button>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
    // </>
  );
};

export default ReviewerDashboard;
