import React from "react";
import { Modal } from "react-bootstrap";
import "./ConfirmationModal.scss";

interface ConfirmationModalProps {
  show: boolean;
  setShow: (e: boolean) => void;
  proceed: () => void;
  headerText?: string;
  message?: string;
}

export const SuccessModalComponent: React.FC<ConfirmationModalProps> = ({
  show,
  setShow,
  proceed,
  headerText,
  message,
}) => {
  return (
    <Modal
      centered
      dialogClassName="m-auto px-7 modal-card-success"
      open={show}
      onOk={() => setShow(false)}
      onCancel={() => setShow(false)}
      show={show}
      onHide={() => setShow(false)}
      footer={null}
      size="lg"
      backdrop={"static"}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Body className="rounded">
        <div className="">
          <div className=" d-flex flex-column align-items-center ">
            <div className="mt-5 mb-3">
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.1562 12.1875L13.2812 18.75L9.84375 15.4688M26.25 15C26.25 21.2132 21.2132 26.25 15 26.25C8.7868 26.25 3.75 21.2132 3.75 15C3.75 8.7868 8.7868 3.75 15 3.75C21.2132 3.75 26.25 8.7868 26.25 15Z"
                  stroke="#61CD85"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>

            <div className="email-verification-container mb-3">
              <span className="email-verification-text">
                {headerText || "Successful!"}
              </span>
            </div>

            <div className="email-verification-container mb-5">
              <span className="email-verification-body">
                {message || "Request successfully sent."}
              </span>
            </div>
          </div>

          <div className=" d-flex justify-content-center align-items-center mt-3 pe-9 ps-9 ">
            <button
              className="otp-button"
              onClick={() => {
                proceed();
              }}
            >
              <span className="otp-button-text">Ok</span>
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
