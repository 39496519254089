import React from "react";
import { Footer } from "app/components/footer";
import NavBar from "app/components/nav/NavBar";
import "./policy.scss";

const PrivacyPolicy: React.FC = () => {
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div>
      <NavBar />

      <div className="center-all my-5">
        <span className="text-center text-dark fs-1 fw-bolder">
          Privacy Policy
        </span>
      </div>

      <div className="body-style px-10 my-5 mb-20">
        <li>
          <span className="heading">Your Privacy Rights</span>
          <h2 className="Poppins">
            This Privacy Policy describes your privacy rights regarding our
            collection, use, storage, sharing and protection of your personal
            information. It applies to the FGN Treasury Portal (which comprises
            of the website and mobile applications), its applications, services,
            tools and content regardless of how you access or use them.
          </h2>
        </li>

        <li>
          <span className="heading">Consent</span>
          <h2 className="Poppins">
            You accept this Privacy Policy when you sign up for, access, or use
            our products, services, content, features, technologies or functions
            offered on the FGN Treasury Portal, all related sites, applications,
            and services. This Policy governs the use of the FGN Treasury Portal
            by registered and non-registered users, unless otherwise agreed
            through a written contract. We may amend this Privacy Policy at any
            time (and without recourse to you) by posting a revised version on
            the Platform and/or through email notification. The revised version
            will be effective immediately or at a date as may be communicated by
            us.
          </h2>
        </li>

        <li>
          <span className="heading">Your Personal Information</span>
          <ol>
            <li>
              When you perform transactions on the FGN Treasury Portal either as
              a registered or non-registered user, we collect information sent
              to us by your computer, mobile phone, Point of Sale (“POS”)
              terminal or other electronic access devices. The information shall
              be collected automatically and shall include but not limited to
              data about the pages on the Platform which you access, your
              computer IP address, device ID or unique identifier, device type,
              geo-location information, computer and connection information,
              mobile network information, statistics on page views, traffic to
              and from the sites, and standard web log data.
            </li>

            <li>
              We may also collect information you directly provide to us
              including but not limited to information on web forms, account
              update information and correspondence with the FGN Treasury Portal
              support services. We also collect information about your
              transactions and your activities. In addition, we collect your
              contact information such as; name, address, phone number, email,
              etc. We may also collect your bank account name and numbers and
              these may be linked to your profile on the platform, where
              applicable. Information obtained through survey responses,
              telephone conversations, user enquiries and other means may also
              be collected and used by us.
            </li>
            <li>
              If the information you have supplied to us is wrong, you can put
              in a request to update it quickly or to delete it – unless we have
              to keep that information for legitimate business or legal
              purposes, and when responding to a request to update your personal
              information, we may ask you to verify your identity before we can
              act on the request. We may reject requests that are unreasonably
              repetitive, require disproportionate technical effort (for
              example, developing a new system or fundamentally changing an
              existing practice), risk the privacy of others, or would be
              extremely impractical. Where we can provide information access and
              correction, we would do so, except where it would require a
              disproportionate effort. We aim to maintain our services in a
              manner that protects information from accidental or malicious
              destruction. Because of this, after you delete information from
              our services, we may not immediately delete residual copies from
              our active servers and may not remove information from our backup
              systems.
            </li>

            <li>
              You may also let us know if you feel that your personal
              information is being processed in any way other than how you have
              consented to it being used. We will take reasonable steps to
              ensure that your personal information is processed in the manner
              in which you have consented to it, or we may delete it based on
              your instruction. However, we may not accede to your request if it
              is unrealistic, impractical or inimical to our compliance with
              applicable regulation or laws.
            </li>
          </ol>
        </li>

        <li>
          <span className="heading">
            What we do with your Personal Information
          </span>
          <h2 className="Poppins">
            Our purpose for collecting your personal information is to give you
            an efficient, enjoyable and secure customer experience. We may use
            your information to:
          </h2>
          <ol>
            <li>Provide Services and Support;</li>
            <li>
              Process transactions, and send notices about your transactions to
              requisite parties;
            </li>
            <li>Verify your identity;</li>
            <li>Resolve disputes and complaints;</li>
            <li>
              Manage risk, or detect, prevent, and/or remediate fraud or other
              potentially prohibited or illegal activities;
            </li>
            <li>
              Detect, prevent or remediate violations of policies or applicable
              user agreements;
            </li>
            <li>
              Improve Services by implementing aggregate user preferences;
            </li>
            <li>
              Measure performance of Services and improve content, technology
              and layout;
            </li>
            <li>
              Manage and protect our information technology infrastructure;
            </li>
            <li>
              Contact you at any time through your provided telephone number,
              email address or other contact details;
            </li>
            <li>
              Notify you of new products, services and/or offerings; or any
              enhancements/amendments to existing products services and/or
              offerings
            </li>

            <li>
              If we intend to process your personal information for any purpose
              other than those listed in this policy, we shall provide you with
              relevant information on the further processing and seek your
              consent before the processing commences.
            </li>
          </ol>
        </li>

        <li>
          <span className="heading">Cookies</span>
          <ol>
            <li>
              Cookies are small files placed on your device hard drive that
              enables the platform or website to identify your device as you
              view different pages. Cookies allow websites and applications to
              store your preferences in order to present content, options or
              functions that are specific to you. Like most interactive
              websites, and online platforms, we use cookies to enable the
              tracking of your activity for the duration of a session. Our
              cookies do not collect information from the user's computer. They
              will typically store information in the form of a session
              identification that does not personally identify the user.
            </li>
            <li>
              We do not have access over the cookies of our partners,
              affiliates, and service providers, nor do we control same. We are
              therefore not responsible for the use of their cookies.
            </li>
          </ol>
        </li>

        <li>
          <span className="heading">
            How we Protect Your Personal Information
          </span>
          <ol>
            <li>
              We store and process your personal information on our computers in
              any location where our facilities are located. Regardless of where
              your information is processed, we apply the same protections
              described in this policy.
            </li>
            <li>
              We protect your information using physical, technical, and
              administrative security measures to reduce the risks of loss,
              misuse, unauthorized access, disclosure and alteration. Some of
              the safeguards we use are firewalls, data encryption and
              information access authorization controls.
            </li>

            <li>
              Furthermore, in the event of an actual or suspected breach of your
              personal information, we will use best effort to remedy the breach
              within <span className="highlight">(5 days)</span> from the
              date we report the breach to you.
            </li>
          </ol>
        </li>

        <li>
          <span className="heading">Access to Your Data</span>
          <ol>
            <li>
              If you are a registered user on the FGN Treasury Portal, you may
              view your personal information using your secure login credentials
              to gain access to the relevant system features for such purpose.
              If you are a non-registered user, you may request for your
              personal information by sending an email to
              support@fgntreasuryportal.gov.ng.
            </li>
            <li>
              This information should only be used for your own purposes and not
              to circumvent the platform, or to be distributed to other parties
              who may use such information to the disadvantage of the FGN
              Treasury Portal.
            </li>

            <li>
              You will be able to access reports of your previous transactions
              up to <span className="highlight">10 years</span> after the
              transaction date, subject to a continuous period of use.
            </li>

            <li>
              You have the right to request that we stop using your data or
              return your data to you or transmit the data directly to another
              data controller. The withdrawal of your consent at any time shall
              not affect the lawfulness of the processing based on your consent
              before its withdrawal. In the event you intend to exercise your
              right in this regard, kindly notify us through
              support@fgntreasuryportal.gov.ng and your request shall be treated
              accordingly.
            </li>
          </ol>
        </li>

        <li>
          <span className="heading">
            How we Share Your Information with other Relevant Parties
          </span>
          <ol>
            <li>
              During the processing of your transaction, we may provide the
              corresponding party with information such as your name, account
              identification details, contact details, shipping and billing
              address, or other information needed to successfully complete the
              processing as well as preserve the integrity and security of the
              transaction. We may also provide details of unsuccessful
              transactions.
            </li>
            <li>
              We may also share your personal information in compliance with
              local or international regulatory requirements, with fraud
              prevention and risk management agencies, with our service
              providers or in compliance with a court order.
            </li>
          </ol>
        </li>

        <li>
          <span className="heading">Security</span>
          <h2 className="Poppins">
            We will always hold your information securely. To prevent
            unauthorized access to your information, we have implemented
            controls and security safeguards at both technical and operational
            levels.{" "}
            <span className="highlight">
              This site uses ……………………………… to ensure secure transmission of your
              personal data ………further describe technology used for security of
              data……………….
            </span>{" "}
            Any data transmitted during the session will be encrypted before
            transmission and decrypted at the receiving end. This is to ensure
            that data cannot be read during transmission.
          </h2>
        </li>

        <li>
          <span className="heading">Data Confidentiality Rights</span>
          <h2 className="Poppins">
            Your information is regarded as confidential and will not be
            divulged to any third party except under legal and/or regulatory
            conditions. You have the right to request for copies of all
            information we keep on you, provided that you give us reasonable
            notice of such a request in writing. While we are responsible for
            safeguarding the information entrusted to us, your role in
            fulfilling confidentiality duties includes but is not limited to
            adopting and enforcing appropriate security measures such as
            non-sharing of passwords and other platform login or transaction
            authentication details. You are ultimately responsible for the
            security of your platform login and transaction authentication
            details.
          </h2>
        </li>

        <li>
          <span className="heading">Links to Other Platforms</span>
          <ol>
            <li>
              Certain transaction processing channels on the FGN Treasury Portal
              may require links to other websites and/or platforms. Please note
              that the Office of the Accountant General of the Federation (OAGF)
              is not responsible for, and has no control over third party
              websites/platforms. We do not monitor or review the contents of
              other parties’ websites/platforms which are linked from this
              website. Opinions expressed or materials appearing on such
              websites are not necessarily shared or endorsed by us, and you
              agree that we shall not be responsible for any liability arising
              from your interpretation of any information on any third party
              website linked to the FGN Treasury Portal. Please be aware that we
              are not responsible for the privacy practices, or contents of any
              third party sites and we encourage our users to read the privacy
              statements of these third party sites. You should evaluate the
              security and trustworthiness of any other sites connected to this
              site or accessed through this site yourself, before disclosing any
              personal information to it. We will not accept any responsibility
              for any loss or damage in whatever manner, howsoever caused,
              resulting from your disclosure to third parties of your personal
              information.
            </li>
            <li>
              We reserve the right to modify our Policy at any time and without
              any prior notice to you. Ensure you check this Policy regularly to
              stay updated with any amendment and understand how we use your
              information.
            </li>
          </ol>
        </li>

        <li>
          <span className="heading">Limitation of Liability</span>
          <ol>
            <li>
              The Office of the Accountant General of the Federation (OAGF) will
              not be liable for any loss of income, profits, business
              opportunity, goodwill, contracts or any indirect, special,
              incidental or consequential damages arising out of or in
              connection with the Service, any computation or failed transaction
              on the FGN Treasury Portal, arising out of any breach of this
              Policy.
            </li>
            <li>
              We will not be liable for any loss or damage arising as a result
              of unauthorized access to the Service if (a) you intentionally or
              negligently failed to take reasonable precautions to protect your
              security, access codes, login details or any device used to access
              the Service, (b) you failed to promptly notify us that the Service
              was being accessed in an unauthorised manner after becoming aware
              of it, or (c) you acted fraudulently.
            </li>

            <li>
              Our liability to you or any third party in any circumstance of
              proven liability by us, shall not exceed the fees paid in respect
              of the specific transaction that gave rise to the claim or
              liability, unless otherwise specified by a court of competent
              jurisdiction.
            </li>
          </ol>
        </li>
      </div>

      <Footer />
    </div>
  );
};

export { PrivacyPolicy };
