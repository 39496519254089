import { Input, Select } from "antd";
import { Download } from "app/components/download";
import { TableComponent } from "app/components/table/Table";
import { useGetBanks } from "app/pages/auth/hooks/useUser";
import {
  FetchAndUnmask,
  LoaderContainer,
  formatMoneyLocal,
} from "core/helpers";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Breadcrumb } from "../../Project/components/Breadcrumb";

const links = [
  {
    name: "Bank Accounts",
  },

  {
    name: "Manage Accounts",
  },
];

const ManageBankHome = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<any>("");
  const lazydayUser = JSON.parse(FetchAndUnmask("lazyday_user") as string);
  useState<boolean>(false);

  const {
    data: bankData,
    mutate: mutateGetBank,
    isLoading: isGetBankLoading,
  } = useGetBanks();


  // useEffect(() => {
  //   mutateGetBank()
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[])
  
  const dataSource: any[] = [];
  const columns = [
    // {
    //   title: "S/N",
    //   dataIndex: "sn",
    //   key: "sn",
    //   width: "30",
    // },

    {
      title: "MDA Name",
      dataIndex: "mdaOrgID",
      key: "mdaOrgID",
      width: "30",
    },

    {
      title: "Bank Name",
      dataIndex: "bankName",
      key: "bankName",
      width: "180",
    },

    {
      title: "Account Name",
      dataIndex: "accountName",
      key: "accountName",
      width: "60",
    },

    {
      title: "Account Number",
      dataIndex: "accountNumber",
      key: "accountNumber",
      width: "60",
    },
    {
      title: "Account Classification",
      dataIndex: "accountType",
      key: "accountType",
      width: "180",
    },

    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      width: "60",
    },
    {
      title: "Opening Balance",
      dataIndex: "openingBalance",
      key: "openingBalance",
      width: "180",
    },

    {
      title: "Daily Transaction Limit",
      dataIndex: "dailyLimit",
      key: "dailyLimit",
      width: "60",
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "60",
    },

    // {
    //   title: "Action",
    //   key: "action",
    //   render(text: any, record: any) {
    //     const items: MenuProps["items"] = [
    //       {
    //         label: (
    //           <button
    //             className="btn-clear"
    //             onClick={() => navigate("view/" + record.id)}
    //           >
    //             View Details
    //           </button>
    //         ),
    //         key: "1",
    //       },
    //       {
    //         label:
    //           record.status.toLowerCase() === "commenced" ? (
    //             <button
    //               className={`btn-clear  ${
    //                 record.status.toLowerCase() === "approved"
    //                   ? ""
    //                   : "cursor-disabled"
    //               }`}
    //               onClick={() => {
    //                 setSelectedRecord(record);
    //                 setshouldUpdateProjectStatus(true);
    //               }}
    //             >
    //               Close
    //             </button>
    //           ) : (
    //             <button
    //               className={`btn-clear  ${
    //                 record.status.toLowerCase() === "approved"
    //                   ? ""
    //                   : "cursor-disabled"
    //               }`}
    //               onClick={() => {
    //                 setSelectedRecord(record);
    //                 setshouldUpdateProjectStatus(true);
    //               }}
    //               disabled={!(record.status.toLowerCase() === "approved")}
    //             >
    //               Commence
    //             </button>
    //           ),
    //         key: "2",
    //       },
    //       {
    //         label: <div>Manage Users</div>,
    //         key: "3",
    //       },
    //       {
    //         label: <div>Make Payment</div>,
    //         key: "3",
    //       },
    //     ];
    //     return (
    //       <Space wrap>
    //         <Dropdown menu={{ items }} placement="bottom" arrow>
    //           <svg
    //             xmlns="http://www.w3.org/2000/svg"
    //             height="24"
    //             viewBox="0 -960 960 960"
    //             width="24"
    //           >
    //             <path d="M480-160q-33 0-56.5-23.5T400-240q0-33 23.5-56.5T480-320q33 0 56.5 23.5T560-240q0 33-23.5 56.5T480-160Zm0-240q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm0-240q-33 0-56.5-23.5T400-720q0-33 23.5-56.5T480-800q33 0 56.5 23.5T560-720q0 33-23.5 56.5T480-640Z" />
    //           </svg>
    //         </Dropdown>
    //       </Space>
    //     );
    //   },
    // },
  ];

  for (let i = 0; i < bankData?.data?.length; i++) {
    const currentData = bankData?.data[i];

    const datad = {
      key: i.toString(),
      // sn: i + 1,
      id: currentData?.id,
      mdaOrgID: currentData?.orgId,
      accountNumber: currentData?.accountNumber,
      accountName: currentData?.accountName,
      bankName: currentData?.bankName,
      accountType: currentData?.accountType?.description,
      openingBalance: currentData?.openingBalance
        ? formatMoneyLocal(currentData?.openingBalance)
        : 0.0,
      currency: currentData?.currency,
      dailyLimit: currentData?.dailyLimit
        ? formatMoneyLocal(currentData?.dailyLimit)
        : 0.0,
      status: "Active", // formatRequestEnums(currentData?.status),
    };
    dataSource.push(datad);
  }

  return (
    <>
      <div className={"adminBody"}>
        <Breadcrumb links={links} />

        {/* {isGetBankLoading && <LoaderContainer />} */}

        <div className="project-content-container">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <Download
                excelFileTitle={"Bank Account Details"}
                pdfFileTitle={"Bank Account Details"}
                excelPageTitle={"Bank Account Details"}
                dataSource={dataSource}
              />
            </div>
            <div>
              <button
                className="bg-success text-white fs-8 p-2 border-0 rounded-1 "
                onClick={() => navigate("/admin/bank-accounts/create")}
              >
                Add New Bank Account
              </button>
            </div>
          </div>

          <div>
            <div className="header-container">
              <span className="text-dark2 fs-5 fw-bolder">
                {lazydayUser?.registeredName} BANK ACCOUNTS
              </span>
            </div>

            <div className="header-container flex-row justify-content-between">
              <div>
                <div>
                  <span className="text-muted fs-9">
                    Total Number of Bank Accounts
                  </span>
                </div>
                <div>
                  <span className="text-muted fs-9 fw-boldest">
                    {bankData?.totalSize}
                  </span>
                </div>
              </div>

              <div>
                <div>
                  <span className="text-muted fs-9">Active Bank Accounts</span>
                </div>
                <div>
                  <span className="text-muted fs-9 fw-boldest">
                    {/* {projectSummary?.totalCommencedProjects} */}
                    {bankData?.totalSize}
                  </span>
                </div>
              </div>

              <div>
                <div>
                  <span className="text-muted fs-9">
                    Inactive Bank Accounts
                  </span>
                </div>
                <div>
                  <span className="text-muted fs-9 fw-boldest">
                    0
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex align-items-center gap-20">
            <div className="d-flex gap-5 align-items-center">
              <div>Filter by:</div>
              <div>
                <Select
                  style={{ width: 150 }}
                  options={[
                    { value: "", label: "All" },
                    { value: "inactive", label: "Inactive" },
                    { value: "active", label: "Active" },
                  ]}
                  popupMatchSelectWidth={false}
                  // onChange={(e) => setStatus(e)}
                  showSearch
                  placeholder="Status"
                  filterOption={(input, option: any) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0 ||
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  className="h-41 w-133px bg-gray-800 text-gray-500 rounded-1"
                />
              </div>
            </div>

            <div className={"mdaDirectorySelectOptions"}>
              <Input
                placeholder={"Search by MDA Name or Account Classification"}
                className="w-400px h-44 fs-8 bg-gray-800 text-gray-500"
                allowClear
                suffix={
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.4431 16.4438L20.9995 21.0001M18.75 10.875C18.75 15.2242 15.2242 18.75 10.875 18.75C6.52576 18.75 3 15.2242 3 10.875C3 6.52576 6.52576 3 10.875 3C15.2242 3 18.75 6.52576 18.75 10.875Z"
                      stroke="#666666"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                }
                value={searchValue}
                onChange={(e: any) => setSearchValue?.(e.target.value)}
              />
            </div>
          </div>

          <div>
            <TableComponent
              dataSource={dataSource}
              columns={columns}
              isLoading={isGetBankLoading}
              mutateTable={mutateGetBank}
            />
          </div>
        </div>
        {/* 
        <ProjectSuccessModal
          show={commenceModal}
          setShow={setcommenceModal}
          done={() => setcommenceModal(false)}
        /> */}

        {/* <ConfirmationModal
          show={shouldUpdateProjectStatus}
          setShow={setshouldUpdateProjectStatus}
          proceed={() =>
            selectedRecord.status.toLowerCase() === "commenced"
              ? CloseProject()
              : commenceProject()
          }
        /> */}
      </div>
    </>
  );
};

export { ManageBankHome };
