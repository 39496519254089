import React, { useEffect } from "react";
import { edit } from "core/assets/icons";
import {
  LoaderContainer,
  convertToFirstLetterCap,
  formatMoneyLocal,
  formatRequestEnums,
} from "core/helpers";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumb } from "../components/Breadcrumb";
import { useGetProjectsById } from "../hooks/hooks";

const links = [
  {
    name: "Project",
  },
  {
    name: "View Details",
  },
];

const TableUsers: React.FC<any> = ({ usersList }) => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th>ID</th>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Phone Number</th>
          <th>Email </th>
        </tr>
      </thead>
      <tbody>
        {usersList?.map((userData, i) => {
          return (
            <tr key={i} className="fs-8">
              <td> {i + 1} </td>
              <td>{userData.firstName}</td>
              <td>{userData.lastName}</td>
              <td>{userData.phoneNumber}</td>
              <td>{userData.email}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const ProjectDetails = () => {
  const TableUserWork: React.FC<any> = ({ workflow }) => {
    return (
      <table className="table">
        <thead>
          <tr className="fs-8 text-start">
            <th>ID</th>
            <th>Process Type</th>
            <th>Initiator</th>
            <th>Reviewer</th>
            <th>Approver </th>
            {/* <th>Action</th> */}
          </tr>
        </thead>
        <tbody>
          {workflow?.map((userFlow, i) => {
            return (
              <tr key={i} className="fs-8 text-start">
                <td> {i + 1} </td>
                <td>{userFlow?.requesttype?.description}</td>
                {userFlow?.routes?.map((user, i) => {
                  return (
                    <td>
                      {user?.users?.map((u, i) => {
                        return (
                          <div>
                            <div>{u?.name}</div>
                          </div>
                        );
                      })}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  let { id } = useParams();
  const navigate = useNavigate();
  const {
    data: projectDetails,
    mutate: mutateDetails,
    isLoading: isLoadingDetails,
  } = useGetProjectsById();

  useEffect(() => {
    mutateDetails(id);
  }, [id, mutateDetails]);

  return (
    <div className={"adminBody"}>
      <Breadcrumb links={links} />

      {isLoadingDetails && <LoaderContainer />}

      {projectDetails && (
        <div className="form align-middle  gx-0 gy-5 mt-3">
          <div className="preview-container" style={{ background: "#F9F9F9" }}>
            <div className="card m-auto project-preview-card pt-0">
              <div className="d-flex justify-content-center align-items-center preview-header">
                <span className="text-success fs-2 fw-bold lh-lg">
                  {convertToFirstLetterCap(projectDetails?.name)}
                </span>
              </div>
              <div>
                <div className="d-flex justify-content-between  px-5">
                  <div className="d-flex justify-content-center align-items-center">
                    <span className="text-dark fs-5 fw-bolder lh-lg">
                      Basic Details
                    </span>
                  </div>

                  <div
                    className="d-flex justify-content-center align-items-center bg-edit rounded-1 h-35e w-49e cursor-pointer"
                    onClick={() => {
                      navigate(
                        `/admin/project/edit/basic-details/${projectDetails?.id}`
                      );
                    }}
                  >
                    <span className="text-white fs-8 fw-bold">Edit</span>
                    <img
                      src={edit}
                      alt="edit"
                      className=" ms-3 w-11r5 h-11r5"
                    />
                  </div>
                </div>

                <hr className="text-muted h-1px" />
                <div className="px-5 d-flex justify-content-between">
                  <div className="d-flex justify-content-between py-3 flex-column">
                    <span className="text-dark2 fs-8 text-start min-w-25">
                      Project ID
                    </span>
                    <span className="text-dark fs-8 fw-bolder">
                      {projectDetails?.projectId}
                    </span>
                  </div>

                  <div className="d-flex justify-content-between py-3 flex-column">
                    <span className="text-dark2 fs-8 text-start min-w-25">
                      Created By
                    </span>
                    <span className="text-dark fs-8 fw-bolder">
                      {projectDetails?.createdBy}
                    </span>
                  </div>

                  <div className="d-flex justify-content-between py-3 flex-column">
                    <span className="text-dark2 fs-8 text-start min-w-25">
                      Date Created
                    </span>
                    <span className="text-dark fs-8 fw-bolder">
                      {moment(projectDetails?.createdDate).format(
                        "MMM DD, YYYY"
                      )}
                    </span>
                  </div>

                  <div className="d-flex justify-content-between py-3 flex-column">
                    <span className="text-dark2 fs-8 text-start min-w-25">
                      Status
                    </span>
                    <span className="text-dark fs-8 fw-bolder">
                      {formatRequestEnums(projectDetails?.status)}
                    </span>
                  </div>

                  <div className="d-flex justify-content-between py-3 flex-column">
                    <span className="text-dark2 fs-8 text-start min-w-25">
                      Start Date
                    </span>
                    <span className="text-dark fs-8 fw-bolder">
                      {projectDetails?.status === "AWAITING_APPROVAL"
                        ? "N/A"
                        : projectDetails?.startDate
                        ? moment(projectDetails?.startDate).format(
                            "MMM DD, YYYY"
                          )
                        : "N/A"}
                    </span>
                  </div>

                  <div className="d-flex justify-content-between py-3 flex-column">
                    <span className="text-dark2 fs-8 text-start min-w-25">
                      End Date
                    </span>
                    <span className="text-dark fs-8 fw-bolder">
                      {projectDetails?.status === "AWAITING_APPROVAL"
                        ? "N/A"
                        : projectDetails?.endDate
                        ? moment(projectDetails?.endDate).format("MMM DD, YYYY")
                        : "N/A"}
                    </span>
                  </div>

                  {/* <div className="d-flex justify-content-between py-3">
                <span className="text-dark2 fs-8 fw-bolder text-start min-w-25">
                  MDAs Involved
                </span>
                <span className="text-dark fs-8 fw-bold text-end">
                  {formik?.formik?.organization}
                </span>
              </div> */}
                </div>

                <div className="d-flex p-5 flex-column">
                  <span className="text-dark2 fs-8 text-start min-w-25">
                    Project Budget
                  </span>
                  <span className="text-dark fs-8 fw-bolder">
                    ₦{formatMoneyLocal(projectDetails?.budget)}
                  </span>
                </div>

                <div className="d-flex px-5 flex-column">
                  <span className="text-dark2 fs-8 text-start min-w-25">
                    Description
                  </span>
                  <span className="text-dark fs-8 fw-bolder">
                    {projectDetails?.description}
                  </span>
                </div>
              </div>
            </div>

            <div className="card m-auto project-preview-card">
              <div className="d-flex justify-content-between px-5">
                <div className="d-flex justify-content-center align-items-center">
                  <span className="text-dark fs-5 fw-bolder lh-lg">
                    Bank Account
                  </span>
                </div>

                <div
                  className="d-flex justify-content-center align-items-center bg-edit rounded-1 h-35e px-3 cursor-pointer"
                  //   onClick={() => {
                  //     setPreviewMode(true);
                  //     setStep(2);
                  //   }}
                >
                  <span className="text-white fs-8 fw-bold">
                    Change Bank Account
                  </span>
                  <img src={edit} alt="edit" className=" ms-3 w-11r5 h-11r5" />
                </div>
              </div>
              <hr className="text-muted h-1px" />
              <div className="px-5 d-flex justify-content-between">
                <div className="d-flex justify-content-between py-3 flex-column ">
                  <span className="text-dark2 fs-8 text-start min-w-25">
                    Bank Name
                  </span>
                  <span className="text-dark fs-8 fw-bolder">
                    {projectDetails?.bankAccount?.bankName}
                  </span>
                </div>

                <div className="d-flex justify-content-between py-3 flex-column">
                  <span className="text-dark2 fs-8 text-start min-w-25">
                    Account Number
                  </span>
                  <span className="text-dark fs-8 fw-bolder">
                    {projectDetails?.bankAccount?.accountNumber}
                  </span>
                </div>

                <div className="d-flex justify-content-between py-3 flex-column">
                  <span className="text-dark2 fs-8 text-start min-w-25">
                    Account Classification
                  </span>
                  <span className="text-dark fs-8 fw-bolder">
                    {projectDetails?.bankAccount?.accountType?.description}
                  </span>
                </div>

                <div className="d-flex justify-content-between py-3 flex-column">
                  <span className="text-dark2 fs-8 text-start min-w-25">
                    Account Name
                  </span>
                  <span className="text-dark fs-8 fw-bolder">
                    {projectDetails?.bankAccount?.accountName}
                  </span>
                </div>
              </div>
            </div>

            <div className="card m-auto p-5 project-preview-card">
              <div className="d-flex justify-content-between ">
                <div className="d-flex justify-content-center align-items-center">
                  <span className="text-dark fs-5 fw-bolder lh-lg">
                    User Details
                  </span>
                </div>

                <div
                  className="d-flex justify-content-center align-items-center bg-edit rounded-1 h-35e px-3 cursor-pointer"
                  //   onClick={() => {
                  //     setPreviewMode(true);
                  //     setStep(3);
                  //   }}
                >
                  <span className="text-white fs-8 fw-bold">Manage Users</span>
                  <img src={edit} alt="edit" className=" ms-3 w-11r5 h-11r5" />
                </div>
              </div>
              <hr className="text-muted h-1px" />
              <div className="mt-5">
                <TableUsers usersList={projectDetails?.users} />
              </div>
            </div>

            <div className="card m-auto p-5 project-preview-card">
              <div className="d-flex justify-content-between ">
                <div className="d-flex justify-content-center align-items-center">
                  <span className="text-dark fs-5 fw-bolder lh-lg">
                    Approval Workflow Definition
                  </span>
                </div>

                {/* <div
              className="d-flex justify-content-center align-items-center bg-edit rounded-1 h-35e w-49e cursor-pointer"
              onClick={() => {
                setPreviewMode(true);
                setStep(4);
              }}
            >
              <span className="text-white fs-8 fw-bold">Edit</span>
              <img src={edit} alt="edit" className=" ms-3 w-11r5 h-11r5" />
            </div> */}
              </div>
              <hr className="text-muted h-1px" />

              <div className="mt-5">
                <TableUserWork workflow={projectDetails?.workflows} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export { ProjectDetails };
