import { AxiosResponse } from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

// import type { User } from '../../../../../shared/types';
import { SuccessToast } from "app/components/toast";
import { FetchAndUnmask, MaskAndSave } from "core/helpers";
import { ErrorHandler } from "core/helpers/ErrorHandler";
import {
  axiosInstance,
  axiosInstance2,
  getJWTHeader,
} from "../../../../axiosInstance";
import { queryKeys } from "../../../react-query/constants";
import { clearStoredUser, getStoredUser, setStoredUser } from "../user-storage";

async function requestSignin(values: any): Promise<any | null> {
  const { data, status }: AxiosResponse<any> = await axiosInstance({
    url: "api/authenticate",
    method: "POST",
    data: values,
    headers: { "Content-Type": "application/json" },
  });

  return { data, status };
}

async function getUser(user: any | null): Promise<any | null> {
  if (!user) return null;
  const { data }: AxiosResponse<{ user: any }> = await axiosInstance.get(
    `/user/${user.id}`,
    {
      headers: getJWTHeader(user),
    }
  );
  return data.user;
}

export async function getUserBankDetails(): Promise<any | null> {
  const orgId: string | null = FetchAndUnmask("orgId");
  const token: string | null = FetchAndUnmask("token");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.get(
    `/services/organization/api/organizations/${orgId}/banks`
  );
  return data;
}

async function createService(values: any): Promise<any | null> {
  const orgId: string | null = FetchAndUnmask("orgId");
  //services/organization/api/organizations/${orgId}/services
  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.post(
    `/services/organization/api/organizations/requests/initiate-service-request`,
    values
  );
  return data;
}

async function getServiceDetails(serviceId: string): Promise<any | null> {
  const orgId: string | null = FetchAndUnmask("orgId");
  const token: string | null = FetchAndUnmask("token");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.get(
    `/services/organization/api/organizations/${orgId}/services/${serviceId}`
  );
  return data;
}

async function getServiceDetailsWithOrgIdOagf(
  orgId: string,
  serviceId: string
): Promise<any | null> {
  const token: string | null = FetchAndUnmask("token");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.get(
    `/services/organization/api/oagf/organizations/${orgId}/services/${serviceId}`
  );
  return data;
}

async function updateServiceDetails(
  serviceId: any,
  values: any
): Promise<any | null> {
  const orgId: string | null = FetchAndUnmask("orgId");
  const token: string | null = FetchAndUnmask("token");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.patch(
    `/services/organization/api/organizations/${orgId}/services/${serviceId}`,
    values
  );
  return data;
}

async function getInactiveService(): Promise<any | null> {
  const token: string | null = FetchAndUnmask("token");
  const orgId: string | null = FetchAndUnmask("orgId");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const { data }: AxiosResponse<any> = await axiosInstance2.get(
    `/services/organization/api/organizations/find-services?status=false`
  );

  return data;
}

async function getAllService(): Promise<any | null> {
  const token: string | null = FetchAndUnmask("token");
  const orgId: string | null = FetchAndUnmask("orgId");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const { data }: AxiosResponse<any> = await axiosInstance2.get(
    `services/organization/api/organizations/find-all-services?size=100000`
  );

  return data;
}

export async function activeService(
  serviceId: any,
  orgId: any,
  revenueCode: any
): Promise<any | null> {
  const token: string | null = FetchAndUnmask("token");
  // const orgId: string | null = FetchAndUnmask("orgId");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const { data }: AxiosResponse<any> = await axiosInstance2.post(
    `services/organization/api/organizations/${orgId}/services/${serviceId}/activate-service`,
    revenueCode
  );
  return data;
}

export async function toggleService(serviceId: any): Promise<any | null> {
  const token: string | null = FetchAndUnmask("token");
  // const orgId: string | null = FetchAndUnmask("orgId");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const { data }: AxiosResponse<any> = await axiosInstance2.post(
    `services/organization/api/organizations/services/${serviceId}/toggle-service-visibility`
  );
  return data;
}

async function getServices(): Promise<any | null> {
  const orgId: string | null = FetchAndUnmask("orgId");

  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.get(
    `/services/organization/api/organizations/${orgId}/find-services`
  );
  return data;
}
async function getOrgUserFLow(): Promise<any | null> {
  const orgId: string | null = FetchAndUnmask("orgId");

  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.get(
    `/services/organization/api/onboarding-step-three/organizations/${orgId}/workflows`
  );
  return data;
}

async function getMda(): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance2.get(
    `/services/organization/api/onboarding-step-two/organizations`
  );
  return data;
}

async function getUsersDetails(): Promise<any | null> {
  const orgId: string | null = FetchAndUnmask("orgId");
  const token: string | null = FetchAndUnmask("token");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.get(
    `/services/organization/api/organizations/${orgId}/users`
  );
  return data;
}

async function verificationSinglecode(values: any): Promise<any | null> {
  const token: string | null = FetchAndUnmask("token");

  axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const { data }: AxiosResponse<any> = await axiosInstance.post(
    `/api/validate-otp`,

    values
  );

  return data;
}

async function verificationcode(values: any): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance.post(
    `/api/onboarding-step-one/validate-otp-and-reset-password`,

    values
  );

  return data;
}

async function resetPassword(values: any): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance.post(
    `/api/account/reset-password/finish`,

    values
  );

  return data;
}

async function getBankInstitutions(): Promise<any | null> {
  const token: string | null = localStorage.getItem("token");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const { data }: AxiosResponse<any> = await axiosInstance2.get(
    `/services/bankingservice/api/bankingservice/nip/institutions`
  );

  return data;
}

async function requestForgotPassword(values: any): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance.post(
    `/api/account/reset-password/init`,
    values
  );

  return data;
}

async function requestResetPassword(values: any): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance.post(
    `/api/account/reset-password/finish`,
    values
  );

  return data;
}

async function requestOnboardingStepOne(values: any): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance.post(
    `/api/onboarding-step-one/personal-details`,
    values
  );

  return data;
}

async function requestOnboardingStepTwo(values: any): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance.post(
    `/api/payment/onboarding/step/two`,
    values
  );

  return data;
}

async function requestOnboardingSendVerificatoinCode(
  values: any
): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance.post(
    `/api/onboarding-step-one/send-verification-code?email=${values}`,
    values
  );

  return data;
}

export async function getLogout(): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance2.post(
    `/api/user/logout`,
    {}
  );

  return data;
}

async function requestOnboardingStepentitydetails(
  values: any
): Promise<any | null> {
  const token: string | null = FetchAndUnmask("token");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const { data }: AxiosResponse<any> = await axiosInstance2.post(
    `/services/organization/api/onboarding-step-two/organizations`,

    values
  );

  return data;
}

async function getUserList(value): Promise<any | null> {
  const orgId: string | null = FetchAndUnmask("orgId");

  const { data }: AxiosResponse<any> = await axiosInstance2.get(
    `/services/organization/api/organizations/${orgId}/users?first-name=${value}&last-name=${value}&phone=${value}&email=${value}`
  );

  return data;
}

async function getRoleList(): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance2.get(
    `/api/authorities/non-role-admin`
  );

  return data;
}

async function getUserRole(login: any): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance2.get(
    `/api/authorities/username/${login} `
  );

  return data;
}

async function setUserRole(values): Promise<any | null> {
  const login: string | null = FetchAndUnmask("login");

  const { data }: AxiosResponse<any> = await axiosInstance2.post(
    `/api/admin/users/${values.userLogin}/authorities`,
    values.role
  );

  return data;
}

async function editUser(values): Promise<any | null> {
  const orgId: string | null = FetchAndUnmask("orgId");

  const { data }: AxiosResponse<any> = await axiosInstance2.put(
    `/services/organization/api/organizations/${orgId}/organization-users/${values.login}`,
    values.value
  );

  return data;
}

async function editRoleUser(values): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance2.put(
    `/api/admin/users/${values.username}/authority`,
    [
      {
        name: values.value,
      },
    ]
  );

  return data;
}

async function CreateRoleUser(values): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance2.post(
    `/api/admin/authorities`,
    {
      name: values,
    }
  );

  return data;
}

async function addResources(values): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance2.post(
    `/api/resources/role`,
    values
  );

  return data;
}

async function editResources(values): Promise<any | null> {
  //console.log(values);

  const { data }: AxiosResponse<any> = await axiosInstance2.put(
    `/api/resources/role/${values?.authority}`,
    values
  );

  return data;
}
async function doAccountNameVerification(payload: any): Promise<any | null> {
  // const { data }: AxiosResponse<any> = await axiosInstanceNameEnquire.post(`/camel/api/name-enquiry`, payload);
  const { data }: AxiosResponse<any> = await axiosInstance2.post(
    `/services/bankingservice/api/bankingservice/nip/name-enquiry`,
    payload
  );

  return data;
}

//

async function requestOnboardingStepBankDetails(
  values: any
): Promise<any | null> {
  const orgId: string | null = FetchAndUnmask("orgId");
  const token: string | null = FetchAndUnmask("token");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const { data }: AxiosResponse<any> = await axiosInstance2.post(
    `/services/organization/api/onboarding-step-two/organizations/${orgId}/banks`,
    values
  );

  return data;
}

export function useGetBankInstitutions(): any {
  return useMutation(() => getBankInstitutions(), {
    onSuccess: (res) => {
      // // SuccessToast("Your request was successful");
    },
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

async function requestOnboardingStepUserCreationDetails(
  values: any
): Promise<any | null> {
  const token: string | null = FetchAndUnmask("token");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const orgId: string | null = FetchAndUnmask("orgId");
  const { data }: AxiosResponse<any> = await axiosInstance2.post(
    `/services/organization/api/organizations/requests/initiate-user-request?type=user_administration`,
    values
  );

  //console.log(data, "eeeeeeeeeeee");

  return data;
}

async function requestOnboardingStepUserCreationDetailsNew(
  values: any
): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance2.post(
    `/services/organization/api/organizations/users`,
    values
  );

  //console.log(data, "eeeeeeeeeeee");

  return data;
}

async function requestOnboardingActivateUser(values: any): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance2.post(
    `/api/resend-activation-key?email=${values}`,
    values
  );

  //console.log(data, "eeeeeeeeeeee");

  return data;
}

async function getRequestOnboarding(): Promise<any | null> {
  const token: string | null = FetchAndUnmask("token");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const orgId: string | null = FetchAndUnmask("orgId");
  const { data }: AxiosResponse<any> = await axiosInstance2.get(
    `/services/organization/api/onboarding-step-three/organizations/${orgId}/onboarding-data`
  );
  return data;
}

async function getProcessTypes(): Promise<any | null> {
  const token: string | null = FetchAndUnmask("token");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const { data }: AxiosResponse<any> = await axiosInstance2.get(
    `/services/organization/api/request-types`
  );
  return data;
}

async function getResource(): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance2.get(
    `/api/resources`
  );
  return data;
}

async function requestOnboardingStepUserWorkFlowProcess(
  values: any
): Promise<any | null> {
  const orgId: string | null = FetchAndUnmask("orgId");
  const { data }: AxiosResponse<any> = await axiosInstance2.post(
    `/services/organization/api/onboarding-step-three/organizations/${orgId}/workflows`,
    values
  );

  return data;
}

async function requestOnboardingFinal(): Promise<any | null> {
  const orgId: string | null = FetchAndUnmask("orgId");
  const { data }: AxiosResponse<any> = await axiosInstance2.post(
    `/services/organization/api/organizations/requests/initiate-mda-request/${orgId}`,
    {}
  );

  return data;
}

async function getMdaAuditTrail(filters: any): Promise<any | null> {
  const orgId: string | null = FetchAndUnmask("orgId");
  const token: string | null = FetchAndUnmask("token");
  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const { data }: AxiosResponse<any> = await axiosInstance2.get(
    `/services/auditservice/api/audit-trail/${orgId}/date/mda-trail?${filters}`
  );

  return data;
}

async function getOagfAuditTrail(filters: any): Promise<any | null> {
  const orgId: string | null = FetchAndUnmask("orgId");
  const token: string | null = FetchAndUnmask("token");
  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const { data }: AxiosResponse<any> = await axiosInstance2.get(
    `/services/auditservice/api/audit-trail/date/OAgf-trail?${filters}`
  );
  return data;
}

async function getMdaDetails(orgId: string | null): Promise<any | null> {
  const token: string | null = FetchAndUnmask("token");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const { data }: AxiosResponse<any> = await axiosInstance2.get(
    `/services/organization/api/onboarding-step-three/organizations/${orgId}/onboarding-data`
  );
  return data;
}

async function getMdaPayables(res: any | null): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance2.get(
    `/services/organization/api/organizations/requests/payable-due?overdue=${res}`
  );
  return data;
}

async function getMdaPayablesData(res: any | null): Promise<any | null> {
  const orgId: string | null = FetchAndUnmask("orgId");
  const { data }: AxiosResponse<any> = await axiosInstance2.get(
    `/services/accountingservice/api/get-payable-view/${orgId}/${res}`
  );
  //`services/organization/api/organizations/requests/payment-report?${res}` get-payable-view
  return data;
}

async function getOagfPayablesData(res: any | null): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance2.get(
    `/services/accountingservice/api/get-payable-view/${res}`
  );

  return data;
}

async function getOagfPaymentView(urlValues: any | null): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance2.get(
    // `/services/accountingservice/api/get-payment-view/analytics/${urlValues.startDate}/${urlValues.endDate}`
    `/services/organization/api/payment-view-oagf?endDate=${urlValues.endDate}&startDate=${urlValues.startDate}`
  );

  return data;
}

async function activateMda(orgId: string | null): Promise<any | null> {
  const token: string | null = FetchAndUnmask("token");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const { data }: AxiosResponse<any> = await axiosInstance2.post(
    `services/organization/api/onboarding-step-two/organizations/${orgId}/activate`
  );
  return data;
}

async function getMdaRecievables(): Promise<any | null> {
  const orgId: string | null = FetchAndUnmask("orgId");
  const { data }: AxiosResponse<any> = await axiosInstance2.get(
    `/services/accountingservice/api/mda/${orgId}/analytics/receivables?size=10000`
  );
  return data;
}
async function getOagfRecievables(): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance2.get(
    `/services/accountingservice/api/oagf/analytics/receivables?size=10000`
  );
  return data;
}

async function getPayables(res: string | null): Promise<any | null> {
  const { data }: AxiosResponse<any> = await axiosInstance2.get(
    `services/organization/api/organizations/requests/payment-report${res}`
  );
  return data;
}

async function getCollections(res: string | null): Promise<any | null> {
  const { data, headers }: AxiosResponse<any> = await axiosInstance2.get(
    `/services/accountingservice/api/analytics/inflows/${res}`
  );
  return { data, totalSize: headers["x-total-count"] };
}

async function getOagfCollections(res: string | null): Promise<any | null> {
  const { data, headers }: AxiosResponse<any> = await axiosInstance2.get(
    `/services/accountingservice/api/oagf/analytics/inflows/${res}`
  );
  return { data, totalSize: headers["x-total-count"] };
}

async function getCollectionAnalytics(values: any): Promise<any | null> {
  const token: string | null = FetchAndUnmask("token");
  const orgId: string | null = FetchAndUnmask("orgId");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const { data }: AxiosResponse<any> = await axiosInstance2.get(
    `/services/accountingservice/api/analytics/${orgId}/${values.startDate}/${values.endDate}`
  );
  return data;
}

async function getCollectionAnalyticsServices(
  values: any
): Promise<any | null> {
  const token: string | null = FetchAndUnmask("token");
  const orgId: string | null = FetchAndUnmask("orgId");

  axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const { data }: AxiosResponse<any> = await axiosInstance2.get(
    `/services/accountingservice/api/analytics/services/top-ten/${orgId}/${values.startDate}/${values.endDate}`
  );
  return data;
}

interface UseUser {
  user: any | null;
  updateUser: (user: any) => void;
  clearUser: () => void;
  isLoading: any;
}

export function useGetRequestOnboarding(): any {
  return useMutation(() => getRequestOnboarding(), {
    onSuccess: (res) => {
      // // SuccessToast("Your request was successful");
    },
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useMdaDetails(): any {
  return useMutation((orgId: any) => getMdaDetails(orgId), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useMdapayables(): any {
  return useMutation((res: any) => getMdaPayables(res), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useMdaPayablesData(): any {
  return useMutation((res: any) => getMdaPayablesData(res), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useOagfpayablesData(): any {
  return useMutation((res: any) => getOagfPayablesData(res), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function usePayablesDue(): any {
  async function getPayablesDue(res: any | null): Promise<any | null> {
    const { data }: AxiosResponse<any> = await axiosInstance2.get(
      `/services/organization/api/organizations/requests/payable-due/?due=true`
    );

    return data;
  }

  return useMutation((res: any) => getPayablesDue(res), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function usePayablesOverdue(): any {
  async function getPayables(res: any | null): Promise<any | null> {
    const { data }: AxiosResponse<any> = await axiosInstance2.get(
      `/services/organization/api/organizations/requests/payable-due/?overdue=true`
    );
    return data;
  }

  return useMutation((res: any) => getPayables(res), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function usePayablesReportDue(): any {
  async function getPayables(res: any | null): Promise<any | null> {
    const { data }: AxiosResponse<any> = await axiosInstance2.get(
      `/services/organization/api/organizations/requests/payable-report?status=AWAITINGAPPROVAL&page=1&size=10000&due=true`
    );
    return data;
  }

  return useMutation((res: any) => getPayables(res), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function usePayablesReportOverdue(): any {
  async function getPayables(res: any | null): Promise<any | null> {
    const { data }: AxiosResponse<any> = await axiosInstance2.get(
      `/services/organization/api/organizations/requests/payable-report?status=AWAITINGAPPROVAL&page=1&size=1000&overdue=true`
    );
    return data;
  }

  return useMutation((res: any) => getPayables(res), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useOagfPaymentView(): any {
  return useMutation((urlValues: any) => getOagfPaymentView(urlValues), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useOagfPaymentPie(): any {
  async function getOagfPaymentPie(urlValues: any | null): Promise<any | null> {
    const { data }: AxiosResponse<any> = await axiosInstance2.get(
      `/services/accountingservice/api/payment-view/payable/pie-chart/${urlValues.startDate}/${urlValues.endDate}`
    );

    return data;
  }

  return useMutation((urlValues: any) => getOagfPaymentPie(urlValues), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useDoAccountNameVerification(): any {
  return useMutation((payload) => doAccountNameVerification(payload), {
    onSuccess: (res) => {
      SuccessToast("Name Enquiry is successful");
    },
    onError: (err: any) => {
      //  ErrorHandler(err);
    },
  });
}

export function useOagfPaymentBar(): any {
  async function getOagfPaymentBar(urlValues: any | null): Promise<any | null> {
    const { data }: AxiosResponse<any> = await axiosInstance2.get(
      `/services/accountingservice/api/payment-view/approved-payments/bar-chart/${urlValues.startDate}/${urlValues.endDate}`
    );

    return data;
  }

  return useMutation((urlValues: any) => getOagfPaymentBar(urlValues), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useOagfPayables(): any {
  async function getOagfPayables(urlValues: any | null): Promise<any | null> {
    const { data }: AxiosResponse<any> = await axiosInstance2.get(
      `/services/accountingservice/api/payment-view/approved-payments/bar-chart/${urlValues.startDate}/${urlValues.endDate}`
      //https://api-web.fgntreasury.gov.ng/services/accountingservice/api/payment-view/:status/:startDate/:endDate
    );

    return data;
  }

  return useMutation((urlValues: any) => getOagfPayables(urlValues), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useOagfPaymentStatus(): any {
  async function getOagfPaymentStatus(
    urlValues: any | null
  ): Promise<any | null> {
    const { data }: AxiosResponse<any> = await axiosInstance2.get(
      //`/services/accountingservice/api/payment-view/${urlValues.status}/${urlValues.startDate}/${urlValues.endDate}`
      `/services/organization/api/organizations/requests/payment-report/?start-date=${urlValues.startDate}&end-date=${urlValues.endDate}&status=${urlValues.status}&page=1&size=10000`
    );

    return data;
  }

  return useMutation((urlValues: any) => getOagfPaymentStatus(urlValues), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useMdaActivate(): any {
  return useMutation((orgId: any) => activateMda(orgId), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}


export function useActivateUser(): any {
  async function activateMda(payload): Promise<any | null> {
    const token: string | null = FetchAndUnmask("token");

    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const { data }: AxiosResponse<any> = await axiosInstance.post(
      `/api/admin/users/${payload.login}/activate/${payload.isActive}`
    );
    return data;
  }
  return useMutation((payload) => activateMda(payload), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}


export function useMdaRecievables(): any {
  return useMutation(() => getMdaRecievables(), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useOagfRecievables(): any {
  return useMutation(() => getOagfRecievables(), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useGetPayables(): any {
  return useMutation((res: any) => getPayables(res), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useGetCollections(): any {
  return useMutation((res: any) => getCollections(res), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useOagfGetCollections(): any {
  return useMutation((res: any) => getOagfCollections(res), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}
export function useCollectionAnalytics(): any {
  return useMutation((res: any) => getCollectionAnalytics(res), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useCollectionAnalyticsServices(): any {
  return useMutation((data: any) => getCollectionAnalyticsServices(data), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useOagfCollectionAnalytics(): any {
  async function getOagfCollectionAnalytics(values: any): Promise<any | null> {
    const token: string | null = FetchAndUnmask("token");

    axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const { data }: AxiosResponse<any> = await axiosInstance2.get(
      `/services/accountingservice/api/oagf/analytics/${values.startDate}/${values.endDate}`
    );
    return data;
  }
  return useMutation((data: any) => getOagfCollectionAnalytics(data), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useOagfCollectionAnalyticsServices(): any {
  async function getOagfCollectionAnalyticsServices(
    values: any
  ): Promise<any | null> {
    const token: string | null = FetchAndUnmask("token");

    axiosInstance2.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const { data }: AxiosResponse<any> = await axiosInstance2.get(
      `/services/accountingservice/api/oagf/analytics/services/top-ten/${values.startDate}/${values.endDate}`
    );
    return data;
  }

  return useMutation((data: any) => getOagfCollectionAnalyticsServices(data), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useUser(): UseUser {
  const queryClient = useQueryClient();

  const {
    data: user,
    isError,
    isLoading,
    error,
  } = useQuery(queryKeys.user, () => getUser(user), {
    initialData: getStoredUser,
    onSuccess: (recieved: any | null) => {
      if (!recieved) {
        clearStoredUser();
      } else {
        setStoredUser(recieved);
      }
    },
  });

  function updateUser(newUser: any): void {
    queryClient.setQueryData(queryKeys.user, newUser);
  }

  function clearUser() {
    queryClient.setQueriesData(queryKeys.user, null);
  }

  return { user, updateUser, clearUser, isLoading };
}

export function useRequestForgotPassword(): any {
  return useMutation((value) => requestForgotPassword(value), {
    onSuccess: (res) => {
      // // SuccessToast("Your request was successful");
    },
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useRequestOnboardingStepOne(): any {
  return useMutation((value) => requestOnboardingStepOne(value), {
    onSuccess: (res) => {
      // SuccessToast("Your request was successful");
    },
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useRequestOnboardingStepTwo(): any {
  return useMutation((value) => requestOnboardingStepTwo(value), {
    onSuccess: (res) => {
      // SuccessToast("Your request was successful");
    },
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useRequestOnboardingSendVerificatoinCode(): any {
  return useMutation((value) => requestOnboardingSendVerificatoinCode(value), {
    onSuccess: (res) => {
      // SuccessToast("Your request was successful");
    },
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useRequestOnboardingStepentitydetails(): any {
  return useMutation((value) => requestOnboardingStepentitydetails(value), {
    onSuccess: (res) => {
      //console.log(res.id);

      MaskAndSave("orgId", res.id);

      // SuccessToast("Your request was successful");
    },
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useRequestOnboardingStepBankDetails(): any {
  return useMutation((value) => requestOnboardingStepBankDetails(value), {
    onSuccess: (res) => {
      // SuccessToast("Your request was successful");
    },
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useRequestOnboardingStepUserCreationDetails(): any {
  return useMutation(
    (value) => requestOnboardingStepUserCreationDetails(value),
    {
      onSuccess: (res) => {
        // SuccessToast("Your request was successful");
      },
      onError: (err: any) => {
        ErrorHandler(err);
      },
    }
  );
}

export function useRequestOnboardingStepUserCreationDetailsNew(): any {
  return useMutation(
    (value) => requestOnboardingStepUserCreationDetailsNew(value),
    {
      onSuccess: (res) => {
        // SuccessToast("Your request was successful");
      },
      onError: (err: any) => {
        ErrorHandler(err);
      },
    }
  );
}

export function useRequestActivateUser(): any {
  return useMutation((value) => requestOnboardingActivateUser(value), {
    onSuccess: (res) => {
      // SuccessToast("Your request was successful");
    },
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useRequestOnboardingStepUserWorkFlowProcess(): any {
  return useMutation(
    (value) => requestOnboardingStepUserWorkFlowProcess(value),
    {
      onSuccess: (res) => {
        // SuccessToast("Your request was successful");
      },
      onError: (err: any) => {
        ErrorHandler(err);
      },
    }
  );
}

export function useRequestOnboardingfinal(): any {
  return useMutation(() => requestOnboardingFinal(), {
    onSuccess: (res) => {
      // SuccessToast("Your request was successful");
    },
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useVerificationcode(): any {
  return useMutation((value) => verificationcode(value), {
    onSuccess: (res) => {
      // SuccessToast("Your request was successful");

      MaskAndSave("token", res.data.token);
    },
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useVerificationSinglecode(): any {
  //verificationSinglecode
  return useMutation((value) => verificationSinglecode(value), {
    onSuccess: (res) => {
      // SuccessToast("Your request was successful");

      // MaskAndSave("token", res.data.token);
    },
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useGetUserBankDetails(): any {
  return useMutation(() => getUserBankDetails(), {
    onSuccess: (res) => {
      // toast.success('Your request was successful', {
      //   position: toast.POSITION.TOP_CENTER,
      //   className: 'fs-8',
      // });
    },
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useGetGLAccount(): any {
  async function getGlList(): Promise<any | null> {
    const { data }: AxiosResponse<{ user: any }> = await axiosInstance2.get(
      `/services/accountingservice/api/chart-of-accounts`
    );
    return data;
  }

  return useMutation(() => getGlList(), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useGetLedgerAccount(): any {
  async function getLedgerList(name: string): Promise<any | null> {
    //accountingservice/api/ledger-accounts
    const { data }: AxiosResponse = await axiosInstance2.get(
      `/services/accountingservice/api/gl-account?name=${name}`
    );

    return data;
  }

  return useMutation((name: string) => getLedgerList(name), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useGetLedgerAccounts(): any {
  async function getLedgerList(filter: any): Promise<any | null> {
    const { data, headers }: AxiosResponse<any> = await axiosInstance2.get(
      `/services/accountingservice/api/ledger-accounts?${filter}`
    );
    return { data, totalSize: headers["x-total-count"] };
  }

  return useMutation((filter: string) => getLedgerList(filter), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useGetOrgUserFLow(): any {
  return useMutation(() => getOrgUserFLow(), {
    onSuccess: (res) => {
      // toast.success('Your request was successful', {
      //   position: toast.POSITION.TOP_CENTER,
      //   className: 'fs-8',
      // });
    },
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}


export function useGetMdas(): any {
  return useMutation(() => getMda(), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useGetProcessTypes(): any {
  return useMutation(() => getProcessTypes(), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useGetResources(): any {
  return useMutation(() => getResource(), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useCreateProject(): any {
  async function createProject(values): Promise<any | null> {
    const { data }: AxiosResponse<any> = await axiosInstance2.post(
      `/services/organization/api/projects`,
      values
    );
    return data;
  }

  return useMutation((values) => createProject(values), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useGetUsersDetails(): any {
  return useMutation(() => getUsersDetails(), {
    onSuccess: (res) => {
      // toast.success('Your request was successful', {
      //   position: toast.POSITION.TOP_CENTER,
      //   className: 'fs-8',
      // });
    },
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useRequestSignin(): any {
  const { clearUser, updateUser } = useUser();
  const navigate = useNavigate();

  return useMutation((value) => requestSignin(value), {
    onSuccess: (res) => {
      // SuccessToast("Your request was successful");

      // if (res.status === 200) {
      //   updateUser(res.data);

      //   MaskAndSave("token", res.data.idToken);

      //   MaskAndSave("orgId", res.data.id);

      //   navigate("/admin", { replace: true });
      // }
    },
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useResetForgotPassword(): any {
  const navigate = useNavigate();

  return useMutation((value) => resetPassword(value), {
    onSuccess: (res) => {
      // SuccessToast("Your request was successful");
      navigate("/auth/login");
    },
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

//createService

export function useCreateService(): any {
  return useMutation((details: any) => createService(details), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

//get Service Details

export function useServiceDetails(): any {
  return useMutation((id: string) => getServiceDetails(id), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

//getServiceDetailsWithOrgIdOagf
export function useServiceDetailsWithOrgId(): any {
  return useMutation(
    (data: { orgId: string; serviceId: string }) =>
      getServiceDetailsWithOrgIdOagf(data.orgId, data.serviceId),
    {
      onSuccess: (res) => {},
      onError: (err: any) => {
        ErrorHandler(err);
      },
    }
  );
}

//update or edit service

export function useUpdateService(): any {
  return useMutation(
    (data: any) => updateServiceDetails(data?.serviceId, data?.values),
    {
      onSuccess: (res) => {},
      onError: (err: any) => {
        ErrorHandler(err);
      },
    }
  );
}

//mda audit trail
export function useMdaTrail(): any {
  return useMutation((filter) => getMdaAuditTrail(filter), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

//oagf audit trail
export function useOagfTrail(): any {
  return useMutation((filter) => getOagfAuditTrail(filter), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useUserService(): any {
  const queryClient = useQueryClient();

  function updateService(newUser: any): void {
    queryClient.setQueryData(queryKeys.userService, newUser);
  }

  return { updateService };
}

// export function usegETsERVICE(): any {
//   return useMutation((value) => requestSignin(value), {
//     onSuccess: (res) => {
//       // useQueryClient().setQueryData("servieList", res);
//     },
//     onError: (err: any) => {
// if (err.response?.status === 401) {
//     // Handle the unauthorized error for this specific query
//     window.location.href = "/auth/login";
// }
//       let message = "";

//       if (err.response) {
//         const errMessage = err.response.data;
//         message = errMessage.message
//           ? errMessage.message
//           : "Something went wrong.";
//       } else {
//         message = err.message;
//       }

//       toast.error(message, {
//         position: toast.POSITION.TOP_CENTER,
//         className: "fs-8",
//       });
//     },
//   });
// }

export function useRequestGetServices(): any {
  const queryClient = useQueryClient();

  return useMutation(() => getServices(), {
    onSuccess: (res) => {
      queryClient.setQueryData("serviceList", res);
    },
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

// export function useInactiveService(): any {
//   const queryClient = useQueryClient();

//   const {
//     data: inactiveService,
//     isError,
//     isLoading,
//     error,
//   } = useQuery(queryKeys.mdaList, () => getInactiveService());

//   return { inactiveService, isLoading, error };
// }

export function useInactiveService(): any {
  const queryClient = useQueryClient();

  return useMutation(() => getInactiveService(), {
    onSuccess: (res) => {
      // queryClient.setQueryData("serviceList", res);
    },
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useAllService(): any {
  const queryClient = useQueryClient();

  return useMutation(() => getAllService(), {
    onSuccess: (res) => {
      // queryClient.setQueryData("serviceList", res);
    },
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useGetLogOut(): any {
  return useMutation(() => getLogout(), {
    onSuccess: (res) => {
      // queryClient.setQueryData("serviceList", res);
    },
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useGetUserList(): any {
  return useMutation((value) => getUserList(value), {
    onSuccess: (res) => {
      // queryClient.setQueryData("serviceList", res);
    },
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useGetAllUserList(): any {
  async function getAllUserList(value): Promise<any | null> {
    const { data }: AxiosResponse<any> = await axiosInstance2.get(
      `/services/organization/api/organizations-users${
        value && `?name=${value}`
      }`
    );

    return data;
  }

  return useMutation((value) => getAllUserList(value), {
    onSuccess: (res) => {
      // queryClient.setQueryData("serviceList", res);
    },
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useGetRoleList(): any {
  return useMutation(() => getRoleList(), {
    onSuccess: (res) => {
      // queryClient.setQueryData("serviceList", res);
    },
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useGetUserRole(): any {
  return useMutation((values) => getUserRole(values), {
    onSuccess: (res) => {
      // queryClient.setQueryData("serviceList", res);
    },
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useSetRole(): any {
  return useMutation((values) => setUserRole(values), {
    onSuccess: (res) => {
      // queryClient.setQueryData("serviceList", res);
    },
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useEditUser(): any {
  return useMutation((values) => editUser(values), {
    onSuccess: (res) => {
      // queryClient.setQueryData("serviceList", res);
    },
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useEditRole(): any {
  return useMutation((values) => editRoleUser(values), {
    onSuccess: (res) => {
      // queryClient.setQueryData("serviceList", res);
    },
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useCreateRole(): any {
  return useMutation((values) => CreateRoleUser(values), {
    onSuccess: (res) => {
      // queryClient.setQueryData("serviceList", res);
    },
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useAddResources(): any {
  return useMutation((values) => addResources(values), {
    onSuccess: (res) => {
      // queryClient.setQueryData("serviceList", res);
    },
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useEditResources(): any {
  return useMutation((values) => editResources(values), {
    onSuccess: (res) => {
      // queryClient.setQueryData("serviceList", res);
    },
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useOagfCreateNewBank(): any {
  const orgId: string | null = FetchAndUnmask("orgId");
  async function createNewBank(values): Promise<any | null> {
    const { data }: AxiosResponse<any> = await axiosInstance2.post(
      `/services/organization/api/organizations/oagf/${orgId}/banks`,
      values
    );
    return data;
  }

  return useMutation((values) => createNewBank(values), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}

export function useGetBanks(): any {
  async function getBank(filter: any): Promise<any | null> {
    const { data, headers }: AxiosResponse<any> = await axiosInstance2.get(
    `/services/organization/api/organizations/banks/details?${filter}`
    );
    return { data, totalSize: headers["x-total-count"] };
  }
  return useMutation((filter: string) => getBank(filter), {
    onSuccess: (res) => {},
    onError: (err: any) => {
      ErrorHandler(err);
    },
  });
}