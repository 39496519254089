import { AboutPage } from 'app/components/about';
import { Footer } from 'app/components/footer';
import { Hero } from 'app/components/Hero.tsx';
import NavBar from 'app/components/nav/NavBar';
import { Service } from 'app/components/service';
import { chat } from 'core/assets/icons';
import { arrowRight, envelope } from 'core/assets/imgs';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useRequestSubscription } from '../payments/hooks/useRevenuePayment';

const profileDetailsSchema = Yup.object().shape({
    email: Yup.string().email('Wrong email format').required('Email is required'),
});

const initialValues = {
    email: '',
};

const HomePage: React.FC = () => {
    const navigate = useNavigate();

    // const [loading, setLoading] = useState(false);
    // const [data, setData] = useState<any>(initialValues);
    const [show, setShow] = useState<any>(false);

    React.useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, []);

    // const [arrow, setArrow] = useState(true);
    // const [FaqNo, setFaqNo] = useState<Number>(0);
    // const handleFaq = (index: Number) => {
    //     if (index === FaqNo && arrow) {
    //         setArrow(false);
    //     } else {
    //         setArrow(true);
    //         setFaqNo(index);
    //     }
    // };

    const done = () => {
        navigate('/auth/register');
    };

    const { mutate, isLoading } = useRequestSubscription();

    // useEffect(() => {
    //   if (subData) {
    //     setShow(true);
    //   }
    // }, [mutate, subData]);

    const formik = useFormik({
        initialValues,
        validationSchema: profileDetailsSchema,
        onSubmit: (values) => {
            //console.log(values);

            mutate(values);

           // setLoading(true);
            // setTimeout(() => {
            //     setLoading(false);
            // }, 1000);
        },
    });

    const FloatingButton = () => {
        return (
            <div className="floating-button-container">
                <button className="floating-button">
                    <img src={chat} alt="" className="w-25e h-25e" />
                </button>
            </div>
        );
    };

    const PageModal = () => {
        return (
            <Modal
                dialogClassName=" m-auto px-9 modal-card"
                aria-hidden="true"
                show={show}
                onHide={() => setShow(false)}
                animation
                centered
                backdrop={'static'}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Body className="rounded">
                    <div className="d-flex flex-column align-items-center m-10">
                        <article className="about-img d-flex justify-content-center align-items-center">
                            <iframe
                                className="hero-photo-video"
                                width="90%"
                                height="304px"
                                src="https://www.youtube.com/embed/zH1HtWW_3-Q"
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; "
                            ></iframe>
                        </article>
                    </div>
                </Modal.Body>
            </Modal>
        );
    };
    // const faq = [
    //     {
    //         title: 'Why use TMS?',
    //         desc: 'The Treasury Portal is an application that provides ease of payments for government services through multiple payment channels for your convenience.',
    //     },
    //     {
    //         title: 'How to pay for school fees?',
    //         desc: 'To make fees payment or any other payment, please go to Revenue Payment, search for the MDA and select service.',
    //     },
    //     {
    //         title: 'Do I have to register to use TMS?',
    //         desc: 'No. You can go ahead to make payment for a services through the provided options without registering. Once you have made your first payment, you can carry out subsequent payment using same details.',
    //     },
    // ];
    return (
        <div>
            <NavBar />
            <Hero />
            <Service />
            <AboutPage />

            <div className="section bg-grey">
                <div className="section-center d-flex flex-column align-items-center  justify-content-center">
                    <span className="text-black fs-2x fw-bolder w-75 text-center flex-wrap  ">
                        Efficient and Reliable Management of the Federal Republic of Nigeria’s Treasury
                    </span>

                    <button className="btn btn-success rounded-1 home-btn my-10" onClick={done}>
                        <span className="fs-5">
                            Get Started
                            <img src={arrowRight} className="icon-style ms-2" alt="arrowRight" />
                        </span>
                    </button>

                    <div className="help-center-hero">
                        <div className="footer--center-bottom">
                            <div className="foot-news-letter">
                                <div className="right-side">
                                    <div>
                                        <span className="subcriber-pool">Join Our Pool of Subscribers</span>
                                        <span className="subcriber-pool-body">
                                            Stay in the loop with everything you need to know.
                                        </span>
                                    </div>
                                </div>
                                <div className="news-letter-container">
                                    <div>
                                        <span className="span-header mb-2">Subscribe to our Newsletter</span>
                                    </div>
                                    <form
                                        onSubmit={formik.handleSubmit}
                                        noValidate
                                        className="form align-middle  gx-0 gy-5 mt-5"
                                    >
                                        <div className="col-12  flex-small align-items-center ">
                                            <div className="flex-col">
                                                <div className="form-control form-control-solid ">
                                                    <img src={envelope} alt="Email" className="icon-style" />
                                                    <input
                                                        type="text"
                                                        placeholder="Email"
                                                        className={'no-outline ps-4 text-dark fs-8'}
                                                        {...formik.getFieldProps('email')}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex-subscribe">
                                                <button className="btn btn-footer py-3 px-8" type="submit">
                                                    {!isLoading && (
                                                        <span className="indicator-label text-dark fs-6 fw-bold">
                                                            Subscribe
                                                        </span>
                                                    )}
                                                    {isLoading && (
                                                        <span className="indicator-progress">
                                                            Subscribe...
                                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                        </span>
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                    {/* <span className="subscribe-policy-body">
                    We care about your data in our <span> Privacy Policy</span>
                  </span> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            {PageModal()}
            {FloatingButton()}
        </div>
    );
};

export { HomePage };
